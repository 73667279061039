import React, { useState, useEffect, useCallback } from 'react'
import { StyleSheet, ScrollView } from 'react-native'
import { bytesToSize } from 'src/utils/documentHelpers'
import {
  DNABox,
  DNAText,
  DNAButton,
} from '@alucio/lux-ui'
import { TabView, Tab } from '@ui-kitten/components'
import loggers from 'src/utils/logger'

const S = StyleSheet.create({
  tabView: {
    flex: 1,
  },
  tabTitle: {
    paddingVertical: 5,
  },
  tabContainer: {
    padding: 20,
  },
});

const GeneralDebug: React.FC = () => {
  const [loggingStats, setLoggingStats] = useState<string>()
  const [selectedIdx, setSelectedIdx] = useState(0)
  const [memoryUsage, setMemoryUsage] = useState<{
    heapSize: string,
    usedHeapSize: string,
    heapSizeLimit: string,
  }>({
    heapSize: 'N/A',
    usedHeapSize: 'N/A',
    heapSizeLimit: 'N/A',
  })

  useEffect(
    () => {
      // @ts-expect-error - Deprecated API
      if (performance.memory) {
        const intervalId = setInterval(
          () => {
            // @ts-expect-error - Deprecated API
            const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } = performance.memory
            setMemoryUsage({
              heapSize: bytesToSize(totalJSHeapSize),
              usedHeapSize: bytesToSize(usedJSHeapSize),
              heapSizeLimit: bytesToSize(jsHeapSizeLimit),
            })
          },
          15000,
        )

        return () => clearInterval(intervalId)
      }
    },
    [],
  )

  const getLoggingStats = useCallback(
    () => {
      const loggerStats = {}

      // [TODO] - Get individual nested logger stats
      Object
        .values(loggers)
        .forEach((logger) => {
          // @ts-expect-error - Not all loggers are Keyed types just yet
          if (logger.stats) {
            // @ts-expect-error - Not all loggers are Keyed types just yet
            loggerStats[logger.name] = logger?.stats?.getStats()
          }
        })

      if (Object.keys(loggerStats)) {
        setLoggingStats(JSON.stringify(loggerStats, null, 2))
      }
    },
    [],
  )

  const crashTheApp = () => {
    loggers.loggerMachine.error(
      'Crash the app button pressed',
      'App has been crashed and email notification has been sent',
      { details: 'Crash the app button details, you should not see me in email notification' },
    )
    throw new Error('Crash the app button pressed')
  }

  return (
    <DNABox>
      <TabView
        selectedIndex={selectedIdx}
        onSelect={setSelectedIdx}
        style={S.tabView}
      >
        {/* GENERAL TAB */}
        <Tab title="General" style={S.tabTitle}>
          <DNABox fill appearance="col" style={S.tabContainer}>
            <DNAText>
              <DNAText bold>
                Build Run:&nbsp;
              </DNAText> (Have CI Inject)
            </DNAText>
            <DNAText>
              <DNAText bold>
                Build Commit SHA:&nbsp;
              </DNAText>
              (Have CI Inject)
            </DNAText>
            <DNAText>
              <DNAText bold>
                Build Date:&nbsp;
              </DNAText>
              (Have CI Inject)
            </DNAText>
            <DNAText bold>Current Reachability: (TODO)</DNAText>
            <DNAText>
              <DNAText bold>Memory Usage (Chromium Only): &nbsp;</DNAText>
              {JSON.stringify(memoryUsage, null, 2)}
            </DNAText>
          </DNABox>
        </Tab>

        {/* LOGGING TAB */}
        <Tab title="Logging" style={S.tabTitle}>
          <DNABox fill appearance="col" style={S.tabContainer}>
            <DNABox as={ScrollView} appearance="col" spacing="sm">
              {/* BUTTONS */}
              <DNAButton disabled={true}>
                Generate Arbitrary Logs (TODO: Generate N logs of random sizes)
              </DNAButton>
              <DNAButton onPress={getLoggingStats}>
                Get Session Stats
              </DNAButton>

              <DNAButton status="danger" onPress={crashTheApp}>
                Crash the app
              </DNAButton>

              {/* INFO */}
              <DNAText bold>Logging Machine Status (TODO)</DNAText>
              <DNAText bold>Logging Stats:&nbsp;<DNAText>{loggingStats}</DNAText></DNAText>
            </DNABox>
          </DNABox>
        </Tab>

        {/* SERVICE WORKER TAB */}
        <Tab title="Service Worker" style={S.tabTitle}>
          <DNABox fill appearance="col" style={S.tabContainer}>
            <DNAText>Installed Version</DNAText>
            <DNAText>Number of Startups</DNAText>
          </DNABox>
        </Tab>
      </TabView>
    </DNABox>
  )
}

export default GeneralDebug
