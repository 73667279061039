import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { ScrollView } from 'react-native';
import { useInterpret } from '@xstate/react';
import { v4 as uuid } from 'uuid';
import { Page } from '@alucio/aws-beacon-amplify/src/models';
import { DocumentVersionORM } from 'src/types/types';
import { useDispatch } from 'src/state/redux';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import useLazyRef from 'src/hooks/useLazyRef';
import useThumbnailSize, {
  ThumbnailSize,
  ThumbnailSizes,
  ThumbnailDimensions,
} from 'src/hooks/useThumbnailSize/useThumbnailSize';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { GroupStatus, PayloadGroup } from '../../state/PresentationBuilderStateProvider';
import { usePresentationBuilderState } from '../../state/PresentationBuilderStateProvider.proxy';
import { findReplacementSM } from 'src/state/machines/findReplacement/findReplacement.machine';
import { FindReplacementService } from 'src/state/machines/findReplacement/findReplacement.types';
import * as findReplacementSelector from 'src/state/machines/findReplacement/FindReplacement.selectors';
import { assignReplacementGroups } from 'src/state/machines/findReplacement/findReplacementUtils';
import CloseConfirmation from 'src/components/DNA/Modal/DNAPresentationBuilder/CloseConfirmation';

export interface FindReplacementStateType {
  service: FindReplacementService,
  closeModal: () => void,
  setActiveGroup: (id: string) => void,
  navPreviousSlide: () => void,
  navNextSlide: () => void,
  removeGroup: (id: string) => void,
  removeAllUnavailableGroups: () => void,
  replaceGroup: (
    pages: Page[],
    groupId: string,
    documentVersionORM: DocumentVersionORM,
    name?: string,
    locked?: boolean,
  ) => void,
  unreplaceGroup: () => void,
  thumbnailSize: ThumbnailSize,
  thumbnailDimensions: ThumbnailDimensions,
  cycleThumbnailSize: () => void,
  slideRollRef: React.MutableRefObject<ScrollView | null>,
  onSave: () => void,
}

export enum GroupDraftStatus {
  DELETED = 'DELETED',
  REVOKED = 'REVOKED',
  ARCHIVED = 'ARCHIVED',
  GROUP_REMOVED = 'GROUP_REMOVED',
  ACTIVE = 'ACTIVE',
  MAJOR_UPDATE = 'MAJOR_UPDATE',
  REPLACED = 'REPLACED',
}

export interface ReplaceGroupDraft {
  groupId: string,
  visible: boolean,
  documentVersionORM?: DocumentVersionORM,
  status: GroupDraftStatus,
  isGroup?: boolean,
  pages: Page[],
  groupReplacement?: PayloadGroup,
  name: string,
  locked: boolean,
}

export const UNAVAILABLE_DOCUMENT_STATUS = [
  GroupDraftStatus.DELETED,
  GroupDraftStatus.REVOKED,
  GroupDraftStatus.ARCHIVED,
]

const AVAILABLE_THUMBNAIL_SIZE = [ThumbnailSizes.lg, ThumbnailSizes.md];
const DEFAULT_THUMBNAIL_SIZE = ThumbnailSizes.lg;

export const FindReplacementStateContext = createContext<FindReplacementStateType | null>(null!);
FindReplacementStateContext.displayName = 'FindReplacementContext';

const FindReplacementStateProvider: React.FC<PropsWithChildren> = (props) => {
  const dispatch = useDispatch();
  const {
    customDeck,
    selectedGroups,
    activeReplacementGroup,
    handleModeChange,
    setActiveReplacementGroup,
    onApplyFindAndReplace,
    saveFindReplacement,
    setFindReplacementVisible,
  } = usePresentationBuilderState();

  const {
    thumbnailSize,
    cycleThumbnailSize,
    thumbnailDimensions,
  } = useThumbnailSize(AVAILABLE_THUMBNAIL_SIZE, DEFAULT_THUMBNAIL_SIZE)

  const initActiveGroup = activeReplacementGroup?.id;
  const slideRollRef = useRef<ScrollView | null>(null);

  /** STATE MACHINE */
  const machineInstance = useLazyRef(() => findReplacementSM.withContext({
    customDeckId: customDeck?.model.id,
    activeGroupId: initActiveGroup,
    replaceGroups: assignReplacementGroups(selectedGroups, true),
    isGroupDirty: false,
  }));

  const service = useInterpret(
    machineInstance.current!,
    // { devTools: true },
  );

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      findReplacementSelector.replaceGroups,
      findReplacementSelector.isGroupDirty,
      findReplacementSelector.isApplyReplacementSlidesState,
    ),
  )

  /** STATE MACHINE FUNCTIONS START */

  const setActiveGroup = useCallback((id: string) => {
    service.send({ type: 'SET_ACTIVE_GROUP', id });
  }, [service])

  const navPreviousSlide = useCallback(() => {
    service.send({ type: 'NAV_PREVIOUS_SLIDE' })
  }, [service])

  const navNextSlide = useCallback(() => {
    service.send({ type: 'NAV_NEXT_SLIDE' })
  }, [service])

  const removeGroup = useCallback((id: string) => {
    service.send({ type: 'UPDATE_GROUP_STATUS', id, newStatus: GroupDraftStatus.GROUP_REMOVED })
  }, [service])

  const removeAllUnavailableGroups = useCallback(() => {
    service.send({ type: 'REMOVE_ALL_UNAVALIABLE_SLIDE' })
  }, [service])

  const replaceGroup = useCallback((
    pages: Page[],
    groupId: string,
    documentVersionORM: DocumentVersionORM,
    name?: string,
    locked?: boolean,
  ) => {
    const payloadGroup: PayloadGroup = {
      id: uuid(),
      documentVersionORM,
      pages,
      groupSrcId: groupId,
      groupStatus: GroupStatus.ACTIVE,
      visible: true,
      docAccessLevel: documentVersionORM.relations.documentORM?.model.accessLevel!,
      name,
      locked,
    }
    service.send({ type: 'REPLACE_GROUP', payloadGroup })
  }, [service])

  const unreplaceGroup = useCallback(() => {
    service.send({ type: 'UNREPLACE_GROUP' })
  }, [service])

  const onSave = useCallback(() => {
    service.send({ type: 'APPLY_REPLACEMENT' })
  }, [service])

  /** STATE MACHINE FUNCTIONS END */

  /** USEEFFECT START */

  useEffect(() => {
    if (activeReplacementGroup) setActiveGroup(activeReplacementGroup.id)
  }, [activeReplacementGroup, setActiveGroup])

  // This is a trigger from the state machine to apply updates to the component side then transitions to the machine's final state
  // In the next useEffect where it will actually update the DataStore record
  useEffect(() => {
    if (cond.isApplyReplacementSlidesState) {
      const updatedGroups: PayloadGroup[] = [];
      cond.replaceGroups.forEach((replaceGroup) => {
        if (replaceGroup.status !== GroupDraftStatus.GROUP_REMOVED) {
          const currentGroup = selectedGroups.find(({ id }) => replaceGroup.groupId === id)!;

          if (replaceGroup.groupReplacement) {
            updatedGroups.push({ ...replaceGroup.groupReplacement, visible: currentGroup.visible });
          } else {
            updatedGroups.push(currentGroup);
          }
        }
      });
      const updatedPayloadGroups = onApplyFindAndReplace(updatedGroups);
      saveFindReplacement(updatedPayloadGroups, cond.replaceGroups, () => setFindReplacementVisible(false))
      service.send({ type: 'GO_TO_FINAL' })
    }
  }, [cond.isApplyReplacementSlidesState, onApplyFindAndReplace, saveFindReplacement])

  /** USEEFFECT END */

  /** CONTEXT FUNCTIONS START */

  const onCloseHandler = useCallback(() => {
    setActiveReplacementGroup(undefined);
    handleModeChange('customization');
    setFindReplacementVisible(false);
  }, [setActiveReplacementGroup, handleModeChange, setFindReplacementVisible])

  const openCloseConfirmation = useCallback(() => {
    dispatch(
      DNAModalActions.setModal(
        {
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => <CloseConfirmation {...props} onClose={onCloseHandler} />,
        },
      ),
    )
  }, [dispatch, onCloseHandler])

  /**
   * Fire when user click on the cancel button.
   */
  const closeModal = useCallback(() => cond.isGroupDirty ? openCloseConfirmation() : onCloseHandler(), [
    cond.isGroupDirty,
    openCloseConfirmation,
    onCloseHandler,
  ])

  /** CONTEXT FUNCTIONS END */

  const contextValue: FindReplacementStateType = {
    service,
    closeModal,
    setActiveGroup,
    navPreviousSlide,
    navNextSlide,
    removeGroup,
    removeAllUnavailableGroups,
    replaceGroup,
    unreplaceGroup,
    thumbnailSize,
    thumbnailDimensions,
    cycleThumbnailSize,
    slideRollRef,
    onSave,
  };

  return (
    <FindReplacementStateContext.Provider value={contextValue}>
      {props.children}
    </FindReplacementStateContext.Provider>
  );
};

FindReplacementStateProvider.displayName = 'FindReplacementStateProvider';

export const useFindReplacementState = () => {
  const context = useContext(FindReplacementStateContext);
  if (!context) {
    throw new Error('useFindReplacementState must be used within the FindReplacementStateProvider');
  }
  return context;
};

export default FindReplacementStateProvider;
