import React, { useCallback, useEffect } from 'react'
import { isFolderItemORM, isPageGroupORM } from 'src/types/typeguards';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import ROUTES, { DNARoute } from 'src/router/routeDef';
import { useLocation, useParams } from 'react-router';
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { useDispatch } from 'react-redux';
import { useDocumentORM, useDocumentVersionORM } from 'src/state/redux/selector/document';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import ForbiddenModal from '../Model/Forbidden';
import { DNAButton, DNABox, DNAText, useToast } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { copyDocPageUrlHandler } from 'src/utils/shareLink/shareLink.web';
import qs from 'qs';
import { useAppSettings } from 'src/state/context/AppSettings';

type validPagesIds =
  typeof ROUTES.LIBRARY.UID
  | typeof ROUTES.SEARCH_RESULTS.UID
  | typeof ROUTES.HOME.UID
  | typeof ROUTES.BOOKMARKS.UID

type RoutePageUrlType = {
  [x in validPagesIds]: () => string;
};

const DNA_ROUTE_PAGE_ID =
  (location, currentPage: DNARoute, docId: string, pageNumber: string) : RoutePageUrlType => ({
    [ROUTES.LIBRARY.UID]: () => {
      const search = location.search
      const replaceParams = ROUTES.BEACON_COPY_LINK.PATH
        .replace(':docId?', docId)
        .replace(':page?', pageNumber)

      const newUrl = `${replaceParams}${search}${location.hash}`;
      replaceUrlState(newUrl);
      return `${currentPage.PATH}${search}${location.hash}`
    },
    [ROUTES.HOME.UID]: () => {
      let search = location.search

      // if search contains ?code=, we want to remove it from the url due that comes from the sso and makes the copied url invalid
      // using qs check if the search contains the code parameter
      const searchWithoutCode = qs.parse(search, { ignoreQueryPrefix: true })
      if (searchWithoutCode.code) {
        delete searchWithoutCode.code
        search = qs.stringify(searchWithoutCode, { addQueryPrefix: true })
      }

      const replaceParams = ROUTES.BEACON_COPY_LINK_HOME.PATH
        .replace(':docId?', docId)
        .replace(':page?', pageNumber)

      const newUrl = `${replaceParams}${search}${location.hash}`;
      replaceUrlState(newUrl);

      return `${currentPage.PATH}${search}${location.hash}`
    },
    [ROUTES.BOOKMARKS.UID]: () => {
      const search = location.search
      const replaceParams = ROUTES.BEACON_COPY_LINK_BOOKMARKS.PATH
        .replace(':docId?', docId)
        .replace(':page?', pageNumber)

      const newUrl = `${replaceParams}${search}${location.hash}`;
      replaceUrlState(newUrl);
      return `${currentPage.PATH}${search}${location.hash}`
    },
    [ROUTES.SEARCH_RESULTS.UID]: () => {
      const search = location.search
      const searchText = location.pathname.split('/')[2]

      const replaceParams = currentPage.PATH
        .replace(':docId?', docId)
        .replace(':page?', pageNumber)
        .replace(':searchText', searchText)

      const newUrl = `${replaceParams}${search}${location.hash}`;
      replaceUrlState(newUrl);
      return `${currentPage.PATH.replace(':searchText', searchText)}${search}${location.hash}`
    },
  })

export const replaceUrlState = (newUrl: string | undefined) => {
  window.history.replaceState({}, '', newUrl)
}

export const useCPMPageRoute = () => {
  const { deviceMode } = useAppSettings()
  const dispatch = useDispatch()
  const { docId, page } = useParams<{ docId: string, page: string }>()
  const doc = useDocumentORM(docId)
  const currentPage = useCurrentPage()
  const status = doc?.model.status
  const isValidStatus = status && ['PUBLISHED'].includes(status)

  const onClosed  = useCallback(() => {
    if (!currentPage) return
    replaceUrlState(removeEmptyParams(currentPage.PATH))
  }, [currentPage])

  useEffect(() => {
    // if the doc id si not a valid id, we don't want to open the modal
    if (!docId || docId.match(/:docId\?{0,1}/)) return
    if (!currentPage) return

    // on tablet we dont have the home page, so we don't want to open the modal
    if (currentPage.UID === 'HOME' && deviceMode === 'tablet') {
      return
    }

    if (doc && page && isValidStatus) {
      const docVer = doc.relations.version.latestPublishedDocumentVersionORM
      dispatch(
        contentPreviewModalActions.setModalVisibility({
          documentVersionId: docVer?.model.id,
          isOpen: true,
          content: docVer,
          fromEllipsis: true,
          pageNumber: parseInt(page, 10),
        }),
      )
    }
    else {
      dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => (<ForbiddenModal
            {...props}
            onClosed={onClosed}
          />),
        }))
    }
  }, [docId])
}

export const removeEmptyParams = (url: string | undefined) => {
  return url?.split('/')
    // used to remove the optional parameters that doesn't have a value those that start with : and end with ?
    .filter(element => !/^:.*\?$/.test(element))
    .join('/')
}

/**
 * This hook updates the URL with the current document and page information when the active presentation changes.
 * It relies on side effects to update the URL and remove any empty parameters.
 */
export const useAddDocAndPageToUrl = () => {
  const { activePresentation } = useContent()
  const location = useLocation()
  const currentPage = useCurrentPage()

  useEffect(() => {
    if (!activePresentation?.presentable?.orm) return
    if (isFolderItemORM(activePresentation?.presentable.orm)) return
    if (isPageGroupORM(activePresentation?.presentable.orm)) return
    if (!currentPage?.UID) return

    const orm = activePresentation.presentable.orm
    const docId = orm.model.documentId
    const pageNumber = activePresentation.currentPresentablePage.presentationPageNumber.toString()
    const ROUTES = DNA_ROUTE_PAGE_ID(location, currentPage, docId, pageNumber)
    const prevUrl = ROUTES[currentPage.UID]?.()

    return () => {
      // we want to remove from the url the optional parameters that doesn't have a value
      replaceUrlState(removeEmptyParams(prevUrl))
    }
  }, [activePresentation])
}

export const CopyCPMLink: React.FC<{
  documentVersionId: string
}> = (props) => {
  const toast = useToast()
  const docVersion = useDocumentVersionORM(props.documentVersionId)
  const doc = useDocumentORM(docVersion?.model.documentId)
  const docStatus = doc?.model.status

  const copy = () => {
    if (!doc) return
    copyDocPageUrlHandler(toast, doc.model.id)
  }

  if (!docStatus || !['PUBLISHED', 'NOT_PUBLISHED'].includes(docStatus)) {
    return null
  }

  return (
    <DNAPopover>
      <DNAPopover.Anchor>
        <DNAButton
          testID="copy-link-button"
          appearance="outline"
          status="tertiary"
          size="sm"
          onPress={copy}
          iconLeft="link-variant"
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content offset={5}>
        <DNABox spacing="sm" appearance="col">
          <DNAText style={{ color: colors['color-text-basic'] }}>
            Copy link to clipboard
          </DNAText>
        </DNABox>
      </DNAPopover.Content>
    </DNAPopover>

  )
}
