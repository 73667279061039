import { Page } from '@alucio/aws-beacon-amplify/src/models'

export const isAllSlidesChecked = (
  pages: Page[],
  selectedSlides: Record<string, boolean>,
) => {
  return (
    pages.length === Object.values(selectedSlides).length &&
    Object.values(selectedSlides).every(selected => selected)
  )
}
