import React, { useEffect, useMemo, useState } from 'react'
import {
  GridList,
  DNADivider,
  Iffy,
  luxColors, DNABox,
} from '@alucio/lux-ui'
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import { SortDir, useSort } from 'src/components/DNA/hooks/useSort'
import { usePagination } from 'src/components/DNA/Pagination/DNAPagination'
import VersioningPanel from 'src/screens/Publishers/Versioning/VersioningPanel'
import { useUserTenant } from 'src/state/redux/selector/user'
import { useAllDocumentsLength } from 'src/state/redux/selector/document'
import {
  DocumentListRow,
  FieldHeader,
  getFieldConfigsForTenant,
  getSortConfigForFields,
  SYSTEM_FIELD_CONFIGS,
} from './common'
import { useAppSettings } from 'src/state/context/AppSettings'
import { Variant, DocumentORM } from 'src/types/types'
import { ActionCallbacks, useDNADocumentActions } from '../Document/DNADocument.actions'
import { useDNADocumentFilters } from '../Document/DNADocumentFilters/DNADocumentFilters'

export interface DNADocumentListProps {
  enableSort?: boolean
  emptyVariant?: EmptyVariant,
  documents?: DocumentORM[],
  isLoading?: boolean,
  loadingComponent?: JSX.Element,
}

const S = {
  HeaderCol: {
    paddingVertical: 12,
  },
  DataCell: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  documentThumbnailBorder: {
    borderWidth: 1,
    borderColor: luxColors.border.primary,
  },
  hoveredRow: {
    backgroundColor: luxColors.hoveredBackground.primary,
  },
  tooltipText: {
    color: luxColors.whiteText.primary,
  },
}

export const DNADocumentListRow = React.memo(DocumentListRow);

// [TODO] - Consider cleaning/breaking this up
export const DNADocumentList: React.FC<DNADocumentListProps> = (props) => {
  const { emptyVariant, isLoading, loadingComponent } = props
  const { pagedRecords } = usePagination()
  const { unpublishedToggle } = useDNADocumentFilters()
  const { sorts, setSorts, toggleSort } = useSort()
  const allDocumentsLength = useAllDocumentsLength()
  const userTenant = useUserTenant()
  const { deviceMode } = useAppSettings()
  const isDesktop = deviceMode === 'desktop';
  const fieldConfigs = useMemo(() => getFieldConfigsForTenant(
    userTenant, Variant.publisher, isDesktop), [])
  const documentActions = useDNADocumentActions()
  const [documentId, setDocumentId] = useState<string>()
  const documents = props.documents ?? pagedRecords;

  const actionCallbacks: ActionCallbacks = useMemo(
    () => ({
      version: (documentORM) => { setDocumentId(documentORM.model.id) },
    }),
    [],
  )

  // [TODO] Probably a cleaner way to init this
  useEffect(() => {
    let configs = fieldConfigs
    if (unpublishedToggle && configs.some((val) => val.fieldName === SYSTEM_FIELD_CONFIGS.DOC_UPDATED_AT.fieldName)) {
      // If unpublished toggle is set we override the tenant's sort config if
      // DOC_UPDATED_AT is available
      configs = configs.map((config) => {
        return {
          ...config,
          defaultSort: config.fieldName !== SYSTEM_FIELD_CONFIGS.DOC_UPDATED_AT.fieldName ? undefined : SortDir.desc,
          // when we sort a custom field that represents a date, we want to sort by value
          // the value that the user see in the screen is the formatted date is not saved on the iso format
          orderByValue: config.dataType === 'date',
        }
      })
    }
    setSorts(getSortConfigForFields(configs))
  }, [unpublishedToggle])

  return (
    <DNABox>
      <Iffy is={!allDocumentsLength}>
        <DNAEmpty emptyVariant={emptyVariant || EmptyVariant.DocumentListEmpty} />
      </Iffy>

      <Iffy is={allDocumentsLength}>
        <GridList
          cols={fieldConfigs.map((field) => `${field.width}px`).join(' ')}
          gap="12px"
          style={{ width: '100%' }}
        >
          <GridList.Header>
            {
              fieldConfigs.map(field => {
                return (
                  <FieldHeader
                    key={field.fieldName}
                    field={field}
                    style={S.HeaderCol}
                    toggleSort={toggleSort}
                    sortIcon={sorts?.[field.fieldName]?.icon}
                  />

                )
              })
            }
          </GridList.Header>
          <Iffy is={ isLoading && loadingComponent}>
            { loadingComponent }
          </Iffy>
          <Iffy is={!isLoading}>
            <Iffy is={allDocumentsLength && !documents.length}>
              <DNAEmpty
                emptyVariant={emptyVariant || EmptyVariant.DocumentListEmptySearch}
              />
            </Iffy>
            {/* Custom Tenant Fields */}
            {
              documents.map((documentORM, idx) => (
                <React.Fragment key={`${documentORM.model.id}-${idx}`}>
                  <DNADivider />
                  {/* [TODO-2128] This component receives a list of fields to display and the source ORM,
                   check how can we specify that the title comes from latestPublishedDocumentVersionORM instead */}
                  <DNADocumentListRow
                    isDesktop={true}
                    isOnline={true}
                    documentORM={documentORM}
                    fields={fieldConfigs}
                    onPress={(docOrm) => setDocumentId(docOrm.model.id)}
                    onThumbnailPress={documentActions.publisherPreview(documentORM)}
                    variant={Variant.publisher}
                    cellStyle={S.DataCell}
                    actionCallbacks={actionCallbacks}
                  />
                </React.Fragment>
              ))
            }
          </Iffy>
          <DNADivider />
        </GridList>
      </Iffy>
      {/* [TODO-2126] - Fix this callback monstrosity */}
      <VersioningPanel
        documentId={documentId}
        setDocumentId={setDocumentId}
      />
    </DNABox>
  )
}

export default DNADocumentList
