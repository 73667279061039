import React, { useCallback, useMemo } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import {
  GridList,
  DNABox,
  DNACard,
  DNADivider,
  DNACheckbox,
  Iffy,
  luxColors,
  util,
  DNAText,
  DNAButton,
  DNAChip,
} from '@alucio/lux-ui'
import { Variant, DocumentORM } from 'src/types/types'
import { SelectedDocuments } from 'src/screens/MyLibrary/DocumentSelectManager'
import { useSort } from 'src/components/DNA/hooks/useSort'
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import { DEFAULT_PAGE_SIZE, usePagination } from 'src/components/DNA/Pagination/DNAPagination'

import { useAllDocumentsLength } from 'src/state/redux/selector/document'
import { useUserTenant } from 'src/state/redux/selector/user'
import { ActionCallbacks, useDNADocumentActions } from '../Document/DNADocument.actions'
import { useAppSettings } from 'src/state/context/AppSettings'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { DocumentListRow, FieldHeader, getFieldConfigsForTenant, ScheduleDocumentIcon } from './common'
import { useLocation } from 'react-router'
import ROUTES from 'src/router/routeDef'
import DNADocumentThumbnail from '../Document/DNADocumentThumbnail'
import { useDocumentSearchV2Context } from 'src/hooks/useDocumentSearchV2'
import { useDocumentSearchV2Context as useSearchContextProxy } from 'src/hooks/useDocumentSearchV2.proxy'
import { renderTextWithHighlight } from 'src/utils/searchHelpers'
import DNADocumentContextMenu from '../Document/DNADocumentContextMenu/DNADocumentContextMenu'
import useCurrentPage from '../hooks/useCurrentPage'
import { CustomLabelDisplay } from '../FlatList/Rows/default/CustomLabelDisplay'
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList'
import useFixedHeader from '@alucio/lux-ui/src/hooks/useFixedLayout'
import { DocumentStatus, FileType } from '@alucio/aws-beacon-amplify/src/models'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags'
import { DNATextProps } from '@alucio/lux-ui/src/components/type/DNAText/DNAText'
import DNADocumentChip from '../Document/DNADocumentChip'
import DNAPopover from '../Popover/DNAPopover'

export interface DNADocumentListProps {
  documents: DocumentORM[],
  emptyVariant?: EmptyVariant,
  selectEnabled?: boolean,
  selectedDocuments?: SelectedDocuments,
  onSelectDocuments?: (documents: DocumentORM[]) => void,
  isSearch?: boolean,
  filtersVisible?: boolean,
  pageNumber?: number,
}
export interface DNADocumentListComponent extends React.FC<DNADocumentListProps> {
  Paginated: typeof DNADocumentListPaginated
}

const S = StyleSheet.create({
  documentThumbnailBorder: {
    borderWidth: 1,
    borderColor: luxColors.border.primary,
  },
  hoveredRow: {
    backgroundColor: luxColors.mainContainer.primary,
  },
  HeaderCol: {
    paddingVertical: 12,
  },
  DesktopContainer: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    overflow: 'hidden',
  },
  MobileContainer: {
    backgroundColor: luxColors.info.primary,
  },
  MobileHeader: {
    backgroundColor: colors['color-gray-10'],
    overflow: 'hidden',
    paddingHorizontal: 16,
    minHeight: 49,
  },
  MobileRow: {
    paddingHorizontal: 16,
  },
  SelectedFilters: {
    backgroundColor: colors['color-brand2-500'],
    borderRadius: 3,
    left: 20,
    paddingHorizontal: 4,
    paddingVertical: 2,
    position: 'absolute',
    top: 0,
  },
  DataCell: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  searchContentWrapper : {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  unPublishedVersionStatus: {
    borderWidth: 2,
    borderColor: luxColors.warning.quaternary,
  },
  excerptContent: {
    overflowWrap: 'break-word' as const,
    whiteSpace: 'pre-wrap' as const,
    maxWidth: '100%',
  },
})

export const RenderExcerpt: React.FC<{
  pageNumber?: number;
  textProps: DNATextProps;
  text: string | null | undefined;
}> = (props) => {
  const { pageNumber, textProps, text } = props;

  // Function to parse the HTML and safely render both text and bold elements
  // Created to prevent XSS attacks by rendering only specific tags
  const renderParsedHTMLWithBoldTags = useCallback((htmlString: string): JSX.Element[] => {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      const body = doc.body;

      const elements: JSX.Element[] = [];

      body.childNodes.forEach((node, index) => {
        if (node.nodeType === Node.TEXT_NODE) {
          // Render normal text
          elements.push(<span key={index}>{node.textContent}</span>);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const element = node as HTMLElement;
          if (element.tagName.toLowerCase() === 'b' || element.tagName.toLowerCase() === 'strong') {
            // Render content inside <b> or <strong>
            elements.push(<strong key={index}>{element.textContent}</strong>);
          }
        }
      });

      return elements;
    } catch (error) {
      console.error('Failed to parse HTML:', error);
      return [<span key="error">Error parsing content</span>];
    }
  }, []);

  // Function to clean the text and add page number if available
  const renderContent = useCallback(
    (text: string | null | undefined, pageNumber?: number): JSX.Element => {
      const cleanedText = (text || '').trim().replace(/\n\s+/g, ' ');
      const contentWithPageNumber = pageNumber ? `Slide ${pageNumber} - ${cleanedText}` : cleanedText;

      return (
        <div style={S.excerptContent}>
          {renderParsedHTMLWithBoldTags(contentWithPageNumber)}
        </div>
      );
    },
    [renderParsedHTMLWithBoldTags], // Dependencies are correctly set
  );

  return (
    <DNABox appearance="row">
      <DNAText {...textProps} numberOfLines={textProps.numberOfLines} testID={textProps.testID}>
        {renderContent(text, pageNumber)}
      </DNAText>
    </DNABox>
  );
};

const DNADocumentListRow = React.memo(DocumentListRow);

interface RowProps {
  documentORM: DocumentORM
  selectEnabled?: boolean,
  onCheck?: (() => void) | undefined,
  checked?: boolean,
  isDropdown?: boolean,
  onPress?: ((pageNumber?: number) => void) | undefined,
}

const currentPageQuery = { exact: false }

const SearchRow: React.FC<RowProps> = (
  { documentORM, selectEnabled, checked, onCheck, isDropdown, onPress }) => {
  const { isOnline, deviceMode } = useAppSettings();
  const unavailableContent =
    !isOnline &&
    (!documentORM.relations.version.cachedDocumentVersionORM?.meta.assets.isContentCached ||
      documentORM.model.type === FileType.WEB)

  const { documentIdSnippetMap, submitFeedback } = useDocumentSearchV2Context();
  const searchProxy = useSearchContextProxy()

  const snippetMap = searchProxy?.documentIdSnippetMap || documentIdSnippetMap;
  const snippet = snippetMap.get(documentORM.model.id);
  const route = useCurrentPage(currentPageQuery)
  const isPublisherRoute = !!route?.configOptions?.modules?.includes('publisher')
  const variant = isPublisherRoute ? Variant.publisher : Variant.MSL;
  const featureFlags = useFeatureFlags('enableKendra', 'enableOpenSearch', 'enableHybridSearch');
  const renderHtmlExcerpt = featureFlags.enableHybridSearch || featureFlags.enableOpenSearch

  const documentActions = useDNADocumentActions();

  const showSnippet = !!snippet?.documentExcerpt;
  const isDesktop = deviceMode === 'desktop';
  const showBookmarkButton = !unavailableContent && !isPublisherRoute && !isDropdown;
  const showContextMenu = !isDropdown;

  const actionCallbacks: ActionCallbacks = useMemo(
    () => ({
      present: (documentORM) => {
        const kendraIndexId = documentORM.relations.tenant.kendraIndexId;
        submitFeedback(documentORM.model.id, kendraIndexId);
      },
      version: (documentORM) => {
        const kendraIndexId = documentORM.relations.tenant.kendraIndexId;
        submitFeedback(documentORM.model.id, kendraIndexId);
        onPress?.(snippet?.pageNumber);
      },
    }),
    [snippet?.pageNumber],
  )

  const handleRowClick = () => {
    const kendraIndexId = documentORM.relations.tenant.kendraIndexId;
    if (!selectEnabled) {
      submitFeedback(documentORM.model.id, kendraIndexId);
    }
    onPress?.(snippet?.pageNumber);
  }

  const handleThumbnailClick = () => {
    const kendraIndexId = documentORM.relations.tenant.kendraIndexId;
    submitFeedback(documentORM.model.id, kendraIndexId);
    documentActions.preview(documentORM, snippet?.pageNumber)();
  }

  const customThumbnail = (documentORM: DocumentORM, pageNumber?: number) => {
    if (!pageNumber) return documentORM.meta.assets.thumbnailKey;
    return documentORM.meta.assets.thumbnailKey?.replace(/(\d+)_small_thumb\.png$/, `${pageNumber}_small_thumb.png`);
  }

  const titleProps: DNATextProps = {
    b1: true,
    numberOfLines: 1,
    testID: 'drop-down-results-title',
  }

  const snippetProps: DNATextProps = {
    numberOfLines: isDropdown ? 3 : 0,
    status: 'flatAlt',
    testID: 'drop-down-results-snippet',
  }

  const customThumbnailKey = customThumbnail(documentORM, snippet?.pageNumber);
  const documentVersion = documentORM.relations.version.latestDocumentVersionORM
  const scheduledPublish = documentVersion.meta.schedule.publish.scheduledAt

  return (
    <DNACard
      appearance="flat"
      interactive={'pressable'}
      style={[{ padding: 16 },
        unavailableContent && { opacity: 0.4 }, checked && { backgroundColor: colors['color-brand2-5'] }]}
      onPress={handleRowClick}
      disabled={unavailableContent}
    >
      <View style={S.searchContentWrapper}>
        <DNABox alignY="center">
          <Iffy is={selectEnabled}>
            <DNACheckbox
              context="altBg"
              status="primary"
              style={{ marginLeft: 8, marginRight: 24 }}
              checked={checked}
              onChange={onCheck}
            />
          </Iffy>
          <Iffy is={showBookmarkButton}>
            <DNAButton
              appearance="ghost"
              status="gray"
              padding={isDesktop ? 'sm' : 'md'}
              rounded="md"
              onPress={documentActions.bookmark(documentORM)}
              iconLeft={documentORM.meta?.bookmark?.isBookmarked
                ? 'bookmark'
                : 'bookmark-outline'
              }
              size={isDesktop ? 'md' : 'lg'}
              style={isDesktop ? null : { width:48, height:48 }}
            />
          </Iffy>
          <Iffy is={showContextMenu}>
            <DNADocumentContextMenu
              documentORM={documentORM}
              variant={variant}
              actionCallbacks={actionCallbacks}
              pageNumber={snippet?.pageNumber}
            >
              <DNAButton
                appearance="ghost"
                status="gray"
                padding={isDesktop ? 'sm' : 'md'}
                rounded="md"
                iconLeft="dots-vertical"
                size={isDesktop ? 'md' : 'lg'}
                style={[isDesktop ? null : { width:48, height:48 }, { marginRight: 8 }]}
              />
            </DNADocumentContextMenu>
          </Iffy>
          <DNADocumentThumbnail
            documentVersionORM={
              (!isOnline && documentORM.relations.version.cachedDocumentVersionORM) ||
              documentORM.relations.version.latestPublishedDocumentVersionORM ||
              documentORM.relations.version.latestDocumentVersionORM
            }
            width={160}
            height={87}
            unavailableContent={unavailableContent}
            onPress={isDropdown ? undefined : handleThumbnailClick}
            showProcessing
            customThumbnailKey={customThumbnailKey}
          />
        </DNABox>
        <DNABox appearance="col" fill spacing="sm" style={{ marginLeft: 16 }}>
          {snippet?.title
            ? renderHtmlExcerpt
              ? <RenderExcerpt
                  textProps={titleProps}
                  text={snippet.title.text}
              />
              : renderTextWithHighlight(snippet?.title, titleProps)
            : <DNAText b1 testID="drop-down-results-title">
              {documentORM.relations.version.latestUsableDocumentVersionORM.model.title}
            </DNAText>}
          {renderHtmlExcerpt && showSnippet &&
            <RenderExcerpt
              pageNumber={snippet.pageNumber}
              textProps={snippetProps}
              text={snippet.documentExcerpt.text}
            />
          }
          {!renderHtmlExcerpt && showSnippet &&
            renderTextWithHighlight(snippet.documentExcerpt, snippetProps, snippet.pageNumber)
          }
          <DNABox>
            <DNABox spacing="sm" style={{ marginRight: 8 }}>
              <CustomFieldBadgeList documentVersionORM={documentORM.relations.version.latestUsableDocumentVersionORM}/>
              <Iffy is={isPublisherRoute}>
                {documentORM.meta.hasUnpublishedVersion ? (
                  <DNABox spacing="xs" key={documentORM.model.id}>
                    <DNAPopover>
                      <DNAPopover.Anchor>
                        <DNABox
                          style={util.mergeStyles(
                            undefined,
                            [
                              S.unPublishedVersionStatus,
                              (
                                documentORM.meta.hasUnpublishedVersion &&
                                documentORM.model.status === DocumentStatus.PUBLISHED
                              ),
                            ],
                          )}
                        >
                          <DNADocumentChip
                            item={documentORM.relations.version.latestUsableDocumentVersionORM}
                            variant="status"
                          />
                        </DNABox>
                      </DNAPopover.Anchor>
                      <DNAPopover.Content offset={-60}>
                        <DNAText
                          numberOfLines={1}
                          style={{ color: colors['color-text-white'] }}
                        >
                          New version in progress
                        </DNAText>
                      </DNAPopover.Content>
                    </DNAPopover>
                    <Iffy is={documentVersion.meta.schedule.publish.isScheduled}>
                      <ScheduleDocumentIcon mode="ICON" scheduledPublish={scheduledPublish} />
                    </Iffy>
                  </DNABox>
                ) : (
                  <DNABox spacing="xs" key={documentORM.model.id}>
                    <DNADocumentChip
                      item={documentORM.relations.version.latestUsableDocumentVersionORM}
                      variant="status"
                    />
                  </DNABox>
                )}
              </Iffy>
              <Iffy is={unavailableContent}>
                <DNAChip
                  appearance="tag"
                  style={{ backgroundColor: luxColors.basicBlack.primary }}
                  testID="content-not-available"
                >
                  CONTENT NOT AVAILABLE
                </DNAChip>
              </Iffy>
            </DNABox>
            <CustomLabelDisplay documentORM={documentORM}/>
          </DNABox>
        </DNABox>
      </View>
    </DNACard>
  )
}

export const SearchResultRow = React.memo(SearchRow);
// [TODO] - Consider cleaning/breaking this up
export const DNADocumentList: DNADocumentListComponent = (props) => {
  const {
    documents,
    emptyVariant,
    selectEnabled,
    selectedDocuments,
    onSelectDocuments,
    isSearch,
    filtersVisible,
  } = props;
  const { sorts, toggleSort } = useSort()
  const allDocumentsLength = useAllDocumentsLength()
  const userTenant = useUserTenant()
  const { deviceMode } = useAppSettings()
  const isDesktop = deviceMode === 'desktop';
  const { pathname } = useLocation();
  const isBookmarkView = pathname.toLowerCase() === ROUTES.BOOKMARKS.PATH ||
      pathname.toLowerCase().startsWith('/bookmarks');
  const variant = isBookmarkView ? Variant.bookmark : Variant.MSL;
  const fieldConfigs = getFieldConfigsForTenant(userTenant, variant, isDesktop)
  const documentActions = useDNADocumentActions()
  const { isOnline } = useAppSettings()
  const fixedHeaderWidth = useFixedHeader({ offSet: 200 });
  const enableKendra = useFeatureFlags('enableKendra');
  const searchProxy = useSearchContextProxy()
  const { documentIdSnippetMap } = useDocumentSearchV2Context();

  const initialNumToRender = DEFAULT_PAGE_SIZE;

  const deviceModeProps = isDesktop
    ? { as: DNACard, style: S.DesktopContainer }
    : { fill: true, style: S.MobileContainer }

  const DeviceModeContainer = isDesktop
    ? DNACard
    : DNABox

  const hasFilterDocumentResults = allDocumentsLength && documents.length;

  const cols = fieldConfigs
    .reduce(
      (acc, field) => (field.fieldType === 'ACTION_MENU' || hasFilterDocumentResults ? `${acc} ${field.width}px` : acc),
      '',
    )

  const allDocmentSelected = useMemo(() => {
    if (!selectedDocuments) return
    return documents.every(doc => selectedDocuments[doc.model.id])
  }, [selectedDocuments, documents])

  const renderItem = ({ item : documentORM } : { item: DocumentORM } ) => {
    const checked = selectedDocuments && selectedDocuments[documentORM.model.id]
    const onCheck = onSelectDocuments ? () => onSelectDocuments([documentORM]) : undefined
    const onPress = selectEnabled ? onCheck : documentActions.preview(documentORM)
    return (
      <>
        <DNADivider />
        <DNADocumentListRow
          isOnline={isOnline}
          isDesktop={isDesktop}
          documentORM={documentORM}
          variant={Variant.MSL}
          onPress={onPress}
          onThumbnailPress={documentActions.present(documentORM)}
          fields={fieldConfigs}
          rowStyle={isDesktop ? undefined : S.MobileRow}
          cellStyle={S.DataCell}
          selectEnabled={selectEnabled}
          checked={checked}
          onCheck={onCheck}
        />
      </>
    )
  }

  const renderSearchItem = ({ item: documentORM } : { item: DocumentORM}) => {
    const snippetMap = searchProxy?.documentIdSnippetMap || documentIdSnippetMap;
    const snippet = snippetMap.get(documentORM.model.id);
    const checked = !!(selectedDocuments && selectedDocuments[documentORM.model.id]);
    const onPress = onSelectDocuments && selectEnabled ? () => onSelectDocuments([documentORM])
      : documentActions.preview(documentORM, snippet?.pageNumber);

    return (
      <SearchResultRow
        documentORM={documentORM}
        selectEnabled={selectEnabled}
        onCheck={onSelectDocuments ? () => onSelectDocuments([documentORM]) : undefined}
        checked={checked}
        onPress={onPress}
      />
    )
  }

  if (isSearch && enableKendra) {
    return (
      <DNABox fill>
        <DNACard appearance="outline" style={{ overflow: 'hidden' }}>
          <Iffy is={selectEnabled}>
            <DNABox appearance="col">
              <DNACheckbox
                context="altBg"
                status="primary"
                style={{ paddingLeft: 24, paddingVertical: 16 }}
                checked={allDocmentSelected}
                onChange={onSelectDocuments ? () => onSelectDocuments(documents) : undefined}
                children={() => {
                  return (
                    <DNAText bold style={{ marginLeft: 16 }}>Select All</DNAText>
                  )
                }}
              />
              <DNADivider />
            </DNABox>
          </Iffy>
          <DNABox style={{ width: fixedHeaderWidth - (filtersVisible ? 400 : 0) }}>
            <FlatList
              data={documents}
              keyExtractor={(documentORM, index) => `${documentORM.model.id}-${index}`}
              initialNumToRender={initialNumToRender}
              renderItem={renderSearchItem}
              ItemSeparatorComponent={DNADivider}
              ListEmptyComponent={<DNAEmpty emptyVariant={emptyVariant || EmptyVariant.DocumentListEmptySearch}/>}
            />
          </DNABox>
        </DNACard>
      </DNABox>
    )
  }

  return (
    <DNABox fill>
      {/* [TODO] - We should standardize around a prop called `emptyWithHeader`  */}
      <Iffy is={!allDocumentsLength}>
        <DNAEmpty emptyVariant={emptyVariant || EmptyVariant.DocumentListEmpty} />
      </Iffy>

      <Iffy is={allDocumentsLength}>
        <DeviceModeContainer {...deviceModeProps}>
          <GridList
            cols={cols}
            gap="12px"
            style={{ width: '100%' }}
          >
            {/* GridList header (Non-Empty) */}
            <Iffy is={hasFilterDocumentResults}>
              <GridList.Header style={util.mergeStyles(undefined, [S.MobileHeader, !isDesktop])}>
                {
                fieldConfigs.map(field => {
                  if (field.fieldType === 'ACTION_MENU' && selectEnabled) {
                    return (
                      <GridList.Col key={field.fieldLabel}>
                        <DNABox spacing="sm" alignY="center">
                          <DNACheckbox
                            context="altBg"
                            status="primary"
                            style={{ margin: 6 }}
                            checked={allDocmentSelected}
                            onChange={onSelectDocuments ? () => onSelectDocuments(documents) : undefined}
                          />
                          <DNAText>Select all</DNAText>
                        </DNABox>

                      </GridList.Col>
                    )
                  } else {
                    return (
                      <FieldHeader
                        key={field.fieldName}
                        field={field}
                        style={S.HeaderCol}
                        toggleSort={toggleSort}
                        sortIcon={sorts?.[field.fieldName]?.icon}
                      />
                    )
                  }
                })
              }
              </GridList.Header>
            </Iffy>

            {/* Empty Results */}
            <Iffy is={allDocumentsLength && !documents.length}>
              <DNABox>
                <DNAEmpty
                  useContainer={false}
                  emptyVariant={emptyVariant || EmptyVariant.DocumentListEmptySearch}
                />
              </DNABox>
            </Iffy>

            {/* Document Rows */}
            <FlatList
              data={documents}
              keyExtractor={(documentORM, index) => `${documentORM.model.id}-${index}`}
              initialNumToRender={initialNumToRender}
              renderItem={renderItem}
            />
          </GridList>
        </DeviceModeContainer>
      </Iffy>
    </DNABox>
  )
}

const DNADocumentListPaginated: React.FC<Omit<DNADocumentListProps, 'documents'>> = (props) => {
  const { pagedRecords } = usePagination()

  return (
    <DNADocumentList
      {...props}
      documents={pagedRecords}
    />
  )
}

DNADocumentList.Paginated = DNADocumentListPaginated
export default DNADocumentList
