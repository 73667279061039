import React, { useState, useMemo } from 'react'
import { ScrollView } from 'react-native'

import { DNABox, Iffy, DNAButton } from '@alucio/lux-ui'
import { Page } from '@alucio/aws-beacon-amplify/src/models'
import DNAThumbnail from './DNAThumbnail'

import { DocumentVersionORM } from 'src/types/orms'

import { ThumbnailSize, thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize';

enum AssociatedSlidesMode {
  READ_ONLY,
  REMOVABLE,
}

const MAX_NUMBER_OF_VISIBLE_ROWS = 3
const HORIZONTAL_MARGIN = 8
const VERTICAL_MARGIN = 11

const AssociatedSlidesThumbnails: { Mode: typeof AssociatedSlidesMode } &
  React.FC<{
    mode?: AssociatedSlidesMode,
    pages: Page[],
    docVerORM: DocumentVersionORM,
    size: ThumbnailSize,
    onClick?: (pageId: string) => void,
    onRemove?: (pageId: string) => void,
    onRemoveAll?: () => void,
    getSlideTitle?: (number: number) => string,
}> = (props) => {
  const {
    mode = AssociatedSlidesMode.READ_ONLY,
    docVerORM,
    pages,
    size,
    onRemove,
    onRemoveAll,
    getSlideTitle,
  } = props

  const [expanded, setExpanded] = useState<boolean>(false)

  const sortedPages = useMemo(
    () => [...pages].sort((a, b) => a.number - b.number),
    [pages],
  )

  const thumbnailSize = thumbnailSizeDimensions[size]
  const rowItemCount = (thumbnailSize.width / (thumbnailSizeDimensions.xxs.width + HORIZONTAL_MARGIN))
  const visibleRow = !expanded
    ? sortedPages.slice(0, rowItemCount)
    : sortedPages

  const maxContainerWidth = thumbnailSize.width
  const maxContainerHeight = expanded
    ? (thumbnailSizeDimensions.xxs.height * MAX_NUMBER_OF_VISIBLE_ROWS) + (VERTICAL_MARGIN * MAX_NUMBER_OF_VISIBLE_ROWS)
    : (thumbnailSizeDimensions.xxs.height) + VERTICAL_MARGIN

  const showRemoveAllButton = (
    (mode === AssociatedSlidesMode.REMOVABLE) &&
    onRemoveAll &&
    sortedPages.length
  )
  const showExpandButton = (
    (visibleRow.length > 0) &&
    (sortedPages.length > rowItemCount)
  )

  return (
    <DNABox
      appearance="col"
      spacing="sm"
      childFill={1}
      style={{ maxWidth: maxContainerWidth }}
    >
      <ScrollView contentContainerStyle={{ maxHeight: maxContainerHeight }}>
        <DNABox
          spacing="sm"
          alignX="center"
          fill
          wrap="center"
          keepSpacingLastItem
          childStyle={{ marginBottom: 4 }}
        >
          {/* PREVIEW ROW */}
          {/* [TODO-ASSOCIATED] - Use DNACollapsibleCard instead? */}
          {
            visibleRow.map(page => {
              if (!page) {
                return null
              }
              const thumbURL = docVerORM.meta
                .assets
                .thumbnailKey
                ?.split('/')
                .slice(0, -1)
                .join('/') + `/${page.number}_medium_thumb.png` ?? ''

              return (
                <DNAThumbnail
                  key={page.pageId}
                  s3URL={thumbURL}
                  size="xxs"
                  pageNumber={page.number}
                  mode={
                    // [TODO-ASSOCIATED] - Extract into a common enum both components can use
                    mode === AssociatedSlidesMode.READ_ONLY
                      ? DNAThumbnail.Modes.READ_ONLY
                      : DNAThumbnail.Modes.REMOVABLE
                  }
                  variant={DNAThumbnail.Variants.ASSOCIATED}
                  onCheck={() => onRemove?.(page.pageId)}
                  resizeMode="cover"
                  preview={{ pages: sortedPages, docVerORM, getSlideTitle }}
                />
              )
            })
          }
        </DNABox>
      </ScrollView>

      <DNABox
        fill
        appearance="row"
        spacing="sm"
        childFill
      >
        <Iffy is={showExpandButton}>
          <DNAButton
            appearance="outline"
            status="tertiary"
            stretch
            onPress={() => setExpanded(p => !p)}
          >
            {
              !expanded
                ? `Show all (+${sortedPages.length - visibleRow.length})`
                : 'Hide'
            }
          </DNAButton>
        </Iffy>

        <Iffy is={showRemoveAllButton}>
          <DNAButton
            appearance="outline"
            status="danger"
            stretch
            onPress={onRemoveAll}
            padding="sm"
          >
            Remove all slides
          </DNAButton>
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

AssociatedSlidesThumbnails.Mode = AssociatedSlidesMode

export default AssociatedSlidesThumbnails
