import { DNAButton, DNAContextMenu } from '@alucio/lux-ui';
import React from 'react';
import { useDispatch } from 'src/state/redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { drawerActions, DRAWER_ENTITIES } from 'src/state/redux/slice/drawer';
import { DocumentVersionORM, MeetingORM } from 'src/types/orms';
import DNAMeetingDeleteModal from '../DNA/Modal/DNAMeetingDeleteModal';
import { useCanDeleteMetting } from './AddMeetingFooter';
import {
  AssociatedFileType,
  ContentPresented,
  ContentPresentedStatus,
  DocumentStatus,
  Hub,
  HubSection,
  HubSectionType,
  HubStatus,
  SharedFileHubSetting,
  HubSectionItemStatus,
  MeetingType,
  MeetingStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { hubActions } from 'src/state/redux/slice/hub';
import format from 'date-fns/format';
import { useHistory } from 'react-router';
import { v4 as uuid } from 'uuid';
import { HUB_SECTION_STATUS, HUB_SHARED_FILE_CONTENT_TYPE } from '@alucio/aws-beacon-amplify/src/API';
import { useAllDocumentVersionMap } from 'src/state/redux/selector/document';
import { HubsIcon } from 'src/assets/icons/Hubs';
import { useIsMeetingLocked } from './AddMeetingProvider';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { useDisabledWidgets } from 'src/screens/Hubs/EditHub/LeftSideBar';
import { meetingActions } from 'src/state/redux/slice/meeting';

interface Props {
  meetingORM: MeetingORM,
  isTablet: boolean,
  isCRMMeetingEnabled?: boolean,
}

const MeetingContextMenu: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { meetingORM, isTablet, isCRMMeetingEnabled } = props;
  const { type, status } = meetingORM.model;
  const { deleteDisabled } = useCanDeleteMetting(meetingORM);
  const history = useHistory()
  const documentVersions = useAllDocumentVersionMap()
  const isMeetingLocked = useIsMeetingLocked(meetingORM)
  const enableNew3PC = useFeatureFlags('enableNew3PC');
  const enableHubs = useFeatureFlags('enableHubs')
  const [isWidgetDisabled] = useDisabledWidgets()

  // REMOVE this once we have enabled 3PC for all tenants
  const availableStartMeetingLegacy = isMeetingLocked ||
  ( !isCRMMeetingEnabled || (type === MeetingType.MANUAL && status !== MeetingStatus.PLANNED) ) ? []
    : status === MeetingStatus.PLANNED ? [MeetingType.IN_PERSON, MeetingType.VIRTUAL] : [MeetingType[type]]

  const availableStartMeeting = isMeetingLocked ||
  ( !isCRMMeetingEnabled || (type === MeetingType.MANUAL && status !== MeetingStatus.PLANNED) ) ? []
    : [MeetingType[type]]

  const createHubsCB = (hub: Hub) => {
    history.push(`/hubs/${hub.id}`)
  }

  const getPresentedDocumentVersionORMs =
  (contentPresented: ContentPresented[]): DocumentVersionORM[] => {
    // contentPresented.contentType returns DOCUMENT_VERSION and the useAllDocumentVersionMap returns documentVersion.
    // the const defined below maps the two together
    const items = {
      DOCUMENT_VERSION: documentVersions,
      DOCUMENT_VERSION_GROUP: documentVersions,
    }
    return contentPresented
      .filter(cp => cp.status !== ContentPresentedStatus.DELETED)
      .filter(cp => cp.contentType !== 'CUSTOM_DECK')
      .map(cp => items[cp.contentType][cp.contentId])
      .filter( content => !!content )
  }

  const getPresentedFiles = (): SharedFileHubSetting[] => {
    const presentedDocVers = getPresentedDocumentVersionORMs(meetingORM.model.contentPresented)
    const duplicateRemoved = [...new Map(presentedDocVers.map((docVer) => [docVer.model.id, docVer])).values()]
    if (presentedDocVers) {
      const result =  duplicateRemoved.map(docVerORM => {
        const associatedFiles = docVerORM.relations.associatedFiles
          .filter(associatedFile => {
            return (
              associatedFile.model.type === AssociatedFileType.ATTACHED_FILE &&
              associatedFile.meta.canBeSharedByMSL
            ) ||
            (
              associatedFile.model.type === AssociatedFileType.DOCUMENT &&
              associatedFile.meta.canBeSharedByMSL &&
              associatedFile.file?.['status'] === DocumentStatus.PUBLISHED
            )
          })
          .map(associatedFile => {
            let versionId: string | undefined
            if (associatedFile.model.type === AssociatedFileType.DOCUMENT) {
              versionId = associatedFile.relations.latestUsableDocumentVersion?.id ?? undefined
            }
            return {
              associatedFileId: associatedFile.model.id,
              versionId,
            }
          })

        return {
          id: uuid(),
          contentId: docVerORM.model.id,
          title: docVerORM.model.title!,
          contentType: HUB_SHARED_FILE_CONTENT_TYPE.DOCUMENT_VERSION,
          documentVersionSettings: docVerORM.relations.associatedFiles.length ? { associatedFiles } : undefined,
          status: HubSectionItemStatus.ACTIVE,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        }
      })
      return result
    } else {
      return []
    }
  }

  const meetingsDefaultWidgets: Record<HubSectionType, HubSection> = {
    [HubSectionType.TEXTAREA]: {
      id: uuid(),
      sectionHeader: 'Text Box',
      status: HUB_SECTION_STATUS.ACTIVE,
      type: HubSectionType.TEXTAREA,
      visible: true,
      order: 0,
      textarea: {
        content: '',
      },
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    },
    [HubSectionType.SHARED_FILES]: {
      id: uuid(),
      sectionHeader: 'Shared Files',
      status: HUB_SECTION_STATUS.ACTIVE,
      type: HubSectionType.SHARED_FILES,
      visible: true,
      order: 1,
      sharedFiles: getPresentedFiles(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    },
    [HubSectionType.TODO_LIST]: {
      id: uuid(),
      sectionHeader: 'To-Do List',
      status: HUB_SECTION_STATUS.ACTIVE,
      type: HubSectionType.TODO_LIST,
      visible: true,
      toDos: [],
      order: 2,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    },
    [HubSectionType.LINKS]: {
      id: uuid(),
      sectionHeader: 'Links',
      status: HUB_SECTION_STATUS.ACTIVE,
      type: HubSectionType.LINKS,
      visible: true,
      links: [],
      order: 3,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    },
  }

  const handleCreateHubs = () => {
    const defaultHubSections: HubSection[] = Object
      .values(meetingsDefaultWidgets)
      .filter(hubSection => !isWidgetDisabled(hubSection.type))
      .map(hubSection => {
        // Update createdAt/updatedAt to present time
        return {
          ...hubSection,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        }
      })

    dispatch(hubActions.createHub({
      name: meetingORM.model.title ? meetingORM.model.title : 'Hub Created ' + format(new Date(), 'LLL d, y'),
      status: HubStatus.ACTIVE,
      hubSections: defaultHubSections,
      meetingId: meetingORM.model.id,
      callback: createHubsCB,
    }))
  }

  const openDeleteModal = () => {
    if (meetingORM) {
      const payload = {
        isVisible: true,
        allowBackdropCancel: true,
        component: () =>
          (<DNAMeetingDeleteModal
            meeting={meetingORM}
          />),
      };
      dispatch(DNAModalActions.setModal(payload));
    }
  }

  const toggleDrawer = () => {
    dispatch(drawerActions.toggle({
      entity: DRAWER_ENTITIES.MEETING,
      entityId: meetingORM.model.id,
    }));
  }

  const restartMeeting = (type: MeetingType) => {
    dispatch(meetingActions.updateMeeting(meetingORM.model, {
      ...meetingORM.model,
      status : MeetingStatus.IN_PROGRESS,
      type,
    }));
    history.push(`/meeting-presentation/${meetingORM.model.id}`)
  }

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        <DNAButton
          appearance="ghost"
          size={ isTablet ? 'lg' : 'md'}
          status="gray"
          padding="sm"
          iconLeft="dots-vertical"
        />
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          onPress={toggleDrawer}
          status="dark"
          icon="pencil"
          title="Edit"
        />
        {enableHubs &&
          <DNAContextMenu.Item
            onPress={() => handleCreateHubs()}
            status="dark"
            title="Share files via Hub"
            CustomIcon={HubsIcon}
          />
        }
        {/* remove this once 3PC has been enabled for all tenants */}
        {!enableNew3PC && availableStartMeetingLegacy.map((type) => (
          <DNAContextMenu.Item
            key={type}
            onPress={() => restartMeeting(type)}
            status="dark"
            icon={type === MeetingType.IN_PERSON ? 'account-multiple' : 'account-group'}
            title={`Start ${type === MeetingType.IN_PERSON ? 'in-person' : 'virtual'} meeting`}
          />
        ))}
        {enableNew3PC && availableStartMeeting.map((type) => (
          <DNAContextMenu.Item
            key={type}
            onPress={() => restartMeeting(type)}
            status="dark"
            icon="account-multiple"
            title={
              (status === MeetingStatus.ENDED_EXIT || status === MeetingStatus.ENDED_TIMEOUT)
                ? 'Restart meeting'
                : 'Start meeting'
            }
          />
        ))}
        {!isMeetingLocked && !deleteDisabled && (
          <DNAContextMenu.Item
            onPress={openDeleteModal}
            status="dark"
            icon="trash-can-outline"
            title="Delete"
          />
        )}
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default MeetingContextMenu;
