import React, { useState } from 'react'
import {
  DNABox,
  DNAButton,
  DNACheckbox,
  DNAContextMenu,
  DNADivider,
  DNAText,
} from '@alucio/lux-ui'

import { useGroupState } from './GroupProvider.proxy'
import { useSlideSettingsState } from '../SlideSettings.proxy'
import truncate from 'lodash/truncate'

import { styles } from '../SlideSettings'

export const AddToGroupMenuButton: React.FC = () => {
  const { send, state } = useSlideSettingsState()
  const { groups, selectedPageIds, addGroupHandler } = useGroupState()
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);

  const addToGroupHandler = (groupName: string) => {
    send({ type: 'ADD_GROUP_SLIDES', payload: { groupName } } )
    setOptionsVisible(false)
  }

  const toggleGroupOptionsVisibility = (): void => {
    if (groups.length === 0) {
      addGroupHandler(selectedPageIds)
    }
    else {
      setOptionsVisible(true);
    }
  }

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        <DNAButton
          onPress={toggleGroupOptionsVisibility}
          appearance="outline"
          status="tertiary"
          size="xs"
          disabled={!state.can({ type: 'ADD_GROUP_SLIDES', payload: { groupName: 'NA' } })}
        >
          Add to group
        </DNAButton>
      </DNAContextMenu.Anchor>
      {
        optionsVisible && (
          <DNAContextMenu.Items>
            {
              groups.length > 1 && <DNAContextMenu.Item
                key="all_add_to_group"
                onPress={() => addToGroupHandler('ADD_TO_ALL_GROUPS')}
                title="All groups"
              />
            }
            {
              groups.map((pageGroup) => (
                <DNAContextMenu.Item
                  key={pageGroup.name}
                  onPress={() => addToGroupHandler(pageGroup.name)}
                  title={truncate(pageGroup.name)}
                />
              ))
            }
          </DNAContextMenu.Items>
        )
      }
    </DNAContextMenu>
  )
}

export const GroupHeader: React.FC = () => {
  const { send, state } = useSlideSettingsState()

  return (
    <DNABox style={ styles.headerBar } spacing="between">
      <DNABox
        alignY="center"
        spacing="sm"
      >
        <DNAText h4>Slide Groups</DNAText>
      </DNABox>
      <DNABox>
        <DNAButton
          appearance="outline"
          status="tertiary"
          padding="sm"
          onPress={() => {
            send({ type: 'BACK_TO_IDLE' })
          }}
          style={{ marginRight: 8 }}
        >
          Cancel
        </DNAButton>
        <DNAButton
          padding="sm"
          onPress={() => send({ type: 'SAVE_GROUP_SLIDES' })}
          disabled={!state.can({ type: 'SAVE_GROUP_SLIDES' })}
        >
          Save
        </DNAButton>
      </DNABox>
    </DNABox>
  )
}

export const ActionBar: React.FC = () => {
  const {
    selectedPageIds,
    pages,
  } = useGroupState()

  const {
    send,
    thumbnailSizes,
  } = useSlideSettingsState()
  return (
    <DNABox
      style={ styles.rowWrapper }
      spacing="between"
      alignY="center"
    >
      {/* LEFT SIDE - ACTIONS */}
      <DNABox spacing="md" alignY="center">
        {/* SELECT CHECKBOX */}
        <DNACheckbox
          onChange={() => send({ type: 'UPDATE_GROUP_SLIDES', payload: { scope: 'all' } }) }
          checked={selectedPageIds.length === pages.length}
        />
        <DNAText status="flatAlt">Select all</DNAText>
        <DNADivider style={styles.verticalDivider} />

        {/* SELECTED COUNT */}
        <DNAText status="flatAlt" style={{ marginRight: 8 }}>
          {`${selectedPageIds.length} of ${pages.length} selected`}
        </DNAText>

        {/* ADD TO GROUP BUTTON */}
        <AddToGroupMenuButton />
      </DNABox>

      {/* RIGHT SIDE - VIEW TOGGLES */}
      <DNABox spacing="sm" alignY="center">
        <DNAButton
          appearance="ghostLink"
          status="tertiary"
          size="md"
          padding="none"
          onPress={thumbnailSizes.cycleThumbnailSize}
          iconLeft={thumbnailSizes.thumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
        />
      </DNABox>
    </DNABox>
  )
}
