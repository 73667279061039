/**
 * [TODO-928] - We should extract these components out and making them the standard way in any slide editor to render slides/groups
 */
import React from 'react'
import { ScrollView, StyleSheet, ViewStyle } from 'react-native'
import DNAThumbnail, { DNAThumbnailProps } from 'src/components/DNA/Thumbnail/DNAThumbnail'

import { DocumentAccessLevel, FileType, Page } from '@alucio/aws-beacon-amplify/src/models'
import {
  GroupStatus,
  ModifiedPayloadGroup,
  unavailableStatus,
  usePresentationBuilderState,
} from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider'

import { DNABox, DNAText, DNAChip, DNAIcon, Iffy, Stack, util, DNAContextMenu, DNAButton } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { GroupItemOverlay } from './Overlays'
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize'
import { useAppSettings } from 'src/state/context/AppSettings'
import { ThumbnailPage } from 'src/components/SlideSelector/SlideSelector'
import { getPresentable } from 'src/state/context/ContentProvider/helper'
import useContentPageData, { getSlideContentPageDataTitle } from 'src/hooks/useContentPageData/useContentPageData'
import { DocumentVersionORM } from 'src/types/orms'
import {
  usePresentationSelector,
} from 'src/components/PresentationBuilder/PresentationSelector/PresentationSelectorStateProvider'

export const S = StyleSheet.create({
  outlineBorder: {
    borderRadius: 4,
    borderWidth: 3,
    padding: 4,
  },
  thumbnailContainer: {
    paddingTop: 4,
    paddingHorizontal: 4,
  },
  groupedContainer: {
    paddingTop: 64,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  // Offset the container so groups aligned with non-grouped items, overlay is also offset though
  groupedOffset: {
    marginHorizontal: -16,
  },
  thumbnailBackdrop: {
    backgroundColor: colors['color-gray-100'],
    borderRadius: 4,
  },
  thumbnailBorder: {
    borderColor: colors['color-gray-100'],
    borderRadius: 6,
    borderWidth: 3,
  },
  disableThumbnailBackdrop: {
    backgroundColor: colors['color-warning-5'],
    borderColor: colors['color-text-basic'],
    borderRadius: 4,
    borderWidth: 3,
  },
  disableThumbnailBorder: {
    borderColor: colors['color-warning-500'],
    borderRadius: 6,
    borderWidth: 3,
  },
  selectedThumbnailBorder: {
    borderColor: colors['color-brand2-500'],
    borderRadius: 6,
    borderWidth: 3,
  },
  footerOffset: {
    paddingHorizontal: 6,
  },
  leftRemoveButton: {
    borderRadius: 4,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  contextMenuButton: {
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: 1,
  },
  contextMenuItems: {
    top: 4,
  },
  buttonIconStyle: {
    marginRight: 4,
  },
})

type SlideProps = {
  group: ModifiedPayloadGroup,
  page: Page,
  displayIdx: number,
  enableOverlay?: boolean,
  style?: ViewStyle,
  parentRef?: React.RefObject<ScrollView>
} & Pick<DNAThumbnailProps, 'height' | 'width' | 'onCheck' | 'mode' | 'checked' | 'deferLoad'>

export const Slide: React.FC<SlideProps> = (
  { group, page, displayIdx, enableOverlay, style, parentRef, checked, onCheck, mode, deferLoad },
) => {
  const {
    editorThumbnailSize,
    displayPageSourceFile,
    isLocked,
    openFindReplacement,
    onRemoveGroup,
    onRemoveAllUnavailableGroups,
  } = usePresentationBuilderState();
  const { isOnline } = useAppSettings()
  const { active } = usePresentationSelector()

  const visibleTypeBadges = [FileType.WEB, FileType.HTML, FileType.MP4]
  const visibleType = visibleTypeBadges
    .find(
      visibleBadgeType => visibleBadgeType === group.documentVersionORM?.model.type,
    )
  const isFromPersonalDocument =
    group.documentVersionORM?.relations.documentORM.model.accessLevel === DocumentAccessLevel.USER;

  const thumbnailDimensions = {
    height: thumbnailSizeDimensions[editorThumbnailSize].height,
    width: thumbnailSizeDimensions[editorThumbnailSize].width,
  };

  const s3URL = detectArchivedFileKeyPath(group.documentVersionORM?.model, page, editorThumbnailSize)
  const presentable = getPresentable(group.documentVersionORM!);
  const { contentPageData } = useContentPageData(presentable);
  const title = contentPageData.length > 0 ? getSlideContentPageDataTitle(page.number - 1, contentPageData) : ''

  const { isContentCached } = group.documentVersionORM?.meta?.assets ?? { }

  const finalStyle = StyleSheet.flatten([
    S.thumbnailContainer,
    style,
  ])

  const borderColor:ViewStyle = {
    borderColor: active?.data.current?.itemId === group.id
      ? colors['color-brand2-500']
      : colors['color-gray-100'],
  }

  const isSingleSlide = group.pages.length === 1
  const isUnavailable = !!unavailableStatus[group.groupStatus]
  const isFindAndReplace = group.groupStatus === GroupStatus.MAJOR_UPDATE

  return (
    <DNABox testID="slide-thumbnail" appearance="col" spacing="xs">
      {/* [TODO-928] - Consider making this composable instead of prop driven */}
      <GroupItemOverlay group={group} enabled={enableOverlay} isLocked={isLocked}>
        {/* Thumbnail Container */}
        <DNABox style={[finalStyle, borderColor]}>
          {
            !isUnavailable
              ? (
                <Stack anchor="center">
                  <Stack.Layer>
                    <DNAThumbnail.Lazy
                      overlayText={displayPageSourceFile ? group.documentVersionORM?.model.title : undefined}
                      style={S.thumbnailBackdrop}
                      s3URL={s3URL}
                      parentRef={parentRef}
                      variant={DNAThumbnail.Variants.INFO}
                      mode={mode}
                      disabled={isSingleSlide && isFindAndReplace}
                      onCheck={onCheck}
                      checked={checked}
                      status={(isSingleSlide && isFindAndReplace) ? 'warning' : undefined}
                      deferLoad={deferLoad}
                      {...thumbnailDimensions}
                    />
                  </Stack.Layer>
                  <Stack.Layer>
                    {
                      (!isOnline && !isContentCached)
                        ? (<DNAIcon.Styled
                            appearance="ghost"
                            status="primary"
                            name="cloud-off-outline"
                            size="xl"
                        />)
                        : null
                    }
                  </Stack.Layer>
                </Stack>
              )
              //  - If a slide in a group is no longer "valid"
              //    show a fallback holder (with additional details/indicators)
              : (
                <DNABox
                  style={util.mergeStyles(
                    undefined,
                    [S.disableThumbnailBorder, isSingleSlide],
                    [S.thumbnailBorder, !isSingleSlide],
                    [S.selectedThumbnailBorder, checked],
                  )}
                >
                  <Stack anchor="center">
                    <Stack.Layer>
                      <DNABox style={[S.disableThumbnailBackdrop, thumbnailDimensions]} />
                    </Stack.Layer>
                    <Stack.Layer>
                      <Iffy is={isUnavailable}>
                        <DNABox fill appearance="col">
                          <DNAText bold b1 status="warning">
                            {group.groupStatus === GroupStatus.DELETED
                              ? 'Source file is unavailable'
                              : `Source file was ${group.groupStatus.toLowerCase()}`
                            }
                          </DNAText>
                        </DNABox>
                      </Iffy>
                    </Stack.Layer>
                  </Stack>
                </DNABox>
              )
          }
        </DNABox>
      </GroupItemOverlay>
      <DNABox
        fill
        appearance="col"
        testID="slide-footer"
        style={S.footerOffset}
        spacing="sm"
      >
        <SlideFooter
          isFromPersonalDocument={isFromPersonalDocument}
          page={page}
          displayIdx={displayIdx}
          type={visibleType}
          title={title}
          width={thumbnailDimensions.width + 10}
        />
        {/* REMOVE SLIDE BUTTON */}
        <Iffy is={!isLocked && isUnavailable && isSingleSlide}>
          <DNABox fill>
            <DNAButton
              status="warning"
              iconLeft="trash-can-outline"
              iconStyle={S.buttonIconStyle}
              onPress={() => onRemoveGroup(group)}
              style={S.leftRemoveButton}
              children="Remove"
              stretch
            />
            <DNAContextMenu
              style={[S.contextMenuItems, { width: thumbnailDimensions.width + 10 }]}
              placement="bottom end"
            >
              <DNAContextMenu.Anchor>
                <DNAButton
                  onPress={() => { }}
                  iconLeft="chevron-down"
                  status="warning"
                  padding="sm"
                  style={S.contextMenuButton}
                />
              </DNAContextMenu.Anchor>
              <DNAContextMenu.Items>
                <DNAContextMenu.Item
                  collapseOnPress
                  delay={100}
                  title="Remove all unavailable slides"
                  onPress={onRemoveAllUnavailableGroups}
                />
              </DNAContextMenu.Items>
            </DNAContextMenu>
          </DNABox>
        </Iffy>
        {/* FIND REPLACEMENT BUTTON */}
        <Iffy is={!isLocked && isFindAndReplace && isSingleSlide}>
          <DNAButton
            testID="find-and-replace-button"
            status="warning"
            iconLeft="sync"
            iconStyle={S.buttonIconStyle}
            onPress={() => openFindReplacement(group)}
            children="Find replacement"
            stretch
          />
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

interface SlideFooterProps {
  page: Page,
  isFromPersonalDocument?: boolean,
  displayIdx: number,
  type?: DocumentVersionORM['model']['type'],
  title?: string,
  width?: number,
}
export const SlideFooter: React.FC<SlideFooterProps> = ({
  isFromPersonalDocument,
  page:unAssertedPage,
  displayIdx,
  type,
  width = 220,
  title,

}) => {
  const { selectedGroups, associatedParentsMap } = usePresentationBuilderState();

  /** TODO: Remove this assertion in this ticket
   * https://alucioinc.atlassian.net/browse/BEAC-3670
   */
  const page = unAssertedPage as ThumbnailPage

  const currentAssociatedParents: number[] = []

  if (associatedParentsMap.get(page.pageId) && !page.isRequired) {
    const allSlideIds: string[] = []
    selectedGroups.forEach(group => {
      group.pages.forEach(page => {
        allSlideIds.push(page.pageId)
      })
    })
    page.parentIds?.forEach(id => {
      if (allSlideIds.indexOf(id) !== -1) {
        currentAssociatedParents.push(allSlideIds.indexOf(id) + 1)
      }
    })
  }
  const formattedCurrentAssociatedParents = currentAssociatedParents.sort().join(', ')
  const hasAssociatedParent = associatedParentsMap.get(page.pageId) && !page.isRequired
  const slideFooterText =
    ((displayIdx !== undefined && displayIdx !== -1) ? `${displayIdx}. ` : '') + title

  return (
    <DNABox fill spacing="between" style={{ width }} childFill={0}>
      <DNAText testID="slide-number" numberOfLines={1}>{slideFooterText}</DNAText>
      <Iffy is={page.isRequired}>
        <DNAChip status="danger" appearance="tag" style={{ marginLeft: 10 }}>
          REQUIRED
        </DNAChip>
      </Iffy>
      <Iffy is={isFromPersonalDocument}>
        <DNAChip appearance="tag" style={{ marginLeft: 10 }}>
          MY UPLOADS
        </DNAChip>
      </Iffy>
      <Iffy is={hasAssociatedParent}>
        <DNAPopover key={page.pageId} >
          <DNAPopover.Anchor>
            <DNAChip status="danger" appearance="tag" iconLeft="link-variant" style={{ marginLeft: 10 }}>
              REQUIRED
            </DNAChip>
          </DNAPopover.Anchor>
          <DNAPopover.Content offset={2}>
            <DNAText
              style={{ color: colors['color-text-white'], marginHorizontal: 12, width: 300 }}
            >
              {`This slide is required because you added the following slides: ${formattedCurrentAssociatedParents}`}
            </DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      </Iffy>
      {type &&
        <DNAChip
          appearance="tag"
          status="basic"
          size="sm"
          style={{ marginLeft: 10 }}
        >
          {type}
        </DNAChip>}
    </DNABox>
  )
}

export default Slide
