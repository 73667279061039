import React from 'react'
import { StyleSheet } from 'react-native'
import {
  // DNAText,
  DNABox,
  Iffy,
  createPortal,
} from '@alucio/lux-ui'
import { DragOverlay, useDndContext } from '@dnd-kit/core'
import { snapCenterToCursor } from '@dnd-kit/modifiers'

import { POOL_CONTAINER_ID } from 'src/components/DnD/Clone/CloneBuilder'
import { getThumbURL } from 'src/screens/Publishers/Versioning/SlideSettings/SlideSettings'
import { useSlideSettingsState } from 'src/screens/Publishers/Versioning/SlideSettings/SlideSettings.proxy'
import { useGroupState } from 'src/screens/Publishers/Versioning/SlideSettings/Grouping/GroupProvider.proxy'

import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize'

const S = StyleSheet.create({
  rect1: {
    minHeight: 6,
    backgroundColor:
    colors['color-brand2-80'],
    marginHorizontal: 20,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  rect2: {
    minHeight: 6,
    backgroundColor:
    colors['color-brand2-10'],
    marginHorizontal: 30,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
})

const SlidesGroupOverlay: React.FC = () => {
  const { documentVersionORM, getSlideTitle } = useSlideSettingsState()
  const { selectedPageIds, pagesMap } = useGroupState()

  // [TODO] - Consider creating a new activeItemState to minimize re-rendering
  const { active } = useDndContext()
  const activeItemId = active?.data.current?.itemId
  const pageNumber = (pagesMap[activeItemId ?? '']?.number ?? 0)
  const isFromPool = active?.data.current?.containerId === POOL_CONTAINER_ID
  return (
    createPortal(
      <DragOverlay modifiers={[snapCenterToCursor]}>
        {
          activeItemId
            ? <DNABox appearance="col" style={{ width: thumbnailSizeDimensions.lg.width + 6 }}>
              {/* <DNAText>{ active?.id }</DNAText> */}
              {/* <DNAText>{ activeItemId }</DNAText> */}
              <Iffy is={isFromPool && selectedPageIds.length >= 3}>
                <DNABox style={S.rect2} />
              </Iffy>
              <Iffy is={isFromPool && selectedPageIds.length >= 2}>
                <DNABox style={S.rect1} />
              </Iffy>
              <DNAThumbnail
                s3URL={getThumbURL(documentVersionORM, pageNumber)}
                useLoadingIndicator
                size="lg"
                checked
                mode={isFromPool ? DNAThumbnail.Modes.SELECTABLE : undefined}
                variant={DNAThumbnail.Variants.INFO}
                pageNumber={pageNumber}
                thumbnailTitle={getSlideTitle(pageNumber)}
              />
            </DNABox>
            : null
        }
      </DragOverlay>,
      window.document.body,
    )
  )
}

export default SlidesGroupOverlay
