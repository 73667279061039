import { SYNC_ENTRY_FETCH_TYPE, SYNC_ENTRY_REQUEST_TYPE, SYNC_ENTRY_STATUS, SyncEntry } from '../ISyncManifest';
import {
  CRMIntegration,
  CrmIntegrationType,
  CRMTableDetailContentPresented,
  Tenant,
} from '@alucio/aws-beacon-amplify/src/models';
import {
  CRMSubmitMeetingPayload,
  CRMSubmitResponseReferences, CRMSubmitStandaloneFormPayload,
  DeleteRecordsResponse,
  FormSettings,
  HANDLED_SYNC_ERROR,
  IndividualSalesforceCompositeResponse,
  isCompositeResponseWithArrayBodyError,
  ObjectInfo,
  ParentTableRelationships,
  RecordSubmitResponse,
  RecordToDelete,
  SalesforceCompositeUpsertPayload,
  SalesforceCompositeUpsertRecordBody,
  SalesforceCompositeUpsertResponse,
  SalesforceFirstSubmitPayload,
  SalesforceFirstSubmitResponse,
  SalesforceFormFetchResponse,
  SalesforceFormSettings,
  SalesforceRecordToUpsert,
  SYNC_STATUS,
} from '../CRMIndexedDBTypes';
import { ICRMSyncer } from '../CRMHandler';
import {
  getFirstSubmitRecordPayload,
  getRecordIdsToDelete,
  getSalesforceFirstSubmitPayload,
  getSalesforceUpdateSubmitPayload,
} from '../Translators/SalesforceFormTranslator';
import { MeetingORM } from 'src/types/orms';
import chunk from 'lodash/chunk';
import { detailContentQuery, getContentPresentedByAttendee } from './SalesforceSyncerUtils';
import { CRMIntegrationSession } from 'src/state/machines/CRM/util';
import { TABLES } from '../Translators/VeevaTranslatorUtils';
import * as logger from 'src/utils/logger'

const MIN_RECORDS_TO_FETCH = 150;

export const ERR_INVALID_SESSION_ID = 'INVALID_SESSION_ID';

export interface HANDLED_ERROR {
  [errorCode: string]: {
    [message: string]: HANDLED_SYNC_ERROR
  }
}

export enum ERROR_CODES {
  FIELD_CUSTOM_VALIDATION_EXCEPTION = 'FIELD_CUSTOM_VALIDATION_EXCEPTION',
  ENTITY_IS_DELETED = 'ENTITY_IS_DELETED'
}

enum ERROR_MESSAGES {
  ENTITY_DELETED = 'entity is deleted'
}

export const DEFAULT_SALESFORCE_HANDLED_ERRORS: HANDLED_ERROR = {
  [ERROR_CODES.ENTITY_IS_DELETED]: {
    [ERROR_MESSAGES.ENTITY_DELETED]: HANDLED_SYNC_ERROR.ENTITY_DELETED,
  },
};

class SalesforceSyncer implements ICRMSyncer {
  baseUrl: string = ''
  config: CRMIntegration = {} as CRMIntegration
  readonly API_VERSION = 'v56.0';
  // Used as a key for the CRM integration session in local storage
  CRM_AUTH_INFORMATION = 'CRM_AUTH_INFORMATION'
  errorHandler: HANDLED_ERROR;

  constructor(config: CRMIntegration) {
    if (!config) throw new Error('CRM Integration is not configured')
    if (!config.instanceUrl) throw new Error('CRM Instance URL is not configured')

    this.config = config
    this.baseUrl = `${config.instanceUrl}/services/data/${this.API_VERSION}`
    this.errorHandler = DEFAULT_SALESFORCE_HANDLED_ERRORS;

    this.handleDeleteMeeting = this.handleDeleteMeeting.bind(this)
  }

  // Add to the sync manifest the parent tables
  public initialize(): SyncEntry[] {
    const config = this.config
    const meetingSettings = config.meetingSetting;
    const entries: SyncEntry[] = [];
    const crmForms: { apiName: string, parentTable?: string, isStandaloneForm?: boolean }[] = [];

    if (!meetingSettings && !config.crmStandaloneForms?.length) return entries

    const baseUrl = this.baseUrl

    if (meetingSettings) {
      crmForms.push({
        apiName: meetingSettings.apiName,
      });
      config.meetingSetting?.children.forEach((childForm) => {
        crmForms.push({
          apiName: childForm.apiName,
          parentTable: meetingSettings.apiName,
        });
      });
    }

    config.crmStandaloneForms?.forEach((form) => {
      crmForms.push({
        apiName: form.apiName,
        isStandaloneForm: true,
        parentTable: form.parents?.[0]?.apiName,
      });
      form.children.forEach((childForm) => {
        crmForms.push({
          apiName: childForm.apiName,
          isStandaloneForm: true,
          parentTable: form.apiName,
        });
      });
    });

    const lastSynced = new Date().getTime();
    crmForms.forEach((formSetting) =>
      entries.push({
        url: `${baseUrl}/ui-api/record-defaults/create/${formSetting.apiName}`,
        parameters: {
          isStandaloneForm: !!formSetting.isStandaloneForm,
          parentId: formSetting.parentTable,
          apiName: formSetting.apiName,
          id: formSetting.apiName,
        },
        type: config.crmIntegrationType as CrmIntegrationType,
        subType: SYNC_ENTRY_REQUEST_TYPE.TABLE,
        fetchType: SYNC_ENTRY_FETCH_TYPE.REGULAR,
        id: formSetting.apiName,
        lastSynced,
        status: SYNC_ENTRY_STATUS.PENDING,
      }));

    return entries
  }

  public async processManifestEntry(
    entry: SyncEntry,
    tableAdditionalHandling?: (
      tableInfo: SalesforceFormFetchResponse,
      recordTypeId?: string) => Promise<void>): Promise<SyncEntry[]> {
    const meetingSetting = this.config.meetingSetting;
    const baseUrl = this.baseUrl;
    const fetch = this.fetchType[entry.fetchType]
    const entries = [] as SyncEntry[]

    if (!meetingSetting) return entries

    if (entry.subType === SYNC_ENTRY_REQUEST_TYPE.TABLE) {
      const tableInfo = await fetch(entry)

      entries.push(tableInfo)

      const { data: table, parameters } = tableInfo
      const { apiName } = table.record
      const { recordTypeId, sections } = table.layout
      const objectInfo = table.objectInfos[apiName]
      const apiObjectInfo = objectInfo.fields
      const { recordTypeInfos } = objectInfo

      const recordTypeInfosArr = ((recordTypeInfos): string[] => {
        // [TODO]: BEAC-5332 | IGNORE ADDITIONAL RECORD TYPES FOR VEEVA CALL DISCUSSIONS
        if (entry.parameters.apiName === TABLES.CALL_DISCUSSION) {
          return [];
        }
        const arr: string[] = []
        const keys = Object.keys(recordTypeInfos)

        // IF IT'S THE MAIN TABLE, WE'LL ONLY DISPLAY THE DEFAULT RECORD TYPE
        // (NO NEED TO FETCH ITS OTHER RECORD TYPES)
        if (apiName === meetingSetting.apiName) {
          return [];
        }

        // 1 Case
        // when there is only one record type, we don't need to fetch the record type
        // because we already have it in the main table would be table.layout.recordTypeId
        if (keys.length === 1) { return [] }

        // always we will remove the record type that we already have in the main table
        const baseRecordTypes = keys.filter((key) => key !== recordTypeId)

        // 2 Case when there are more than one record type we need to check the number of available record types
        const availableRecordTypes = baseRecordTypes.filter((key) => recordTypeInfos[key].available)
        if (availableRecordTypes.length === 0) { return [] }

        // 3 Case when there are more than one record available we don need to fetch the master due we will not use it
        const availableRecordTypesWithoutMaster = availableRecordTypes.filter((key) => !recordTypeInfos[key].master)

        availableRecordTypesWithoutMaster.forEach((key) => arr.push(key))
        return arr;
      })(recordTypeInfos)

      recordTypeInfosArr.forEach((recordTypeId) =>
        entries.push(...[{
          url: `${baseUrl}/ui-api/record-defaults/create/${apiName}?recordTypeId=${recordTypeId}`,
          type: CrmIntegrationType.SALESFORCE,
          subType: SYNC_ENTRY_REQUEST_TYPE.RECORD_TYPE,
          parameters: {
            apiName,
            recordTypeId,
            parentId: parameters.parentId,
            id: parameters.id,
            isStandaloneForm: parameters.isStandaloneForm,
          },
          fetchType: SYNC_ENTRY_FETCH_TYPE.REGULAR,
          id: `${apiName}_record_type_id_${recordTypeId}`,
          lastSynced: new Date().getTime(),
          status: SYNC_ENTRY_STATUS.PENDING,
        }, {
          url: `${baseUrl}/ui-api/object-info/${apiName}/picklist-values/${recordTypeId}`,
          type: CrmIntegrationType.SALESFORCE,
          subType: SYNC_ENTRY_REQUEST_TYPE.PICKLIST,
          parameters: { apiName, recordTypeId, parentId: parameters.id },
          fetchType: SYNC_ENTRY_FETCH_TYPE.REGULAR,
          id: `${apiName}_${recordTypeId}`,
          lastSynced: new Date().getTime(),
          status: SYNC_ENTRY_STATUS.PENDING,
        }]),
      )

      const lookupApiNames = sections
        ?.map((section) => section?.layoutRows.map((row) => row?.layoutItems.map((item) => item)))
        ?.flat(2)
        ?.filter((item) => item.lookupIdApiName === 'Id')
        ?.map((item) => item.layoutComponents)
        ?.flat()
        ?.filter((item) => apiObjectInfo[item.apiName]?.dataType === 'Reference')
        ?.map((item) => item.apiName)

      lookupApiNames.forEach((lookupApiName) => {
        const lookupEntries = this.handleAddLookupEntry(
          apiName,
          lookupApiName,
          apiObjectInfo[lookupApiName].referenceToInfos[0].apiName,
          parameters.id || '',
        );
        lookupEntries.forEach((lookupEntry) => entries.push(lookupEntry));
      });

      entries.push({
        url: `${baseUrl}/ui-api/object-info/${apiName}/picklist-values/${recordTypeId}`,
        type: CrmIntegrationType.SALESFORCE,
        subType: SYNC_ENTRY_REQUEST_TYPE.PICKLIST,
        parameters: { apiName, recordTypeId, parentId: parameters.id },
        fetchType: SYNC_ENTRY_FETCH_TYPE.REGULAR,
        id: `${apiName}_${recordTypeId}`,
        lastSynced: new Date().getTime(),
        status: SYNC_ENTRY_STATUS.PENDING,
      })

      if (tableAdditionalHandling) {
        await tableAdditionalHandling(table);
      }
    }
    else if (entry.subType === SYNC_ENTRY_REQUEST_TYPE.LOOKUP) {
      const result = await fetch(entry)
      entries.push(result)
    }
    else if (entry.subType === SYNC_ENTRY_REQUEST_TYPE.PICKLIST) {
      const result = await fetch(entry)
      entries.push(result)
    }
    else if (entry.subType === SYNC_ENTRY_REQUEST_TYPE.RECORD_TYPE) {
      const result = await fetch(entry)
      if (tableAdditionalHandling) {
        await tableAdditionalHandling(result.data, result.data.recordTypeId);
      }
      entries.push(result)
    }
    else {
      throw new Error('Invalid entry type')
    }

    return entries
  }

  protected handleAddLookupEntry(
    apiName: string, // TABLE THAT USES THE FIELD
    lookupApiName: string, // FIELD NAME IN THE TABLE
    lookupTableName: string, // TABLE'S NAME OF THE LOOKUP
    parentId: string): SyncEntry[] {
    return [{
      url: `${this.baseUrl}/query/?q=SELECT Id, Name FROM ${lookupTableName}`,
      type: CrmIntegrationType.SALESFORCE,
      subType: SYNC_ENTRY_REQUEST_TYPE.LOOKUP,
      parameters: { apiName, lookupApiName, parentId: parentId },
      fetchType: SYNC_ENTRY_FETCH_TYPE.SOQL,
      id: `${apiName}_${lookupApiName}`,
      lastSynced: new Date().getTime(),
      status: SYNC_ENTRY_STATUS.PENDING,
    }];
  }

  public async configSyncComplete(entries: SyncEntry[]): Promise<FormSettings[]> {
    const results: FormSettings[] = []
    const meetingSetting = this.config.meetingSetting;
    const standaloneForms = this.config.crmStandaloneForms;

    if (!meetingSetting && !standaloneForms?.length) return results

    const tableData: SyncEntry[] = [];
    const pickLists: SyncEntry[] = [];
    const lookups: SyncEntry[] = [];
    const recordTypes: SyncEntry[] = [];

    entries.forEach((entry) => {
      switch (entry.subType) {
        case SYNC_ENTRY_REQUEST_TYPE.LOOKUP:
          lookups.push(entry);
          break;
        case SYNC_ENTRY_REQUEST_TYPE.RECORD_TYPE:
          recordTypes.push(entry);
          break;
        case SYNC_ENTRY_REQUEST_TYPE.PICKLIST:
          pickLists.push(entry);
          break;
        case SYNC_ENTRY_REQUEST_TYPE.TABLE:
          tableData.push(entry);
          break;
      }
    });

    [
      ...tableData,
      ...recordTypes,
    ]
      .forEach(({ data: table, parameters, subType }, idx) => {
        const { apiName, recordTypeId } = table.record
        const { id: parentId, isStandaloneForm } = parameters;
        const isMeetingFormMainTable = meetingSetting?.apiName === apiName &&
          subType !== SYNC_ENTRY_REQUEST_TYPE.RECORD_TYPE;
        let tableSetting = meetingSetting?.children.find((childSetting) => apiName === childSetting.apiName);

        if (!tableSetting && isStandaloneForm) {
          standaloneForms?.forEach(({ children }) => {
            tableSetting = children.find((childSetting) => apiName === childSetting.apiName);
          });
        }

        const picklistData = pickLists.filter((picklist) =>
          picklist.parameters.recordTypeId === recordTypeId &&
          picklist.parameters.apiName === apiName,
        )
        const lookupData = lookups.filter((lookup) => lookup.parameters.parentId === parentId)
        const isMainTable = meetingSetting?.apiName === apiName && subType !== SYNC_ENTRY_REQUEST_TYPE.RECORD_TYPE;
        const isStandAloneMainTable = isStandaloneForm && standaloneForms?.some((form) => form.apiName === apiName);
        const lookupsResults = lookupData.reduce((acc, { data, parameters }) => ({
          ...acc,
          [parameters.lookupApiName || '']: {
            records: data.records.map((record) => ({ fields: record })),
          },
        }), {});

        const objectInfosBase = table.objectInfos[apiName]
        const recordTypeName = objectInfosBase.recordTypeInfos[parameters.recordTypeId || recordTypeId]?.name

        // we need to add the record type id to the id so we can differentiate between the main object that will have the same apiId
        const id = subType === SYNC_ENTRY_REQUEST_TYPE.RECORD_TYPE
          ? `${apiName}-recordType-${parameters.recordTypeId}-`
          : apiName

        const hasMoreRecordTypes = [...tableData, ...recordTypes].some((data, index) =>
          index !== idx && table.record.apiName === data.data.record.apiName);

        const label = recordTypeName && recordTypeName !== 'Master' && hasMoreRecordTypes
          ? objectInfosBase.label !== recordTypeName
            ? `${objectInfosBase.label} - ${recordTypeName}`
            : recordTypeName
          : objectInfosBase.label

        const objectInfos = {
          ...objectInfosBase,
          label: label,
        }

        const fromConfig: SalesforceFormSettings = {
          id,
          apiName,
          label: objectInfosBase.label,
          recordTypeLabel: recordTypeName,
          recordTypeId: parameters.recordTypeId,
          isMainTable: isMainTable || isStandAloneMainTable,
          presentationsFieldName: isMeetingFormMainTable ? meetingSetting?.presentationsFieldName : undefined,
          relationshipWithParentApiName: tableSetting?.relationshipName,
          parentTablesRelationship: this.getParentRelationships(objectInfos, !!isStandaloneForm),
          picklists: picklistData.map(({ data }) => data.picklistFieldValues)[0] || {},
          lookups: lookupsResults,
          layout: table.layout,
          objectInfos,
          isStandaloneForm,
          defaultRecord: table.record,
        }
        results.push(fromConfig)
      })

    return results
  }

  // WHEN A FORM IS USED, THERE MIGHT BE PLACES WHERE IT'S REQUIRED TO LINK IT WITH A PARENT ENTITY.
  // BASED ON THE GIVEN OBJECT, DETERMINES ITS PARENT TABLES/RELATIONSHIP FIELD NAMES.
  private getParentRelationships(object: ObjectInfo, isStandaloneForm: boolean): ParentTableRelationships {
    const relationship: ParentTableRelationships = {};
    const parentApiNames: string[] = [];

    // 1. GETS PARENT TABLES
    if (isStandaloneForm) {
      this.config.crmStandaloneForms?.forEach((form) => {
        if (form.apiName === object.apiName) {
          form.parents?.forEach((parent) => {
            parentApiNames.push(parent.apiName);
          })
        }
        form.children.forEach((child) => {
          if (object.apiName === child.apiName) {
            parentApiNames.push(form.apiName);
          }
        });
      });
    } else {
      this.config.meetingSetting?.children.forEach((child) => {
        if (this.config.meetingSetting && child.apiName === object.apiName) {
          parentApiNames.push(this.config.meetingSetting.apiName);
        }
      });
    }

    // 2. GET THE RELATIONSHIP NAME
    if (parentApiNames.length) {
      Object.entries(object.fields).forEach(([key, value]) => {
        value.referenceToInfos?.forEach(({ apiName }) => {
          if (parentApiNames.includes(apiName)) {
            relationship[apiName] = key;
          }
        });
      });
    }

    return relationship;
  }

  async submitToCRM(crmSubmitPayload: CRMSubmitMeetingPayload): Promise<CRMSubmitResponseReferences> {
    logger.salesforceSyncer.debug('Submit to CRM is called.', crmSubmitPayload);
    let result: CRMSubmitResponseReferences
    // IF IT'S THE FIRST TIME A MEETING IS BEING SUBMITTED, WE CAN USE THE COMPOSITE TREE SF'S
    // ENDPOINT TO CREATE ALL THE RECORDS WITH ONE CALL
    if (!crmSubmitPayload.mainCrmRecordId) {
      result = await this.handleFirstSubmit(crmSubmitPayload);
    }
    else {
      result = await this.handleUpdates(crmSubmitPayload);
    }
    await this.addBeaconContentRecords(crmSubmitPayload, result);
    return result;
  }

  async submitStandaloneFormToCRM(crmSubmitPayload: CRMSubmitStandaloneFormPayload):
    Promise<CRMSubmitResponseReferences> {
    logger.salesforceSyncer.debug('StandaloneForm Submit to CRM is called.', crmSubmitPayload);
    const isFirstSubmission = !crmSubmitPayload.recordFormORM.model.crmFields?.externalId;
    return isFirstSubmission ? this.handleFirstStandaloneFormSubmit(crmSubmitPayload) : {};
  }

  private async handleUpdates(crmSubmitPayload: CRMSubmitMeetingPayload): Promise<CRMSubmitResponseReferences> {
    logger.salesforceSyncer.debug('Handling update submit to CRM', crmSubmitPayload);
    // GETS AN ARRAY OF RECORDS TO BE UPDATED/CREATED/DELETED
    const separatedRecords = getSalesforceUpdateSubmitPayload(crmSubmitPayload);
    logger.salesforceSyncer.debug('Salesforce upserts payload', separatedRecords);

    const responses = await Promise.all([
      this.deleteRecords(separatedRecords.recordsToDelete),
      this.upsertRecords(
        crmSubmitPayload.tenant,
        separatedRecords.recordsToUpsert,
        separatedRecords.recordsToInsert),
    ]);

    return responses.reduce((acc, resp) => ({ ...acc, ...resp }), {});
  }

  public async handleDeleteMeeting(meeting: MeetingORM): Promise<CRMSubmitResponseReferences> {
    const { crmRecord } = meeting.model;

    if (!crmRecord?.crmCallId) {
      return {};
    }

    const recordToDelete = getRecordIdsToDelete(meeting)

    analytics.track('MEETING_DELETE', {
      category: 'MEETING',
      type: 'DELETE',
      integration: 'SALESFORCE',
      crmRecordId: crmRecord.crmCallId,
    });

    return this.deleteRecords(recordToDelete.concat({
      crmId: crmRecord.crmCallId,
      beaconId: 'main-record',
    }));
  }

  protected async upsertRecords(
    tenant: Tenant,
    recordsToUpsert: SalesforceRecordToUpsert[],
    insertPayload: SalesforceFirstSubmitPayload): Promise<CRMSubmitResponseReferences> {
    logger.salesforceSyncer.debug('Performing upserts...');

    // NEW INSERTS
    const response = await this.handleSubmit(insertPayload, tenant);

    // GETS THE PAYLOAD TO A COMPOSITE UPSERT
    const compositeUpsertPayloads = this.getCompositeUpsertPayload(recordsToUpsert);
    const chunks: SalesforceCompositeUpsertPayload[] = [];

    for (let i = 0; i < compositeUpsertPayloads.compositeRequest.length; i += 24) {
      chunks.push({
        compositeRequest: compositeUpsertPayloads.compositeRequest.slice(i, i + 24),
      });
    }

    // UPSERTS
    const compositeUpsertResponses = await Promise.all(chunks.map((compositeUpsertPayload) =>
      fetch(`${this.baseUrl}/composite`,
        this.getRequestHeaderBody('POST', compositeUpsertPayload))));

    const hasError = compositeUpsertResponses.some((response) => response.status >= 400)

    if (hasError) {
      logger.salesforceSyncer.error('An error occurred while upserting', compositeUpsertResponses);
      const errArr = (await Promise.all(compositeUpsertResponses
        .filter((response) => response.status >= 400)
        .map((response) => response.json())))
        .flat()
        .map((response) => response.message as string);

      logger.salesforceSyncer.error('Error messages', errArr);

      if (errArr.some((err) => err === 'Session expired or invalid')) {
        throw new ErrorEvent('INVALID_SESSION_ID', { error: 'Session expired or invalid' });
      }

      throw new ErrorEvent('An error occurred while upserting', { error: errArr });
    }

    const parsedResponses: SalesforceCompositeUpsertResponse[] =
      await Promise.all(compositeUpsertResponses.map((response) => response.json()));

    const parsedResponse = parsedResponses.reduce((acc, response) => ({
      compositeResponse: [...acc.compositeResponse, ...response.compositeResponse],
    }), { compositeResponse: [] });
    logger.salesforceSyncer.debug('Composite request response', parsedResponse);

    const formattedResponse: CRMSubmitResponseReferences =
      parsedResponse.compositeResponse.reduce<CRMSubmitResponseReferences>((acc, response, idx) => ({
        ...acc,
        [recordsToUpsert[idx].beaconId]: this.processCompositeRequestResponse(
          response, recordsToUpsert[idx].salesforceId),
      }), response);

    logger.salesforceSyncer.debug('Formatted upserts response', formattedResponse);
    return formattedResponse;
  }

  // ** FORMATS A COMPOSITE RESPONSE AND CHECK ERRORS ** //
  protected processCompositeRequestResponse(
    response: IndividualSalesforceCompositeResponse, externalId?: string): RecordSubmitResponse {
    const hasErrors = ![201, 204].includes(response.httpStatusCode);
    const formattedResponse: RecordSubmitResponse = {
      errorMessages: [],
      syncStatus: hasErrors ? SYNC_STATUS.ERROR : SYNC_STATUS.SYNCED,
      externalId,
    };

    if (isCompositeResponseWithArrayBodyError(response.body)) {
      if (hasErrors) {
        response.body.forEach((body) => {
          body.message && formattedResponse.errorMessages?.push(body.message);
          // CHECKS IF THE ERROR IS HANDLED
          if (this.errorHandler[body.errorCode]?.[body.message]) {
            formattedResponse.handledError = this.errorHandler[body.errorCode][body.message];
          }
        });
      }
    } else if (response.body?.id) {
      formattedResponse.externalId = response.body?.id;
    }

    return formattedResponse;
  }

  private getCompositeUpsertPayload(upserts: SalesforceRecordToUpsert[]): SalesforceCompositeUpsertPayload {
    const baseUrl = `/services/data/${this.API_VERSION}/sobjects`;
    return {
      compositeRequest: upserts.map<SalesforceCompositeUpsertRecordBody>((upsert, idx) => ({
        body: upsert.fields,
        method: upsert.salesforceId ? 'PATCH' : 'POST',
        referenceId: idx.toString(),
        url: `${baseUrl}/${upsert.apiName}${upsert.salesforceId ? '/' + upsert.salesforceId : ''}`,
      })),
    };
  }

  protected async deleteRecords(records: RecordToDelete[]): Promise<CRMSubmitResponseReferences> {
    if (!records.length) {
      return {};
    }
    logger.salesforceSyncer.debug('Deleting the following records: ', records);
    const authHeaders = this.getAuthHeader();
    const headers = {
      method: 'DELETE',
      headers: authHeaders,
    }

    const chunks = chunk<RecordToDelete>(records, 25);
    const responses = await Promise.all<Promise<Response>[]>(chunks.map((chunk) => {
      const ids = chunk.map(({ crmId }) => crmId);
      const url = `${this.baseUrl}/composite/sobjects?ids=${ids.join(',')}`;
      return fetch(url, headers);
    }));
    const jsons: DeleteRecordsResponse[] = await Promise.all(responses
      .map((response) => response?.json()));
    logger.salesforceSyncer.debug('Got delete records responses', jsons.flat());

    if (jsons.some((res) => Array.isArray(res) && res.some((resp) => resp.errorCode === 'INVALID_SESSION_ID'))) {
      throw new ErrorEvent('INVALID_SESSION_ID', { error: 'Session expired or invalid' });
    }

    return jsons.flat().reduce<CRMSubmitResponseReferences>((acc, response) => {
      const beaconId = records.find(({ crmId }) => crmId === response.id)?.beaconId || '';
      const errors = response.errors?.reduce<string[]>((acc, { message }) => {
        if (message === 'entity is deleted') {
          return acc;
        }
        return acc.concat(message);
      }, []);

      return {
        ...acc,
        [beaconId]: {
          syncStatus: errors?.length ? SYNC_STATUS.ERROR : SYNC_STATUS.DELETED,
          externalId: response.id,
          errorMessages: errors,
        },
      };
    }, {});
  }

  protected async handleFirstSubmit(crmSubmitPayload: CRMSubmitMeetingPayload): Promise<CRMSubmitResponseReferences> {
    logger.salesforceSyncer.debug('Handling first submit to CRM');
    const payload = getSalesforceFirstSubmitPayload(crmSubmitPayload);
    return this.handleSubmit(payload, crmSubmitPayload.tenant);
  }

  protected async handleFirstStandaloneFormSubmit(crmSubmitPayload: CRMSubmitStandaloneFormPayload):
    Promise<CRMSubmitResponseReferences> {
    logger.salesforceSyncer.debug('Handling first standalone form submit to CRM');
    const { standaloneForm, recordFormORM, tenant } = crmSubmitPayload;

    if (!standaloneForm.crmFormSetting) {
      throw Error('Settings for main table not found.');
    }

    const payload = getFirstSubmitRecordPayload(standaloneForm.crmFormSetting.apiName, recordFormORM.model.id,
      recordFormORM.meta.formValues, standaloneForm.crmFormSetting, [standaloneForm.crmFormSetting]);
    return this.handleSubmit({ records: [payload] }, tenant, standaloneForm.crmFormSetting.apiName);
  }

  private async handleSubmit(
    payload: SalesforceFirstSubmitPayload,
    tenant: Tenant, apiName?: string): Promise<CRMSubmitResponseReferences> {
    if (!payload.records.length) {
      return {};
    }

    const chunks: SalesforceFirstSubmitPayload[] = []

    for (let i = 0; i < payload.records.length; i += 20) {
      chunks.push({
        records: payload.records.slice(i, i + 20),
      });
    }

    const responses = await Promise.all(chunks.map((chunk) => this.postFirstSubmit(chunk, tenant, apiName)));

    return responses.reduce<CRMSubmitResponseReferences>((acc, response) => {
      if (response.hasErrors) {
        logger.salesforceSyncer.error('An error occurred ', response.results);
      }

      if (Array.isArray(response) && response.some((res) => res.errorCode === 'INVALID_SESSION_ID')) {
        throw new ErrorEvent('INVALID_SESSION_ID', { error: 'Session expired or invalid' });
      }

      return response.results.reduce<CRMSubmitResponseReferences>((acc, result) => {
        const errors = result.errors?.map((error) => error.message) || [];
        return {
          ...acc,
          [result.referenceId]: {
            externalId: result.id,
            syncStatus: errors.length ? SYNC_STATUS.ERROR : SYNC_STATUS.SYNCED,
            errorMessages: errors,
          },
        }
      }, {});
    }, {});
  }

  protected async postFirstSubmit(payload: SalesforceFirstSubmitPayload, tenant: Tenant, apiName?: string)
    : Promise<SalesforceFirstSubmitResponse> {
    logger.salesforceSyncer.debug('Calling endpoint', payload);

    if (!tenant.config.crmIntegration?.meetingSetting?.apiName && !apiName) {
      throw new Error('API name is not defined')
    }

    const mainTableName = apiName || tenant.config.crmIntegration?.meetingSetting?.apiName;
    const url = `${this.baseUrl}/composite/tree/${mainTableName}`;
    const response = await fetch(url, this.getRequestHeaderBody('POST', payload));
    const json: SalesforceFirstSubmitResponse = await response.json();
    logger.salesforceSyncer.debug('Got response from API', json);
    return json;
  }

  /// ///////////////////////
  // Utility functions
  /// ///////////////////////

  protected getAuthInformation = (): CRMIntegrationSession['authInformation'] => {
    const authInformation = localStorage.getItem(this.CRM_AUTH_INFORMATION)
      ? JSON.parse(localStorage.getItem(this.CRM_AUTH_INFORMATION) as string)
      : undefined;

    if (!authInformation) {
      throw new Error('No CRM auth information found');
    }

    return authInformation;
  }

  private getAuthHeader = () => {
    const authInformation = this.getAuthInformation();
    const accessToken = authInformation.accessToken;
    return {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };
  }

  private getRequestHeaderBody = (method: 'POST' | 'PATCH', payload) => {
    const authHeader = this.getAuthHeader();
    return {
      method,
      headers: authHeader,
      body: JSON.stringify(payload),
    }
  };

  private fetch = async (entry: SyncEntry, options?: RequestInit): Promise<SyncEntry> => {
    try {
      const authHeader = this.getAuthHeader();
      const headers = {
        method: options?.method || 'GET',
        headers: authHeader,
      }
      const response = await fetch(entry.url, headers);
      if (response.status === 401) {
        throw new Error(`Auth error entry ${entry.url}`);
      }
      else if (response.status === 403) {
        throw new Error(`Forbidden error entry ${entry.url}`);
      }
      else if (response.status >= 400) {
        throw new Error(`Error fetching ${entry.url} status ${response.status}`);
      }

      const json = await response.json()
      return { ...entry, data: json, status: SYNC_ENTRY_STATUS.SYNCED };
    } catch (error) {
      if ((error as { message: string }).message.startsWith('Auth error entry')) {
        throw new ErrorEvent(ERR_INVALID_SESSION_ID, { error: 'Session expired or invalid' });
      }
      return { ...entry, data: undefined, status: SYNC_ENTRY_STATUS.ERROR };
    }
  }

  protected fetchSOQL = async (entry: SyncEntry, options?: RequestInit,
    recordsToFetch: number = MIN_RECORDS_TO_FETCH): Promise<SyncEntry> => {
    try {
      const limit = recordsToFetch < MIN_RECORDS_TO_FETCH ? MIN_RECORDS_TO_FETCH : recordsToFetch;
      const includesLimit = entry.url.includes(' LIMIT ');
      const maxRecords = limit;
      let offset = 0;
      const authHeader = this.getAuthHeader();
      const headers = {
        method: options?.method || 'GET',
        headers: authHeader,
      }

      const data: { Id: string, Name: string }[] = [];

      while (true) {
        const url = `${entry.url} ${!includesLimit ? `LIMIT ${limit}` : ''} OFFSET ${offset}`;
        const response = await fetch(url, headers);
        if (response.status >= 400) {
          throw new Error(`Error fetching ${url}`);
        }
        const json = await response.json()

        if (!json.records.length) {
          break;
        }

        data.push(...json.records);

        // means we have reached the end of the records
        if (json.records.length < limit) {
          break;
        }

        if (data.length >= maxRecords) {
          break;
        }

        offset += maxRecords;
      }

      return {
        ...entry,
        data: {
          done: true,
          totalSize: data.length,
          records: data,
        },
        status: SYNC_ENTRY_STATUS.SYNCED,
      };
    } catch (error) {
      return { ...entry, data: undefined, status: SYNC_ENTRY_STATUS.ERROR };
    }
  }

  fetchType = {
    [SYNC_ENTRY_FETCH_TYPE.REGULAR]: this.fetch,
    [SYNC_ENTRY_FETCH_TYPE.SOQL]: this.fetchSOQL,
  }

  protected async addBeaconContentRecords(crmSubmitPayload: CRMSubmitMeetingPayload,
    responseReference: CRMSubmitResponseReferences): Promise<void> {
    const detailContentTableSettings =
      crmSubmitPayload.tenant.config.crmIntegration?.additionalSettings?.detailContentTableSettings

    if (!detailContentTableSettings || !detailContentTableSettings.apiName) return;

    const contentPresentedByAttendee = getContentPresentedByAttendee(crmSubmitPayload, responseReference);

    const contentPresentedCRMRecords = await this.getContentPresentedCRMRecords(
      detailContentTableSettings,
      responseReference,
      contentPresentedByAttendee.length,
      crmSubmitPayload,
    );

    const recordsToDelete = contentPresentedCRMRecords
      .data.records.filter((record) => !contentPresentedByAttendee.some((beaconContent) =>
        beaconContent.call_account_beac__c === record.call_account_beac__c &&
        beaconContent.content_id_beac__c === record.content_id_beac__c &&
        beaconContent.page_number_beac__c === record.page_number_beac__c)).map((record) => {
        return {
          beaconId: 'main-record',
          crmId: record.Id!,
        }
      },
      )

    const recordsToUpsert = contentPresentedByAttendee.map((record) => {
      const salesforceId = contentPresentedCRMRecords.data.records.find((parsedContent) =>
        record.call_account_beac__c === parsedContent.call_account_beac__c &&
        record.content_id_beac__c === parsedContent.content_id_beac__c &&
        record.page_number_beac__c === parsedContent.page_number_beac__c)?.Id
      const fields = Object.keys(record).reduce((acc, key) => {
        acc[key] = record[key];
        return acc;
      }
      , {} as Record<string, any>);
      if (salesforceId) delete fields.call_call_beac__c;
      return {
        beaconId: 'main-record',
        apiName: detailContentTableSettings.apiName,
        fields,
        salesforceId,
      }
    },
    )

    const responses = await Promise.all([
      await this.deleteRecords(recordsToDelete),
      await this.upsertRecords(crmSubmitPayload.tenant, recordsToUpsert, { records: [] })],
    );
    const result = responses.reduce((acc, resp) => ({ ...acc, ...resp }), {});
    Object.values(result).forEach((value) => {
      if (value.syncStatus === SYNC_STATUS.ERROR) {
        throw Error('Error adding beacon content records', result);
      }
    });
  }

  private async getContentPresentedCRMRecords(tableDetailContentPresented: CRMTableDetailContentPresented,
    responseReference: CRMSubmitResponseReferences, limit: number, payload: CRMSubmitMeetingPayload) {
    const { apiName, name, relationshipName } = tableDetailContentPresented
    const callIds = Object.values(responseReference).map((reference) => reference.externalId);
    const callIdsString = `('${callIds.join('\',\'')}')`;
    const query = detailContentQuery(apiName, relationshipName, callIdsString, payload);
    const detailContentQueryEntry: SyncEntry = {
      type: CrmIntegrationType.SALESFORCE,
      subType: SYNC_ENTRY_REQUEST_TYPE.LOOKUP,
      fetchType: SYNC_ENTRY_FETCH_TYPE.SOQL,
      lastSynced: new Date().getTime(),
      status: SYNC_ENTRY_STATUS.PENDING,
      parameters: {},
      url: `${this.baseUrl}/query/?q=${query}`,
      id: name,
    }

    return this.fetchSOQL(detailContentQueryEntry, undefined, limit)
  }
}

export { SalesforceSyncer }
