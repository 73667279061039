import { createMachine } from 'xstate';
import { assign } from '@xstate/immer'
import format from 'date-fns/format';
import type { ShareStateMachine } from './shareMachine.types'
import store from '../../redux/store'
import { ShareVariantOptions } from 'src/components/DNA/Modal/ContentShareModal/ContentShareModal';
import { AssociatedFileType, ShareType } from '@alucio/aws-beacon-amplify/src/models';
import { canPerformAction, documentVersionORMById } from 'src/state/redux/selector/document';
import { DocumentVersionORM, FolderItemORM } from 'src/types/orms';
import {
  copyHubsEmailTemplate,
  copyHubsLink,
  copyLink,
  copyShareEmailTemplate,
  getAndUpdateReduxContentShare,
  getAttachmentsToShare,
  getDocVerORMFromEntityShareIds,
  getHubORMFromEntityShareIds,
  getMainFileShareOptions,
  getSelectedNumOfContent,
  getShareFileOption,
  getUser,
  shareEmailLinks,
  shareEmailTemplate,
  shareHubsEmailTemplate,
  shareHubsLink,
  ShareFileOptionMapping,
  getCustomDeckDependencies,
  getItemORMFromEntityShareIds,
} from './shareUtils';
import { ShareFileOption } from 'src/utils/shareLink/shareLink.web';
import { tenant as getTenant } from 'src/state/redux/selector/tenant'
import config from 'src/config/app.json';
import { DOCUMENT_ACTIONS_ENUM, isCustomDeckORM } from 'src/types/types';
import { folderItemORMByIdNoFolder } from 'src/state/redux/selector/folder';

export const shareType: Record<Exclude<ShareType, 'RECORDING'>, string> = {
  [ShareType.HUB]: 'hubs',
  [ShareType.DOCUMENT_VERSION]: 'share',
  [ShareType.ATTACHED_FILE]: 'share',
  [ShareType.CUSTOM_DECK]: 'share',
}

export enum TAGS {
  HUBS = 'HUBS',
  DOC_SHARE = 'DOC_SHARE',
  COPY_FILE_NAME_SUCCESS = 'COPY_FILE_NAME_SUCCESS',
  COPY_FILE_NAME_ERROR = 'COPY_FILE_NAME_ERROR',
  COPY_EMAIL_SUCCESS = 'COPY_EMAIL_SUCCESS',
  COPY_EMAIL_ERROR = 'COPY_EMAIL_ERROR',
  OPEN_EMAIL_SUCCESS = 'OPEN_EMAIL_SUCCESS',
  OPEN_EMAIL_ERROR = 'OPEN_EMAIL_ERROR',
}

export const shareMachine = createMachine(
  {
    id: 'shareMachine',
    tsTypes: {} as import('./shareMachine.machine.typegen').Typegen0,
    preserveActionOrder: true,
    context: {
      entityShareIds: undefined,
      selectedEmailTemplate: undefined,
      variant: undefined!,
      toast: undefined!,
      availableContentToShare: undefined,
      existingShareLink: undefined,
      existingContentShareORM: undefined,
      meetingId: undefined,
      hubTitle: undefined,
      totalNumAvailableContent: 0,
      selectedNumContent: 0,
      isCopyingFile: false,
      isOpeningEmail: false,
      isCopyingEmail: false,
    },
    schema: {
      context: {} as ShareStateMachine.Context,
      events: {} as ShareStateMachine.Events,
      services: {} as ShareStateMachine.Services,
    },
    initial: 'determine',
    states: {
      determine: {
        always: [
          { target: 'hubShare', cond: 'isHubShare' },
          { target: 'docShare', cond: 'isDocShare' },
          { target: 'customDeckShare', cond: 'isCustomDeckShare' },
        ],
      },
      hubShare: {
        initial: 'determine',
        states: {
          determine: {
            always: [
              { target: 'resharingExistingLinks', cond: 'hasExistingShareLinks' },
              { target: 'generatingNewLinks' },
            ],
          },
          generatingNewLinks: {
            initial: 'loadContent',
            states: {
              loadContent: {
                description: 'loading things',
                always: {
                  actions: 'loadContent',
                  target: 'idle',
                },
              },
              idle: {
                description: 'waiting',
                on: {
                  RESET_FORM:{
                    actions: 'loadContent',
                  },
                  UPDATE_EMAIL_TEMPLATE: {
                    actions: 'updateEmailTemplateSelected',
                  },
                  COPY_LINK: {
                    target: 'copyHubsLink',
                  },
                  COPY_EMAIL: {
                    target: 'copyHubsEmail',
                  },
                  OPEN_EMAIL: {
                    target: 'openHubsEmail',
                  },
                },
              },
              copyHubsLink: {
                invoke: {
                  src: 'copyHubsLink',
                  onDone: {
                    actions: 'updateCopyFileNameStatus',
                    target: 'copyHubsLinkSuccess',
                  },
                  onError: {
                    actions: 'updateCopyFileNameStatus',
                    target: 'copyHubsLinkError',
                  },
                },
              },
              copyHubsLinkSuccess: {
                tags: [TAGS.COPY_FILE_NAME_SUCCESS, TAGS.HUBS],
                after: {
                  3000: { target: 'idle' },
                },
              },
              copyHubsLinkError: {
                tags: [TAGS.COPY_FILE_NAME_ERROR, TAGS.HUBS],
                after: {
                  3000: { target: 'idle' },
                },
              },
              copyHubsEmail: {
                invoke: {
                  src: 'copyHubsEmail',
                  onDone: {
                    actions: 'updateCopyEmailStatus',
                    target: 'copyHubsEmailSuccess',
                  },
                  onError: {
                    actions: 'updateCopyEmailStatus',
                    target: 'copyHubsEmailError',
                  },
                },
              },
              copyHubsEmailSuccess: {
                tags: [TAGS.COPY_EMAIL_SUCCESS, TAGS.HUBS],
                after: {
                  3000: { target: 'idle' },
                },
              },
              copyHubsEmailError: {
                tags: [TAGS.COPY_EMAIL_ERROR, TAGS.HUBS],
                after: {
                  3000: { target: 'idle' },
                },
              },
              openHubsEmail: {
                invoke: {
                  src: 'openHubsEmail',
                  onDone: {
                    actions: 'updateOpenEmailStatus',
                    target: 'openHubsEmailSuccess',
                  },
                  onError: {
                    actions: 'updateOpenEmailStatus',
                    target: 'openHubsEmailError',
                  },
                },
              },
              openHubsEmailSuccess: {
                tags: [TAGS.OPEN_EMAIL_SUCCESS, TAGS.HUBS],
                after: {
                  3000: { target: 'idle' },
                },
              },
              openHubsEmailError: {
                tags: [TAGS.OPEN_EMAIL_ERROR, TAGS.HUBS],
                after: {
                  3000: { target: 'idle' },
                },
              },
            },
          },
          resharingExistingLinks: {
            initial: 'loadReshareContent',
            states: {
              loadReshareContent: {
                always: {
                  actions: 'loadReshareContent',
                  target: 'idle',
                },
              },
              idle: {
                on: {
                  RESET_FORM:{
                    actions: 'loadContent',
                  },
                  UPDATE_EMAIL_TEMPLATE: {
                    actions: 'updateEmailTemplateSelected',
                  },
                  COPY_LINK: {
                    target: 'copyHubsLink',
                  },
                  COPY_EMAIL: {
                    target: 'copyHubsEmail',
                  },
                  OPEN_EMAIL: {
                    target: 'openHubsEmail',
                  },
                },
              },
              copyHubsLink: {
                invoke: {
                  src: 'copyHubsLink',
                  onDone: {
                    actions: 'updateCopyFileNameStatus',
                    target: 'copyHubsLinkSuccess',
                  },
                  onError: {
                    actions: 'updateCopyFileNameStatus',
                    target: 'copyHubsLinkError',
                  },
                },
              },
              copyHubsLinkSuccess: {
                tags: [TAGS.COPY_FILE_NAME_SUCCESS, TAGS.HUBS],
                after: {
                  3000: { target: 'idle' },
                },
              },
              copyHubsLinkError: {
                tags: [TAGS.COPY_FILE_NAME_ERROR, TAGS.HUBS],
                after: {
                  3000: { target: 'idle' },
                },
              },
              copyHubsEmail: {
                invoke: {
                  src: 'copyHubsEmail',
                  onDone: {
                    actions: 'updateCopyEmailStatus',
                    target: 'copyHubsEmailSuccess',
                  },
                  onError: {
                    actions: 'updateCopyEmailStatus',
                    target: 'copyHubsEmailError',
                  },
                },
              },
              copyHubsEmailSuccess: {
                tags: [TAGS.COPY_EMAIL_SUCCESS, TAGS.HUBS],
                after: {
                  3000: { target: 'idle' },
                },
              },
              copyHubsEmailError: {
                tags: [TAGS.COPY_EMAIL_ERROR, TAGS.HUBS],
                after: {
                  3000: { target: 'idle' },
                },
              },
              openHubsEmail: {
                invoke: {
                  src: 'openHubsEmail',
                  onDone: {
                    actions: 'updateOpenEmailStatus',
                    target: 'openHubsEmailSuccess',
                  },
                  onError: {
                    actions: 'updateOpenEmailStatus',
                    target: 'openHubsEmailError',
                  },
                },
              },
              openHubsEmailSuccess: {
                tags: [TAGS.OPEN_EMAIL_SUCCESS, TAGS.HUBS],
                after: {
                  3000: { target: 'idle' },
                },
              },
              openHubsEmailError: {
                tags: [TAGS.OPEN_EMAIL_ERROR, TAGS.HUBS],
                after: {
                  3000: { target: 'idle' },
                },
              },
            },
          },
        },
      },
      docShare: {
        initial: 'loadContent',
        states: {
          loadContent: {
            description: 'loadContent populates the required associated files and main doc in the contentToShare',
            always: {
              actions: 'loadContent',
              target: 'idle',
            },
          },
          idle: {
            description: 'Waiting for user to select available options',
            on: {
              UPDATE_ASSOCIATED_FILES:  { // remain in idle, this is updating the select all option for associated files
                actions: 'updateAssociatedFiles',
              },
              UPDATE_EMAIL_TEMPLATE: { // remain in idle updating selected email template
                actions: 'updateEmailTemplateSelected',
              },
              TOGGLE_SELECT_ALL: { // remain in idle, this is updating the select all option for associated files
                actions: 'toggleSelectAll',
              },
              RESET_FORM:{ // remain in idle, when user presses reset, the form goes back to the inital state in loadContent
                actions: 'loadContent',
              },
              COPY_LINK: {
                target: 'copyLink',
              },
              COPY_EMAIL: {
                target: 'copyEmail',
              },
              OPEN_EMAIL: {
                target: 'openEmail',
              },
            },
          },
          copyLink: {
            invoke: {
              src: 'copyFileName',
              onDone: {
                actions: 'updateCopyFileNameStatus',
                target: 'copyFileNameSuccess',
              },
              onError: {
                actions: 'updateCopyFileNameStatus',
                target: 'copyFileNameError',
              },
            },
          },
          copyFileNameSuccess: {
            tags: [TAGS.COPY_FILE_NAME_SUCCESS, TAGS.DOC_SHARE],
            after: {
              3000: { target: 'idle' },
            },
          },
          copyFileNameError: {
            tags: [TAGS.COPY_FILE_NAME_ERROR, TAGS.DOC_SHARE],
            after: {
              3000: { target: 'idle' },
            },
          },
          copyEmail: {
            invoke: {
              src: 'copyEmail',
              onDone: {
                actions: 'updateCopyEmailStatus',
                target: 'copyEmailSuccess',
              },
              onError: {
                actions: 'updateCopyEmailStatus',
                target: 'copyEmailError',
              },
            },
          },
          copyEmailSuccess: {
            tags: [TAGS.COPY_EMAIL_SUCCESS, TAGS.DOC_SHARE],
            after: {
              3000: { target: 'idle' },
            },
          },
          copyEmailError: {
            tags: [TAGS.COPY_EMAIL_ERROR, TAGS.DOC_SHARE],
            after: {
              3000: { target: 'idle' },
            },
          },
          openEmail: {
            invoke: {
              src: 'openEmail',
              onDone: {
                actions: 'updateOpenEmailStatus',
                target: 'openEmailSuccess',
              },
              onError: {
                actions: 'updateOpenEmailStatus',
                target: 'openEmailError',
              },
            },
          },
          openEmailSuccess: {
            tags: [TAGS.OPEN_EMAIL_SUCCESS, TAGS.DOC_SHARE],
            after: {
              3000: { target: 'idle' },
            },
          },
          openEmailError: {
            tags: [TAGS.OPEN_EMAIL_ERROR, TAGS.DOC_SHARE],
            after: {
              3000: { target: 'idle' },
            },
          },
        },
      },
      customDeckShare: {
        initial: 'loadCustomDeckContent',
        states: {
          loadCustomDeckContent: {
            description: 'loadCustomDeckContent loads custom fields and email templates if there are any.',
            always: {
              actions: 'loadCustomDeckContent',
              target: 'idle',
            },
          },
          idle: {
            description: 'Waiting for user to select available options',
            on: {
              UPDATE_EMAIL_TEMPLATE: { // remain in idle updating selected email template
                actions: 'updateEmailTemplateSelected',
              },
              RESET_FORM:{ // remain in idle, when user presses reset, the form goes back to the inital state in loadContent
                actions: 'loadCustomDeckContent',
              },
              COPY_LINK: {
                target: 'copyCustomDeckName',
              },
              COPY_EMAIL: {
                target: 'copyCustomDeckEmail',
              },
              OPEN_EMAIL: {
                target: 'openCustomDeckEmail',
              },
            },
          },
          copyCustomDeckName: {
            invoke: {
              src: 'copyCustomDeckName',
              onDone: {
                actions: 'updateCopyFileNameStatus',
                target: 'copyFileNameSuccess',
              },
              onError: {
                actions: 'updateCopyFileNameStatus',
                target: 'copyFileNameError',
              },
            },
          },
          copyFileNameSuccess: {
            tags: [TAGS.COPY_FILE_NAME_SUCCESS, TAGS.DOC_SHARE],
            after: {
              3000: { target: 'idle' },
            },
          },
          copyFileNameError: {
            tags: [TAGS.COPY_FILE_NAME_ERROR, TAGS.DOC_SHARE],
            after: {
              3000: { target: 'idle' },
            },
          },
          copyCustomDeckEmail: {
            invoke: {
              src: 'copyCustomDeckEmail',
              onDone: {
                actions: 'updateCopyEmailStatus',
                target: 'copyEmailSuccess',
              },
              onError: {
                actions: 'updateCopyEmailStatus',
                target: 'copyEmailError',
              },
            },
          },
          copyEmailSuccess: {
            tags: [TAGS.COPY_EMAIL_SUCCESS, TAGS.DOC_SHARE],
            after: {
              3000: { target: 'idle' },
            },
          },
          copyEmailError: {
            tags: [TAGS.COPY_EMAIL_ERROR, TAGS.DOC_SHARE],
            after: {
              3000: { target: 'idle' },
            },
          },
          openCustomDeckEmail: {
            invoke: {
              src: 'openCustomDeckEmail',
              onDone: {
                actions: 'updateOpenEmailStatus',
                target: 'openEmailSuccess',
              },
              onError: {
                actions: 'updateOpenEmailStatus',
                target: 'openEmailError',
              },
            },
          },
          openEmailSuccess: {
            tags: [TAGS.OPEN_EMAIL_SUCCESS, TAGS.DOC_SHARE],
            after: {
              3000: { target: 'idle' },
            },
          },
          openEmailError: {
            tags: [TAGS.OPEN_EMAIL_ERROR, TAGS.DOC_SHARE],
            after: {
              3000: { target: 'idle' },
            },
          },
        },
      },
    },
  },
  {
    guards: {
      isHubShare: (ctx) => {
        return ctx.variant === ShareVariantOptions.HUB_SHARE
      },
      isDocShare: (ctx) => {
        return ctx.variant === ShareVariantOptions.DOC_VER_SHARE
      },
      isCustomDeckShare: (ctx) => {
        return ctx.variant === ShareVariantOptions.CUSTOM_DECK_SHARE
      },
      hasExistingShareLinks: (ctx) => {
        return !!ctx.existingContentShareORM
      },
    },
    actions: {
      loadContent: assign((ctx) => {
        const reduxState = store.getState()
        // the next two lines are here because reset form button and loadContent do the same thing
        ctx.availableContentToShare = undefined
        ctx.selectedEmailTemplate = undefined
        // TODO better way to get tenant? we are using the selector from redux to get tenant but we are getting the id from current redux store
        // seems like we are going in circles to get the tenant
        const tenant = getTenant(reduxState, undefined, reduxState.tenant.records[0].id)
        if (ctx.entityShareIds) {
          const documentVersionORMs: DocumentVersionORM[] = []
          ctx.entityShareIds.forEach(shareId => {
            const docVerORM = documentVersionORMById(reduxState, shareId)
            if (docVerORM) documentVersionORMs.push(docVerORM)
          })

          const getInitialShareFileOptions = (docVerORM: DocumentVersionORM) => {
            const canBeSharedByMSL = canPerformAction(
              DOCUMENT_ACTIONS_ENUM.share,
              docVerORM.meta.customValues.configsMap,
              tenant?.tenant!,
            )
            const mainFileShareOptions = getMainFileShareOptions(
              docVerORM,
              tenant?.tenant!,
              canBeSharedByMSL,
            )
            const shareFileOptionsList: ShareFileOption[] = [mainFileShareOptions]
            docVerORM.relations.associatedFiles?.forEach(af => {
              if (af.meta.canBeSharedByMSL) {
                const shareFileOption = getShareFileOption(af)
                shareFileOptionsList.push(shareFileOption)
              }
            })
            return shareFileOptionsList
          }

          const allContentAvailableToShareInit = documentVersionORMs
            .reduce<ShareFileOptionMapping>((acc, docVerORM) => {
              acc[docVerORM.model.id] = getInitialShareFileOptions(docVerORM)
              return acc
            }, {} as ShareFileOptionMapping)

          ctx.availableContentToShare = allContentAvailableToShareInit

          const allDocVerORMArr = Object
            .values(allContentAvailableToShareInit)
            .flat(1)
            .filter(sfo => sfo.isDistributable)
          let selectedNumContent = getSelectedNumOfContent(allDocVerORMArr)
          // we are increasing by one here becasue allDocVerORMArr is only docVerORM and not hubORM
          if (ctx.variant === ShareVariantOptions.HUB_SHARE) selectedNumContent++
          ctx.totalNumAvailableContent = allDocVerORMArr.length
          ctx.selectedNumContent = selectedNumContent
        }
      }),
      loadCustomDeckContent: assign((ctx) => {
        const reduxState = store.getState()
        // the next two lines are here because reset form button and loadContent do the same thing
        ctx.availableContentToShare = undefined
        ctx.selectedEmailTemplate = undefined
        if (ctx.entityShareIds) {
          ctx.entityShareIds.forEach(shareId => {
            const folderItemORM: FolderItemORM =
              folderItemORMByIdNoFolder(reduxState, { id: shareId, folderId: undefined })
            // shareId should be folderItemORM.model.id
            if (folderItemORM && isCustomDeckORM(folderItemORM.relations.itemORM)) {
              const customDeckFileShareOptions: ShareFileOptionMapping = {
                [folderItemORM.model.id]: [{
                  isMainDoc: true,
                  isDefault: false,
                  beingShared: true,
                  isDistributable: true,
                  contentProps: {
                    contentId: folderItemORM.model.id,
                    title: folderItemORM.relations.itemORM.model.title,
                    type: ShareType.CUSTOM_DECK,
                    sharedContentId: folderItemORM.model.id,
                    customDeckDependencies: getCustomDeckDependencies(folderItemORM),
                  },
                }],
              }
              ctx.availableContentToShare = customDeckFileShareOptions

              const customDeckORMShareFileOption = Object
                .values(customDeckFileShareOptions)
                .flat(1)
                .filter(sfo => sfo.isDistributable)
              let selectedNumContent = getSelectedNumOfContent(customDeckORMShareFileOption)
              // we are increasing by one here becasue we use this variable to determine the copy name and title button state
              if (ctx.variant === ShareVariantOptions.CUSTOM_DECK_SHARE) selectedNumContent++
              ctx.totalNumAvailableContent = customDeckORMShareFileOption.length
              ctx.selectedNumContent = selectedNumContent
            }
          })
        }
      }),
      updateAssociatedFiles: assign((ctx, evt) => {
        const { availableContentToShare } = ctx
        if (!availableContentToShare) return
        // TODO why is this evt type never
        // @ts-ignore
        const { mainDocVerId, entityORM } = evt.ids
        const targetedEntityId = entityORM.type === 'DOCUMENT_VERSION'
          ? entityORM.model.id
          : entityORM.model.type === AssociatedFileType.ATTACHED_FILE
            ? entityORM.file.id
            : entityORM.relations.latestUsableDocumentVersion.id!

        // toggle value
        if (!availableContentToShare[mainDocVerId]) return false
        availableContentToShare[mainDocVerId].forEach(sfo => {
          if (sfo.contentProps.contentId === targetedEntityId) {
            sfo.beingShared = !sfo.beingShared
          }
        })

        const allDocVerORMArr = Object
          .values(availableContentToShare)
          .flat(1)
          .filter(sfo => sfo.isDistributable)
        const selectedNumContent = getSelectedNumOfContent(allDocVerORMArr)
        ctx.totalNumAvailableContent = allDocVerORMArr.length
        ctx.selectedNumContent = selectedNumContent
      }),
      updateEmailTemplateSelected: assign((ctx, evt) => {
        const { emailTemplateORM } = evt
        // TODO why is evt typed as never?
        // @ts-ignore
        ctx.selectedEmailTemplate?.model.id === emailTemplateORM.model.id
          ? ctx.selectedEmailTemplate = undefined
          : ctx.selectedEmailTemplate = emailTemplateORM
      }),
      // not sure if this should be an action or a service, for Hubs we need the return value but not for Document share
      updateCopyFileNameStatus:  assign((ctx) => {
        ctx.isCopyingFile = false
      }),
      updateCopyEmailStatus: assign((ctx) => {
        ctx.isCopyingEmail = false
      }),
      updateOpenEmailStatus: assign((ctx) => {
        ctx.isOpeningEmail = false
      }),
      loadReshareContent: assign((ctx) => {
        const { existingContentShareORM } = ctx

        if (existingContentShareORM) {
          const existingLink = {
            expiresAt: format(new Date(existingContentShareORM.model.expiresAt), 'yyyy-MM-dd'),
            id: existingContentShareORM.model.id,
            // eslint-disable-next-line max-len
            link: `${config.contentURL}/${shareType[existingContentShareORM.model.type]}/${existingContentShareORM.model.token}`,
            title: ctx.hubTitle || '',
            customFieldValues: existingContentShareORM.model.customValues,
          }
          ctx.existingShareLink = existingLink
          // if resharing a hub link then selected num of content should be one
          ctx.selectedNumContent = 1
        }
      }),
      toggleSelectAll: assign((ctx) => {
        const { availableContentToShare } = ctx
        if (!availableContentToShare) return
        const allDocVerORMArr = Object
          .values(availableContentToShare)
          .flat(1)
          .filter(sfo => sfo.isDistributable)
        if (ctx.totalNumAvailableContent === ctx.selectedNumContent) {
          allDocVerORMArr.forEach(sfo => { sfo.beingShared = !!sfo.isDefault })
        } else {
          allDocVerORMArr.forEach(sfo => { if (sfo.isDistributable) sfo.beingShared = true })
        }

        const selectedNumContent = getSelectedNumOfContent(allDocVerORMArr)
        ctx.totalNumAvailableContent = allDocVerORMArr.length
        ctx.selectedNumContent = selectedNumContent
      }),
    },
    services: {
      // doc share services
      copyFileName: async (ctx, evt) => {
        ctx.isCopyingFile = true
        const { availableContentToShare } = ctx
        const { customFieldsValues } = evt
        const docVerORMs = getDocVerORMFromEntityShareIds(ctx.entityShareIds ?? [])
        if (!availableContentToShare) return null
        ctx.isCopyingFile = true
        await copyLink(
          docVerORMs,
          availableContentToShare,
          ctx.toast!,
          customFieldsValues,
          ctx.meetingId,
        )
        return null
      },
      copyEmail: async (ctx, evt) => {
        ctx.isCopyingEmail = true
        const { availableContentToShare, selectedEmailTemplate } = ctx
        const { customFieldsValues } = evt
        const docVerORMs = getDocVerORMFromEntityShareIds(ctx.entityShareIds ?? [])
        if (!availableContentToShare) return null
        if (!selectedEmailTemplate) return null

        const attachmentsInfo = getAttachmentsToShare(selectedEmailTemplate.relations.associatedFiles)

        await copyShareEmailTemplate(
          selectedEmailTemplate,
          docVerORMs,
          ctx.toast!,
          availableContentToShare,
          attachmentsInfo,
          selectedEmailTemplate.model,
          customFieldsValues,
          ctx.meetingId,
        )
        ctx.isCopyingEmail = false
        return null
      },
      openEmail: async (ctx, evt) => {
        ctx.isOpeningEmail = true
        const { availableContentToShare, selectedEmailTemplate } = ctx
        const { customFieldsValues } = evt
        const docVerORMs = getDocVerORMFromEntityShareIds(ctx.entityShareIds || [])
        const user = getUser()
        if (!availableContentToShare) return null

        if (selectedEmailTemplate) {
          const attachmentsInfo = getAttachmentsToShare(selectedEmailTemplate.relations.associatedFiles)
          await shareEmailTemplate(
            selectedEmailTemplate,
            docVerORMs,
            availableContentToShare,
            attachmentsInfo,
            selectedEmailTemplate.model,
            customFieldsValues,
            ctx.toast!,
            ctx.meetingId,
          )
          return null
        } else {
          await shareEmailLinks(
            availableContentToShare,
            user?.model!,
            customFieldsValues,
            ctx.toast!,
            ctx.meetingId,
          )
          return null
        }
      },
      // custom deck share services
      copyCustomDeckName: async (ctx, evt) => {
        ctx.isCopyingFile = true
        const { availableContentToShare } = ctx
        const { customFieldsValues } = evt
        const [itemORM] = getItemORMFromEntityShareIds(ctx.entityShareIds ?? [])
        if (!availableContentToShare) return null
        await copyLink(
          itemORM,
          availableContentToShare,
          ctx.toast!,
          customFieldsValues,
          ctx.meetingId,
        )
      },
      copyCustomDeckEmail: async (ctx, evt) => {
        ctx.isCopyingEmail = true
        const { entityShareIds, availableContentToShare, selectedEmailTemplate } = ctx
        const { customFieldsValues } = evt
        if (!entityShareIds) return null
        if (!selectedEmailTemplate) return null
        if (!availableContentToShare) return null
        const [itemORM] = getItemORMFromEntityShareIds(entityShareIds)
        const attachmentsInfo = getAttachmentsToShare(selectedEmailTemplate.relations.associatedFiles)
        ctx.isCopyingEmail = true

        await copyShareEmailTemplate(
          selectedEmailTemplate,
          itemORM,
          ctx.toast!,
          availableContentToShare,
          attachmentsInfo,
          selectedEmailTemplate.model,
          customFieldsValues,
          ctx.meetingId,
        )
      },
      openCustomDeckEmail: async (ctx, evt) => {
        ctx.isOpeningEmail = true
        const { availableContentToShare, selectedEmailTemplate } = ctx
        const { customFieldsValues } = evt
        const [itemORM] = getItemORMFromEntityShareIds(ctx.entityShareIds ?? [])
        const user = getUser()
        if (!availableContentToShare) return null
        if (selectedEmailTemplate) {
          const attachmentsInfo = getAttachmentsToShare(selectedEmailTemplate.relations.associatedFiles)
          await shareEmailTemplate(
            selectedEmailTemplate,
            itemORM,
            availableContentToShare,
            attachmentsInfo,
            selectedEmailTemplate.model,
            customFieldsValues,
            ctx.toast!,
            ctx.meetingId,
          )
          return null
        } else {
          await shareEmailLinks(
            availableContentToShare,
            user?.model!,
            customFieldsValues,
            ctx.toast!,
            ctx.meetingId,
          )
          return null
        }
      },
      // hub share services
      copyHubsLink: async (ctx, evt) => {
        ctx.isCopyingFile = true
        const { entityShareIds } = ctx
        const { customFieldsValues } = evt
        if (!entityShareIds) return null
        const [hubORM] = getHubORMFromEntityShareIds(entityShareIds)
        const linkResults = await copyHubsLink(
          hubORM,
          ctx.toast!,
          customFieldsValues,
          ctx.existingShareLink && [ctx.existingShareLink], // copyHubsLinks has many nested funcs, one of those funcs requires an array of shareable links, this is the reason for the array of existingShareLink
        )
        // for hubs we only have one link, the array is always one length we can just get the first index and pass it in this function
        const [linkResult] = linkResults
        await getAndUpdateReduxContentShare(linkResult.id)
        return null
      },
      copyHubsEmail: async (ctx, evt) => {
        ctx.isCopyingEmail = true
        const { entityShareIds, selectedEmailTemplate } = ctx
        const { customFieldsValues } = evt
        if (!entityShareIds) return null
        if (!selectedEmailTemplate) return null
        const [hubORM] = getHubORMFromEntityShareIds(entityShareIds)
        const attachmentsInfo = getAttachmentsToShare(selectedEmailTemplate.relations.associatedFiles)
        ctx.isCopyingEmail = true
        const linkResults = await copyHubsEmailTemplate(
          hubORM,
          ctx.toast!,
          attachmentsInfo,
          selectedEmailTemplate.model,
          customFieldsValues,
          ctx.existingShareLink && [ctx.existingShareLink],
        )
        const [linkResult] = linkResults
        await getAndUpdateReduxContentShare(linkResult.id)
        ctx.selectedEmailTemplate = undefined
        return null
      },
      openHubsEmail: async (ctx, evt) => {
        ctx.isOpeningEmail = true
        const { entityShareIds, selectedEmailTemplate } = ctx
        const { customFieldsValues } = evt
        if (!entityShareIds) return null
        const user = getUser()
        const [hubORM] = getHubORMFromEntityShareIds(entityShareIds)
        if (selectedEmailTemplate) {
          const attachmentsInfo = getAttachmentsToShare(selectedEmailTemplate.relations.associatedFiles)
          const linkResults = await shareHubsEmailTemplate(
            hubORM,
            attachmentsInfo,
            selectedEmailTemplate.model,
            customFieldsValues,
            ctx.toast!,
            ctx.existingShareLink && [ctx.existingShareLink],
          )
          const [linkResult] = linkResults
          await getAndUpdateReduxContentShare(linkResult.id)
          return null
        } else {
          const linkResults = await shareHubsLink(
            hubORM,
            user?.model!,
            customFieldsValues,
            ctx.toast!,
            ctx.existingShareLink && [ctx.existingShareLink],
          )
          // for hubs we only have one link, the array is always one length we can just get the first index and pass it in this function
          const [linkResult] = linkResults
          await getAndUpdateReduxContentShare(linkResult.id)
          return null
        }
      },
    },
  },
)

export default shareMachine
