import React from 'react';
import { useParams } from 'react-router';
import ContentProvider from 'src/state/context/ContentProvider/ContentProvider';
import MeetingsContent from './MeetingsContent';
import { useMeeting } from 'src/state/redux/selector/meeting';
import MeetingsStateProvider from 'src/state/context/Meetings/MeetingsStateProvider';
import { LoadingAnimation } from 'src/components/LoadingAnimation/LoadingAnimation';

interface Props {
  meetingId: string
}

const Meetings = (props: Props) => {
  return (
    <ContentProvider meetingId={props.meetingId}>
      <MeetingsStateProvider meetingId={props.meetingId}>
        <MeetingsContent />
      </MeetingsStateProvider>
    </ContentProvider>
  );
};

const MeetingsWrapper = () => {
  const { meetingId } = useParams<{ meetingId: string }>();
  const meeting = useMeeting(meetingId);

  return meeting
    ? <Meetings meetingId={meetingId} />
    : <LoadingAnimation />
}

MeetingsWrapper.displayName = 'Meetings';

export default MeetingsWrapper;
