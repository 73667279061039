import React, { useEffect, useRef, useMemo } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import {
  DNABox,
  DNAChip,
  DNAButton,
  DNACollapsibleCard,
  DNAText,
} from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { useDndMonitor, DndMonitorListener } from '@dnd-kit/core'

import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import {
  SortableItem,
  DroppableContainer,
} from 'src/components/DnD/DnDWrapper'
import GroupContextMenu from 'src/screens/Publishers/Versioning/SlideSettings/Grouping/ContextMenu'
import { DNACollapsibleActionMenu } from '@alucio/lux-ui/src/components/layout/DNACollapsibleCard/DNACollapsibleCard'

import { getThumbURL } from 'src/screens/Publishers/Versioning/SlideSettings/SlideSettings'
import { useCloneBuilder, POOL_CONTAINER_ID } from 'src/components/DnD/Clone/CloneBuilder'
import { useGroupState } from 'src/screens/Publishers/Versioning/SlideSettings/Grouping/GroupProvider.proxy'
import { useSlideSettingsState } from 'src/screens/Publishers/Versioning/SlideSettings/SlideSettings.proxy'

import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { GroupHeader } from 'src/screens/Publishers/Versioning/SlideSettings/Grouping/ReadOnlyGroups'

const S = StyleSheet.create({
  container: {
    padding: 32,
    backgroundColor: colors['color-gray-80'],
  },
  groupContainer: {
    borderRadius: 4,
    marginBottom: 16,
  },
  groupContainerDragOver: {
    borderStyle: 'dashed',
    borderColor: colors['color-primary-400'],
    borderWidth: 2,
  },
})

// Renderless component that minimizes rerenders due to chatty over event
const GroupHoverTrigger = React.memo(() => {
  const { activeContainerOrigin, addedToGroup } = useCloneBuilder()
  const { setOverContainer } = useGroupState()

  const listeners = useMemo<DndMonitorListener>(
    () => ({
      onDragOver(event) {
        const isFromPool = activeContainerOrigin.current === POOL_CONTAINER_ID
        const overContainer = event.over?.data.current?.containerId
        const lastGroupAdded = addedToGroup.current?.group

        if (isFromPool && !lastGroupAdded) {
          return
        }

        if (isFromPool && overContainer && lastGroupAdded) {
          setOverContainer(p => p === lastGroupAdded ? p : lastGroupAdded)
        } else if (!isFromPool) {
          setOverContainer(activeContainerOrigin.current)
        }
      },
      onDragEnd() {
        setOverContainer(null)
      },
    }),
    [setOverContainer],
  )

  useDndMonitor(listeners)

  return null
})

const RightGroupMenu : React.FC<{
  groupName: string;
}> = (props) => {
  const { groupName } = props
  const { send, state } = useSlideSettingsState()

  const draftGroup = state.context.groupings.groups[groupName]
  const groupLength = draftGroup?.pageIds?.length ?? 0
  const isGroupLocked = draftGroup?.locked

  return (
    <DNABox spacing="between">
      <DNABox alignX="center" alignY="center" style={{ marginRight: 4 }} fill childFill>
        <DNAChip
          appearance="subtle"
          status="basic"
          size="lg"
          rounded="sm"
        >
          { `${groupLength}` }
        </DNAChip>
      </DNABox>
      <DNAPopover>
        <DNAPopover.Anchor>
          <DNAButton
            appearance="ghost"
            size="md"
            padding="sm"
            status={'gray'}
            iconLeft={isGroupLocked ? 'lock' : 'lock-open-variant-outline'}
            onPress={() => send({
              type: 'TOGGLE_LOCK_GROUP',
              payload: groupName,
            })}
          />
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText style={{ color: colors['color-text-white'] }}>
            { isGroupLocked ? 'Ungrouping restricted' : 'Ungrouping allowed' }
          </DNAText>
        </DNAPopover.Content>
      </DNAPopover>
      <GroupContextMenu
        groupName={groupName}
        isLocked={isGroupLocked}
      >
        <DNAButton
          status="gray"
          appearance="ghost"
          size="md"
          padding="sm"
          iconLeft="dots-vertical"
        />
      </GroupContextMenu>
    </DNABox>
  )
}

const SlideGroupTargets: React.FC = () => {
  const { groupedTargetItems } = useCloneBuilder()
  const { send, documentVersionORM } = useSlideSettingsState()
  const {
    pagesMap,
    isExpanded,
    collapsedGroups,
    toggleCollapsedGroups,
    overContainer,
    latestAddedGroup,
    groups,
  } = useGroupState()
  const { getSlideTitle } = useSlideSettingsState()

  const scrollViewRef = useRef<ScrollView>(null);
  const groupRefs = useRef<Record<string, any>>({});

  const sortedGroupNames = useMemo(
    () => {
      return Object
        .keys(groupedTargetItems)
        .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
    },
    [groupedTargetItems],
  )

  // Scrolls to latest added group
  useEffect(
    () => {
      if (latestAddedGroup) {
        const yPos = groupRefs.current[latestAddedGroup]?.offsetTop
        scrollViewRef.current?.scrollTo({ y: yPos, animated: true });
      }
    },
    [latestAddedGroup],
  )

  return (
    <ScrollView contentContainerStyle={S.container} ref={scrollViewRef}>
      <DNABox testID="slide-group-container" fill appearance="col">
        {
        sortedGroupNames.map((groupName) => {
          const items = groupedTargetItems[groupName]
          const group = groups.find(g => g.name === groupName)
          const headerTitle = (
            <DNABox testID="collapsible-header-title" appearance="col" alignY="center" fill>
              {!group && <DNAText h5 numberOfLines={1}>{groupName}</DNAText>}
              {group && <GroupHeader group={group} />}
              {
                items.length < 2
                  ? (
                    <DNAText status="warning" c1 numberOfLines={1}>
                      Add at least 2 slides to create a group
                    </DNAText>
                  )
                  : null
              }
            </DNABox>
          )

          return (<DNABox
            testID="slide-group-container"
            key={groupName}
            appearance="col"
            ref={(ref) => { groupRefs.current[groupName] = ref }}
            // [NOTE] Refs don't work when using Spacing because of spacing wrapping the child node
            style={[
              S.groupContainer,
              overContainer === groupName
                ? S.groupContainerDragOver
                : undefined,
            ]}
          >
            <DNACollapsibleCard
              headerTitle={headerTitle}
              isCollapsed={!!collapsedGroups[groupName]}
              onToggleChanged={() => toggleCollapsedGroups(groupName)}
            >
              {/* CONTEXT MENU */}
              <DNACollapsibleActionMenu>
                <RightGroupMenu groupName={groupName} />
              </DNACollapsibleActionMenu>

              <DroppableContainer
                disabled={collapsedGroups[groupName]}
                id={groupName}
                items={items}
                strategy={isExpanded ? 'rectSortingStrategy' : 'verticalListSortingStrategy'}
              >
                <DNABox
                  childFill={!items.length ? 0 : undefined}
                  fill={!items.length}
                  appearance={isExpanded ? 'row' : 'col'}
                  alignX={(!isExpanded && groupedTargetItems[groupName].length) ? 'center' : undefined}
                  spacing="md"
                  childStyle={isExpanded ? { marginBottom: 16 } : { }}
                  wrap={isExpanded ? 'start' : undefined}
                >
                  {
                    !items.length
                      ? (
                        <DNABox
                          fill
                          appearance="col"
                          alignX="center"
                          alignY="center"
                          style={{
                            width: '100%',
                            borderStyle: 'dashed',
                            borderWidth: 1,
                            borderColor: colors['color-gray-100'],
                            borderRadius: 4,
                          }}
                        >
                          <DNAText style={{ margin: 40 }}>
                            Drag slides here
                          </DNAText>
                        </DNABox>
                      )
                      : items.map(({ id, itemId }, idx) => {
                        // since itemid or id could be duplicated if we add the same slide multiple times
                        // we need to add a unique key to the sortable item
                        // so that it can be sorted and remove correctly if whe have the same slides multiple times
                        // the keys will be itemId, itemId-2, etc
                        const slideLength = items.slice(0, idx + 1).filter(i => i.itemId === itemId).length
                        const key = slideLength > 1 ? `${itemId}-${slideLength}` : `${itemId}`

                        return (
                          <SortableItem
                            disabled={collapsedGroups[groupName]}
                            key={key}
                            id={id}
                            itemId={itemId}
                            containerId={groupName}
                          >
                            <DNABox appearance="col" spacing="sm">
                              {/* <DNAText>{ id }</DNAText> */}
                              {/* <DNAText>{ itemId }</DNAText> */}
                              <DNAThumbnail
                                s3URL={getThumbURL(documentVersionORM, pagesMap[itemId]?.number ?? 0)}
                                useLoadingIndicator
                                size="lg"
                                mode={DNAThumbnail.Modes.REMOVABLE}
                                variant={DNAThumbnail.Variants.INFO}
                                pageNumber={pagesMap[itemId]?.number ?? 0}
                                onCheck={() => send({
                                  type: 'REMOVE_GROUP_SLIDES',
                                  payload: { groupName, itemIdx: idx, pageId: itemId },
                                },
                                )}
                                thumbnailTitle={getSlideTitle(pagesMap[itemId]?.number)}
                              />
                            </DNABox>
                          </SortableItem>
                        )
                      })
                  }
                </DNABox>
              </DroppableContainer>
            </DNACollapsibleCard>
          </DNABox>)
        })
      }
      </DNABox>
      <GroupHoverTrigger />
    </ScrollView>
  )
}

export default SlideGroupTargets
