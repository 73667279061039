import React, { useState } from 'react'
import { Pressable, StyleSheet, ScrollView, ViewStyle } from 'react-native'
import {
  DNABox,
  DNAIcon,
  Iffy,
  DNAText,
  DNAButton,
  DNADivider,
  LuxStatus,
  DNACard,
  Rotate,
} from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { WebLinking as Linking } from '@alucio/core'

import useScreenNav from 'src/components/DNA/hooks/useScreenNav'
import { useSyncState, useSyncStatus, SyncStatusORM } from 'src/state/redux/selector/cache'
import { useAllDocumentVersionMap } from 'src/state/redux/selector/document'
import { bytesToSize } from 'src/utils/documentHelpers'
import workerChannel from 'src/worker/channels/workerChannel'
import { useAppSettings, DeviceMode } from 'src/state/context/AppSettings'
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton'
import { CRMStatus, useCrmStateMachine } from 'src/screens/Profile/CRMIntegration'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { format, parseISO } from 'date-fns'
interface Styles {
  centerIcon:ViewStyle,
  menuBorder:ViewStyle,
}

type DeviceModeStyles = Record<DeviceMode, StyleSheet.NamedStyles<Styles>>

const deviceModeStyles:DeviceModeStyles = {
  desktop: StyleSheet.create({
    centerIcon: {
      position: 'absolute',
      right: -6,
      top: -6,
    },
    menuBorder: {
      shadowColor: colors['color-black'],
      shadowOffset: {
        width: 0,
        height: 9,
      },
      shadowOpacity: 0.12,
      shadowRadius: 12.35,
      borderRadius: 4,
    },
  }),
  tablet: StyleSheet.create({
    centerIcon: {
      position: 'absolute',
      // @ts-expect-error - Works fine in web
      transform: [{ translateX: '40%' }, { translateY: '95%' }],
    },
    menuBorder: {
      shadowColor: colors['color-black'],
      shadowOffset: {
        width: 0,
        height: 9,
      },
      shadowOpacity: 0.12,
      shadowRadius: 12.35,
      borderRadius: 4,
    },
  }),
}

type SyncStatusDefinition = {
  statuses: string[],
  buttonIcon: string,
  labelIcon: string,
  status: LuxStatus,
  label: string,
}

const syncStatusDefinition: SyncStatusDefinition[] = [
  {
    statuses: ['offline'],
    buttonIcon: '',
    labelIcon: 'wifi-off',
    status: 'warning',
    label: "Looks like you're offline.",
  },
  {
    statuses: ['online.idle.init'],
    buttonIcon: 'sync-circle',
    labelIcon: 'sync-circle',
    status: 'primary',
    label: 'Waiting for sync...',
  },
  {
    statuses: ['online.sync.prepare'],
    buttonIcon: 'sync-circle',
    labelIcon: 'sync-circle',
    status: 'primary',
    label: 'Preparing download...',
  },
  {
    statuses: ['online.sync.thumbnails', 'online.sync.content', 'online.sync.determine'],
    buttonIcon: 'sync-circle',
    labelIcon: 'sync-circle',
    status: 'primary',
    label: 'Downloading in progress...',
  },
  {
    statuses: ['online.paused'],
    buttonIcon: 'pause-circle',
    labelIcon: 'pause-circle',
    status: 'primary',
    label: 'Downloading paused...',
  },
  {
    statuses: ['online.idle.pending'],
    buttonIcon: 'check-circle',
    labelIcon: 'check-circle',
    status: 'success',
    label: "You're all synced for offline use.",
  },
  {
    statuses: ['online.idle.error'],
    buttonIcon: 'alert-circle',
    labelIcon: 'alert-circle',
    status: 'warning',
    label: 'Downloading complete',
  },
]

const getSyncStatusDefinition = (syncStatus: SyncStatusORM): SyncStatusDefinition | undefined => (
  syncStatusDefinition.find(definition => (
    definition
      .statuses
      .some(defStatus => syncStatus.matches(defStatus))
  )) ??
  {
    statuses: ['online.idle.error'],
    buttonIcon: 'alert-circle',
    labelIcon: 'alert-circle',
    status: 'danger',
    label: 'Something went wrong',
  }
)

type DeviceModeButtonProps = Record<DeviceMode, DNAButtonProps>
const deviceModeButtonProps:DeviceModeButtonProps = {
  desktop: {
    appearance: 'outline',
    status: 'tertiary',
    size: 'lg',
    padding: 'sm',
  },
  tablet: {
    appearance: 'ghostLink',
    status: 'gray',
    size: 'xl',
    padding: 'none',
    rounded: 'full',
  },
}

const SyncStatusButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const syncStatus = useSyncStatus()
  const { cond } = useCrmStateMachine()
  const screenNav = useScreenNav()
  const { deviceMode, isCRMEnabled, isOfflineEnabled } = useAppSettings()
  const { centerIcon, menuBorder } = deviceModeStyles[deviceMode]
  const currentDeviceModeButtonProps = deviceModeButtonProps[deviceMode]

  const syncStatusDef = getSyncStatusDefinition(syncStatus)
  const handlePress = () => {
    setIsOpen(p => !p)
  }

  return (
    <DNAPopover
      lazyMount
      placement="bottom"
      interactive={true}
      visible={isOpen}
      style={menuBorder}
      onBackdropPress={handlePress}
      type="menu"

    >
      <DNAPopover.Anchor>
        <DNAButton
          iconLeft="cloud-outline"
          onPress={handlePress}
          {...currentDeviceModeButtonProps}
        />
        {/* Solid Red Circle Icon on the cloud */}
        <Iffy is={syncStatusDef?.buttonIcon && isOfflineEnabled}>
          <Pressable
            delayLongPress={1000}
            onLongPress={() => {
              isOfflineEnabled && screenNav.goTo.SYNC_STATUS_DEBUG()
            }}
            onPress={() => handlePress()}
            style={centerIcon}
          >
            <DNAIcon.Styled
              name="circle"
              status="primary"
              appearance="filled"
            />
          </Pressable>
        </Iffy>
        {/* [TODO-PWA]
              - Needs to show warning icon if there are failed files
              - This button minimized render by not taking the entire sync object
              - Maybe need to break out another variable for errorLength
        */}
        <Iffy is={syncStatusDef && isOfflineEnabled}>
          <Pressable
            delayLongPress={1000}
            onLongPress={() => {
              isOfflineEnabled && screenNav.goTo.SYNC_STATUS_DEBUG()
            }}
            onPress={() => handlePress()}
            style={centerIcon}
          >
            <Rotate disabled={syncStatusDef?.buttonIcon !== 'sync-circle'}>
              <DNAIcon.Styled
                name={syncStatusDef?.buttonIcon}
                status={syncStatusDef?.status}
                appearance="ghost"
              />
            </Rotate>
          </Pressable>
        </Iffy>
      </DNAPopover.Anchor>

      <DNAPopover.Content>
        <Iffy is={isOfflineEnabled}>
          <DNACard style={{ width: 400, padding: 24 }}>
            <SyncStatusInfo />
          </DNACard>
        </Iffy>
        <Iffy is={isCRMEnabled && cond.isConnected}>
          <CRMStatus />
        </Iffy>
      </DNAPopover.Content>
    </DNAPopover>
  )
}

export const SyncStatusInfo: React.FC = () => {
  const syncState = useSyncState()
  const syncStatusDef = getSyncStatusDefinition(syncState)
  const allDocumentVersionMap = useAllDocumentVersionMap()

  const contentSyncManifest = Object.values(syncState.context.syncManifest)

  const contentEntriesRemaining = contentSyncManifest
    ? `${contentSyncManifest.length} file(s) remaining`
    : ''

  const totalSizeLeft = contentSyncManifest.reduce<number>(
    (acc, entry) => acc + entry.fileSize,
    0,
  )
  const contentSizeLeft =
    contentSyncManifest.length
      ? bytesToSize(totalSizeLeft)
      : '0 MB'

  const isPaused = syncState.matches('online.paused')
  const isSync = syncState.matches('online.sync')
  const isOffline = syncState.matches('offline.idle')
  const hasRemainingSync = contentSyncManifest.length
  const failedSyncEntries = syncState.context.error.syncEntries
  const hasFailedSyncEntries = Object.keys(syncState.context.error.syncEntries).length
  const isIdleWithWarning = syncState.matches('online.idle.error')

  const contactSupportMailTo = () => {
    Linking.openURL('mailto:support@alucio.io', '_self')
  }

  const handlePausedSync = async () => {
    if (syncState?.matches('online.paused')) { workerChannel.postMessageExtended({ type: 'RESUME_SYNC' }) }

    if (syncState?.matches('online.sync')) { workerChannel.postMessageExtended({ type: 'PAUSE_SYNC' }) }
  }

  const lastSyncDate = localStorage.getItem('lastSyncDate')
  if (lastSyncDate && !hasRemainingSync && !hasFailedSyncEntries && isOffline) {
    const formatDate =  format(parseISO(lastSyncDate), "MMMM d, yyyy 'at' h:mm a")
    return (
      <DNABox
        fill
        appearance="col"
        style={{ padding: 24, margin: 24, borderWidth: 1, borderColor: colors['color-gray-80'] }}
        spacing="md"
      >
        <DNAText h4 >Content successfully downloaded on:</DNAText>
        <DNAText status="flat">{formatDate}</DNAText>
      </DNABox>
    )
  }

  return (
    <DNABox
      fill
      appearance="col"
      style={{ padding: 24, borderWidth: 1, borderColor: colors['color-gray-80'] }}
      spacing="lg"
      childFill={2}
    >
      {/* 0. Icons and Status Label */}
      <DNABox spacing="sm" alignY="center">
        <Rotate disabled={syncStatusDef?.labelIcon !== 'sync-circle'}>
          <DNAIcon.Styled
            name={syncStatusDef?.labelIcon}
            status={syncStatusDef?.status}
            appearance="ghost"
            size="xl"
          />
        </Rotate>
        <Iffy is={hasFailedSyncEntries && syncStatusDef?.labelIcon !== 'alert-circle'}>
          <DNAIcon.Styled
            name="alert-circle"
            status="warning"
            appearance="ghost"
            size="xl"
          />
        </Iffy>
        <DNAText h4>
          { syncStatusDef?.label }
        </DNAText>
      </DNABox>

      {/* 1. Offline label */}
      {/* Use ternaries instead of Iffy due to DNABox bug */}
      {
        (isOffline && hasRemainingSync)
          ? <DNAText status="flat">Please connect to the internet to resume download</DNAText>
          : null
      }

      {/* 2.Failed Sync Entries */}
      {
        hasFailedSyncEntries
          ? <DNABox
              fill
              appearance="col"
              spacing="md"
              childFill={2}
          >
            <DNAText status="flat">
              The following file(s) have failed to download:
            </DNAText>
            <DNADivider />
            <ScrollView style={{ flex: 1, maxHeight: 200 }}>
              <DNABox
                fill
                childFill
                appearance="col"
                spacing="md"
                wrap="start"
              >
                {/* [TODO-PWA] - We should put a cap on how many rows to render */}
                {
                        Object
                          .values(failedSyncEntries)
                          .map((value, idx) => (
                            <DNAText key={idx} numberOfLines={3} bold>
                              {
                              allDocumentVersionMap[value.documentVersionId]
                                ?.relations
                                ?.documentORM
                                ?.relations
                                .version
                                .latestPublishedDocumentVersionORM
                                ?.model
                                .title
                            }
                            </DNAText>
                          ))
                      }
              </DNABox>
            </ScrollView>
            <DNADivider />
          </DNABox>
          : null
        }

      {/* 3. Download Progress */}
      {
        hasRemainingSync
          ? <DNABox fill appearance="col">
            {/* Download remaining */}
            <DNAText b1 status="flat">{contentEntriesRemaining}</DNAText>
            <DNAText b1 status="flat">{contentSizeLeft} remaining</DNAText>
          </DNABox>
          : null
      }

      {/* 4. Pause Button */}
      {
        (isPaused || isSync)
          ? <DNAButton
              style={{ flex: 1 }}
              onPress={handlePausedSync}
          >
            {isPaused ? 'Resume download' : 'Pause Download'}
          </DNAButton>
          : null
      }

      {/* 5. Contact Support Button */}
      {
        isIdleWithWarning
          ? <DNAButton
              style={{ flex: 1 }}
              onPress={contactSupportMailTo}
          >
            Contact support
          </DNAButton>
          : null
      }
    </DNABox>
  )
}

export default SyncStatusButton
