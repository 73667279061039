import React, { useEffect } from 'react'

import { DNAHeader, DNABox, Iffy } from '@alucio/lux-ui'
import { useAppSettings } from 'src/state/context/AppSettings'

import TenantLogo from './TenantLogo'
import SearchInput from 'src/components/Header/SearchInput/SearchInput'

import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import useScreenNav from 'src/components/DNA/hooks/useScreenNav'

import ProfileMenu from 'src/components/DNA/Header/ProfileMenu'
import FAQButton from 'src/components/DNA/Header/FAQButton'
import Notification from 'src/components/Header/Notifications';
import NetworkOfflineButton from 'src/components/DNA/Header/NetworkOfflineButton'
import SyncStatusButton from 'src/components/DNA/Header/SyncStatusButton'
import MeetingButton from 'src/components/Header/MeetingButton/MeetingButton';
import { CRMIntegrationProvider, useCrmStateMachine } from 'src/screens/Profile/CRMIntegration'
import { useSyncState } from 'src/state/redux/selector/cache'
import CustomDeckButton from 'src/components/Header/CustomDeckButton/CustomDeckButton'
import StandaloneFormsButton from './StandaloneFormsButton';

const useDNAAppHeader = () => {
  const { deviceMode } = useAppSettings()
  const { goTo } = useScreenNav()
  const currentPage = useCurrentPage()
  /** Route config options */
  const {
    disableGlobalHeader,
    disableMeetings,
    disableSearch,
    disableNotificaions,
  } = { ...currentPage?.configOptions?.[deviceMode] }
  const showMeetingsButton = !disableMeetings

  return {
    disableGlobalHeader,
    disableSearch,
    disableNotificaions,
    deviceMode,
    showMeetingsButton,
    goToHome: () => goTo.HOME(),
  }
}

const DNAAppHeaderDesktop = () => {
  const { isOnline } = useAppSettings()
  const {
    showMeetingsButton,
    disableSearch,
    disableNotificaions,
  } = useDNAAppHeader()
  const { isOfflineEnabled, isCRMEnabled } = useAppSettings()
  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const { cond } = useCrmStateMachine()

  return (
    <DNAHeader testID="desktop-header">
      {/*
        NOTE: This is a workaround to be able to use flex that's aware
          of both sides. If using Header.Left and Header.Right, they are
          independent of each other and cannot flex properly when shrinking.
          In this case, it applies to the reflexive nature of the SearchInput
          that needs to shrink when the screen is small enough and the right side
          begins to overlap the search
      */}
      <DNAHeader.Center style={{ position: 'relative' }}>
        <DNABox
          alignY="center"
          spacing="between"
          childFill={0}
        >
          {/* LEFT SIDE */}
          <DNABox fill style={{ minHeight: 50, marginRight: 24 }}>
            <TenantLogo />
            <Iffy is={!disableSearch}>
              <DNABox fill style={{ maxWidth: 720 }}>
                <SearchInput />
              </DNABox>
            </Iffy>
          </DNABox>

          {/* RIGHT SIDE */}
          <DNABox alignY="center" spacing="lg">
            <DNABox spacing="md" alignY="center">
              <Iffy is={showMeetingsButton}>
                <MeetingButton />
              </Iffy>
              <Iffy is={!isPublisherRoute}>
                <CustomDeckButton mode="header" />
              </Iffy>
              <StandaloneFormsButton />
              <Iffy is={!isOnline}>
                <DNABox>
                  <NetworkOfflineButton />
                </DNABox>
              </Iffy>
              <Iffy is={isOfflineEnabled || (isCRMEnabled && cond.isConnected)}>
                <SyncStatusButton />
              </Iffy>
              <DNABox>
                <FAQButton />
              </DNABox>
              <DNABox>
                <Iffy is={!disableNotificaions}>
                  <Notification />
                </Iffy>
              </DNABox>
            </DNABox>
            <ProfileMenu />
          </DNABox>
        </DNABox>
      </DNAHeader.Center>
    </DNAHeader>
  )
}

const DNAAppHeaderTablet: React.FC = () => {
  const { showMeetingsButton } = useDNAAppHeader()
  const { isOnline, isOfflineEnabled, isCRMEnabled } = useAppSettings()
  const { LABEL, configOptions } = { ...useCurrentPage() }
  const { cond } = useCrmStateMachine()
  const isPublisherRoute = configOptions?.modules?.includes('publisher')

  return (
    <DNAHeader testID="tablet-header" status="brand" title={LABEL}>
      <DNAHeader.Right>
        <DNABox alignY="center" spacing="md">
          <Iffy is={showMeetingsButton}>
            <MeetingButton />
          </Iffy>
          <Iffy is={!isPublisherRoute}>
            <CustomDeckButton mode="header" />
          </Iffy>
          <StandaloneFormsButton />
          <SearchInput />
          <Iffy is={!isOnline}>
            <NetworkOfflineButton />
          </Iffy>
          <Iffy is={isOfflineEnabled || (isCRMEnabled && cond.isConnected)}>
            <SyncStatusButton />
          </Iffy>
          <Notification />
          <ProfileMenu />
        </DNABox>
      </DNAHeader.Right>
    </DNAHeader>
  )
}

const DNAAppHeader = (props) => {
  const { disableGlobalHeader, deviceMode } = useDNAAppHeader()
  const syncState = useSyncState()

  useEffect(() => {
    if (syncState.context.lastSyncDate) {
      const lastSyncDate = new Date(syncState.context.lastSyncDate).toISOString()
      localStorage.setItem('lastSyncDate', lastSyncDate)
    }
  }, [syncState.context.lastSyncDate])

  /** No need to continue instantiating if this component is disabled on current route */
  if (disableGlobalHeader) return null

  return (
    <CRMIntegrationProvider>
      {deviceMode === 'desktop'
        ? <DNAAppHeaderDesktop {...props} />
        : <DNAAppHeaderTablet {...props} />}
    </CRMIntegrationProvider>
  )
}

export default DNAAppHeader
