import { DNABox } from '@alucio/lux-ui'
import React from 'react'
import { StyleSheet, Image } from 'react-native'

const styles = StyleSheet.create({
  resultsLoadingImage: {
    width: 200,
    height: 200,
  },
})

interface LoadingProps {
  variant?: 'meetings'
}

export const LoadingAnimation: React.FC<LoadingProps> = ({ variant }) => (
  <DNABox fill alignY="center">
    <DNABox
      fill
      appearance="col"
      alignX="center"
      alignY="center"
      spacing="md"
      style={[{ padding: 48 }]}
    >
      <Image
        style={styles.resultsLoadingImage}
        source={variant === 'meetings'
          ? require('../../../assets/images/LoaderDNA_dark.gif')
          : require('../../../assets/images/LoaderDNA.gif')}
      />
    </DNABox>
  </DNABox>
)
