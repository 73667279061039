import React from 'react'
import { ScrollView } from 'react-native'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import {
  DNABox,
  DNAButton,
  DNASlider,
} from '@alucio/lux-ui'
import { styles, useSlideSettings, getThumbURL } from './SlideSettings'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import HeaderSettings from './HeaderSettings'

const CoverThumbnailModal: React.FC = () => {
  const {
    state,
    send,
    cond,
    documentVersionORM,
    thumbnailSizes,
  } = useSlideSettings()

  return (
    <DNASlider
      visible={cond.mode.coverThumbnail}
      setVisible={() => send({ type: 'BACK_TO_IDLE' })}
      orientation="horizontal"
    >
      <DNABox
        fill
        appearance="col"
        style={{ backgroundColor: colors['color-text-white'] }}
      >
        <HeaderSettings
          title="Select cover thumbnail"
          onSave={() => send({ type: 'SAVE_COVER_THUMBNAIL' })}
          onCancel={() => send({ type: 'BACK_TO_IDLE' }) }
          saveDisabled={!state.can({ type: 'SAVE_COVER_THUMBNAIL' })}
        />

        {/* CONTENT */}
        <DNABox fill>
          <DNABox fill appearance="col">
            <DNABox style={styles.rowWrapper} >
              <DNABox fill alignX="end">
                <DNAButton
                  appearance="ghostLink"
                  status="tertiary"
                  size="md"
                  padding="none"
                  onPress={thumbnailSizes.cycleThumbnailSize}
                  iconLeft={thumbnailSizes.thumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
                />
              </DNABox>
            </DNABox>
            <DNABox fill>
              <ScrollView style={styles.content}>
                <DNABox
                  appearance="row"
                  wrap="start"
                  spacing="lg"
                  childStyle={{ marginBottom: 32 }}
                >
                  {
                    state.context.versionDraft.pages?.map((page) => {
                      const coverThumbnailPageNumber = state.context.selectedCoverThumbnail ?? 1
                      const thumbURL = getThumbURL(documentVersionORM, page.number)
                      const isCoverThumbnail = page.number === coverThumbnailPageNumber

                      return (
                        <DNAThumbnail
                          key={thumbURL}
                          s3URL={thumbURL}
                          useLoadingIndicator
                          size={thumbnailSizes.thumbnailSize}
                          mode={DNAThumbnail.Modes.SELECTABLE}
                          variant={DNAThumbnail.Variants.INFO}
                          pageNumber={page.number}
                          checked={isCoverThumbnail}
                          isCover={isCoverThumbnail}
                          isRequired={page.isRequired}
                          onCheck={() => {
                            send({
                              type: 'UPDATE_COVER_THUMBNAIL',
                              payload: page.number,
                            })
                          }}
                        />
                      )
                    })
                  }
                </DNABox>
              </ScrollView>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNASlider>
  )
}

export default CoverThumbnailModal
