import React, { useCallback, useMemo } from 'react'
import { useWindowDimensions, ScrollView, StyleSheet, TouchableOpacity } from 'react-native'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import { useCurrentUser } from 'src/state/redux/selector/user';
import { useDNARouteStack } from 'src/components/DNA/Navigation/DNARouteStack/DNARouteStack'

import {
  DNABox,
  DNAText,
  DNACard,
  DNADivider,
  luxColors,
  Iffy,
  DNAIcon,
} from '@alucio/lux-ui'
import { UserRole } from '@alucio/aws-beacon-amplify/src/models';
import { useChatButtonSharedResources } from 'src/components/DNA/Header/ChatButton';
import { openKnowledgeBase } from 'src/components/DNA/Header/FAQButton';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { SOURCE } from 'src/components/Authenticator/LogOut';
import { useLogOut } from 'src/components/Authenticator/LogOut.proxy';
import { useLoggerState } from 'src/state/machines/logger/LoggerProvider';
import { SendLogsButtonStates } from 'src/state/machines/logger/logger.selector';

const USER_ROLES = {
  [UserRole.ALUCIO_ADMIN]: 'Alucio Admin',
  [UserRole.TENANT_ADMIN]: 'Admin',
  [UserRole.TENANT_PUBLISHER]: 'Publisher',
  [UserRole.TENANT_VIEWER]: 'Viewer',
};

type TabletMenuProps = {
  onClose?: () => void,
  onChangePassword?: () => void
}

const styles = StyleSheet.create({
  cardWrapper: {
    paddingBottom: 40,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 8,
  },
  mainWrapper: {
    maxWidth: 1440,
    paddingHorizontal: 48,
    paddingVertical: 20,
  },
  tabsWrapper: {
    borderBottomColor: luxColors.border.primary,
    borderBottomWidth: 1,
  },
  header: {
    backgroundColor: luxColors.tag.secondary,
    minHeight: 72,
    maxHeight: 72,
  },
  item: {
    marginVertical: 12,
    paddingHorizontal: 16,
    paddingVertical: 12,
    height: 64,
  },
  menu: {
    marginLeft: 24,
    marginTop: 40,
  },
  scrollableContainer: {
    paddingBottom: 50,
  },
});

type options = {
  field: string | React.JSX.Element,
  value: string,
  type: 'text' | 'link',
  onPress?: () => void,
  testID?: string,
}

interface ProfileTabletMenuProps {
  title: string,
  options: options[]
}

type sendLogsButtonDetails = {
  [key in keyof typeof SendLogsButtonStates]: {
    field: options['field'],
    onPress?: options['onPress'],
  }
}

const MenuItem = (props: options) => {
  return (
    <>
      <DNABox
        appearance="row"
        spacing="between"
        style={styles.item}
        fill
        alignY="center"
      >
        { typeof props.field === 'string' ? <DNAText testID="menu-list-name">{props.field}</DNAText> : props.field}
        <DNAText style={props.type === 'link' && { color: luxColors.contentText.quaternary }} >{props.value}</DNAText>
      </DNABox>
      <DNADivider />
    </>
  )
}

const Menu = (props: ProfileTabletMenuProps) => {
  return (<DNABox spacing="md" appearance="col" alignY="center" style={{ paddingHorizontal: 15 }}>
    <DNAText h5 style={styles.menu} testID="menu-header-name">{props.title}</DNAText>
    <DNACard style={{ marginHorizontal: 30 }}>

      {props.options.map((option, index) => (
        option.type === 'text'
          ? <MenuItem key={`menu_item_${index}`} {...option} />
          : <TouchableOpacity
              onPress={() => option.onPress && option.onPress()}
              testID={option.testID}
          >
            <MenuItem {...option} />
          </TouchableOpacity>
      ))}

    </DNACard>
  </DNABox>)
}

const useProfileMenu = () => {
  const { userProfile: user } = useCurrentUser();
  const { isSSO } = useAppSettings()

  const fullName = user?.givenName + ' ' + user?.familyName
  const rawLabelValues = user?.defaultFilterValues
    ?.reduce<string[]>(
      (acc, label) =>
        (label.values ? [...acc, ...label.values] : acc),
      [],
    ) ?? []

  const labelValues = Array
    .from(new Set(rawLabelValues))
    .sort()

  return {
    user: {
      user,
      fullName,
      labelValues,
    },
    isSSO,
  }
}

const useHelpSupportMenu = () => {
  const { openChat, unreadMessages } = useChatButtonSharedResources()
  const featureFlags = useFeatureFlags('enableZendeskKnowledgebase', 'enableZendeskChat');
  const { isOnline } = useAppSettings()
  const { sendLogsToCloudWatch, canSendLogs, sendLogsButtonState } = useLoggerState()
  const logButtonFieldWithIcon = useCallback((state: 'Failed' | 'Sent') => {
    const iconLeft = state === 'Sent' ? 'check-bold' : 'close'
    const iconStatus = state === 'Sent' ? 'success' : 'danger'
    return (
      <DNABox spacing="sm">
        <DNAIcon.Styled appearance="outline" name={iconLeft} status={iconStatus}/>
        <DNAText testID="menu-list-name">{state}</DNAText>
      </DNABox>
    )
  }, [])
  const sendLogsButtonDetails: sendLogsButtonDetails = useMemo(() => {
    return {
      [SendLogsButtonStates.failedToSendLogs]: {
        field: logButtonFieldWithIcon('Failed'),
      },
      [SendLogsButtonStates.successfullySentLogs]: {
        field: logButtonFieldWithIcon('Sent'),
      },
      [SendLogsButtonStates.idle]: {
        field: canSendLogs ? 'Send Logs' : 'Sending...',
        onPress: canSendLogs ? sendLogsToCloudWatch : undefined,
      },
    }
  }, [canSendLogs, logButtonFieldWithIcon, sendLogsToCloudWatch])
  const sendLogsButtonDetail = useMemo(() => sendLogsButtonDetails[sendLogsButtonState],
    [sendLogsButtonDetails, sendLogsButtonState])

  const options: options[] = []

  if (featureFlags.enableZendeskKnowledgebase) {
    options.push({
      field: 'Help',
      value: '>',
      type: 'link',
      testID:'help-support',
      onPress: () => {
        if (isOnline && featureFlags.enableZendeskKnowledgebase) {
          openKnowledgeBase(DeviceMode.tablet);
        }
        else {
          window.open('/faq', '_blank');
        }
      },
    })
  }

  if (featureFlags.enableZendeskChat && isOnline) {
    options.push({
      field: unreadMessages ? 'Chat Support (You have unread messages)' : 'Chat Support',
      value: '>',
      type: 'link',
      testID:'chat-support',
      onPress: openChat,
    })
  }

  if (isOnline && sendLogsButtonDetail) {
    options.push({
      field: sendLogsButtonDetail.field,
      value: '',
      type: 'link',
      testID:'send-logs',
      onPress: sendLogsButtonDetail.onPress,
    })
  }

  return options
}

const SettingsTablet: React.FC<TabletMenuProps> = () => {
  const common = useProfileMenu()
  const stack = useDNARouteStack()
  const { isDeviceSwitchEnabled, dispatch, isCRMEnabled } = useAppSettings()
  const { isOnline } = useAppSettings()
  const useHelpMenuSupport = useHelpSupportMenu()
  const { height } = useWindowDimensions()

  const logOut  = useLogOut()

  const profileNavigation = useCallback(() => {
    if (isOnline) {
      stack.push('/profile/password');
    } else {
      stack.push('/profile/offline');
    }
  }, [isOnline])

  if (!common.user.user) {
    return null
  }

  const menuOptions: options[] = [
    { field: 'Name', value: common.user.fullName, type: 'text' },
    { field: 'Email', value: common.user.user?.email || '', type: 'text' },
    { field: 'Role', value: USER_ROLES[common.user.user.role], type: 'text' },
    { field: 'My filters', value: common.user.labelValues.map(label => label).join(', '), type: 'text' },
  ]

  const SSOOptions: options[] = [
    {
      field: 'Change password',
      value: '>',
      type: 'link',
      testID: 'account-profile-button',
      onPress: profileNavigation,
    },
  ]

  return (
    <DNABox fill appearance="col" spacing="md" keepSpacingLastItem>
      <ScrollView contentContainerStyle={styles.scrollableContainer} style={{ height: height - 72 }}>
        <Menu
          title="HELP & SUPPORT"
          options={useHelpMenuSupport}
        />
        <Menu
          title="ACCOUNT INFORMATION"
          options={[
            ...menuOptions,
            ...(!common.isSSO ? SSOOptions : []),
          ]}
        />
        {isCRMEnabled && <Menu
          title="INTEGRATIONS"
          options={
            [
              {
                field: 'CRM Integration',
                value: '>',
                type: 'link',
                onPress: () => { stack.push('/profile/crm-integration') },
              },
            ]
          }
        />}
        <Menu
          title="ABOUT"
          options={
            [
              {
                field: 'Privacy Policy',
                value: '>',
                type: 'link',
                onPress: () => { stack.push('/profile/privacy') },
              },
              {
                field: 'Terms of use',
                value: '>',
                type: 'link',
                onPress: () => { stack.push('/profile/tou') },
              },
            ]
          }
        />
        <Menu
          title="LOGOUT"
          options={
            [
              {
                field: 'Log out',
                value: '>',
                type: 'link',
                onPress: () => logOut?.signOut(SOURCE.USER),
              },
            ]
          }
        />
        <Iffy is={isDeviceSwitchEnabled}>
          <Menu
            title="Developer"
            options={
              [
                {
                  field: 'Switch to Desktop Mode',
                  value: '>',
                  type: 'link',
                  onPress: () => { dispatch({ type: 'toggleDeviceMode' }) },
                },
              ]
            }
          />
        </Iffy>
      </ScrollView>
    </DNABox>
  )
}

const SettingsDesktop: React.FC = () => {
  return null
}

const Settings: React.FC = () => {
  const appSettings = useAppSettings()

  return appSettings.deviceMode === 'desktop'
    ? <SettingsDesktop />
    : <SettingsTablet />
}

export default Settings
