import React, { createContext, useContext, useState, useCallback, useMemo, useEffect, PropsWithChildren } from 'react';
import { Page, PageGroup } from '@alucio/aws-beacon-amplify/src/models';

import { useDispatch } from 'src/state/redux';
import im from 'immer'

import AddGroupModal from './AddGroupModal';
import { useSlideSettings } from 'src/screens/Publishers/Versioning/SlideSettings/SlideSettings';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';

export type DraftPageGroup = Omit<PageGroup, 'id'>

export type GroupContextType = {
  showEmptyMessage: boolean,
  selectedPageIds: string[],
  selectedPagesMap: Record<string, boolean>,
  pages: Page[],
  groups: DraftPageGroup[],
  latestAddedGroup?: string,
  collapsedGroups: Record<string, boolean>,
  toggleCollapsedGroups: (groupName?: string) => void,
  isExpanded: boolean,
  toggleExpanded: () => void,
  addGroupHandler: (pageIds?: string[]) => void,
  poolItemIds: string[],
  pagesMap: Record<string, Page>,
  groupItemIds: Record<string, string[]>,
  overContainer: string | null,
  setOverContainer: React.Dispatch<React.SetStateAction<string|null>>
}

const GroupContext = createContext<GroupContextType>(undefined!);

export const GroupsProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const dispatch = useDispatch();
  const {
    state,
    send,
    cond,
  } = useSlideSettings()
  const pages = state.context.versionDraft.pages

  const [isExpanded, setIsExpanded] = useState(false)
  const [collapsedGroups, setCollapsedGroups] = useState<Record<string, boolean>>({})
  const [latestAddedGroup, setLatestAddedGroup] = useState<string>()
  const [overContainer, setOverContainer] = useState<string|null>(null)

  useEffect(
    () => setIsExpanded(false),
    [cond.mode.groupSlides],
  )

  useEffect(
    () => {
      setCollapsedGroups(p => Object
        .keys(state.context.groupings.groups)
        .reduce<Record<string, boolean>>(
          (acc, groupName) => ({
            ...acc,
            [groupName]: !!p[groupName],
          }),
          { },
        ),
      )
    },
    [state.context.groupings.groups],
  )

  const selectedPageIds = useMemo(
    () => Object
      .entries(state.context.groupings.selectedPages)
      .filter(([_, selected]) => selected)
      .map(([pageId]) => pageId)
      .sort((a, b) => a.localeCompare(b, 'en', { numeric: true })),
    [state.context.groupings.selectedPages],
  )

  const selectedPagesMap = useMemo<Record<string, boolean>>(
    () => Object
      .entries(state.context.groupings.selectedPages)
      .reduce(
        (acc, [key, value]) => ({ ...acc, [key]: !!value }),
        {},
      ),
    [state.context.groupings.selectedPages],
  )

  const poolItemIds = useMemo<string[]>(
    () => {
      return state
        .context
        .versionDraft
        .pages
        .map(p => p.pageId)
    },
    [pages],
  )

  const groups = useMemo(
    () => Object
      .values(state.context.groupings.groups)
      .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true })),
    [state.context.groupings.groups],
  )

  const groupItemIds = useMemo(
    () => {
      return groups.reduce<Record<string, string[]>>(
        (acc, group) => ({
          ...acc,
          [group.name]: group.pageIds ?? [],
        }),
        {},
      )
    },
    [groups],
  )

  const pagesMap = useMemo<Record<string, Page>>(
    () => {
      return pages
        .reduce<Record<string, Page>>(
          (acc, page) => ({ ...acc, [page.pageId]: page }),
          {},
        )
    },
    [pages],
  )

  const addGroupHandler = useCallback(
    (pageIds?: string[]): void => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        component: (props) => (
          <AddGroupModal
            groupNames={groups.map(group => group.name)}
            mode="add"
            onSuccess={(name) => {
              send({
                type: 'ADD_GROUP',
                payload: {
                  name: `${name}`,
                  pageIds: pageIds,
                },
              })
              setLatestAddedGroup(name)
            }}
            {...props}
          />
        ),
      }),
      )
    }, [groups, send])

  const toggleExpanded = useCallback(
    () => setIsExpanded(p => !p),
    [],
  )

  const toggleCollapsedGroups = useCallback(
    (groupName?: string) => {
      setCollapsedGroups(im(draft => {
        if (groupName)
        { draft[groupName] = !draft[groupName] }
        else {
          const shouldCollapseAll = Object
            .values(draft)
            .some(val => !val)

          for (const name in draft) {
            draft[name] = shouldCollapseAll
          }
        }

        return draft
      }))
    },
    [],
  )

  const showEmptyMessage = groups.length === 0

  const value: GroupContextType = {
    addGroupHandler,
    groups,
    collapsedGroups,
    toggleCollapsedGroups,
    isExpanded,
    latestAddedGroup,
    pages,
    selectedPageIds,
    selectedPagesMap,
    toggleExpanded,
    showEmptyMessage,
    poolItemIds,
    groupItemIds,
    pagesMap,
    overContainer,
    setOverContainer,
  }

  return (
    <GroupContext.Provider value={value}>
      {children}
    </GroupContext.Provider>
  );
}

const useGroups = () => {
  const ctx = useContext(GroupContext);
  return ctx
}

export default useGroups;
