import { createMachine } from 'xstate';
import type * as FindReplacement from './findReplacement.types';
import * as selectors from './FindReplacement.selectors';
import { GroupDraftStatus }
  from 'src/components/PresentationBuilder/PresentationEditor/FindReplacement/FindReplacementProvider';
import { unavailableStatus } from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider';
import * as logger from 'src/utils/logger'

export const findReplacementSM = createMachine(
  {
    id: 'FindReplacementSM',
    tsTypes: {} as import('./findReplacement.machine.typegen').Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,
    schema: {
      context: {} as FindReplacement.SMContext,
      events: {} as FindReplacement.SMEvents,
      services: {} as FindReplacement.SMServices,
    },
    context: {
      customDeckId: undefined,
      activeGroupId: undefined,
      replaceGroups: undefined!,
      isGroupDirty: false,
    },
    initial: 'idle',
    entry: (ctx) => logger.findReplacementMachine
      .info(`Entering find replacement state machine for custom deck: ${ctx.customDeckId}`),
    states: {
      idle: {
        description: 'Waiting for user actions',
        on: {
          SET_ACTIVE_GROUP: {
            actions: 'setActiveGroup',
          },
          NAV_PREVIOUS_SLIDE: {
            cond: 'canNavPrevious',
            actions: 'navPreviousSlide',
          },
          NAV_NEXT_SLIDE: {
            cond: 'canNavNext',
            actions: 'navNextSlide',
          },
          UPDATE_GROUP_STATUS: {
            actions: 'updateGroupStatus',
          },
          REMOVE_ALL_UNAVALIABLE_SLIDE: {
            actions: 'removeAllUnavailableSlide',
          },
          REPLACE_GROUP: {
            actions: 'replaceGroup',
          },
          UNREPLACE_GROUP: {
            actions: 'unreplaceGroup',
          },
          APPLY_REPLACEMENT: {
            target: 'applyReplacementSlides',
          },
        },
      },
      applyReplacementSlides: {
        entry: (ctx) => {
          logger.findReplacementMachine
            .info(`Applying changes from find replacement modal to Custom Deck: ${ctx.customDeckId}`)
        },
        description: 'Apply updated slide to custom deck',
        on: {
          GO_TO_FINAL: {
            target: 'final',
          },
        },
      },
      final: {
        type: 'final',
      },
    },
  },
  {
    guards: {
      canNavPrevious: (ctx, _evt, meta) => {
        // @ts-expect-error - machine states don't exactly match, but it works
        const needReviewGroupIndexes = selectors.needReviewGroupIndexes(meta.state).needReviewGroupIndexes;
        if (ctx.activeGroupId) {
          const indexOfActiveGroup = needReviewGroupIndexes[ctx.activeGroupId]?.idx ?? -1
          return (indexOfActiveGroup > 0)
        }
        else return false
      },
      canNavNext: (ctx, _evt, meta) => {
        // @ts-expect-error - machine states don't exactly match, but it works
        const needReviewGroupIndexes = selectors.needReviewGroupIndexes(meta.state).needReviewGroupIndexes;
        if (ctx.activeGroupId) {
          const indexOfActiveGroup = needReviewGroupIndexes[ctx.activeGroupId]?.idx ?? -1;
          const needReviewGroupIndexesLength = Object.keys(needReviewGroupIndexes).length
          return (
            (indexOfActiveGroup > -1) &&
            (indexOfActiveGroup < needReviewGroupIndexesLength - 1)
          )
        }
        else return false
      },
    },
    actions: {
      setActiveGroup: (ctx, evt) => {
        logger.findReplacementMachine.info(`Set active group: ${evt.id}`);
        ctx.activeGroupId = evt.id;
      },
      navPreviousSlide: (ctx) => {
        if (!ctx.activeGroupId) {
          logger.findReplacementMachine.error('Cannot find activeGroupId when trying to perform navPreviousSlide');
          return;
        }
        const needReviewGroups = ctx.replaceGroups.filter(group => {
          return group.status !== GroupDraftStatus.ACTIVE && group.status !== GroupDraftStatus.GROUP_REMOVED;
        });
        const indexOfActiveGroup = needReviewGroups.findIndex(group => group.groupId === ctx.activeGroupId);
        const newActiveGroupId = needReviewGroups[indexOfActiveGroup - 1].groupId;
        ctx.activeGroupId = newActiveGroupId;
        logger.findReplacementMachine.info(`Navigate to previous slide: ${newActiveGroupId}`);
      },
      navNextSlide: (ctx) => {
        if (!ctx.activeGroupId) {
          logger.findReplacementMachine.error('Cannot find activeGroupId when trying to perform navNextSlide');
          return;
        }
        const needReviewGroups = ctx.replaceGroups.filter(group => {
          return group.status !== GroupDraftStatus.ACTIVE && group.status !== GroupDraftStatus.GROUP_REMOVED;
        });
        const indexOfActiveGroup = needReviewGroups.findIndex(group => group.groupId === ctx.activeGroupId);
        const newActiveGroupId = needReviewGroups[indexOfActiveGroup + 1].groupId;
        ctx.activeGroupId = newActiveGroupId;
        logger.findReplacementMachine.info(`Navigate to next slide: ${newActiveGroupId}`);
      },
      updateGroupStatus: (ctx, evt) => {
        if (!ctx.activeGroupId) {
          logger.findReplacementMachine.error('Cannot find activeGroupId when trying to perform updateGroupStatus');
          return;
        }
        ctx.isGroupDirty = true;
        const needReviewGroupsIds = ctx.replaceGroups.filter(group => {
          return group.status !== GroupDraftStatus.ACTIVE && group.status !== GroupDraftStatus.GROUP_REMOVED;
        }).map(group => group.groupId);
        const indexOfActiveGroup = needReviewGroupsIds.findIndex(groupId => groupId === ctx.activeGroupId);
        const isLastSlide = needReviewGroupsIds.length - 1 === indexOfActiveGroup;
        const newReplaceGroups = ctx.replaceGroups.map(group => {
          const newGroup = { ...group }
          if (group.groupId === evt.id) {
            // IN CASE WE'RE "RESTORING" THE REMOVAL OF A GROUP THAT ALREADY SELECTED A REPLACEMENT
            if (evt.newStatus === GroupDraftStatus.MAJOR_UPDATE && group.groupReplacement) {
              newGroup.status = GroupDraftStatus.REPLACED;
            } else {
              newGroup.status = evt.newStatus;
            }
            logger.findReplacementMachine
              .info(`Update group ${ctx.activeGroupId} status from ${group.status} to ${newGroup.status}`);
          }
          return newGroup;
        })
        ctx.replaceGroups = newReplaceGroups;

        const newNeedReviewGroupsIds = newReplaceGroups.filter(group => {
          return group.status !== GroupDraftStatus.ACTIVE && group.status !== GroupDraftStatus.GROUP_REMOVED;
        }).map(group => group.groupId);

        // NAV TO NEXT SLIDE
        const isRemoved = evt.newStatus === GroupDraftStatus.GROUP_REMOVED;
        // If this is the last slide and it is deleted, go to the last slide from the new array
        if (isLastSlide && isRemoved) {
          ctx.activeGroupId = newNeedReviewGroupsIds[newNeedReviewGroupsIds.length - 1];
        }
        // If this is NOT the last slide and it is deleted, maintain index
        else if (!isLastSlide && isRemoved) {
          ctx.activeGroupId = newNeedReviewGroupsIds[indexOfActiveGroup];
        }
        // If this is NOT the last slide and it is not deleted, go to next slide
        else if (!isLastSlide && !isRemoved) {
          ctx.activeGroupId = newNeedReviewGroupsIds[indexOfActiveGroup + 1];
        }
        // If this is the last slide and it is not deleted, then maintain position (do nothing)
      },
      removeAllUnavailableSlide: (ctx) => {
        if (!ctx.activeGroupId) {
          logger.findReplacementMachine
            .error('Cannot find activeGroupId when trying to perform removeAllUnavailableSlide');
          return;
        }
        logger.findReplacementMachine.info('Remove all unavailable slide.');
        ctx.isGroupDirty = true;

        let newActiveId: string | undefined;
        let indexOfOldActiveGroup: number;
        const needReviewStatus = [GroupDraftStatus.MAJOR_UPDATE, GroupDraftStatus.REPLACED];
        const newReplaceGroups = ctx.replaceGroups.map((group, index) => {
          const newGroup = { ...group };
          const isNextNeedReviewGroup = indexOfOldActiveGroup !== undefined &&
            needReviewStatus.includes(newGroup.status);

          if (unavailableStatus[group.status]) newGroup.status = GroupDraftStatus.GROUP_REMOVED;
          // WE TRY TO NAV TO THE NEXT SLIDE THAT NEEDS REVIEW
          else if (!newActiveId && isNextNeedReviewGroup) newActiveId = newGroup.groupId;

          if (group.groupId === ctx.activeGroupId) indexOfOldActiveGroup = index;
          return newGroup;
        });

        if (!newActiveId) {
          // IF WE CAN"T FIND THE NEXT SLIDE TO NAV TO
          // WE TRY TO NAV TO THE PREVIOUS SLIDE THAT NEEDS REVIEW
          // ELSE IF CAN BE UNDEFINED
          const reverseGroups = [...ctx.replaceGroups].reverse();
          let indexOfOldActiveGroup: number;
          reverseGroups.forEach((group, index) => {
            const isPreviousNeedReviewGroup = indexOfOldActiveGroup !== undefined &&
            needReviewStatus.includes(group.status);
            if (!newActiveId && isPreviousNeedReviewGroup) newActiveId = group.groupId;
            if (group.groupId === ctx.activeGroupId) indexOfOldActiveGroup = index;
          })
        }
        ctx.replaceGroups = newReplaceGroups;
        ctx.activeGroupId = newActiveId;
      },
      replaceGroup: (ctx, evt) => {
        if (!ctx.activeGroupId) {
          logger.findReplacementMachine.error('Cannot find activeGroupId when trying to perform replaceGroup');
          return;
        }
        logger.findReplacementMachine.info(`Replace group: ${ctx.activeGroupId}`);

        const needReviewGroups = ctx.replaceGroups.filter(group => {
          return group.status !== GroupDraftStatus.ACTIVE && group.status !== GroupDraftStatus.GROUP_REMOVED;
        });
        const indexOfActiveGroup = needReviewGroups.findIndex(group => group.groupId === ctx.activeGroupId);
        const isLastSlide = needReviewGroups.length - 1 === indexOfActiveGroup;
        ctx.isGroupDirty = true;
        const newReplaceGroups = ctx.replaceGroups.map(group => {
          const newGroup = { ...group }
          if (group.groupId === ctx.activeGroupId) {
            newGroup.groupReplacement = evt.payloadGroup;
            newGroup.status = GroupDraftStatus.REPLACED;
            analytics?.track('CUSTOM_REPLACE', {
              action: 'REPLACE',
              category: 'CUSTOM',
              customDeckId: ctx.customDeckId,
            });
          }
          return newGroup;
        });
        ctx.replaceGroups = newReplaceGroups;

        // NAV TO NEXT SLIDE
        if (!isLastSlide) {
          ctx.activeGroupId = needReviewGroups[indexOfActiveGroup + 1].groupId;
        }
      },
      unreplaceGroup: (ctx) => {
        if (!ctx.activeGroupId) {
          logger.findReplacementMachine.error('Cannot find activeGroupId when trying to perform unreplaceGroup');
          return;
        }
        logger.findReplacementMachine.info(`Unreplace group: ${ctx.activeGroupId}`);

        const newReplaceGroups = ctx.replaceGroups.map(group => {
          const newGroup = { ...group }
          if (group.groupId === ctx.activeGroupId) {
            newGroup.groupReplacement = undefined;
            newGroup.status = GroupDraftStatus.MAJOR_UPDATE;
          }
          return newGroup;
        });
        ctx.replaceGroups = newReplaceGroups;
      },
    },
    services: {},
  },
)

export default findReplacementSM
