import React, { useState } from 'react'
import { useDispatch } from 'src/state/redux'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import {
  DNAModal,
  DNABox,
  useDisableSwipe,
  DNAButton,
  DatePicker,
  DNAText,
} from '@alucio/lux-ui'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import isBefore from 'date-fns/isBefore'
import isEqual from 'date-fns/isEqual'

interface DNAUpdateSlideDataProps {
  send: (event: Date) => void,
}

const SCHEDULED_PUBLISH_MINUTE_INTERVALS = 30
const maxTime = new Date(2099, 11, 31, 23, 59, 59)

const getNextAvailableHourDate = () => {
  const minDate = new Date();
  minDate.setHours(minDate.getHours() + 1);
  minDate.setMinutes(30);
  minDate.setSeconds(0);
  minDate.setMilliseconds(0)
  return minDate;
}

const DNASchedulePublishDocument: DNAModalVariant<DNAUpdateSlideDataProps> = ({
  toggleModal,
  send,
}) => {
  useDisableSwipe()
  const minDate = getNextAvailableHourDate();
  const [schedulePublishDate, setSchedulePublishDate] = useState(minDate);
  const [error, setError] = useState('');
  const dispatch = useDispatch()

  const isSameDayTimeRange = (isBefore(schedulePublishDate, minDate) || isEqual(schedulePublishDate, minDate))
  const fullTimeRange = new Date(
    schedulePublishDate.getFullYear(),
    schedulePublishDate.getMonth(),
    schedulePublishDate.getDate(),
    0,
    0,
    0,
  );

  const minTime = isSameDayTimeRange 
    ? minDate
    : fullTimeRange

  const onAction = () => {
    send(schedulePublishDate)
    dispatch(DNAModalActions.toggleModal())
  }

  const onDateSelected = (date: Date) => {    
    setSchedulePublishDate(date)

    const isInvalidRetroactiveTime = isBefore(date, minDate)

    if(isInvalidRetroactiveTime) {
      setError('A scheduled publish date must be in the future.')
      return
    }

    setError('')
  }

  return (
    <DNAModal>
      <DNAModal.Header onClose={toggleModal}>
        Schedule publish
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox fill appearance="col" spacing="sm">
          <DNABox fill alignY="center" spacing="md">
            <DatePicker
              label="DATE"
              selectedDate={schedulePublishDate}
              onDateSelected={onDateSelected}
              dateFormat="MM/dd/yyyy"
              minDate={minDate}
              minTime={minTime}
              maxTime={maxTime}
            />
            <DatePicker
              selectedDate={schedulePublishDate}
              onDateSelected={onDateSelected}
              label="TIME"
              showTimeSelectOnly={true}
              includeTimePicker={true}
              timeIntervals={SCHEDULED_PUBLISH_MINUTE_INTERVALS}
              minTime={minTime}
              customFormat="h:mm aa z"
              minDate={minDate}
              maxTime={maxTime}
            />
          </DNABox>
          <DNAText status='danger'>{error}</DNAText>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNABox alignY="center" spacing="sm">
          <DNAButton size="sm" onPress={toggleModal} status="tertiary">
            Cancel
          </DNAButton>
          <DNAButton
            size="sm"
            testID="confirmation-button"
            onPress={onAction}
            status="primary"
            disabled={!!error}
          >
            Schedule
          </DNAButton>
        </DNABox>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}

export default DNASchedulePublishDocument
