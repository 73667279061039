import React, { useMemo } from 'react'
import { DNABox, DNACard, DNAText, Iffy, DNAChip, DNAButton } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import { Pressable, StyleSheet } from 'react-native';
import { LoadedPresentation, PresentableModelORM } from 'src/state/context/ContentProvider/ContentProvider';
import { isDocumentVersionORM, isFolderItemORM, isPageGroupORM } from 'src/types/typeguards';
import { Icon } from '@ui-kitten/components';
import { OpenedPresentationsVariantOption } from './OpenedPresentations';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import DocumentsAssociated from './DocumentsAssociated';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import { getPresentationVirtualStatus } from 'src/screens/Meetings/util';

const styles = StyleSheet.create({
  presentButton: {
    borderRadious: 4,
    borderWidth: 1,
  },
  containerStyle: {
    paddingLeft: 8,
    paddingRight: 12,
    paddingTop: 8,
    backgroundColor: colors['color-gray-800'],
    marginVertical: 4,
    borderRadius: 4,
  },
  inPersonControlContainerStyle: {
    paddingLeft: 8,
    paddingRight: 12,
    paddingVertical: 8,
    paddingBottom: 8,
    backgroundColor: 'transparent',
    marginVertical: 4,
    borderBottomColor: 'rgba(255, 255, 255, 0.16)',
    borderBottomWidth: 1,
  },
  activeItem: {
    backgroundColor: 'rgba(48, 173, 127, 0.16)',
    borderWidth: 1,
    borderColor: colors['color-success-500'],
    bordeRadius: 4,
    paddingLeft: 8,
    paddingRight: 12,
    paddingTop: 8,
    marginVertical: 4,
  },
  icon: {
    color: colors['color-success-500'],
    width: 32,
    height: 32,
  },
});

export interface OpenedPresentationRowProps {
  isVirtual?: boolean
  item: LoadedPresentation,
  removePresentation: (id: string) => void,
  changeActivePresentation: (id: string) => void,
  activePresentation?: LoadedPresentation,
  variant?:OpenedPresentationsVariantOption,
  associatedDocuments?:LoadedPresentation[],
  isAssociatedDocument?: boolean,
  onCloseActionBarPopout?: () => void,
}

enum Actions {
  change,
  close
}
type Action = keyof typeof Actions
type ActionEffects = Record<Action, (id: string, optionalCondition?: (orm?: PresentableModelORM) => boolean) => void>

const DocumentOpenedPresentationRow: React.FC<OpenedPresentationRowProps> = ({
  item,
  removePresentation,
  changeActivePresentation,
  activePresentation,
  variant = 'default',
  associatedDocuments,
  isAssociatedDocument,
  isVirtual,
  onCloseActionBarPopout,
}) => {
  const { setPresentationControlsVisible, setCurrentTab, setSelectedPresentableId } = useMeetingsState()
  const s3URL = detectArchivedFileKeyPath(item.currentPresentablePage.documentVersionORM?.model,
    item.currentPresentablePage.page)
  const isActive = activePresentation?.presentable.id === item.presentable.id;
  const isDocumentVersion = !isPageGroupORM(item.presentable.orm) && (isDocumentVersionORM(item.presentable.orm) ||
  isDocumentVersionORM(item.presentable.orm.relations.itemORM));
  const isModifiedDocumentVersion = isFolderItemORM(item.presentable.orm) && isDocumentVersion &&
  item.presentable.orm.model.visiblePages?.length;
  const presentationVirtualStatus =
    useMemo(() => getPresentationVirtualStatus(item.presentable.orm), [item.presentable.orm]);
  const isContentDisabled = isVirtual && presentationVirtualStatus.isHTMLWebPresentation;
  const isFromActionBar = variant === 'actionBar'

  const rowStyle = [
    !isFromActionBar && isActive && styles.activeItem,
    !isFromActionBar && !isActive && styles.containerStyle,
    isFromActionBar && styles.inPersonControlContainerStyle,
  ]

  const actionEffects: ActionEffects = {
    change: changeActivePresentation,
    close: removePresentation,
  }

  const canOpenPresentation = (orm?: PresentableModelORM): boolean => {
    return !getPresentationVirtualStatus(orm)?.isHTMLWebPresentation
  };

  const triggerAction = (action:Action) => {
    actionEffects[action](item.presentable.id, canOpenPresentation)
  }

  return (
    <DNAPopover disablePopover={!isContentDisabled} placement="top-start">
      <DNAPopover.Anchor style={{ flex: 1 }}>
        <DNACard
          appearance="flat"
          style={rowStyle}
        >
          <DNABox
            alignY="center"
            appearance="col"
            disabled={isContentDisabled}
            spacing="sm"
            childFill={0}
          >
            <DNABox spacing="between" alignY="center" childFill={0}>
              <Pressable
                onPress={() => triggerAction('change')}
                style={isAssociatedDocument && { paddingLeft: 40 }}
              >
                <DNABox spacing="md" alignY="center" childFill={1}>
                  <DNABox
                    style={{
                      backgroundColor: colors['color-gray-500'],
                      borderWidth: 1,
                      borderColor: colors['color-text-tertiary'],
                    }}
                  >
                    <DNAThumbnail
                      s3URL={s3URL}
                      useLoadingIndicator={true}
                      width={80}
                      height={46}
                    />
                  </DNABox>
                  <DNABox spacing="sm" fill childFill={0}>

                    {/* Content Info */}
                    <DNABox spacing="sm" appearance="col" fill>
                      <DNABox spacing="xs" appearance="col" fill>
                        <DNAText testID="document-presenation-title" numberOfLines={2} status="basic">
                          {item.presentable.title}
                        </DNAText>
                        { isPageGroupORM(item.presentable.orm) &&
                          <DNAText numberOfLines={2} status="subtle">{item.presentable.orm.model.name}</DNAText>
                          }
                      </DNABox>
                      <DNABox spacing="sm">
                        <Iffy is={isActive && variant !== 'actionBar'}>
                          <DNAChip
                            appearance="tag"
                            style={{ backgroundColor: colors['color-success-500'] }}
                          >
                            PRESENTING...
                          </DNAChip>
                        </Iffy>
                        <Iffy is={isDocumentVersion}>
                          <DNABox spacing="sm">
                            <CustomFieldBadgeList documentVersionORM={item.currentPresentablePage.documentVersionORM} />
                            <DNAChip appearance="tag">
                              {item.currentPresentablePage.documentVersionORM.model?.type}
                            </DNAChip>
                          </DNABox>
                        </Iffy>
                        <Iffy is={isModifiedDocumentVersion || !isDocumentVersion}>
                          <DNAChip
                            appearance="tag"
                            style={{ backgroundColor: colors['color-gray-500'] }}
                          >
                            MODIFIED
                          </DNAChip>
                        </Iffy>
                        <Iffy is={isContentDisabled}>
                          <DNAChip
                            appearance="tag"
                            style={{ backgroundColor: colors['color-black'] }}
                            testID={`content-not-available-${item.presentable.orm.model.id}`}
                          >
                            CONTENT NOT AVAILABLE
                          </DNAChip>
                        </Iffy>
                        <Iffy is={isAssociatedDocument}>
                          <DNAChip appearance="tag">
                            ASSOCIATED FILE
                          </DNAChip>
                        </Iffy>
                      </DNABox>
                    </DNABox>

                    {/* Action buttons */}
                    <Iffy is={!isFromActionBar && !isContentDisabled}>
                      <DNABox spacing="sm" fill alignY="center">
                        <DNAButton
                          onPress={() => triggerAction('close')}
                          appearance="filled"
                          status="dark"
                          size="sm"
                          colorMode="dark"
                          style={styles.presentButton}
                        >
                          <DNAText status="basic">Close</DNAText>
                        </DNAButton>
                      </DNABox>
                    </Iffy>
                    <Iffy is={isFromActionBar && isActive}>
                      <DNABox fill alignY="center">
                        <Icon name="check" style={styles.icon} />
                      </DNABox>
                    </Iffy>
                  </DNABox>

                </DNABox>
              </Pressable>

            </DNABox>
            <Iffy is={associatedDocuments?.length && !isFromActionBar}>
              <DocumentsAssociated
                associatedDocuments={associatedDocuments}
                isActive={isActive}
                parentPresentableId={item.presentable.id}
                handleShowPresentation={changeActivePresentation}
              />
            </Iffy>
            <Iffy is={isFromActionBar && associatedDocuments?.length}>
              <DNABox fill alignY="center">
                <DNAButton
                  testID="view-associated-files-btn"
                  appearance="filled"
                  status="dark"
                  size="xs"
                  colorMode="dark"
                  style={styles.presentButton}
                  onPress={() =>
                  {
                    setPresentationControlsVisible(true)
                    setCurrentTab('OPENED_PRESENTATIONS')
                    setSelectedPresentableId(item.presentable.id)
                    onCloseActionBarPopout?.()
                  }
                    }
                >
                  <DNAText status="basic">View associated files</DNAText>
                </DNAButton>
              </DNABox>
            </Iffy>
          </DNABox>
        </DNACard>
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText testID="web-html-custom-popup" status="basic">
          {presentationVirtualStatus.message}
        </DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  )
}

export default DocumentOpenedPresentationRow
