// [TODO-ASSOCIATED] - Rename (Removed -> Remove)
import React from 'react'
import { ScrollView } from 'react-native'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import {
  DNABox,
  DNAButton,
  DNASlider,
} from '@alucio/lux-ui'
import { styles, useSlideSettings, getThumbURL } from './SlideSettings'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import HeaderSettings from './HeaderSettings'
import AssociatedSlidesThumbnails from 'src/components/DNA/Thumbnail/AssociatedSlidesThumbnails'

const RemoveAssociatedSlidesModal: React.FC = () => {
  const {
    state,
    send,
    cond,
    documentVersionORM,
    thumbnailSizes,
    pageMap,
  } = useSlideSettings()

  return (
    <DNASlider
      visible={cond.mode.associatedSlides.remove}
      setVisible={() => send({ type: 'BACK_TO_IDLE' })}
      orientation="horizontal"
    >
      <DNABox
        fill
        appearance="col"
        style={{ backgroundColor: colors['color-text-white'] }}
      >
        <HeaderSettings
          title="Remove associated slides"
          onSave={() => {
            send({ type: 'SAVE_REMOVED_SLIDES' })
          }}
          onCancel={() => {
            send({ type: 'BACK_TO_IDLE' })
          }}
          // saveDisabled={!state.can({ type: 'SAVE_ASSOCIATED_SLIDES' })}
        />

        {/* CONTENT */}
        <DNABox fill>
          <DNABox fill appearance="col">
            <DNABox style={styles.rowWrapper} >
              <DNABox fill alignX="end">
                <DNAButton
                  appearance="ghostLink"
                  status="tertiary"
                  size="md"
                  padding="none"
                  onPress={thumbnailSizes.cycleThumbnailSize}
                  iconLeft={thumbnailSizes.thumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
                />
              </DNABox>
            </DNABox>
            <DNABox fill>
              <ScrollView style={styles.content}>
                <DNABox
                  appearance="row"
                  wrap="start"
                  childStyle={{ marginBottom: 32 }}
                  alignX="center"
                >
                  {/* [TODO-ASSOCIATED] - Extract into a composite component */}
                  {
                    state.context.versionDraft.pages?.map((page) => {
                      const coverThumbnailPageNumber = state.context.versionDraft.selectedThumbnail ?? 1
                      const thumbURL = getThumbURL(documentVersionORM, page.number)
                      const isSelected = page.number === coverThumbnailPageNumber
                      const associatedPages = Object
                        .values(state.context.selectedRemoveAssociatedSlides?.[page.pageId] ?? {})
                        ?.map(linkedSlideId => pageMap[linkedSlideId]) ?? []

                      return (
                        <DNABox
                          key={page.pageId}
                          appearance="col"
                          spacing="sm"
                          childFill={1}
                          style={{ marginRight: 12, marginBottom: 24 }} // this is to replace the spacing on the parent element, because spacing does not apply on the last item
                        >
                          {/* [TODO-ASSOCIATED] - Figure out a better way to avoid shifting scrollbar */}
                          <DNABox style={{ marginRight: 12 }}>
                            <DNAThumbnail
                              key={thumbURL}
                              s3URL={thumbURL}
                              useLoadingIndicator
                              size={thumbnailSizes.thumbnailSize}
                              mode={DNAThumbnail.Modes.READ_ONLY}
                              variant={DNAThumbnail.Variants.INFO}
                              pageNumber={page.number}
                              isCover={isSelected}
                              isRequired={page.isRequired}
                            />
                          </DNABox>
                          <AssociatedSlidesThumbnails
                            mode={AssociatedSlidesThumbnails.Mode.REMOVABLE}
                            docVerORM={documentVersionORM}
                            pages={associatedPages}
                            size={thumbnailSizes.thumbnailSize}
                            onRemoveAll={
                              () => send({
                                type: 'UPDATE_REMOVED_SLIDES',
                                payload: { parent: page.pageId, removed: 'all' },
                              })
                            }
                            onRemove={(pageId) => {
                              send({
                                type: 'UPDATE_REMOVED_SLIDES',
                                payload: { parent: page.pageId, removed: pageId },
                              })
                            }}
                          />
                        </DNABox>
                      )
                    })
                  }
                </DNABox>
              </ScrollView>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNASlider>
  )
}

export default RemoveAssociatedSlidesModal
