import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useDndContext } from '@dnd-kit/core'
import { DNABox, DNAText, Iffy, util } from '@alucio/lux-ui'
import { DNABoxProps } from '@alucio/lux-ui/lib/components/layout/DNABox/DNABox'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

import {
  usePresentationBuilderState,
  GroupStatus,
  ModifiedPayloadGroup,
  unavailableStatus,
} from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider'
import { DroppableContainer, SortableItem, Strategies } from 'src/components/DnD/DnDWrapper'

import GroupedSlides from './TargetComponents/GroupedSlides'
import Slide from './TargetComponents/Slide'
import { ThumbnailPage } from 'src/components/SlideSelector/SlideSelector'
import { useCloneBuilder } from '../CloneBuilder'
import { usePresentationSelector }
  from 'src/components/PresentationBuilder/PresentationSelector/PresentationSelectorStateProvider'
import { GroupDraft } from 'src/components/SlideSelector/PageGroupList'
import { GroupCard, SingleSlide } from './SlidesGroupOverlay'
import { DNAThumbnailProps } from 'src/components/DNA/Thumbnail/DNAThumbnail'
import DNAThumbnail from 'src/components/DNA/Thumbnail/LazyDNAThumbnail'
import InformationBanner
  from 'src/components/PresentationBuilder/PresentationEditor/InformationBanner/InformationBanner'

const S = StyleSheet.create({
  parentContainer: {
    marginTop: 32,
    marginHorizontal: '4%',
  },
  groupedSlidesFill: {
    flexBasis: '100%',
  },
  wrappedRowHorizontalSpacing: {
    marginRight: 32,
  },
  wrappedRowVerticalSpacing: {
    marginBottom: 24,
  },
  isDraggingBorder: {
    borderColor: colors['color-brand2-400'],
  },
})

export const useSlideThumbnails = () => {
  const {
    selectedGroups,
    visibleGroupsMap,
    associatedParentsMap,
    setAssociatedParentsMap,
  } = usePresentationBuilderState();
  const [localGroupOrder, setLocalGroupOrder] = useState<string[]>(selectedGroups.map(group => group.id))

  // GroupId -> PayloadGroup
  const groupMap = useMemo(
    () => selectedGroups.reduce<Map<string, ModifiedPayloadGroup>>(
      (map, group) => {
        const newModifiedPayloadGroup:ModifiedPayloadGroup = {
          ...group,
          /** TODO: Remove this assertion in this ticket
        * https://alucioinc.atlassian.net/browse/BEAC-3670
        */
          pages:group.pages as ThumbnailPage[],
        }
        map.set(group.id, newModifiedPayloadGroup)
        return map
      }, new Map<string, ModifiedPayloadGroup>(),
    ),
    [selectedGroups],
  )

  // GroupId -> Starting Idx
  const { groupStartingIdxMap } = useMemo(
    () => localGroupOrder.reduce<{
      idxAcc: number
      groupStartingIdxMap: Record<string, number>
    }>(
      (acc, groupId) => {
        const group = groupMap.get(groupId)

        if (!group || !group.visible) return acc

        acc.groupStartingIdxMap[groupId] = acc.idxAcc
        acc.idxAcc += group.pages.length

        return acc
      },
      {
        idxAcc: 1,
        groupStartingIdxMap: { },
      },
    ),
    [localGroupOrder],
  )

  // Refresh local group on selected groups update
  useEffect(
    () => {
      // Recalculate and update the associatedParentsMap property
      const allSlideIds = new Set()
      selectedGroups.forEach(group => {
        group.pages.forEach(page => {
          allSlideIds.add(page.pageId)
        })
      })
      // Create a shallow copy of selectedGroups, only if there is new update to this copy than selectedGroups will be reassign
      let hasNewUpdate = false
      const associatedParents = new Map(associatedParentsMap)
      selectedGroups.forEach(group => {
        /** TODO: Remove this assertion in this ticket
        * https://alucioinc.atlassian.net/browse/BEAC-3670
        */
        (group.pages as ThumbnailPage[]).forEach(page => {
          let hasAssociatedParent = false
          if (page.parentIds) {
            for (const parentId of page.parentIds) {
              if (allSlideIds.has(parentId)) {
                hasAssociatedParent = true
              }
            }
            if (associatedParents.get(page.pageId) !== hasAssociatedParent) {
              associatedParents.set(page.pageId, hasAssociatedParent)
              hasNewUpdate = true
            }
          }
        })
      })
      if (hasNewUpdate)setAssociatedParentsMap(associatedParents)
      setLocalGroupOrder(selectedGroups.map(group => group.id))
    },
    [selectedGroups],
  )

  return {
    groupMap,
    visibleGroupsMap,
    groupStartingIdxMap,
    localGroupOrder,
    setLocalGroupOrder,
  }
}

const TargetItemContainer: React.FC<{
  group: ModifiedPayloadGroup,
  itemId: string,
  id: string,
  parentRef?: React.RefObject<ScrollView>
} & Pick<DNAThumbnailProps, 'onCheck' | 'mode' | 'checked'>> = ({
  group,
  itemId,
  id,
  parentRef,
  onCheck,
  mode,
  checked,
}) => {
  const { builderMode, isLocked, selectedTargetItems } = usePresentationBuilderState()
  const { groupStartingIdxMap } = useSlideThumbnails()
  const { active } = useDndContext()
  const [orientation, setOrientation] = useState<DNABoxProps['appearance']>('row')
  const isGroup = group.pages.length > 1

  useEffect(() => {
    if (builderMode === 'customization') setOrientation('row')
    else if (builderMode === 'selection') setOrientation('col')
  }, [builderMode])

  const { activeId } = useCloneBuilder()

  const groupedProps = {
    group,
    startingIdx: groupStartingIdxMap[group.id],
    orientation,
    islocked: isLocked,
    isDraggingMode: !!active?.data.current,
    parentRef,
    isChecked: checked,
    onCheck: onCheck,
    mode,
  }

  const slideProps = {
    group,
    page: group.pages[0],
    displayIdx: groupStartingIdxMap[group.id],
    enableOverlay: true,
    isDraggingMode: !!active?.data.current,
    parentRef,
    checked,
    onCheck,
    mode,
  }

  const isUnavailable = !!unavailableStatus[group.groupStatus]
  const isFindAndReplace = group.groupStatus === GroupStatus.MAJOR_UPDATE
  const isOpaqueScenario = (isUnavailable || isFindAndReplace)

  const itemContainer = isGroup
    ? <GroupedSlides {...groupedProps} />
    : <Slide {...slideProps} />

  const isSelectedForDrag = (selectedTargetItems.includes(itemId) || active?.id === id) && activeId

  return (
    <View
      key={itemId}
      style={util.mergeStyles(
        undefined,
        S.wrappedRowVerticalSpacing,
        [S.groupedSlidesFill, isGroup && orientation === 'row'],
        [S.wrappedRowHorizontalSpacing, orientation === 'row'],
      )}
    >
      {/* If sortable item is a group and is currently active, we need to create an invisible overlay to extend the width to 100% */}
      {!isOpaqueScenario && isGroup
        ? <DNABox style={{ width: '100%', height: 238, position: 'absolute' }} />
        : null
      }
      {!isOpaqueScenario
        ? <SortableItem
            key={itemId}
            id={id}
            itemId={itemId}
            containerId="default"
            style={StyleSheet.flatten([
              active?.id === id ? { width: 350 } : undefined,
              isSelectedForDrag ? { opacity: 0.30 } : undefined,
            ])}
        >
          {itemContainer}
        </SortableItem>
        : itemContainer
      }
      {/* <DNABox appearance="col">
        <DNAText>{id}</DNAText>
        <DNAText>{itemId}</DNAText>
      </DNABox> */}
    </View>
  )
}

const PoolItemContainer: React.FC<{
  activeItem: GroupDraft,
  itemId: string,
  id: string
}> = ({
  activeItem,
  itemId,
  id,
}) => {
  const isGroup = activeItem.pages.length > 1
  return (
    <View key={itemId} style={S.wrappedRowVerticalSpacing}>
      <SortableItem
        key={itemId}
        id={id}
        itemId={itemId}
        containerId="default"
      >
        <Iffy is={isGroup}>
          <GroupCard activeItem={activeItem} />
        </Iffy>
        <Iffy is={!isGroup}>
          <SingleSlide page={activeItem.pages[0]} />
        </Iffy>
      </SortableItem>
    </View>
  )
}

const EditorEmptyState: React.FC = () => {
  return (
    <DNABox
      fill
      appearance="col"
      alignX="center"
      alignY="center"
      spacing="sm"
      style={{ width: '100%', height: '100%' }}
    >
      <DNAText h3>
        Build your presentation
      </DNAText>
      <DNAText>Choose a file then add slides here</DNAText>
    </DNABox>
  )
}

const SlidesGroupTarget: React.FC = () => {
  const {
    builderMode,
    selectedGroups,
    selectedTargetItems,
    setSelectedTargetItems,
    hasNeedReviewGroup,
  } = usePresentationBuilderState()
  const { getGroupDrafts, active } = usePresentationSelector()
  const { groupedTargetItems } = useCloneBuilder()
  const { groupMap, visibleGroupsMap } = useSlideThumbnails()
  const scrollViewRef = useRef<ScrollView>(null)
  const [sortingStrategy, setSortingStrategy] = useState<Strategies>('rectSortingStrategy')
  const selectedContent = selectedGroups.length

  useEffect(() => {
    if (builderMode === 'customization') setSortingStrategy('rectSortingStrategy')
    else if (builderMode === 'selection') setSortingStrategy('verticalListSortingStrategy')
  }, [builderMode])

  const onCheck = useCallback((id: string) => {
    const isSelected = selectedTargetItems.includes(id)

    setSelectedTargetItems(isSelected
      ? selectedTargetItems.filter(itemId => itemId !== id)
      : [...selectedTargetItems, id],
    )
  }, [selectedTargetItems])

  return (
    <DNABox
      fill
      style={{ backgroundColor: colors['color-gray-10'], zIndex: -1 }}
    >
      <DNABox
        testID="custom-deck-container"
        as={ScrollView}
        fill
        appearance="col"
        ref={scrollViewRef}
      >
        <InformationBanner />
        <DroppableContainer
          id="default"
          items={groupedTargetItems.default}
          strategy={sortingStrategy}
          style={{ width: '100%', height: '100%' }}
          disabled={hasNeedReviewGroup}
        >
          {!selectedContent && !active ? <EditorEmptyState />
            : <DNABox
                wrap="start"
                alignX={builderMode === 'selection' ? 'center' : undefined}
                style={S.parentContainer}
            >
              {/* Map draggable Slides and Grouped Slides */}
              {
                groupedTargetItems.default
                  .filter(({ itemId }) => {
                  // If value in visibleGroupsMap is false, that means the slide is hidden and user does not want to show hidden slide(s)
                    return visibleGroupsMap[itemId] !== false
                  })
                  .map(({ id, itemId }) => {
                    const groupFromTarget = groupMap.get(itemId)
                    const isSelected = selectedTargetItems.includes(itemId)

                    if (groupFromTarget) {
                      return (
                        <TargetItemContainer
                          key={itemId}
                          group={groupFromTarget}
                          itemId={itemId}
                          id={id}
                          parentRef={scrollViewRef}
                          mode={DNAThumbnail.Modes.SELECTABLE}
                          onCheck={() => onCheck(itemId)}
                          checked={isSelected}

                        />
                      )
                    }

                    // If there is no groupFromTarget, create temporary placeholder based on itemId from the selector
                    const groupFromPool = getGroupDrafts().find(groupDraft => groupDraft.id === itemId)
                    if (groupFromPool) {
                      return (
                        <PoolItemContainer
                          key={itemId}
                          activeItem={groupFromPool}
                          itemId={itemId}
                          id={id}
                        />
                      )
                    }
                    else return null
                  })
              }
            </DNABox>
          }
        </DroppableContainer>
      </DNABox>
    </DNABox>
  )
}

export default SlidesGroupTarget
