import React, { PropsWithChildren } from 'react'
import {
  DNAContextMenu,
} from '@alucio/lux-ui'
import { useDispatch } from 'src/state/redux'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import AddGroupModal from './AddGroupModal'
import { useSlideSettingsState } from '../SlideSettings.proxy'
import { useGroupState } from './GroupProvider.proxy'
import DeleteGroupModal from './RemoveGroupModal'

const GroupContextMenu: React.FC<PropsWithChildren<{
  groupName: string
  isLocked: boolean,
}>> = (props) => {
  const {
    groupName,
    children,
  } = props

  const { send } = useSlideSettingsState()
  const { groups } = useGroupState()
  const dispatch = useDispatch();

  const renameGroup = () => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: false,
      component: (props) => (
        <AddGroupModal
          defaultValue={groupName}
          groupNames={groups.map(group => group.name)}
          mode="edit"
          onSuccess={( name ) => send({ type: 'RENAME_GROUP', payload: { oldName: groupName, newName: name } } )}
          {...props}
        />
      ),
    }))
  }

  const removeGroup = () => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <DeleteGroupModal
          groupName={groupName}
          onSuccess={() => send({ type: 'REMOVE_GROUP', payload: groupName })}
          {...props}
        />
      ),
    }))
  }

  const removeAllSlides = () => {
    send({ type: 'REMOVE_GROUP_SLIDES', payload: { groupName, itemIdx: 'all' } })
  }

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        { children }
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          onPress={renameGroup}
          title="Rename group"
        />
        <DNAContextMenu.Item
          onPress={removeAllSlides}
          title="Remove all slides"
        />
        <DNAContextMenu.Item
          onPress={removeGroup}
          title="Delete group"
          status="danger"
        />
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default GroupContextMenu
