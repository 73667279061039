import React from 'react'
import { StyleSheet } from 'react-native'
import {
  DNAText,
  DNABox,
  DNAButton,
  Iffy,
  luxColors,
  DNADivider,
  DNAChip,
} from '@alucio/lux-ui'

import { useDisplayMode } from 'src/components/DNA/hooks/useDisplayMode'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import useCurrentPage from '../hooks/useCurrentPage'
import { ROUTES } from 'src/router/routes'
import { useDNADocumentFilters } from '../Document/DNADocumentFilters/DNADocumentFilters'
import { useDocumentSelectManager } from 'src/screens/MyLibrary/DocumentSelectManager'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags'

export interface GridListTabletHeaderProps {
  totalRecordCount: number,
  searchText?: string,
  setFiltersVisible?: (visible: boolean) => void,
  isFiltersVisible?: boolean,
  isLoading?: boolean,
}

const S = StyleSheet.create({
  hoveredRow: {
    backgroundColor: luxColors.mainContainer.primary,
  },
  itemsCountText: {
    color: colors['color-brand2-500'],
  },
  HeaderCol: {
    paddingVertical: 12,
  },
  DesktopContainer: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    overflow: 'hidden',
  },
  MobileContainer: {
    backgroundColor: luxColors.info.primary,
  },
  MobileHeader: {
    backgroundColor: colors['color-gray-10'],
    paddingHorizontal: 16,
    minHeight: 49,
  },
  MobileRow: {
    paddingHorizontal: 16,
  },
  DataCell: {
    paddingTop: 8,
    paddingBottom: 8,
  },
})

export const GridListTabletHeader: React.FC<GridListTabletHeaderProps> = ({
  searchText, totalRecordCount, isFiltersVisible, setFiltersVisible, isLoading,
}) => {
  const { activeFilters } = useDNADocumentFilters()
  const { displayModeIconName, toggleDisplayMode } = useDisplayMode()
  const { selectEnabled, setSelectEnabled } = useDocumentSelectManager()

  const currentPage = useCurrentPage();
  const featureFlags = useFeatureFlags('enableBulkAddToFolder', 'enableBulkShareEmail');
  const isBulkSelectEnabled = featureFlags.enableBulkAddToFolder || featureFlags.enableBulkShareEmail

  const isSearch = !!searchText?.length

  return (
    <DNABox alignY="center" spacing="between" style={S.MobileHeader}>
      {/* LEFT SIDE OF THE HEADER */}
      <DNABox alignY="center" spacing="sm">
        {/* FILTER/QUICK FILTER BUTTON AND DIVIDER */}
        <DNABox fill alignY="center" spacing="sm">
          <Iffy is={currentPage === ROUTES.LIBRARY || isSearch}>
            <Iffy is={!isFiltersVisible}>
              <DNAButton
                status="tertiary"
                appearance="outline"
                onPress={() => setFiltersVisible?.(true)}
                testID="filters-header-button"
              >
                <DNABox spacing="sm">
                  <DNAText bold>Filters</DNAText>
                  {activeFilters.length > 0 &&
                    <DNAChip appearance="tag" status="primary">{`${activeFilters.length}`}</DNAChip>
                  }
                </DNABox>
              </DNAButton>
            </Iffy>
            <Iffy is={!isFiltersVisible}>
              <DNADivider vertical={true} height={20} />
            </Iffy>
          </Iffy>
        </DNABox>
        {/* ITEM COUNTS TEXT */}
        <DNAText status="flatAlt" testID="filter-count-label">
          {
            searchText
              ? `Search results for "${searchText}"${isLoading ? ''
                : ` | ${totalRecordCount} result${totalRecordCount === 1 ? '' : 's'}`}`
              : `${totalRecordCount} item(s)`
          }
        </DNAText>
      </DNABox>
      {/* RIGHT SIDE OF THE HEADER */}
      <DNABox alignY="center" spacing="sm">
        <Iffy is={(currentPage === ROUTES.LIBRARY || isSearch ) && isBulkSelectEnabled}>
          <DNAButton
            testID="header-select-button"
            size="md"
            padding="sm"
            status="tertiary"
            appearance="outline"
            rounded="md"
            iconLeft={selectEnabled ? undefined : 'checkbox-marked-outline'}
            onPress={() => setSelectEnabled(pre => !pre)}
          >
            {selectEnabled ? 'Cancel' : 'Select'}
          </DNAButton>
        </Iffy>
        <Iffy is={!isSearch}>
          <DNAButton
            appearance="outline"
            status="tertiary"
            size="md"
            padding="sm"
            iconLeft={displayModeIconName}
            onPress={toggleDisplayMode}
          />
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

export default GridListTabletHeader
