import React, { useCallback, useMemo } from 'react';
import { DNABox, DNAButton, DNACard, DNAText } from '@alucio/lux-ui';
import { FlatList, StyleSheet } from 'react-native';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import DocumentOpenedPresentationRow from './DocumentOpenedPresentationRow';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { LoadedPresentation } from 'src/state/context/ContentProvider/ContentProvider';
import useThumbnailSize from 'src/hooks/useThumbnailSize/useThumbnailSize';
import { Presentable } from 'src/types/types';
import { getPresentable } from 'src/state/context/ContentProvider/helper';
import DNASlideRoll from 'src/components/DNA/SlideRoll/DNASlideRoll';
import { SLIDE_ROLL_VARIANT } from 'src/components/DNA/SlideRoll/StyleSetting';

enum OpenedPresentationVariantOptions {
  default,
  actionBar,
}
export type OpenedPresentationsVariantOption = keyof typeof OpenedPresentationVariantOptions
type OpenedPresentationsVariants = Record<OpenedPresentationsVariantOption, React.ElementType>
type OpenedPresentationsVariantStylesheets = Record<
  OpenedPresentationsVariantOption,
  StyleSheet.NamedStyles<{[keys:string]:StyleSheet}>
  >

interface OpenedPresentationProps {
  onClose: () => void
  variant?: OpenedPresentationsVariantOption
}
interface RenderItem {
  item: LoadedPresentation,
  variant?: OpenedPresentationsVariantOption,
  associatedDocuments?: LoadedPresentation[],
  onCloseActionBarPopout?: () => void,
}

interface ActionBarProps {
  onClose: () => void
}

const openedPresentationsVariantStylesheets:OpenedPresentationsVariantStylesheets = {
  default: StyleSheet.create({
    header: {
      backgroundColor: colors['color-gray-900'],
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      padding: 6,
    },
    panel: {
      backgroundColor: colors['color-gray-900'],
      padding: 24,
    },
  }),
  actionBar: StyleSheet.create({
    header: {
      backgroundColor: colors['color-gray-900'],
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      padding: 6,
      width: 600,
    },
    panel: {
      borderRadius: 6,
      backgroundColor: colors['color-gray-800'],
      width: 600,
    },
  }),
}

const RenderItem:React.FC<RenderItem> = (
  {
    item,
    variant = 'default',
    associatedDocuments = [],
    onCloseActionBarPopout,
  },
) => {
  const {
    removePresentation,
    activePresentation,
    changeActivePresentation,
    contentPresented,
  } = useContent()
  const {
    isVirtual,
    setPresentationControlsVisible,
  } = useMeetingsState()

  const handleShowPresentation = (itemId: string) => {
    changeActivePresentation(itemId)
    setPresentationControlsVisible(false)
  }

  return (
    <DNABox appearance="col">
      <DocumentOpenedPresentationRow
        removePresentation={removePresentation}
        variant={variant}
        activePresentation={activePresentation}
        changeActivePresentation={handleShowPresentation}
        item={item}
        isVirtual={isVirtual}
        associatedDocuments={associatedDocuments}
        onCloseActionBarPopout={onCloseActionBarPopout}
      />
      {/* Presented Associated File */}
      {variant === 'actionBar' ? associatedDocuments.map(associatedDocument => {
        const ormId = associatedDocument.presentable.orm.model.id;
        const hasPresentedAssociatedFile = !!contentPresented?.find(content => content.contentId === ormId)
        if (!hasPresentedAssociatedFile) return null
        else {
          return (
            <DocumentOpenedPresentationRow
              removePresentation={removePresentation}
              variant={variant}
              activePresentation={activePresentation}
              changeActivePresentation={handleShowPresentation}
              item={associatedDocument}
              isVirtual={isVirtual}
              isAssociatedDocument
            />
          )
        }
      }) : null}
    </DNABox>
  )
}

/** SECTION: VARIANTS */
const DefaultVariant: React.FC = () => {
  const { presentations } = useContent()
  const { panel } = openedPresentationsVariantStylesheets.default

  return (
    <DNACard style={panel}>
      <DNAText h5 style={{ color: colors['color-text-white'], marginBottom: 20 }}>
        You can open multiple files and quickly switch between them.
      </DNAText>

      { presentations.length
        ? <FlatList
            keyExtractor={(item) => item.presentable.id}
            data={presentations.filter((item) => !item.meta)}
            initialNumToRender={10}
            renderItem={({ item }) => (<RenderItem
              associatedDocuments={presentations.filter(p => p.meta?.parentPresentableId === item.presentable.id )}
              item={item}
            />)}
            contentContainerStyle={{ borderRadius: 8 }}
        />
        : <DNAEmpty
            emptyVariant={EmptyVariant.MeetingsOpenedPresentationsEmpty}
            variant="default"
        />
      }
    </DNACard>
  )
}

const ActionBarVariant: React.FC<ActionBarProps> = ({ onClose }) => {
  const { presentations } = useContent()
  const { panel, header } = openedPresentationsVariantStylesheets.actionBar

  const renderItem = useCallback(({ item }) =>
    (<RenderItem
      associatedDocuments={presentations.filter(p => p.meta?.parentPresentableId === item.presentable.id )}
      item={item}
      variant="actionBar"
      onCloseActionBarPopout={onClose}
    />), [presentations])

  return (
    presentations.length
      ? (
        <DNABox
          appearance="col"
          alignX="end"
          style={{ marginRight: 0, maxHeight: 500 }}
        >
          <DNACard style={panel}>
            <DNABox alignX="end" style={header}>
              <DNAButton
                status="dark"
                iconLeft="close"
                padding="sm"
                colorMode="dark"
                onPress={onClose}
              />
            </DNABox>
            <FlatList
              keyExtractor={(item) => item.presentable.id}
              data={presentations.filter((item) => !item.meta)}
              initialNumToRender={10}
              renderItem={renderItem}
              contentContainerStyle={{ borderRadius: 8 }}
            />
          </DNACard>
        </DNABox>
      ) : null
  )
}

const SlideRollVariant: React.FC = () => {
  const {
    meetingORM,
    selectedAssociatedDocument,
    setSelectedAssociatedDocument,
    togglePresentationControlsVisibility,
  } = useMeetingsState()
  const { addPresentation } = useContent();
  const presentable: Presentable | undefined = useMemo(() =>
    selectedAssociatedDocument ? getPresentable(selectedAssociatedDocument) : undefined, [selectedAssociatedDocument]);

  const presentPage = (index: number) => {
    selectedAssociatedDocument &&
    addPresentation(selectedAssociatedDocument, index, undefined, undefined, true)
    togglePresentationControlsVisibility()
    setSelectedAssociatedDocument(undefined)
  }

  const { thumbnailDimensions } = useThumbnailSize(['lg'])
  return (
    <DNABox fill appearance="col" style={{ padding: 15 }}>
      <DNABox spacing="md" alignY="center" style={{ marginBottom: 20 }}>
        <DNAButton
          status="dark"
          iconLeft="chevron-left"
          appearance="filled"
          onPress={() => setSelectedAssociatedDocument(undefined)}
        >
          Back
        </DNAButton>
        <DNAText numberOfLines={1} status="subtle">
          {selectedAssociatedDocument?.model.title}
        </DNAText>
      </DNABox>
      <DNABox fill appearance="col" alignX="center">
        {
          presentable &&
            <DNASlideRoll
              activeSlide={presentable.presentablePages[0]}
              horizontal={false}
              ignoreSelected
              isGridView
              itemWidth={thumbnailDimensions.width}
              itemHeight={thumbnailDimensions.height}
              onSelect={presentPage}
              presentable={presentable}
              showPreview={meetingORM?.model.type === 'VIRTUAL'}
              variant={SLIDE_ROLL_VARIANT.MEETINGS}
            />
        }
      </DNABox>
    </DNABox>
  )
}

/** !SECTION: VARIANTS */

const openedPresentationsVariants:OpenedPresentationsVariants = {
  default: DefaultVariant,
  actionBar: ActionBarVariant,
}

const OpenedPresentations: React.FC<OpenedPresentationProps> = ({
  variant = 'default',
  onClose,
}) => {
  const CurrentVariant = openedPresentationsVariants[variant]
  const { selectedAssociatedDocument } = useMeetingsState()
  if (selectedAssociatedDocument && variant !== 'actionBar') return <SlideRollVariant />
  return <CurrentVariant onClose={onClose}/>
}

export default OpenedPresentations
