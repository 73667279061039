// [SESSION] - Capitalize FileName
import React from 'react'
import { ScrollView } from 'react-native'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import {
  DNABox,
  DNAButton,
  DNAText,
  DNASlider,
  DNACheckbox,
  DNADivider,
} from '@alucio/lux-ui'
import { styles, useSlideSettings, getThumbURL } from './SlideSettings'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import HeaderSettings from './HeaderSettings'
import { isAllSlidesChecked } from 'src/state/machines/versioning/SlideSettings/util'

const RequiredSlidesModal: React.FC = () => {
  const {
    state,
    send,
    cond,
    documentVersionORM,
    thumbnailSizes,
    getSlideTitle,
  } = useSlideSettings()

  // [SESSION] - Review temp state holding for this
  const selectedPages = Object.values(state.context.selectedRequiredSlides).filter(val => val)
  const selectedText = `${selectedPages.length} of ${state.context.versionDraft.pages.length} Selected`
  const allSelected = isAllSlidesChecked(
    state.context.versionDraft.pages,
    state.context.selectedRequiredSlides,
  )

  return (
    <DNASlider
      visible={cond.mode.requiredSlides}
      setVisible={() => send({ type: 'BACK_TO_IDLE' })}
      orientation="horizontal"
    >
      <DNABox
        fill
        appearance="col"
        style={{ backgroundColor: colors['color-text-white'] }}
      >
        <HeaderSettings
          title="Which slides are required when building a custom presentation?"
          saveDisabled={!state.can({ type: 'SAVE_REQUIRED_SLIDES' })}
          onSave={() => send({ type: 'SAVE_REQUIRED_SLIDES' })}
          onCancel={() => send({ type: 'BACK_TO_IDLE' })}
        />

        {/* CONTENT */}
        <DNABox fill>
          <DNABox fill appearance="col">
            <DNABox style={styles.rowWrapper} alignY="center">
              <DNABox spacing="md" alignY="center">
                <DNACheckbox
                  checked={allSelected}
                  onChange={() =>
                    send({ type: 'UPDATE_REQUIRED_SLIDES', payload: 'all' })
                  }
                  style={{ marginLeft: 5, marginRight: 7 }}
                />
                <DNAText status="flatAlt">Select all</DNAText>
                <DNADivider style={styles.verticalDivider} />
                <DNAText status="flatAlt">
                  {selectedText}
                </DNAText>
              </DNABox>
              <DNABox fill alignX="end">
                <DNAButton
                  appearance="ghostLink"
                  status="tertiary"
                  size="md"
                  padding="none"
                  onPress={thumbnailSizes.cycleThumbnailSize}
                  iconLeft={thumbnailSizes.thumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
                />
              </DNABox>
            </DNABox>

            <DNABox fill>
              <ScrollView style={styles.content}>
                <DNABox
                  appearance="row"
                  wrap="start"
                  childStyle={{ marginBottom: 32 }}
                  alignX="center"
                >
                  {
                    state.context.versionDraft.pages?.map((page) => {
                      const coverThumbnailPageNumber = state.context.versionDraft.selectedThumbnail ?? 1
                      const thumbURL = getThumbURL(documentVersionORM, page.number)
                      const isRequired = state.context.selectedRequiredSlides[page.pageId]
                      const isCover = page.number === coverThumbnailPageNumber

                      return (
                        <DNABox key={thumbURL} style={{ marginRight: 24, marginBottom: 24 }}>
                          <DNAThumbnail
                            s3URL={thumbURL}
                            useLoadingIndicator
                            size={thumbnailSizes.thumbnailSize}
                            mode={DNAThumbnail.Modes.SELECTABLE}
                            variant={DNAThumbnail.Variants.INFO}
                            pageNumber={page.number}
                            checked={isRequired}
                            isCover={isCover}
                            thumbnailTitle={getSlideTitle(page.number)}
                            isRequired={isRequired}
                            onCheck={() => send({ type: 'UPDATE_REQUIRED_SLIDES', payload: page.pageId })}
                          />
                        </DNABox>
                      )
                    })
                  }
                </DNABox>
              </ScrollView>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNASlider>
  )
}

export default RequiredSlidesModal
