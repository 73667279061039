import React from 'react'
import { StyleSheet } from 'react-native';
import { DNABox, DNADivider, DNAText, Iffy } from '@alucio/lux-ui'
import { ReportProvider } from 'src/components/Reports/ReportProvider';
import { ReportSelector } from 'src/components/Reports/ReportSelector';
import { EmbeddedReport } from 'src/components/Reports/EmbeddedReport';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';

const styles = StyleSheet.create({
  header: {
    marginBottom: 0,
  },
  mainWrapper: {
    paddingHorizontal: 48,
    paddingVertical: 20,
  },
});

const publisherFolder = 'Beacon_Embedded_Publisher'
const FIGMA_ANALYTICS_DEMO_URL = 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com' +
    '%2Fproto%2FEuo76my4iCPmlWBh5kpcyp%2FAnalytics-%255BViewer%252C-Publisher%252C-Reports%255D%3Fpage-id' +
    '%3D786%253A15789%26hide-ui=1%26node-id%3D786%253A19106%26viewport%3D747%252C415%252C0.34%26scaling%3Dmin-zoom' +
    '%26starting-point-node-id%3D752%253A11272';

const PublisherReports = () => {
  const enableDemoMode = useFeatureFlags('enableDemoMode');

  return (
    <ReportProvider folderName={publisherFolder}>
      <DNABox
        spacing="md"
        style={styles.mainWrapper}
        appearance="col"
        fill
      >
        { /* HEADER */}
        <DNABox alignY="center" spacing="between">
          <DNAText testID="page-title" h5 status="secondary">Analytics</DNAText>
        </DNABox>
        <DNADivider />
        <Iffy is ={!enableDemoMode}>
          <ReportSelector />
          <DNABox fill>
            <EmbeddedReport />
          </DNABox>
        </Iffy>
        <Iffy is={enableDemoMode}>
          <DNABox fill>
            <iframe
              style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
              width="1288"
              height="1148"
              src={FIGMA_ANALYTICS_DEMO_URL}
              allowFullScreen
            />
          </DNABox>
        </Iffy>
      </DNABox>
    </ReportProvider >
  )
}

PublisherReports.displayName = 'Reports';

export default PublisherReports
