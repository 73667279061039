import { CacheManifestEntry } from '@alucio/core'
import deepEqual from 'fast-deep-equal'
import { matchesState, State } from 'xstate'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  SyncManagerStateValue,
  SyncManagerContext,
  SyncManagerEvents,
  SyncManagerState,
} from 'src/worker/machines/sync/sync.types'
import * as logger from 'src/utils/logger'
import workerChannel from 'src/worker/channels/workerChannel'

const SW_HEARTBEAT_INTERVAL = 15000
const sliceName = 'cache'

export type CacheSliceState = {
  manifestEntries: CacheManifestEntry[],
  isOnline: boolean,
  sync: {
    stateSerialized: string,
    context: SyncManagerContext,
    value: SyncManagerStateValue['value'],
  },
  keepAliveTimer?: number,
  logs: string[]
}

const initialState:CacheSliceState = {
  manifestEntries: [],
  isOnline: navigator.onLine,
  sync: {
    stateSerialized: '',
    context: {
      attemptedSync: false,
      syncManifest: {},
      lastSyncDate : undefined,
      error: {
        machine: undefined,
        syncEntries: {},
      },
    },
    value: '',
  },
  logs: [],
}

const cacheSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    clearManifests: (state) => {
      state.manifestEntries = []
      return state
    },
    addLog: (state, action: PayloadAction<string>) => {
      state.logs.push(action.payload)
    },
    clearLog: (state) => {
      state.logs = []
    },
    addManifestEntries: (state, action: PayloadAction<CacheManifestEntry[] | CacheManifestEntry>) => {
      // [TODO-PWA] - Consider only non-redundant properties
      if (Array.isArray(action.payload)) {
        state.manifestEntries = [...state.manifestEntries, ...action.payload]
      }
      else { state.manifestEntries.push(action.payload) }

      return state
    },
    setManifestEntries: (state, action: PayloadAction<CacheManifestEntry[] | CacheManifestEntry>) => {
      // [TODO-PWA] - Consider only non-redundant properties
      if (Array.isArray(action.payload)) {
        state.manifestEntries = action.payload
      }
      else { state.manifestEntries = [action.payload] }

      return state
    },
    setOnline: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload
      return state
    },
    updateSync: (state, action: PayloadAction<string>) => {
      state.sync.stateSerialized = action.payload
      const hydratedState = new State<
        SyncManagerContext,
        SyncManagerEvents,
        SyncManagerState
      >(JSON.parse(action.payload))

      if (!deepEqual(state.sync.context, hydratedState.context)) { state.sync.context = hydratedState.context }

      if (!deepEqual(state.sync.value, hydratedState.value)) {
        // eslint-disable-next-line max-len
        logger.cacheSlice.debug(`Sync State Value going from: ${JSON.stringify(state.sync.value)} to ${JSON.stringify(hydratedState.value)}`)
        if (state.sync.value && matchesState('init', hydratedState.value)) {
          const status = (
            matchesState('online.paused', state.sync.value) ||
            matchesState('offline.paused', state.sync.value)
          )
            ? 'PAUSE_SYNC'
            : 'START_SYNC'
          logger.cacheSlice.info(`SW Restarted: Sending CLIENT_CONNECTED /w status ${status}`)
          workerChannel.postMessageExtended({
            type: 'CLIENT_CONNECTED',
            value: status,
          })
          return
        }
        state.sync.value = hydratedState.value
        // When syncing we need to make periodic fetch requests to keep the SW running
        // see: https://stackoverflow.com/questions/29741922/prevent-service-worker-from-automatically-stopping
        if (matchesState('online.sync', state.sync.value)) {
          if (!state.keepAliveTimer) {
            logger.cacheSlice.debug('Activating SW heartbeat interval')
            state.keepAliveTimer = window.setInterval(async () => {
              logger.cacheSlice.debug('Sending fetch heartbeat')
              const result = await fetch('/keepalive')
              if (!(await result.text()).startsWith('OK')) {
                logger.cacheSlice.warn('Detected SW failure refreshing page')
                window.location.reload()
              }
            }, SW_HEARTBEAT_INTERVAL)
          }
        } else if (state.keepAliveTimer) {
          logger.cacheSlice.debug('Clearing heartbeat interval')
          clearInterval(state.keepAliveTimer)
          state.keepAliveTimer = undefined
        }
      }
    },
  },
})

export default cacheSlice
export const cacheActions = cacheSlice.actions
