import React from 'react';
import { View } from 'react-native'
import { Route, Switch, Redirect } from './index';

import DNAMSLHome from 'src/screens/Home/DNAMSL/DNAMSLHome'
import DNAMyFolders from 'src/screens/MyFolders/DNAMyFolders'
import DNALibrary from 'src/screens/MyLibrary/DNALibrary'
import DNABookmarks from 'src/screens/Bookmarks/DNABookmarks'
import DNANotifications from 'src/screens/Notifications/Notifications'
import DNAMeetingsHistory from 'src/screens/MeetingsHistory/DNAMeetingsHistory'
import Profile from 'src/screens/Profile/Profile';

import Playground from '../screens/Playground';
import PublisherReports from '../screens/Reports/PublisherReports';
import ViewerReports from '../screens/Reports/ViewerReports';
import Documents from '../screens/Documents';
import Tenants from '../screens/Tenants/Tenants';
import EmailTemplates from '../screens/EmailTemplates/EmailTemplates';
import Meetings from '../screens/Meetings/Meetings';
import FAQ from 'src/screens/FAQ/FAQ'
import SyncStatusDebug from 'src/screens/Debug/SyncStatus'

import { useCurrentUser } from 'src/state/redux/selector/user';
import { UserRole } from '@alucio/aws-beacon-amplify/src/models';
import NotFound from 'src/components/DNA/Error'

import { ROUTES, HTMLTroubleshootingPage } from './routeDef'
import { useHistory } from 'react-router';
import PublisherSearchResults from 'src/screens/PublisherSearchResults/PublisherSearchResults';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import PopoutContent from 'src/screens/Meetings/PopoutContent/PopoutContent';
import { DeviceMode } from 'src/state/context/AppSettings'
import { openKnowledgeBase } from 'src/components/DNA/Header/FAQButton'
import AdminSettings from 'src/screens/AdminSettings/AdminSettings';
import DNAHubList from 'src/screens/Hubs/DNAHubList';
import GeneralDebug from 'src/screens/Debug/GeneralDebug';

export { ROUTES } from './routeDef'
export type { DNARoute, PAGES } from './routeDef'

const ProtectedRoute = ({ allowedRoles, component: Component, onInvalidRedirectTo, ...rest }) => {
  // [TODO] Make this more configurable later, currently a workaround for performance
  const { userProfile: user } = useCurrentUser();
  const canUserAccess = allowedRoles.includes(user?.role) ||
    (user?.additionalRoles as UserRole[]).some((role) => allowedRoles.includes(role)); // as assertion is needed because of how additionalRoles was typed in the codegen

  return (
    <Route
      {...rest}
      render={(props) => (
        canUserAccess
          ? <Component {...props} />
          : <Redirect to={onInvalidRedirectTo} />
      )}
    />
  )
}
// [TODO]: This is a workaround to improve perceived smoothness
//  This is due to slow first render times on new pages
//  Most likely due to UI Kitten :(
const PerformanceWorkaroundRoute = () => {
  return (
    <View style={{ flex: 1 }} />
  )
}

const Routes = () => {
  const featureFlags = useFeatureFlags(
    'enableHubs',
    'enableSharedFolders',
    'enablePublisherReports',
    'enableMSLReports',
    'disablePublisherFolder',
  );

  return (
    <Switch>
      {/* Currently Home only pertains to the MSL, but could be different in the future */}
      <Route exact path={ROUTES.HOME.PATH} component={DNAMSLHome} />
      <ProtectedRoute
        path={ROUTES.PUBLISHER_DASHBOARD.PATH}
        component={Documents}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.PUBLISHER_DASHBOARD.ALLOWED_ROLES}
      />
      <ProtectedRoute
        path={ROUTES.TENANTS.PATH}
        component={Tenants}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.TENANTS.ALLOWED_ROLES}
      />
      <ProtectedRoute
        path={ROUTES.INTEGRATIONS.PATH}
        component={Tenants}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.INTEGRATIONS.ALLOWED_ROLES}
      />
      <ProtectedRoute
        path={ROUTES.EMAIL_TEMPLATES.PATH}
        component={EmailTemplates}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.EMAIL_TEMPLATES.ALLOWED_ROLES}
      />
      <ProtectedRoute
        path={ROUTES.REPORTS.PATH}
        component={featureFlags.enablePublisherReports ? PublisherReports : NotFound}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.REPORTS.ALLOWED_ROLES}
      />
      <ProtectedRoute
        path={ROUTES.SEARCH_RESULTS_PUBLISHER.PATH}
        component={() => <PublisherSearchResults initUserDefaultFilters={false}/>}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.SEARCH_RESULTS_PUBLISHER.ALLOWED_ROLES}
      />
      <ProtectedRoute
        path={ROUTES.ADMIN_SETTINGS.PATH}
        component={AdminSettings}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.ADMIN_SETTINGS.ALLOWED_ROLES}
      />
      <ProtectedRoute
        path={ROUTES.ADMIN_INTEGRATION_SETTINGS.PATH}
        component={AdminSettings}
        onInvalidRedirectTo={'/'}
        allowedRoles={ROUTES.ADMIN_INTEGRATION_SETTINGS.ALLOWED_ROLES}
      />
      <Route path={ROUTES.GENERAL_DEBUG.PATH} component={GeneralDebug} />
      <Route path={ROUTES.PLAYGROUND.PATH} component={Playground} />
      <Route path={ROUTES.LIBRARY.PATH} component={DNALibrary} exact />
      <Route path={ROUTES.VIEWER_REPORTS.PATH} component={featureFlags.enableMSLReports ? ViewerReports : NotFound} />
      <Route path={ROUTES.BOOKMARKS.PATH} component={DNABookmarks} exact />
      <Route path={ROUTES.MEETING_HISTORY.PATH} component={DNAMeetingsHistory} />
      <Route path={ROUTES.HUBS.PATH} component={featureFlags.enableHubs ? DNAHubList : NotFound} exact/>
      <Route path={ROUTES.EDIT_HUBS.PATH} component={featureFlags.enableHubs ? DNAHubList : NotFound} exact/>
      <Route path={ROUTES.FOLDERS.PATH} component={DNAMyFolders} />
      <Route path={ROUTES.SHARED_FOLDERS.PATH} component={featureFlags.enableSharedFolders ? DNAMyFolders : NotFound} />
      <Route path={ROUTES.ARCHIVED_FOLDERS.PATH} component={DNAMyFolders} />
      <Route
        path={ROUTES.PUBLISHER_FOLDERS.PATH}
        component={!featureFlags.disablePublisherFolder ? DNAMyFolders : NotFound}
      />
      <Route
        path={ROUTES.PUBLISHER_SHARED_FOLDERS.PATH}
        component={featureFlags.enableSharedFolders ? DNAMyFolders : NotFound}
      />
      <Route
        path={ROUTES.PUBLISHER_ARCHIVED_FOLDERS.PATH}
        component={!featureFlags.disablePublisherFolder ? DNAMyFolders : NotFound}
      />
      {/* We're using DNALibrary to handle both search and non-search views. We will probably refactor this later in the future. */}
      <Route
        path={ROUTES.SEARCH_RESULTS.PATH}
        component={() => <DNALibrary initUserDefaultFilters={false} />}
      />
      <Route path={ROUTES.MEETING_PRESENTATION.PATH} component={Meetings} />
      <Route
        path={ROUTES.MEETING_CONTENT.PATH}
        component={PopoutContent}
      />
      <Route path={ROUTES.FAQ.PATH} component={FAQ} />
      <Route path={ROUTES.NOTIFICATIONS.PATH} component={DNANotifications} />
      <Route path={ROUTES.PROFILE.PATH} component={Profile} />
      <Route path={ROUTES.SYNC_STATUS_DEBUG.PATH} component={SyncStatusDebug} />
      <Route path="/." component={PerformanceWorkaroundRoute} />
      {/* Beacon Copy Link Routes */}
      <Route path={ROUTES.BEACON_COPY_LINK.PATH} component={DNALibrary} />
      <Route path={ROUTES.BEACON_COPY_LINK_BOOKMARKS.PATH} component={DNABookmarks} />
      <Route path={ROUTES.BEACON_COPY_LINK_HOME.PATH} component={DNAMSLHome} exact />
      {/* 404 Route */}
      <Route path={ROUTES.NOT_FOUND.PATH} component={NotFound} />
      <Route component={NotFound} />
    </Switch>
  );
};

export const RoutesListener = () => {
  const history = useHistory();
  const currentUser = useCurrentUser();

  if (history.location.pathname === '/' && currentUser?.userProfile?.role === UserRole.ALUCIO_ADMIN) {
    history.push('/tenants');
  }

  analytics.page(history.location.pathname);

  return null;
};

export const goToHTMLTroubleshootingPage = async () => openKnowledgeBase(DeviceMode.desktop, HTMLTroubleshootingPage)
export default Routes;
