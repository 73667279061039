import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import {
  DNABox,
  DNAButton,
  DNAText,
  DNAIcon,
  DNASlider,
  DNACheckbox,
  DNADivider,
  Iffy,
} from '@alucio/lux-ui'
import { styles, useSlideSettings, getThumbURL } from './SlideSettings'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import AssociatedSlidesThumbnails from 'src/components/DNA/Thumbnail/AssociatedSlidesThumbnails'

const PublisherAssociatedSlidesModal: React.FC = () => {
  const {
    state,
    send,
    cond,
    documentVersionORM,
    thumbnailSizes,
    pageMap,
    getSlideTitle,
  } = useSlideSettings()

  // [SESSION] - Should not be necessary? Interact directly with state machine instead
  const [selectedChild, setSelectedChild] = useState<string[]>([])

  useEffect(() => {
    const allSelectedSlides:string[] = []
    Object.keys(state.context.associatedSlides.child).forEach(id => {
      if (state.context.associatedSlides.child[id]) allSelectedSlides.push(id)
    })
    setSelectedChild(allSelectedSlides.sort())
  }, [state.context.associatedSlides.child])

  const numberOfSelectedChild = Object.values(state.context.associatedSlides.child)
    .filter((value) => value)?.length || 0
  const numberOfSelectedParent = Object.values(state.context.associatedSlides.parent)
    .filter((value) => value)?.length || 0
  const numberOfSlidesSelected =  cond.mode.associatedSlides.addStepOne
    ? numberOfSelectedChild
    : numberOfSelectedParent
  // calculate the selectable slides num NOT the total slide num
  const SelectableNumberOfSlides = state.context.versionDraft.pages?.reduce((acc, page) => {
    const allChildSlide = {}
    state.context.versionDraft.pages?.forEach(page => {
      page.linkedSlides?.forEach(id => {
        allChildSlide[id] = true
      })
    })
    const disabled = (cond.mode.associatedSlides.addStepOne && !!page.linkedSlides?.length) ||
      (cond.mode.associatedSlides.addStepTwo && !!allChildSlide[page.pageId]) ||
      (cond.mode.associatedSlides.addStepTwo && state.context.associatedSlides.child[page.pageId])
    if (disabled) return acc
    else return acc + 1
  }, 0)
  const currentNumberOfSlidesSelected = `${numberOfSlidesSelected} of ${SelectableNumberOfSlides} Selected`
  const isAllSlidesChecked = numberOfSlidesSelected === SelectableNumberOfSlides

  // [SESSION] - Break out into smaller components
  return (
    <DNASlider
      visible={cond.mode.associatedSlides.add}
      setVisible={() => send({ type: 'BACK_TO_IDLE' })}
      orientation="horizontal"
    >
      <DNABox
        fill
        appearance="col"
        style={{ backgroundColor: colors['color-text-white'] }}
      >
        {/* HEADER */}
        <DNABox style={ styles.headerBar } spacing="between">
          <Iffy is={cond.mode.associatedSlides.addStepOne}>
            <DNAButton
              appearance="outline"
              status="tertiary"
              padding="sm"
              onPress={() => {
                send({ type: 'BACK_TO_IDLE' })
              }}
            >
              Cancel
            </DNAButton>
          </Iffy>
          <Iffy is={cond.mode.associatedSlides.addStepTwo}>
            <DNAButton
              appearance="outline"
              status="tertiary"
              padding="sm"
              onPress={() => send({ type: 'ASSOCIATED_SLIDES_PREV_STEP' })}
            >
              Back
            </DNAButton>
          </Iffy>
          <DNABox
            alignY="center"
            spacing="sm"
          >
            <DNAIcon.Styled
              appearance="outline"
              name={'numeric-1-box'}
              size="xl"
              status={cond.mode.associatedSlides.addStepOne ? 'primary' : 'gray'}
            />
            <DNAText
              h4
              status={cond.mode.associatedSlides.addStepOne ? 'primary' : 'subtle'}
              testID="add-associated-step-one"
            >
              Which slides are required?
            </DNAText>
            <DNAIcon.Styled
              appearance="outline"
              name={'numeric-2-box'}
              size="xl"
              status={cond.mode.associatedSlides.addStepTwo ? 'primary' : 'gray'}
              style={{ marginLeft: 50 }}
            />
            <DNAText
              h4
              status={cond.mode.associatedSlides.addStepTwo ? 'primary' : 'subtle'}
              testID="add-associated-step-two"
            >
              Where should they be attached?
            </DNAText>
          </DNABox>
          <Iffy is={cond.mode.associatedSlides.addStepOne}>
            <DNAButton
              padding="sm"
              disabled={!state.can({ type: 'ASSOCIATED_SLIDES_NEXT_STEP' })}
              onPress={() => send({ type: 'ASSOCIATED_SLIDES_NEXT_STEP' })}
            >
              Next
            </DNAButton>
          </Iffy>
          <Iffy is={cond.mode.associatedSlides.addStepTwo}>
            <DNAButton
              padding="sm"
              disabled={!state.can({ type: 'SAVE_ASSOCIATED_SLIDES' })}
              onPress={() => send({ type: 'SAVE_ASSOCIATED_SLIDES' })}
            >
              Save
            </DNAButton>
          </Iffy>
        </DNABox>

        {/* CONTENT */}
        <DNABox fill>
          {/* STEP TWO - LEFT SIDE BAR */}
          <Iffy is={cond.mode.associatedSlides.addStepTwo}>
            <DNABox style={ styles.attachmentSlides } appearance="col">
              <DNABox style= { styles.attachmentSlidesHeader }>
                <DNAText status="flatAlt" bold>
                  Required Slides ({Object.values(state.context.associatedSlides.child)
                  .filter(b => b).length})
                </DNAText>
              </DNABox>
              <ScrollView>
                <DNABox appearance="col" alignX="center" style={ styles.attachmentSlidesContent } spacing="lg">
                  {selectedChild.map(id => {
                    const coverThumbnailPageNumber = documentVersionORM.model.selectedThumbnail ?? 1
                    const page = documentVersionORM.meta.allPages.find(page => page.pageId === id)
                    const thumbURL = page?.number ? getThumbURL(documentVersionORM, page.number) : ''

                    return (
                      <DNAThumbnail
                        key={thumbURL}
                        s3URL={thumbURL}
                        variant={DNAThumbnail.Variants.INFO}
                        useLoadingIndicator
                        size="lg"
                        pageNumber={page?.number}
                        isCover={page?.number === coverThumbnailPageNumber}
                        isRequired={page?.isRequired}
                        thumbnailTitle={getSlideTitle(page?.number!)}
                      />
                    )
                  })}
                </DNABox>
              </ScrollView>
            </DNABox>
          </Iffy>

          {/* ACTION BAR */}
          <DNABox fill appearance="col">
            <DNABox style={styles.rowWrapper} alignY="center">
              <Iffy is={cond.mode.associatedSlides.add}>
                <DNABox spacing="md" alignY="center">
                  <DNACheckbox
                    checked={isAllSlidesChecked}
                    onChange={() => send({ type: 'UPDATE_SELECTED_SLIDES', payload: { selection: 'all' } }) }
                    style={{ marginLeft: 5, marginRight: 7 }}
                  />
                  <DNAText status="flatAlt">Select all</DNAText>
                  <DNADivider style={styles.verticalDivider} />
                  <DNAText status="flatAlt">
                    {currentNumberOfSlidesSelected}
                  </DNAText>
                </DNABox>
              </Iffy>
              <DNABox fill alignX="end">
                <DNAButton
                  appearance="ghostLink"
                  status="tertiary"
                  size="md"
                  padding="none"
                  onPress={thumbnailSizes.cycleThumbnailSize}
                  iconLeft={thumbnailSizes.thumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
                />
              </DNABox>
            </DNABox>

            {/* SLIDES */}
            <DNABox fill>
              <ScrollView style={styles.content}>
                <Iffy is={cond.mode.associatedSlides.add}>
                  <DNABox
                    appearance="row"
                    wrap="start"
                    // [TODO-ASSOCIATED] - Consider adding marginBottom in DNABox when using spacing
                    childStyle={{ marginBottom: 32 }}
                    alignX="center"
                  >
                    {
                      state.context.versionDraft.pages?.map((page) => {
                        const coverThumbnailPageNumber = documentVersionORM.model.selectedThumbnail ?? 1
                        const thumbURL = getThumbURL(documentVersionORM, page.number)

                        const isChecked = cond.mode.associatedSlides.addStepOne
                          ? state.context.associatedSlides.child[page.pageId]
                          : state.context.associatedSlides.parent[page.pageId]

                        const associatedPages = Object
                          .values(page.linkedSlides ?? {})
                          ?.map(linkedSlideId => pageMap[linkedSlideId]) ?? []

                        const allChildSlide = {}
                        state.context.versionDraft.pages?.forEach(page => {
                          page.linkedSlides?.forEach(id => {
                            allChildSlide[id] = true
                          })
                        })

                        // Disabled: 1. If user at stepOne and the slide already has linkedslides
                        //           2. If user at stepTwo and the slide is already some other slide's associated slide
                        //           3. If user at stepTwo and the slide is already selected at stepOne
                        const disabled = (cond.mode.associatedSlides.addStepOne && !!page.linkedSlides?.length) ||
                          (cond.mode.associatedSlides.addStepTwo && !!allChildSlide[page.pageId]) ||
                          (cond.mode.associatedSlides.addStepTwo && state.context.associatedSlides.child[page.pageId])

                        // [TODO-ASSOCIATED] - Make a generic context to synchronize thumbnail loading
                        //                   - Add support for generic interactive/badge rendering
                        //                   - Add variant supports for different styling types
                        return (
                          <DNABox
                            key={page.pageId}
                            appearance="col"
                            spacing="sm"
                            childFill={1}
                            style={{ marginRight: 12, marginBottom: 8 }} // this is to replace the spacing on the parent element, because spacing does not apply on the last item
                          >
                            {/* [TODO-ASSOCIATED] - Figure out a better way to avoid shifting scrollbar */}
                            <DNABox style={{ marginRight: 12 }}>
                              <DNAThumbnail
                                key={thumbURL}
                                s3URL={thumbURL}
                                useLoadingIndicator
                                size={thumbnailSizes.thumbnailSize}
                                mode={DNAThumbnail.Modes.SELECTABLE}
                                variant={DNAThumbnail.Variants.INFO}
                                pageNumber={page.number}
                                checked={isChecked}
                                disabled={disabled}
                                thumbnailTitle={getSlideTitle(page.number)}
                                disabledMessage={cond.mode.associatedSlides
                                  // eslint-disable-next-line max-len
                                  ? 'This slide cannot have associated slides because it is already associated with another slide.'
                                  : undefined}
                                isCover={page.number === coverThumbnailPageNumber}
                                isRequired={page.isRequired}
                                onCheck={
                                  () => send({
                                    type: 'UPDATE_SELECTED_SLIDES',
                                    payload: { selection: page.pageId },
                                  })
                                }
                              />
                            </DNABox>
                            <AssociatedSlidesThumbnails
                              docVerORM={documentVersionORM}
                              pages={associatedPages}
                              size={thumbnailSizes.thumbnailSize}
                            />
                          </DNABox>
                        )
                      })
                    }
                  </DNABox>
                </Iffy>
              </ScrollView>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNASlider>
  )
}

export default PublisherAssociatedSlidesModal
