/** MODULES */
import { createMachine, sendParent } from 'xstate'
import { assign } from '@xstate/immer'
import { v4 as uuid } from 'uuid';
import deepEqual from 'fast-deep-equal'

/** BEACON TYPES */
import { DocumentVersion, PageGroup, Page, PageSetting, PageGroupSource } from '@alucio/aws-beacon-amplify/src/models'

/** TYPES */
import * as Types from './slideSettingsTypes'

const slideSettings = (docVer: DocumentVersion, pages: Page[]) => createMachine<
  Types.SlideSettingsContext,
  Types.SlideSettingsEvents,
  Types.SlideSettingsState
>(
  {
    predictableActionArguments: false,
    id: 'UpdateVersion',
    strict: true,
    context: {
      documentVersionId: docVer.id,
      versionDraft: {
        pages: pages,
        pageGroups: docVer.pageGroups,
        selectedThumbnail: docVer.selectedThumbnail,
      },
      associatedSlides: {
        child: {},
        parent: {},
      },

      groupings: {
        selectedPages: {},
        groups: {},
      },

      selectedRemoveAssociatedSlides: {},
      selectedRequiredSlides: {},
      selectedCoverThumbnail: undefined,

      stepOneIsDirty: false,
      stepTwoIsDirty: false,

      // [TODO] - Use a equal comparison instead of setting ctx flags
      isRequiredSlidesDirty: false,
      errors: [],
    },
    initial: 'idle',
    states: {
      idle: {
        description: 'The initial state of slide settings tab.',
        on: {
          SET_COVER_THUMBNAIL: { target: 'setCoverThumbnail' },
          EDIT_REQUIRED_SLIDES: { target: 'editRequiredSlides', actions: ['analyticTrackingEditRequiredSlide'] },
          ADD_ASSOCIATED_SLIDES: { target: 'addAssociatedSlides', actions: ['analyticTrackingAddAssociatedSlide'] },
          REMOVE_ASSOCIATED_SLIDES: { target: 'removeAssociatedSlides' },
          EDIT_GROUP_SLIDES: { target: 'editGroupSlides' },
          REMOVE_IMPORTED_GROUPS: {
            target: 'idle',
            actions: ['populateContext', 'removeImportedGroups', 'sendVersionDraftSync', 'clearContext'],
          },
        },
      },
      setCoverThumbnail: {
        description: 'Enter the modal to set the cover thumbnail.',
        tags: ['SLIDES_SETTING_MODAL'],
        entry: ['populateContext'],
        on: {
          SAVE_COVER_THUMBNAIL: {
            target: '#UpdateVersion.idle',
            actions: [
              'saveCoverThumbnailToDraft',
              'sendVersionDraftSync',
              'clearContext',
            ],
          },
          UPDATE_COVER_THUMBNAIL: {
            actions: ['updateCoverThumbnail'],
          },
        },
      },
      addAssociatedSlides: {
        description: 'Enter the modal of adding associated slides.',
        tags: ['SLIDES_SETTING_MODAL'],
        initial: 'stepOne',
        states: {
          stepOne: {
            description: 'Step one - choose the slides you want to link to other slides.',
            on: {
              ASSOCIATED_SLIDES_NEXT_STEP: {
                target: 'stepTwo',
                cond: 'checkIfStepOneIsDirty',
              },
              UPDATE_SELECTED_SLIDES: {
                actions: ['updateSelectedSlides'],
              },
            },
          },
          stepTwo: {
            description: 'Step two - choose the slides that require the attachment slides from step one.',
            on: {
              ASSOCIATED_SLIDES_PREV_STEP: { target: 'stepOne', actions: 'clearParent' },
              SAVE_ASSOCIATED_SLIDES: {
                target: '#UpdateVersion.idle',
                // [TODO] - Probably create another step here
                cond: 'checkIfAssociatedIsDirty',
                actions: [
                  'saveAsscociatedSlidesDraft',
                  'sendVersionDraftSync',
                  'clearContext',
                ],
              },
              UPDATE_SELECTED_SLIDES: {
                actions: ['updateSelectedSlides'],
              },
            },
          },
        },
      },
      removeAssociatedSlides: {
        description: 'Enter the modal of removing associated slides.',
        tags: ['SLIDES_SETTING_MODAL'],
        entry: ['populateContext'],
        on: {
          UPDATE_REMOVED_SLIDES: {
            actions: 'updateRemovedSlides',
          },
          SAVE_REMOVED_SLIDES: {
            target: '#UpdateVersion.idle',
            actions: [
              'commitRemovedSlidesToDraft',
              'sendVersionDraftSync',
              'clearContext',
            ],
          },
        },
      },
      editRequiredSlides: {
        description: 'Enter a modal which user can select slides that are required.',
        tags: ['SLIDES_SETTING_MODAL'],
        entry: ['populateContext'],
        on: {
          SAVE_REQUIRED_SLIDES: {
            target: '#UpdateVersion.idle',
            actions: [
              'commitRequiredSlidesToDraft',
              'sendVersionDraftSync',
              'clearContext',
            ],
          },
          UPDATE_REQUIRED_SLIDES: {
            actions: ['updateRequiredSlides'],
          },
        },
      },
      editGroupSlides: {
        description: 'Enter a modal which user can create his groups.',
        tags: ['SLIDES_SETTING_MODAL'],
        entry: ['populateContext'],
        on: {
          SYNC_GROUPS: {
            actions: [
              'syncDraftGroupSlides',
              'updateGroupSlides',
            ],
          },
          SAVE_GROUP_SLIDES: {
            target: '#UpdateVersion.idle',
            cond: 'checkIfGroupingCanSave',
            actions: [
              'commitGroupsToDraft',
              'sendVersionDraftSync',
              'clearContext',
            ],
          },
          UPDATE_GROUP_SLIDES: {
            actions: ['updateGroupSlides'],
          },
          ADD_GROUP: {
            actions: ['addGroup'],
          },
          ADD_GROUP_SLIDES: {
            actions: [
              'addGroupSlides',
              'updateGroupSlides',
            ],
            cond: 'canAddSlidesToGroup',
          },
          REMOVE_GROUP_SLIDES: {
            actions: ['removeGroupSlides'],
          },
          RENAME_GROUP: {
            actions: ['renameGroup'],
          },
          REMOVE_GROUP: {
            actions: ['removeGroup'],
          },
          TOGGLE_LOCK_GROUP: {
            actions: ['toggleLockGroup'],
          },
        },
      },
    },
    on: {
      BACK_TO_IDLE: {
        target: 'idle',
        actions: ['clearContext'],
      },
      SIGNAL_VERSION_UPDATE: {
        actions: ['clearContext', 'setVersionDraft'],
      },
    },
  },
  {
    // [Side Effects]
    actions: {
      // ACTOR
      sendVersionDraftSync: sendParent((ctx) => {
        // Need to convert pages back to pageSettings
        const pageSettings = ctx.versionDraft.pages.reduce<PageSetting[]>((acc: PageSetting[], currVal: Page) => {
          if (currVal.isRequired || (currVal.linkedSlides && currVal.linkedSlides.length > 0)) {
            acc.push({
              pageId: currVal.pageId,
              number: currVal.number,
              isRequired: currVal.isRequired,
              linkedSlides: currVal.linkedSlides ?? [],
            })
          }
          return acc;
        }, [])

        const payload: Partial<DocumentVersion> = {
          pageSettings,
          selectedThumbnail: ctx.versionDraft.selectedThumbnail,
          pageGroups: ctx.versionDraft.pageGroups,
        }
        return {
          type: 'SLIDE_SETTINGS_SYNC',
          payload: payload,
        }
      }),
      // ANALYTIC TRACKING
      analyticTrackingEditRequiredSlide: assign((ctx) => {
        analytics?.track('REQUIRED_SLIDE_ADDED', {
          documentVersionId: ctx.documentVersionId,
          type: 'DOCUMENT',
        })
      }),
      analyticTrackingAddAssociatedSlide: assign((ctx) => {
        analytics?.track('REQUIRED_SLIDE_ADDED', {
          documentVersionId: ctx.documentVersionId,
          type: 'SLIDE',
        })
      }),
      // ---- SET/CLEAR SELECTED ----
      clearContext: assign((ctx, _, meta) => {
        if (meta.state?.value === 'editRequiredSlides') {
          ctx.selectedRequiredSlides = {};
          ctx.isRequiredSlidesDirty = false;
        }

        else if (meta.state?.value === 'addAssociatedSlides') {
          ctx.associatedSlides.child = {}
          ctx.associatedSlides.parent = {}
        }
        else if (meta.state?.value === 'setCoverThumbnail') {
          ctx.selectedCoverThumbnail = undefined
        }
        else if (meta.state?.value === 'removeAssociatedSlides') {
          ctx.selectedRemoveAssociatedSlides = {}
        }
        else {
          ctx.selectedRequiredSlides = {};
          ctx.isRequiredSlidesDirty = false;
          ctx.associatedSlides.child = {}
          ctx.associatedSlides.parent = {}
          ctx.selectedCoverThumbnail = undefined
          ctx.selectedRemoveAssociatedSlides = {}
          ctx.stepOneIsDirty = false
          ctx.stepTwoIsDirty = false
          ctx.groupings = {
            selectedPages: {},
            groups: {},
          }
        }
      }),
      clearParent: assign((ctx) => {
        ctx.associatedSlides.parent = {}
      }),
      populateContext: assign((ctx, event) => {
        if (event.type === 'EDIT_REQUIRED_SLIDES') {
          const requiredSlides = ctx.versionDraft.pages.reduce(
            (acc, page) => {
              if (page.isRequired) { acc[page.pageId] = true }
              return acc
            },
            {},
          )
          ctx.selectedRequiredSlides = requiredSlides
        }

        if (event.type === 'SET_COVER_THUMBNAIL') {
          ctx.selectedCoverThumbnail = ctx.versionDraft.selectedThumbnail || 1
        }

        if (event.type === 'REMOVE_ASSOCIATED_SLIDES') {
          const associatedSlides = ctx.versionDraft.pages.reduce<Record<string, string[]>>(
            (acc, page) => {
              acc[page.pageId] = page.linkedSlides ?? []
              return acc
            },
            {},
          )

          ctx.selectedRemoveAssociatedSlides = associatedSlides
        }

        if (event.type === 'EDIT_GROUP_SLIDES' || event.type === 'REMOVE_IMPORTED_GROUPS') {
          if (!ctx.versionDraft?.pageGroups) {
            ctx.versionDraft.pageGroups = []
          }

          ctx.groupings = {
            selectedPages: {},
            groups: ctx
              .versionDraft
              ?.pageGroups
              ?.reduce<Record<string, PageGroup>>(
                (acc, group) => {
                  acc[group.name] = group
                  return acc
                },
                {},
              ),
          }
        }
      }),
      // SLIDE ASSOCIATIONS
      updateSelectedSlides: assign((ctx, event, meta) => {
        const evt = event as Types.EVT_UPDATE_SELECTED_SLIDES
        const isStepOne = meta.state?.matches('addAssociatedSlides.stepOne')
        const isStepTwo = meta.state?.matches('addAssociatedSlides.stepTwo')

        if (isStepOne) {
          // Select all slides
          if (evt.payload.selection === 'all') {
            const numberOfSlidesSelected = Object.values(ctx.associatedSlides.child)
              .filter((value) => value)?.length || 0

            const SelectableNumberOfSlides = ctx.versionDraft.pages?.reduce((acc, page) => {
              const allChildSlide = {}
              ctx.versionDraft.pages?.forEach(page => {
                page.linkedSlides?.forEach(id => {
                  allChildSlide[id] = true
                })
              })
              const disabled = page.linkedSlides?.length
              if (disabled) return acc
              else return acc + 1
            }, 0)

            const isAllAlreadySelected = numberOfSlidesSelected === SelectableNumberOfSlides

            ctx.associatedSlides.child = ctx
              .versionDraft
              .pages
              .reduce<Record<string, boolean>>(
                (acc, page) => {
                  // check if the slide is disabled (has linkedskides)
                  const disabled = page.linkedSlides?.length
                  return { ...acc, [page.pageId]: disabled ? false : !isAllAlreadySelected }
                },
                {},
              )
            return;
          }
          // Toggle individual slides
          ctx.associatedSlides.child[evt.payload.selection] = !ctx.associatedSlides.child[evt.payload.selection]
        } else if (isStepTwo) {
          // Select all slides
          if (evt.payload.selection === 'all') {
            const numberOfSlidesSelected = Object.values(ctx.associatedSlides.parent)
              .filter((value) => value)?.length || 0

            const allChildSlide = {}
            ctx.versionDraft.pages?.forEach(page => {
              page.linkedSlides?.forEach(id => {
                allChildSlide[id] = true
              })
            })
            const SelectableNumberOfSlides = ctx.versionDraft.pages?.reduce((acc, page) => {
              const disabled = !!allChildSlide[page.pageId] || ctx.associatedSlides.child[page.pageId]
              if (disabled) return acc
              else return acc + 1
            }, 0)

            const isAllAlreadySelected = numberOfSlidesSelected === SelectableNumberOfSlides
            ctx.associatedSlides.parent = ctx
              .versionDraft
              .pages
              .reduce<Record<string, boolean>>(
                (acc, page) => {
                  const disabled = !!allChildSlide[page.pageId] || ctx.associatedSlides.child[page.pageId]
                  return {
                    ...acc,
                    [page.pageId]: disabled
                      ? false
                      : !isAllAlreadySelected,
                  }
                },
                {},
              )
            return;
          }
          // Toggle individual slides
          ctx.associatedSlides.parent[evt.payload.selection] = !ctx.associatedSlides.parent[evt.payload.selection]
        }
      }),
      updateRemovedSlides: assign((ctx, event) => {
        const evt = event as Types.EVT_UPDATE_REMOVED_SLIDES
        const updatedAssociatedList = evt.payload.removed === 'all'
          ? []
          : ctx
            .selectedRemoveAssociatedSlides[evt.payload.parent]
            .filter(id => id !== evt.payload.removed)

        ctx.selectedRemoveAssociatedSlides[evt.payload.parent] = updatedAssociatedList
      }),
      updateCoverThumbnail: assign((ctx, event, _meta) => {
        const evt = event as Types.EVT_UPDATE_COVER_THUMBNAIL
        ctx.selectedCoverThumbnail = evt.payload
      }),
      updateRequiredSlides: assign((ctx, event, _meta) => {
        const evt = event as Types.EVT_UPDATE_REQUIRED_SLIDES
        ctx.isRequiredSlidesDirty = true
        // Select all slides
        if (evt.payload === 'all') {
          const isAllAlreadySelected = (
            (ctx.versionDraft.pages.length === Object.values(ctx.selectedRequiredSlides).length) &&
            Object.values(ctx.selectedRequiredSlides).every(slide => slide)
          )

          ctx.selectedRequiredSlides = ctx
            .versionDraft
            .pages
            .reduce<Record<string, boolean>>(
              (acc, page) => ({ ...acc, [page.pageId]: !isAllAlreadySelected }),
              {},
            )
          return;
        }
        // Toggle individual slides
        ctx.selectedRequiredSlides[evt.payload] = !ctx.selectedRequiredSlides[evt.payload]
      }),

      // --- COMMIT TO DRAFT ----
      saveAsscociatedSlidesDraft: assign((ctx) => {
        for (const page of ctx.versionDraft.pages) {
          if (ctx.associatedSlides.parent[page.pageId]) {
            const linkedSlides = Object
              .entries(ctx.associatedSlides.child)
              .filter(([_, selected]) => selected)
              .map(([childPageId]) => childPageId)
              .sort()

            const dedupedLinkedSlidesSum = [
              ...new Set([
                ...page?.linkedSlides ?? [],
                ...linkedSlides,
              ]),
            ]

            page.linkedSlides = dedupedLinkedSlidesSum
          }
        }
      }),
      saveCoverThumbnailToDraft: assign((ctx) => {
        ctx.versionDraft.selectedThumbnail = ctx.selectedCoverThumbnail
      }),
      commitRequiredSlidesToDraft: assign((ctx) => {
        for (const page of ctx.versionDraft.pages) {
          page.isRequired = !!ctx.selectedRequiredSlides[page.pageId]
        }
      }),
      commitRemovedSlidesToDraft: assign((ctx) => {
        for (const page of ctx.versionDraft.pages) {
          page.linkedSlides = ctx.selectedRemoveAssociatedSlides[page.pageId]
        }
      }),
      setVersionDraft: assign((ctx, event, _meta) => {
        const evt = event as Types.EVT_SIGNAL_VERSION_UPDATE
        ctx.versionDraft.pageGroups = evt.payload.model.pageGroups
        ctx.versionDraft.pages = evt.payload.meta.allPages
        ctx.versionDraft.selectedThumbnail = evt.payload.model.selectedThumbnail
      }),
      updateGroupSlides: assign((ctx, event, _meta) => {
        const evt = event as Types.EVT_UPDATE_GROUP_SLIDES
        const { scope, forceActive } = evt.payload

        if (scope === 'all') {
          const isAllAlreadySelected = (
            (ctx.versionDraft.pages.length === Object.values(ctx.groupings.selectedPages).length) &&
            Object.values(ctx.groupings.selectedPages).every(page => page)
          )

          ctx.groupings.selectedPages = ctx
            .versionDraft
            .pages
            .reduce<Record<string, boolean>>(
              (acc, page) => ({ ...acc, [page.pageId]: !isAllAlreadySelected }),
              {},
            )
        }
        // [NOTE] - Edge case support for also clearing when SYNC_GROUPS event is called
        //        - Consider adding an optional payload argument that could be used to clear as well
        //        - or raising this from another action
        else if (scope === 'none' || event.type === 'SYNC_GROUPS' || event.type === 'ADD_GROUP_SLIDES') {
          ctx.groupings.selectedPages = {}
        }
        else {
          ctx.groupings.selectedPages[scope] = forceActive
            ? true
            : !ctx.groupings.selectedPages[scope]
        }
      }),
      addGroup: assign((ctx, event, _meta) => {
        const evt = event as Types.EVT_ADD_GROUP
        const groupId = uuid()
        ctx.groupings.groups[evt.payload.name] = {
          id: groupId,
          name: evt.payload.name,
          pageIds: evt.payload.pageIds ?? [],
          locked: true,
          source: PageGroupSource.USER,
        }

        // ANALYTIC TRACKING: added named group
        analytics?.track('ADD_GROUP', { groupId })
      }),
      addGroupSlides: assign((ctx, event, _meta) => {
        const evt = event as Types.EVT_ADD_GROUP_SLIDES
        const { groupName, pageIds } = evt.payload
        const selectedPages = ctx.groupings.selectedPages

        if (groupName === 'ADD_TO_ALL_GROUPS') {
          Object.entries(ctx.groupings.groups).forEach(([groupName]) => {
            const group = ctx.groupings.groups[groupName]
            addSlidesToGroup(pageIds, selectedPages, group);
          })
        }
        else {
          const group = ctx.groupings.groups[groupName]
          addSlidesToGroup(pageIds, selectedPages, group);
        }
      }),
      removeGroupSlides: assign((ctx, event, _meta) => {
        const evt = event as Types.EVT_REMOVE_GROUP_SLIDES

        if (evt.payload.itemIdx === 'all') {
          const groupId = ctx.groupings.groups[evt.payload.groupName].id
          ctx.groupings.groups[evt.payload.groupName]
            .pageIds
            ?.forEach(slideId => {
              analytics?.track('REMOVE_SLIDE', { groupId, pageId: slideId })
            })
          ctx.groupings.groups[evt.payload.groupName].pageIds = []
        }
        else {
          const group = ctx.groupings.groups[evt.payload.groupName]
          ctx.groupings.groups[evt.payload.groupName] = {
            ...group,
            pageIds: group
              .pageIds
              ?.filter((_, idx) => idx !== evt.payload.itemIdx),
          }

          // ANALYTIC TRACKING: removing slide from a group
          analytics?.track('REMOVE_SLIDE', { groupId: group.id, pageId: evt.payload.pageId })
        }
      }),
      syncDraftGroupSlides: assign((ctx, event) => {
        const evt = event as Types.EVT_SYNC_GROUPS
        // [TODO] - We can probably limit this to only the Active Group
        const { groupItems } = evt.payload

        for (const groupName in groupItems) {
          const group = groupItems[groupName]

          const draftGroup = ctx.groupings.groups[groupName]
          draftGroup.pageIds = group.map(({ itemId }) => itemId)

          // [TODO] - We need to add slide adding analytics tracking here as well
        }
      }),
      renameGroup: assign((ctx, event, _meta) => {
        // TODO: This should change probably an id instead of an string for the key to avoid having collisions
        const evt = event as Types.EVT_RENAME_GROUP
        const { newName, oldName } = evt.payload

        ctx.groupings.groups[newName] = {
          ...ctx.groupings.groups[oldName],
          name: evt.payload.newName,
        }

        delete ctx.groupings.groups[evt.payload.oldName]
      }),
      removeGroup: assign((ctx, event, _meta) => {
        const evt = event as Types.EVT_REMOVE_GROUP
        const groupId = ctx.groupings.groups[evt.payload].id

        // ANALYTIC TRACKING: delete named group
        analytics?.track('DELETE_GROUP', { groupId })

        delete ctx.groupings.groups[evt.payload]
      }),
      removeImportedGroups: assign((ctx, event) => {
        if (!ctx.versionDraft.pageGroups?.length) return
        const evt = event as Types.EVT_REMOVE_IMPORTED_GROUPS

        if (evt.payload?.groupName) {
          ctx.versionDraft.pageGroups =
            ctx.versionDraft
              .pageGroups
              .filter(group => group.name !== evt.payload?.groupName)
        }
        else {
          ctx.versionDraft.pageGroups =
            ctx.versionDraft.pageGroups.filter(group => group.source !== PageGroupSource.DOCUMENT)
        }
      }),
      toggleLockGroup: assign((ctx, event, _meta) => {
        const evt = event as Types.EVT_TOGGLE_LOCK_GROUP
        ctx.groupings.groups[evt.payload].locked = !ctx.groupings.groups[evt.payload].locked
      }),
      commitGroupsToDraft: assign((ctx) => {
        ctx.versionDraft.pageGroups = Object.values(ctx.groupings.groups)
      }),
    },
    // [Conditional Checks]
    guards: {
      // [TODO] - Need to check if against original selection?
      //        - Selecting and unselecting one will trigger "dirty"
      checkIfStepOneIsDirty: (ctx) => {
        // check if stepOne is dirty and NOT everything is selected
        const numberOfSlidesSelected = Object.values(ctx.associatedSlides.child)
          .filter((value) => value)?.length || 0
        const totalNumOfSlides = ctx.versionDraft.pages.length
        return Object.values(ctx.associatedSlides.child).some(child => child) &&
          numberOfSlidesSelected !== totalNumOfSlides
      },
      checkIfAssociatedIsDirty: (ctx) => {
        return (
          Object.values(ctx.associatedSlides.child).some(child => child) &&
          Object.values(ctx.associatedSlides.parent).some(parent => parent)
        )
      },
      checkIfGroupingCanSave: (ctx) => {
        /** Step 1: Check for valid groups */
        const areValidGroups = !Object
          .values(ctx.groupings.groups)
          .some(group => group.pageIds && group.pageIds.length < 2)

        // If we have invalid groups might as well skip the heavier sort and compare below to save cycles
        if (!areValidGroups) return areValidGroups

        /** Step 2: Check for modifications */
        const versionDraft = ctx
          .versionDraft
          .pageGroups
          ?.map((group) => ({ id: group.id, locked: group.locked, pageIds: group.pageIds, name: group.name }))
          ?.sort((a, b) => (a.name.localeCompare(b.name, 'en', { numeric: true })))

        const editDraft = Object
          .values(ctx.groupings.groups)
          .sort((a, b) => (a.name.localeCompare(b.name, 'en', { numeric: true })))

        const isModified = !deepEqual(versionDraft, editDraft)

        return isModified
      },
      canAddSlidesToGroup: (ctx, event) => {
        const evt = event as Types.EVT_ADD_GROUP_SLIDES
        const hasSelectedPages = evt.payload.pageIds?.length ?? Object
          .values(ctx.groupings.selectedPages)
          .some(v => v)

        return !!hasSelectedPages
      },
    },
  },
)

const addSlidesToGroup = (pageIds: string[] | undefined, selectedPages, group) => {
  const pagesToAdd = pageIds ?? Object
    .entries(selectedPages)
    .filter(([_, v]) => v)
    .map(([k]) => k)
    .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
  group.pageIds = [...group.pageIds ?? [], ...pagesToAdd];

  // ANALYTIC TRACKING: adding slide to a group
  pagesToAdd.forEach(slideId => {
    analytics?.track('ADD_SLIDE', { groupId: group.id, pageId: slideId });
  });
}

export default slideSettings
