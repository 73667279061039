import React from 'react';

import { DNABox, DNACheckbox, DNAText, Icon, Iffy } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { styles } from 'src/components/Publishers/Styles';
import { useVersioningPanel } from './VersioningPanelContext';
import { NotificationScope } from '@alucio/aws-beacon-amplify/src/models';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';

const DocumentReleaseNotes = () => {
  const { currentDocumentVersionORM } = useVersioningPanel()
  const featureFlags = useFeatureFlags('enableEmailNotification', 'enableSendEmailImmediatly');

  return (
    <DNABox
      fill
      appearance="col"
      spacing="lg"
      style={styles.tabContentWrapper}
    >
      <Iffy is={featureFlags.enableEmailNotification}>
        <DNABox appearance="col" spacing="sm" fill>
          <DNABox
            testID="notify-users-email-checkbox"
            spacing="xs"
          >
            <DNACheckbox
              testID={true ? 'checkbox-checked' : 'checkbox-unchecked'}
              checked={currentDocumentVersionORM.model.notificationScope === NotificationScope.EMAIL}
              onChange={() => { }}
              disabled
            />

            <DNAText
              numberOfLines={3}
              ellipsizeMode="tail"
              bold
            >
              Notify users who have saved this file of new version
            </DNAText>
            <DNAPopover >
              <DNAPopover.Anchor>
                <Icon style={styles.HelpToolTipIconStyle} name="help-circle-outline" />
              </DNAPopover.Anchor>
              <DNAPopover.Content>
                <DNAText style={{ color: colors['color-text-basic'] }}>
                  Users who have saved this file in their folders will
                  receive an email notification at the end of the day.
                </DNAText>
              </DNAPopover.Content>
            </DNAPopover>

          </DNABox>
          {featureFlags.enableSendEmailImmediatly && <DNABox
            testID="notify-users-email-immediately-checkbox"
            childFill={1}
            spacing="xs"
          >
            <DNACheckbox
              testID={true ? 'checkbox-immediately-checked' : 'checkbox-immediately-unchecked'}
              checked={currentDocumentVersionORM.model.notificationScope === NotificationScope.EMAIL_IMMEDIATE}
              onChange={() => { }}
              disabled
            />
            <DNABox>
              <DNAText
                numberOfLines={3}
                ellipsizeMode="tail"
                bold
              >
                Notify all users of new version immediately
              </DNAText>
              <DNAPopover >
                <DNAPopover.Anchor>
                  <Icon style={styles.HelpToolTipIconStyle} name="help-circle-outline" />
                </DNAPopover.Anchor>
                <DNAPopover.Content>
                  <DNAText style={{ color: colors['color-text-basic'] }}>
                    All users will immediately receive an email notification.
                  </DNAText>
                </DNAPopover.Content>
              </DNAPopover>
            </DNABox>
          </DNABox>}
        </DNABox>
      </Iffy>
      <DNABox style={{ maxWidth: 560 }} appearance="col" spacing="sm">
        <DNAText bold>Revision Level</DNAText>
        <DNAText>
          {currentDocumentVersionORM.model.changeType === 'MAJOR' ? 'Major Change' : 'Minor Change'}
        </DNAText>
      </DNABox>
      <DNABox style={{ maxWidth: 560 }} appearance="col" spacing="sm">
        <DNAText bold>Release Notes</DNAText>
        <DNAText>
          {currentDocumentVersionORM.model.releaseNotes}
        </DNAText>
      </DNABox>
    </DNABox>
  )
}

export default DocumentReleaseNotes
