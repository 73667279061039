import React, { useEffect, useState } from 'react';
import {
  getAssociatedFilesORM,
  selAttachedFilesMap,
  selDocumentsMap,
  selDocumentVersionsMap,
} from 'src/state/redux/selector/document'
import AssociatedFilesManager from 'src/components/DNA/AssociatedFiles/AssociatedFilesManager';
import { AssociatedFileORM, DocumentORM, DocumentVersionORM, ORMTypes } from 'src/types/types';
import { AssociatedFile, AttachedFile } from '@alucio/aws-beacon-amplify/src/models';
import { useVersioningPanel } from '../VersioningPanelContext';
import { useSelector } from 'react-redux'

import { UploadStatus } from 'src/components/DNA/FileUpload/FileUpload';
import { loggedUser } from 'src/state/redux/selector/user';

const AssociatedFiles: React.FC = () => {
  const {
    currentDocumentVersionORM,
    cond,
    send,
    state,
  } = useVersioningPanel()

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const attachedFilesMap = useSelector(selAttachedFilesMap)
  const documentsMap = useSelector(selDocumentsMap)
  const documentVersionsMap = useSelector(selDocumentVersionsMap)
  const user = useSelector(loggedUser);

  // Collapse the search bar on tab switching
  useEffect(
    () => setIsSearching(false),
    [state.context.selectedTabIndex],
  )

  const associatedFileORMs: AssociatedFileORM[] = (cond.isPublishedViewMode
    ? currentDocumentVersionORM?.relations.associatedFiles
    : getAssociatedFilesORM(
      state.context.versionForm.associatedFiles ?? [],
      attachedFilesMap,
      documentsMap,
      documentVersionsMap,
      user.relations.tenant,
    )
  ) ?? []

  const dummyDocumentVersionORM: DocumentVersionORM = {
    ...currentDocumentVersionORM,
    // @ts-expect-error - Because versionForm is partial, we'll get some possible undefined values, but should be fine
    model: {
      ...currentDocumentVersionORM,
      ...state.context.versionForm,
    },
    relations: {
      ...currentDocumentVersionORM.relations,
      associatedFiles: associatedFileORMs,
    },
  }
  function onSelect(_, linkedDocument: DocumentORM): void {
    analytics?.track('SEARCH_AF', {
      action: 'INPUT',
      category: 'SEARCH',
    });
    send({
      type: 'ASSOCIATED_DOCUMENT_LINK',
      payload: { documentId: linkedDocument.model.id },
    })
  }

  function onFinishedUpload(attachedFile: AttachedFile): void {
    send({
      type: 'ATTACHED_FILE_UPLOADED',
      payload: { attachedFile: attachedFile },
    })
  }

  function onDelete(_, item: DocumentORM | AttachedFile): void {
    send({
      type: 'ASSOCIATED_FILE_DELETE',
      payload: {
        attachmentId: item.type === ORMTypes.DOCUMENT
          ? item.model.id
          : item.id,
      },
    })
  }

  function onUpdateAssociatedFile(associatedFile: AssociatedFile): void {
    send({
      type: 'ASSOCIATED_FILE_UPDATED',
      payload: { associatedFile },
    })
  }

  const handleUploadStatusChange = (status: UploadStatus) => {
    send({
      type: 'ATTACHED_FILE_UPLOAD_STATUS_CHANGE',
      payload: { status },
    })
  }

  return (
    <AssociatedFilesManager<DocumentVersionORM>
      entityORM={dummyDocumentVersionORM}
      canLinkFiles={cond.isInDraftingState}
      isDisabled={cond.isPublishedViewMode}
      isCollapsed={false}
      searching={isSearching}
      setSearching={setIsSearching}
      emptyMessage="No associated files"
      associatedFilesMode={!cond.isInDraftingState ? 'READ_DOWNLOADABLE' : 'EDITABLE'}
      files={associatedFileORMs}
      onUploadStatusChange={handleUploadStatusChange}
      onFinishedUpload={onFinishedUpload}
      onSelect={onSelect}
      onDelete={onDelete}
      onUpdateAssociatedFile={onUpdateAssociatedFile}
    />
  );
};

export default AssociatedFiles;
