import React from 'react'

import { DocumentORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import { CustomLabelDisplay } from '../default/CustomLabelDisplay'
import { DNABox, DNACard, DNAText, Iffy, useTheme, util, luxColors } from '@alucio/lux-ui'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList'
import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models'
import { useDocumentSearchV2Context } from 'src/hooks/useDocumentSearchV2'
import { renderTextWithHighlight } from 'src/utils/searchHelpers'
import { DNATextProps } from '@alucio/lux-ui/src/components/type/DNAText/DNAText'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags'
import { RenderExcerpt } from 'src/components/DNA/GridList/DNADocumentGridList'

const DNAFileDrawerDefaultRow: DNARowsComponent<
  DocumentORM
> = (props) => {
  const {
    ORM: documentORM,
    onPress,
    style,
    isSearch,
  } = props

  const theme = useTheme()
  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const isDocument = documentORM.type === 'DOCUMENT'
  const isDocumentWithUnpublishedVersion = isDocument &&
    documentORM.meta.hasUnpublishedVersion &&
    documentORM.model.status === DocumentStatus.PUBLISHED

  const { documentIdSnippetMap } = useDocumentSearchV2Context();

  const snippet = documentIdSnippetMap.get(documentORM.model.id);

  const showSnippet = snippet?.documentExcerpt && isSearch;
  const featureFlags = useFeatureFlags('enableKendra', 'enableOpenSearch', 'enableHybridSearch');
  const renderHtmlExcerpt = featureFlags.enableHybridSearch || featureFlags.enableOpenSearch

  const titleProps: DNATextProps = {
    b1: true,
    testID: 'folder-item-row-name',
    numberOfLines: 1,
  }

  const snippetProps: DNATextProps = {
    status: 'flatAlt',
    numberOfLines: 3,
    testID: 'drop-down-results-snippet',
  }

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={() => { onPress?.(documentORM) }}
      style={[
        {
          paddingHorizontal: theme['row-padding-horizontal'],
          paddingVertical: theme['row-padding-vertical'],
        },
        style,
      ]}
    >
      <DNABox alignY="center" spacing="between" childFill={0}>
        <DNABox alignY="center" spacing="lg" childFill={1}>
          <DNABox appearance="row">
            <DNADocumentThumbnail
              documentVersionORM={
                documentORM.relations.version.latestPublishedDocumentVersionORM ||
                documentORM.relations.version.latestDocumentVersionORM
              }
              width={isSearch ? 160 : 76}
              height={isSearch ? 87 : 44}
              showProcessing
            />
          </DNABox>
          {/* Title */}
          <DNABox
            spacing="sm"
            appearance="col"
            childFill
            style={{ paddingRight: 16 }}
          >
            {snippet?.title
              ? renderHtmlExcerpt
                ? <RenderExcerpt
                    textProps={titleProps}
                    text={snippet.title.text}
                />
                : renderTextWithHighlight(snippet?.title, titleProps)
              : <DNAText {...titleProps} >
                {documentORM.relations.version.latestUsableDocumentVersionORM.model.title}</DNAText>}
            {showSnippet &&
            (
              renderHtmlExcerpt
                ? <RenderExcerpt
                    pageNumber={snippet.pageNumber}
                    textProps={snippetProps}
                    text={snippet.documentExcerpt.text}
                />
                : renderTextWithHighlight(snippet.documentExcerpt, snippetProps, snippet.pageNumber)
            )}
            <DNABox alignY="center" spacing="sm" fill childFill={2}>
              <CustomFieldBadgeList
                documentVersionORM={documentORM.relations.version.latestUsableDocumentVersionORM}
              />
              <Iffy is={isPublisherRoute}>
                <DNABox
                  style={util.mergeStyles(undefined,
                    [{ borderWidth: 2, borderColor: luxColors.warning.quaternary }, isDocumentWithUnpublishedVersion])}
                >
                  <DNADocumentChip item={documentORM} variant="status"/>
                </DNABox>
              </Iffy>
              <DNABox fill>
                <CustomLabelDisplay documentORM={documentORM} />
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>
        {/* Actions */}
        <DNABox spacing="sm" alignY="center">
          <DNAText b1 status="primary">
            Add
          </DNAText>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNAFileDrawerDefaultRow
