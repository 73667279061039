import { State as StateDef, ResolveTypegenMeta, BaseActionObject } from 'xstate';
import { capitalize } from 'lodash';

import type * as FindReplacement from './findReplacement.types';
import { Typegen0 } from './findReplacement.machine.typegen';
import { GroupDraftStatus, ReplaceGroupDraft, UNAVAILABLE_DOCUMENT_STATUS }
  from 'src/components/PresentationBuilder/PresentationEditor/FindReplacement/FindReplacementProvider';
import { Page } from '@alucio/aws-beacon-amplify/src/models';

import useSelector from 'src/hooks/useSelector';
import { getPresentable } from 'src/state/context/ContentProvider/helper';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import useContentPageData, { getSlideContentPageDataTitle } from 'src/hooks/useContentPageData/useContentPageData';

// [NOTE] - Normally we can use some util helpers, other TS access to get this
//          but it's possible to run into a circular dependency error when using selector as machine guards
//        - This probably creates a 2nd instance of the State type but it's needed for now
type State = StateDef<
  FindReplacement.SMContext,
  FindReplacement.SMEvents,
  any,
  { value: any, context: FindReplacement.SMContext },
  ResolveTypegenMeta<
    Typegen0,
    FindReplacement.SMEvents,
    BaseActionObject,
    FindReplacement.SMServices
  >
>

type ReviewGroupSlideThumbnail = {
  group: ReplaceGroupDraft,
  idx: number,
  isGroup: boolean,
  isUnavailableDocument: boolean,
  unavailableLabel?: string,
  targetPage: Page,
  pageNumInCustomDeck: number,
  s3URL: string,
  doneReview: boolean,
  slideNum?: number,
  isActive: boolean,
}

// CHECK STATE
export const isIdleState = (state: State) => ({ isIdleState: state.matches('idle') })
export const isApplyReplacementSlidesState = (state: State) =>
  ({ isApplyReplacementSlidesState: state.matches('applyReplacementSlides') })

// CONTEXT
export const activeGroupId = (state: State) => ({ activeGroupId: state.context.activeGroupId })
export const replaceGroups = (state: State) => ({ replaceGroups: state.context.replaceGroups })
export const needReviewGroups = (state: State) => {
  const replaceGroupsValue = replaceGroups(state).replaceGroups
  const needReviewGroups = replaceGroupsValue.filter(group => {
    return group.status !== GroupDraftStatus.ACTIVE && group.status !== GroupDraftStatus.GROUP_REMOVED;
  });
  return { needReviewGroups }
}
export const needReviewGroupIndexes = (state: State) => {
  const needReviewGroupsValue = needReviewGroups(state).needReviewGroups
  const needReviewGroupIndexes: Record<string, { group: ReplaceGroupDraft, idx: number }> = {}
  needReviewGroupsValue.forEach((group, index) => {
    needReviewGroupIndexes[group.groupId] = { group, idx: index }
  })
  return { needReviewGroupIndexes }
}

// [NOTE] - This is a temporary solution, but we should find a more integrated way to work this into a machine `useSelector`
export const needReviewGroupSlideThumbnails = (state: State) => {
  const replaceGroupsValue = replaceGroups(state).replaceGroups
  const needReviewGroupsValue = needReviewGroups(state).needReviewGroups
  const activeGroupValue = activeGroup(state).activeGroup

  const needReviewGroupSlideThumbnail: Record<string, ReviewGroupSlideThumbnail> = {};

  needReviewGroupsValue.forEach((group, index) => {
    const { status, documentVersionORM, pages, visible, groupReplacement } = group;

    const isGroup = groupReplacement
      ? groupReplacement.pages.length > 1
      : group.pages.length > 1;

    const targetPage = groupReplacement
      ? groupReplacement.pages[0]
      : pages[0];

    const isUnavailableDocument = UNAVAILABLE_DOCUMENT_STATUS.includes(status);
    const unavailableLabel = isUnavailableDocument
      ? capitalize(group.status)
      : undefined

    const currentGroupIndex = replaceGroupsValue.findIndex(g => g.groupId === group.groupId);

    const pageNumInCustomDeck = replaceGroupsValue.reduce((acc, curr, currentIndex) => {
      if (currentIndex > currentGroupIndex) return acc
      return currentIndex < currentGroupIndex ? acc + curr.pages.length : acc
    }, 0) + 1;

    const s3URL = groupReplacement
      ? detectArchivedFileKeyPath(groupReplacement.documentVersionORM?.model, targetPage)
      : detectArchivedFileKeyPath(documentVersionORM?.model, targetPage)

    const doneReview = status === GroupDraftStatus.REPLACED;
    const slideNum = visible ? pageNumInCustomDeck : undefined;
    const isActive = activeGroupValue?.groupId === group.groupId

    needReviewGroupSlideThumbnail[group.groupId] = {
      group,
      idx: index,
      isGroup,
      isUnavailableDocument,
      targetPage,
      pageNumInCustomDeck,
      s3URL,
      doneReview,
      slideNum,
      isActive,
      unavailableLabel,
    }
  })

  return { needReviewGroupSlideThumbnail }
}

// [NOTE] - This is a temp solution to composing other hooks into `useSelector`
//        - We should find a first class way to improve the `useSelector` to also
//          properly compose and subscribe other hooks, values
//        - For now, this is just a wrapped way while still trying to maintain an atomic style (like our other selectors)
export const useNeedReviewGroupSlideThumbnail = (
  service: FindReplacement.FindReplacementService,
  groupId: string
) => {
  const cond = useSelector(
    service,
    (state) => needReviewGroupSlideThumbnails(state)
  )

  const currentGroup = cond.needReviewGroupSlideThumbnail[groupId]

  const presentable = currentGroup?.group.groupReplacement
    ? getPresentable(currentGroup.group.groupReplacement.documentVersionORM)
    : getPresentable(currentGroup?.group.documentVersionORM)

  const { contentPageData } = useContentPageData(presentable)
  const pageTitle = (currentGroup && contentPageData.length)
    ? getSlideContentPageDataTitle(currentGroup.targetPage.number - 1, contentPageData)
    : ''

  return currentGroup
    ? {
      ...currentGroup,
      pageTitle,
    }
    : undefined
}

export const isGroupDirty = (state: State) => ({ isGroupDirty: state.context.isGroupDirty })

export const indexOfActiveGroup = (state: State) => {
  const needReviewGroupsValue = needReviewGroups(state).needReviewGroups
  const groupId = activeGroupId(state).activeGroupId
  const indexOfActiveGroup = needReviewGroupsValue.findIndex(group => group.groupId === groupId)
  return { indexOfActiveGroup }
}
export const activeGroup = (state: State) => {
  const replaceGroupsValue = replaceGroups(state).replaceGroups
  const groupId = activeGroupId(state).activeGroupId
  const activeGroup = replaceGroupsValue.find(group => group.groupId === groupId)
  return { activeGroup }
}
export const disableRemoveButton = (state: State) => {
  const replaceGroupsValue = replaceGroups(state).replaceGroups
  const nonRemovedGroups = replaceGroupsValue.filter(group => {
    return group.status !== GroupDraftStatus.GROUP_REMOVED;
  });
  return { disableRemoveButton: nonRemovedGroups.length <= 1 }
}

// MACHINE
export const canNavPrevious = (state: State) => ({ canNavPrevious: state.can('NAV_PREVIOUS_SLIDE') })
export const canNavNext = (state: State) => ({ canNavNext: state.can('NAV_NEXT_SLIDE') })
