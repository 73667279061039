import {
  ControlType,
  CustomFieldDefinition,
  CustomFieldValueDefinition,
  CustomFieldUsage,
  FieldDataType,
  FieldStatus,
  FieldPosition,
} from '@alucio/aws-beacon-amplify/src/models';
import { FormSettings, LayoutItem, LookupRecordItem, SalesforceFormSettings } from '../CRMIndexedDBTypes';
import { TABLES } from './VeevaTranslatorUtils';
import { orderArray } from 'src/utils/arrayHelpers';

export enum SUPPORTED_ZVODS {
  MEDICAL_DISCUSSION = 'zvod_Medical_Discussion_vod__c',
  FOLLOW_UP = 'zvod_Followup_vod__c',
  BUSINESS_ACCOUNT = 'zvod_Business_Account_vod__c',
  CALL_DETAIL = 'zvod_Detailing_vod__c',
  DELIVERY_METHOD = 'zvod_Delivery_Method_vod__c',
  // * TARSUS' SPECIFIC CHECKBOX FIELD (NOT AN ACTUAL VEEVA ZVOD) * //
  SEP = 'zvod_TSF_SEP__c',
}

export enum INQUIRY_SUPPORTED_METHODS {
  MAIL = 'Delivery_Method_Address_Id',
  PHONE = 'Phone_Number_vod__c',
  EMAIL = 'Email_vod__c',
  FAX = 'Fax_Number_vod__c',
}

// USED TO HANDLE SELF-MANAGED BEHAVIOR
export enum VEEVA_FIELDS_PLACEHOLDER {
  CALL_DETAIL = 'zvod_Detailing_vod__c__p'
}

interface ZvodHandler {
  [key: string]: (formSettings: SalesforceFormSettings[],
                    processChild: (formSetting: SalesforceFormSettings) => CustomFieldDefinition[],
                    item: LayoutItem, processItem: () => CustomFieldDefinition[]) => CustomFieldDefinition[]
}

const zvodHandlers: ZvodHandler = {
  [SUPPORTED_ZVODS.MEDICAL_DISCUSSION]: handleMedicalDiscussionZvod,
  [SUPPORTED_ZVODS.FOLLOW_UP]: handleFollowUpZvod,
  [SUPPORTED_ZVODS.BUSINESS_ACCOUNT]: handleBusinessAccount,
  [SUPPORTED_ZVODS.CALL_DETAIL]: handleCallDetail,
  [SUPPORTED_ZVODS.DELIVERY_METHOD]: handleInquiryDeliveryMethodZvod,
  [SUPPORTED_ZVODS.SEP]: handleSEPZvod,
};

export interface Marker {
  key: string;
  control: string;
}

export function handleZvod(
  item: LayoutItem,
  formSettings: SalesforceFormSettings[],
  processChild: (formSetting: SalesforceFormSettings) => CustomFieldDefinition[],
  processItem: () => CustomFieldDefinition[],
): CustomFieldDefinition[] {
  const zvodName = item.layoutComponents[0]?.apiName as SUPPORTED_ZVODS;
  if (!Object.values(SUPPORTED_ZVODS).includes(zvodName)) {
    return [];
  }

  return zvodHandlers[zvodName](formSettings, processChild, item, processItem);
}

function handleMedicalDiscussionZvod(
  formSettings: SalesforceFormSettings[],
  processChild: (formSetting: SalesforceFormSettings) => CustomFieldDefinition[],
): CustomFieldDefinition[] {
  // WILL HANDLE MULTIPLE RECORD TYPES
  const settings = formSettings.filter((setting) => setting.apiName === TABLES.MEDICAL_DISCUSSION);

  if (!settings.length) {
    return [];
  }

  return settings.map((setting) => processChild(setting)).flat();
}

// THIS ZVOD DOESN'T REQUIRE ADDITIONAL HANDLING //
function handleSEPZvod(_, __, ___, processItem: () => CustomFieldDefinition[]): CustomFieldDefinition [] {
  return processItem();
}

function handleBusinessAccount(_, __, item: LayoutItem): CustomFieldDefinition[] {
  return [{
    id: SUPPORTED_ZVODS.BUSINESS_ACCOUNT,
    fieldName: item.label || 'Location',
    fieldLabel: '',
    status: FieldStatus.ENABLED,
    fieldType: FieldDataType.CATEGORICAL,
    required: item.required,
    dependentFieldId: 'primary_attendee_crm_account_id',
    displayOnParentSelection: true,
    controlType: ControlType.SELECT,
    usage: [CustomFieldUsage.MEETING],
    fieldValueDefinitions: [],
    createdAt: new Date().toISOString(),
  }];
}

function handleFollowUpZvod(
  formSettings: SalesforceFormSettings[],
  processChild: (formSetting: SalesforceFormSettings, marker?: Marker[]) => CustomFieldDefinition[],
): CustomFieldDefinition[] {
  const formSetting = formSettings.find((setting) => setting.apiName === TABLES.TASK);

  if (!formSetting) {
    return [];
  }

  return processChild(formSetting, [{ key: 'WhoId', control: 'CRM_ATTENDEE_LIST' }]);
}

function handleCallDetail(formSettings: SalesforceFormSettings[]): CustomFieldDefinition[] {
  const mainSetting = formSettings.find(({ isMainTable }) => isMainTable);
  const callDetailSetting = formSettings.find(({ apiName }) => apiName === TABLES.CALL_DETAIL);

  if (!mainSetting || !callDetailSetting) {
    return [];
  }

  let sectionName: string = '';

  // GETS THE NAME OF THE SECTION
  mainSetting.layout.sections.forEach((section) => {
    const isDetailSection = section.layoutRows.some((row) =>
      row.layoutItems.some((item) =>
        item.layoutComponents.some((item) =>
          item.apiName === SUPPORTED_ZVODS.CALL_DETAIL,
        ),
      ),
    )

    if (isDetailSection) {
      sectionName = removeVeevaDecorator(section.heading);
    }
  });

  // GETS THE PRODUCTS TO ADD AS CUSTOM VALUE DEFINITION
  const products: CustomFieldValueDefinition[] = getCallDetailsProductValues(callDetailSetting);

  return [{
    id: sectionName,
    fieldLabel: sectionName,
    createdAt: new Date().toISOString(),
    fieldName: sectionName,
    fieldValueDefinitions: [],
    status: FieldStatus.ENABLED,
    usage: [CustomFieldUsage.MEETING],
    controlType: ControlType.LABEL,
    fieldType: FieldDataType.STRING,
  }, {
    id: VEEVA_FIELDS_PLACEHOLDER.CALL_DETAIL,
    fieldName: VEEVA_FIELDS_PLACEHOLDER.CALL_DETAIL,
    fieldLabel: '',
    status: FieldStatus.ENABLED,
    fieldType: FieldDataType.MULTICATEGORICAL,
    controlType: ControlType.SELECT,
    usage: [CustomFieldUsage.MEETING],
    fieldValueDefinitions: orderArray(products, ['label']),
    createdAt: new Date().toISOString(),
  }];
}

function handleInquiryDeliveryMethodZvod(formSettings: SalesforceFormSettings[], _, __): CustomFieldDefinition[] {
  const mainSetting = formSettings.find(({ isMainTable }) => isMainTable);
  const dependencyFieldId = mainSetting?.apiName === 'Call2_vod__c'
    ? 'Medical_Inquiry_vod__c_Delivery_Method_vod__c'
    : 'Delivery_Method_vod__c';
  return [
    {
      id: INQUIRY_SUPPORTED_METHODS.MAIL,
      fieldName: INQUIRY_SUPPORTED_METHODS.MAIL,
      fieldLabel: 'Mail',
      status: FieldStatus.ENABLED,
      fieldType: FieldDataType.CATEGORICAL,
      settings: {
        fieldPosition: FieldPosition.LEFT,
      },
      required: true,
      displayOnParentSelection: true,
      displayOnValueSelection: ['Mail_vod', 'Urgent_Mail_vod'],
      dependentFieldId: dependencyFieldId,
      controlType: ControlType.SELECT,
      usage: [CustomFieldUsage.MEETING],
      fieldValueDefinitions: [],
      createdAt: new Date().toISOString(),
    },
    {
      id: INQUIRY_SUPPORTED_METHODS.EMAIL,
      fieldName: INQUIRY_SUPPORTED_METHODS.EMAIL,
      fieldLabel: 'Email',
      status: FieldStatus.ENABLED,
      fieldType: FieldDataType.CATEGORICAL,
      settings: {
        fieldPosition: FieldPosition.LEFT,
      },
      required: true,
      displayOnParentSelection: true,
      displayOnValueSelection: ['Email_vod'],
      dependentFieldId: dependencyFieldId,
      controlType: ControlType.SELECT,
      usage: [CustomFieldUsage.MEETING],
      fieldValueDefinitions: [],
      createdAt: new Date().toISOString(),
    },
    {
      id: INQUIRY_SUPPORTED_METHODS.PHONE,
      fieldName: INQUIRY_SUPPORTED_METHODS.PHONE,
      fieldLabel: 'Phone',
      status: FieldStatus.ENABLED,
      fieldType: FieldDataType.CATEGORICAL,
      settings: {
        fieldPosition: FieldPosition.LEFT,
      },
      required: true,
      displayOnParentSelection: true,
      displayOnValueSelection: ['Phone_vod'],
      dependentFieldId: dependencyFieldId,
      controlType: ControlType.SELECT,
      usage: [CustomFieldUsage.MEETING],
      fieldValueDefinitions: [],
      createdAt: new Date().toISOString(),
    },
    {
      id: INQUIRY_SUPPORTED_METHODS.FAX,
      fieldName: INQUIRY_SUPPORTED_METHODS.FAX,
      fieldLabel: 'Fax',
      status: FieldStatus.ENABLED,
      fieldType: FieldDataType.CATEGORICAL,
      settings: {
        fieldPosition: FieldPosition.LEFT,
      },
      required: true,
      displayOnParentSelection: true,
      displayOnValueSelection: ['Fax_vod'],
      dependentFieldId: dependencyFieldId,
      controlType: ControlType.SELECT,
      usage: [CustomFieldUsage.MEETING],
      fieldValueDefinitions: [],
      createdAt: new Date().toISOString(),
    },
  ];
}

// THIS ARRAY OF PRODUCTS IN THE FORM OF CUSTOM FIELD DEFINITION
// IS USED IN BOTH CALL_DETAILS AND CALL_DISCUSSIONS
export function getCallDetailsProductValues(callDetailSetting: FormSettings): CustomFieldValueDefinition[] {
  return callDetailSetting.lookups.Product_vod__c?.records.reduce<CustomFieldValueDefinition[]>((acc, record) => {
    if (record.fields.Product_Type_vod__c === 'Detail') {
      const productItemTemplate = {
        id: record.fields.Id,
        value: record.fields.Id,
        label: record.fields.Name,
        createdAt: new Date().toISOString(),
        dependentValueIds: [],
      };

      const productGroups = getProductGroups(
        callDetailSetting.lookups.Product_vod__c.records,
        callDetailSetting.lookups.Product_Group_vod__c?.records || [], record.fields.Id);

      // PER GROUP, IT'LL CREATE AN ITEM WITH ITS GROUP'S NAME APPENDED
      productGroups.forEach((productGroup) => {
        acc.push({
          ...productItemTemplate,
          // APPENDS, TO THE PRODUCT'S ID, THE ID OF ITS GROUP
          value: `${record.fields.Id}.${productGroup.fields.Id}`,
          id: `${record.fields.Id}.${productGroup.fields.Id}`,
          label: `${record.fields.Name} | ${productGroup.fields.Name}`,
        });
      });

      if (!productGroups.length) {
        acc.push(productItemTemplate);
      }
    }
    return acc;
  }, []) || [];
}

function removeVeevaDecorator(sectionHeading: string): string {
  const individualWords = sectionHeading.split(' ');
  return individualWords.reduce<string>((acc, word) => {
    if (word.includes('--')) {
      return acc;
    }

    return `${acc} ${word}`;
  }, '');
}

export function getProductGroups(
  products: LookupRecordItem[],
  productGroupRelationship: LookupRecordItem[],
  productId): LookupRecordItem[] {
  const groupIds = productGroupRelationship.filter((record) => productId === record.fields.Product_vod__c)
    .map((record) => record.fields.Product_Catalog_vod__c);
  return products.filter((record) => groupIds.includes(record.fields.Id));
}
