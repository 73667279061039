import { useState } from 'react'

export enum ThumbnailSizes {
  xxl = 'xxl',
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
  xxs = 'xxs'
}

export type ThumbnailSize = keyof typeof ThumbnailSizes

export interface ThumbnailDimensions {
  width: number
  height: number
}

type ThumbnailSizeDimensions = Record<ThumbnailSize, ThumbnailDimensions>

export const thumbnailSizeDimensions: ThumbnailSizeDimensions = {
  xxs: {
    height: 60,
    width: 96,
  },
  xs: {
    height: 66,
    width: 120,
  },
  sm: {
    height: 89,
    width: 160,
  },
  md: {
    height: 135,
    width: 240,
  },
  lg: {
    height: 190,
    width: 336,
  },
  xl: {
    height: 283,
    width: 500,
  },
  xxl: {
    height: 396,
    width: 700,
  },
}

/**
 * This hook can be used to cycle between various predefined thumbnail modes.
 * Additionally, using this in a context allows you to synchronize thumbnail
 * mode changes across several components with a single call.
 *
 * @param sizes - defaults to one instance of each mode. Supports custom sequences such as `['sm', 'xl', 'md'...]`
 * @param defaultSize - defaults to large
 * @returns
 */
const useThumbnailSize = (
  /** NOTE: Unfortunately there's no practical way of restricting this array to unique values.
   * However, a happy side effect of this is that we can define a custom size cycle for the cycle
   * thumbs function such as sm > xl > md > xl > lg > xl. Not sure when that
   * would be useful but it sure is fun!
   *
   * We don't include xxs by default since it's only used for Associated Slides (atm)
   */
  sizes: ThumbnailSize[] = Object.values(ThumbnailSizes).slice(1),
  defaultSize: ThumbnailSize = sizes[0] ?? 'lg',
) => {
  const [thumbnailSize, setThumbnailSize] = useState<ThumbnailSize>(defaultSize)

  const thumbnailDimensions = thumbnailSizeDimensions[thumbnailSize]

  const cycleThumbnailSize = () => {
    const nextMode = sizes[sizes.indexOf(thumbnailSize) - 1]

    /** Cycle through the modes until the end then go back to the beginning */
    setThumbnailSize(nextMode ?? sizes[sizes.length - 1])
  }

  return {
    thumbnailSize,
    setThumbnailSize,
    thumbnailDimensions,
    cycleThumbnailSize,
  }
}

export default useThumbnailSize
