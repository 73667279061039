import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import im from 'immer'
import isPast from 'date-fns/isPast';
import addDays from 'date-fns/addDays';
import { createSelector, Selector } from '@reduxjs/toolkit'
import {
  AssociatedFile,
  AssociatedFileStatus,
  AssociatedFileType,
  AttachedFile,
  CustomFieldUsage,
  CustomFieldValueDefinition,
  Document,
  DocumentAccessLevel,
  DocumentStatus,
  DocumentVersion,
  DocumentVersionChangeType,
  FieldConfig,
  FileType,
  LabelValue,
  Page,
  PermissionEffect,
  S3Object,
  Tenant,
  UserRole,
  UserNotations,
} from '@alucio/aws-beacon-amplify/src/models'
import { CacheManifestEntry, CONTENT_CACHE_TYPE } from '@alucio/core'
import { RootState } from 'src/state/redux'
import {
  AssociatedFileORM,
  CustomFieldValuesMap,
  DocumentORM,
  DocumentPermission,
  DocumentVersionORM,
  ORMTypes,
  PageGroupORM,
  PageORM,
  VERSION_UPDATE_STATUS,
  DOCUMENT_ACTIONS_ENUM,
} from 'src/types/types'
import { indexArray } from 'src/utils/arrayHelpers';
import { getAuthHeaders } from 'src/utils/loadCloudfrontAsset/common'

import {
  FilterAndSortOptions,
  filterCollection,
  getMappedCustomValues,
  hasMissingRequiredFields,
  paginateCollection,
  sortCollection,
} from 'src/state/redux/selector/common'
import { loggedUser } from 'src/state/redux/selector/user'
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'
import capitalize from 'lodash/capitalize'
import { selAuthTokens } from 'src/state/redux/selector/authToken'
import { AccessTokenDataExtended } from '../slice/authToken'
import { generateAllPagesForVersion } from 'src/utils/documentHelpers'

export type { FilterAndSortOptions, SortOptions } from 'src/state/redux/selector/common'

export type AttachedFilesMap = { [attachedFileId: string]: AttachedFile }
export type BookmarkMap = { [docId: string]: { isBookmarked: boolean, createdAt: string } }
export type CacheMap = Record<
  string,
  { [key in CONTENT_CACHE_TYPE]?: CacheManifestEntry }
>
export type DocumentsMap = { [docId: string]: Document }
export type DocumentORMMap = { [docId: string]: DocumentORM }
export type DocumentVersionsMap = { [docId: string]: DocumentVersion[] }
export type DocumentVersionORMMap = { [docVerId: string]: DocumentVersionORM }
export type TenantsMap = { [tenantId: string]: Tenant }
export type TenantFieldMap = {
  values: {
    [key: string]: LabelValue['value'][]
  },
  configs: {
    [key: string]: FieldConfig
  }
}
export type UserNotationsMap = {
  documentLevel: {
    [documentVersionId: string]: UserNotations
  }
  customDeckLevel: {
    [customDeckId: string]: UserNotations
  }
}

// [TODO] Keep naming consistent map vs indexed
export interface IndexedDocumentORM {
  [key: string]: DocumentORM
}

export interface IndexedDocumentVersion {
  [key: string]: DocumentVersion
}

export const selAttachedFiles = (state: RootState): AttachedFile[] => state.attachedFile.records
export const selCache = (state: RootState): CacheManifestEntry[] => state.cache.manifestEntries
export const selDocuments = (state: RootState): Document[] => state.document.records
export const selDocumentVersions = (state: RootState): DocumentVersion[] => state.documentVersion.records
const selIsOnline = (state: RootState): boolean => state.cache.isOnline
export const selOpts = (_: RootState, __: any, opts: FilterAndSortOptions<DocumentORM>) => opts
export const selTenants = (state: RootState): Tenant[] => state.tenant.records
export const selUserNotations = (state: RootState): UserNotations[] => state.userNotations.records

export const selAttachedFilesMap: Selector<RootState, AttachedFilesMap> = createSelector(
  selAttachedFiles,
  (attachedFiles: AttachedFile[]): AttachedFilesMap => {
    return attachedFiles.reduce<AttachedFilesMap>(
      (acc, attachedFile) => {
        acc[attachedFile.id] = attachedFile;
        return acc;
      },
      {},
    )
  },
);

export const selDocumentsMap: Selector<RootState, DocumentsMap> = createSelector(
  selDocuments,
  (documents: Document[]): DocumentsMap => {
    return documents.reduce<DocumentsMap>(
      (acc, document) => {
        acc[document.id] = document;
        return acc;
      },
      {},
    )
  },
)

export const selDocumentVersionsMap: Selector<RootState, DocumentVersionsMap> = createSelector(
  selDocumentVersions,
  (docVers): DocumentVersionsMap => {
    return docVers.reduce<DocumentVersionsMap>(
      (acc, docVer) => {
        if (!acc[docVer.documentId]) acc[docVer.documentId] = []
        acc[docVer.documentId].push(docVer)
        return acc
      },
      {},
    )
  },
)

export const selUserNotationsMap: Selector<RootState, UserNotationsMap> = createSelector(
  selUserNotations,
  (allUserNotations): UserNotationsMap => {
    return allUserNotations.reduce<UserNotationsMap>(
      (acc, userNotations) => {
        const customDeckId = userNotations.customDeckId
        const documentVersionId = userNotations.documentVersionId
        if (customDeckId) acc.customDeckLevel[customDeckId] = userNotations
        else if (documentVersionId) acc.documentLevel[documentVersionId] = userNotations
        return acc
      },
      { documentLevel: {}, customDeckLevel: {} },
    )
  },
)

export const selTenantMap: Selector<RootState, TenantsMap> = createSelector(
  selTenants,
  (tenants): TenantsMap => {
    return tenants.reduce<TenantsMap>(
      (acc, tenant) => {
        acc[tenant.id] = tenant
        return acc
      },
      {},
    )
  },
)

export const selCacheMap: Selector<RootState, CacheMap> = createSelector(
  selCache,
  (cache): CacheMap => {
    return cache.reduce<CacheMap>(
      (acc, cache) => {
        if (!acc[cache.documentVersionId]) {
          acc[cache.documentVersionId] = {
            CONTENT: undefined,
            THUMBNAIL: undefined,
          }
        }

        acc[cache.documentVersionId][cache.cacheType] = cache

        return acc
      },
      {},
    )
  },
)

function hasMissingRequiredDocumentFields(documentVersion: DocumentVersion, tenant: Tenant): boolean {
  return hasMissingRequiredFields(
    documentVersion.customValues || [],
    tenant.config?.customFields || [],
    CustomFieldUsage.DOCUMENT) || !documentVersion.title;
}

export function getMissingRequiredFieldNames(
  docVersion: DocumentVersion,
  tenantFieldConfig?: FieldConfig[],
): string[] {
  const missingFields: string[] = [];
  // Check Document's mandatory fields
  const mandatoryDocumentFields = ['title', 'shortDescription', 'purpose']
  mandatoryDocumentFields.forEach(field => {
    if ((docVersion[field] === undefined) ||
      (docVersion[field] === null) ||
      (docVersion[field] === '')) {
      missingFields.push(field);
    }
  });

  const requiredTenantFields = tenantFieldConfig?.filter(field => field.required)
  if (requiredTenantFields?.length) {
    // Map document label into object for easier comparison
    const docMappedLabelValues = docVersion?.labelValues?.reduce((acc, { key, value }) =>
      ({ ...acc, [key]: value }), {}) ?? {}

    // Check if all required fields are met
    requiredTenantFields.forEach((field) => {
      if (!docMappedLabelValues[field.fieldName]) {
        missingFields.push(field.fieldName);
      }
    });
  }
  return missingFields;
}

export function getMappedTenantFields(docVersion: DocumentVersion, tenant: Tenant): TenantFieldMap {
  const reduced = im({ values: {}, configs: {} }, (draft: TenantFieldMap) => {
    const docValues = docVersion?.labelValues?.reduce((acc, { key, value }) => {
      if (key in acc) {
        const obj = { ...acc };
        obj[key] = [value, ...obj[key]];
        return obj;
      } else {
        return { ...acc, [key]: [value] };
      }
    }, {}) ?? {}

    // [NOTE]: This is omitting any labelValues not present in Tenant.fields
    tenant?.fields?.forEach(field => {
      draft.values[field.fieldName] = docValues[field.fieldName]
        ? [...new Set<string>(docValues[field.fieldName].sort())]
        : [];
      draft.configs[field.fieldName] = field
    })
  })

  return reduced
}

// [TODO-3073] - Disabled fields should not be included in the calculation
export function getPermissions(
  document: Document,
  configsMap: CustomFieldValuesMap,
  associatedFiles: AssociatedFileORM[],
  tenant: Tenant,
  siblingDocVer: DocumentVersion[],
): DocumentPermission {
  const { status, accessLevel } = document;
  const { modify, download, present, share, externalNotation } =
    Object
      .values(DOCUMENT_ACTIONS_ENUM)
      .reduce((acc, action) => {
        acc[action] = canPerformAction(action, configsMap, tenant);
        return acc;
      }, { modify: false, download: false, present: false, share: false, externalNotation: false });

  const isPublished = status === DocumentStatus.PUBLISHED;
  const inProgress = status === DocumentStatus.NOT_PUBLISHED;
  const isUserDoc = accessLevel === DocumentAccessLevel.USER;

  // [NOTE]: If the latest published version is not shareable, all previous versions are also not shareable
  const latestPublishedCustomValues = siblingDocVer.find(docVer => docVer.status === 'PUBLISHED')?.customValues ?? []
  const latestPublishedConfigsMap = getMappedCustomValues(
    { internalUsages: [CustomFieldUsage.DOCUMENT] },
    latestPublishedCustomValues,
    tenant.config?.customFields)
  const latestPublishedDocVerORMPermission =
    Object
      .values(DOCUMENT_ACTIONS_ENUM)
      .reduce((acc, action) => {
        acc[action] = canPerformAction(action, latestPublishedConfigsMap, tenant);
        return acc;
      }, { modify: false, download: false, present: false, share: false, externalNotation: false })
  const canShareLatestVersion = latestPublishedDocVerORMPermission.share
  const sharePermission = canShareLatestVersion === true ? share : false

  const hasDocumentSharePermission = (sharePermission || shareableAssociatedFiles(associatedFiles, tenant))

  return {
    bookmark: isPublished && !isUserDoc,
    addToFolder: (inProgress || isPublished) && !isUserDoc,
    // [NOTE] - We assume that User Uploaded docs are always presentable (they're only presentable as part of Custom Deck anyways)
    MSLPresent: (isUserDoc) || (present && isPublished && !isUserDoc),
    MSLDownload: download && !isUserDoc,
    // [TODO] - Does this need to be renamed to imply custom deck creation?
    MSLSelectSlides: modify && isPublished && !isUserDoc,
    MSLNonModifiable: !modify && isPublished && !isUserDoc,
    MSLShare: isPublished && hasDocumentSharePermission && !isUserDoc,
    MSLExternalNotatable: isPublished && externalNotation,
    fieldLevel: { modify, download, present, share, externalNotation },
  }
}

// ITERATES OVER THE CUSTOM VALUES TO CHECK IF THE DOCUMENT CAN PERFORM OR NOT A SPECIFIC ACTION
// Assumes fieldValueDefinitions have already been filtered to match current CustomValues
export function canPerformAction(
  action: DOCUMENT_ACTIONS_ENUM,
  configsMap: CustomFieldValuesMap,
  tenant: Tenant,
): boolean {
  let canPerform = false;

  if (tenant.config?.defaultDocumentPermissions[action] === PermissionEffect.ALLOW) {
    canPerform = true;
  } else if (tenant.config?.defaultDocumentPermissions[action] === PermissionEffect.BLOCK) {
    return false;
  }

  for (const config of Object.values(configsMap)) {
    for (const valueDefinition of config.valuesDefinition || []) {
      const permissionEffect = valueDefinition.documentSettings?.permission?.[action];
      if (permissionEffect === PermissionEffect.BLOCK) {
        return false;
      } else if (permissionEffect === PermissionEffect.ALLOW) {
        canPerform = true;
      }
    }
  }

  return canPerform;
}

function shareableAssociatedFiles(associatedFiles: AssociatedFileORM[], tenant: Tenant) {
  if (associatedFiles.length! < 1) {
    return false;
  }

  return associatedFiles.some((associatedDoc) => {
    if (associatedDoc.model.type === AssociatedFileType.ATTACHED_FILE) {
      if (associatedDoc.model.status === AssociatedFileStatus.ACTIVE) {
        return !!associatedDoc.meta.canBeSharedByMSL;
      } else {
        return false;
      }
    }

    // CHECKS IF THE ASSOCIATED FILE IS SHAREABLE BY CHECKING THEIR CUSTOM VALUES
    const usableDocVer = associatedDoc.relations.latestUsableDocumentVersion;
    if (usableDocVer) {
      const configsMap = getMappedCustomValues(
        { internalUsages: [CustomFieldUsage.DOCUMENT] },
        usableDocVer.customValues,
        tenant.config?.customFields);
      return canPerformAction(DOCUMENT_ACTIONS_ENUM.share, configsMap, tenant);
    }
  });
}

// This function concatenate all watermarks into one string separate by comma
function getWatermarkText(
  configsMap: CustomFieldValuesMap,
) {
  let watermarkText: string = ''
  Object.values(configsMap).forEach(field => {
    const valueDefinition: CustomFieldValueDefinition | undefined =
      field?.valuesDefinition?.find(({ documentSettings }) => documentSettings?.presentationWatermarkText)
    if (valueDefinition?.documentSettings?.presentationWatermarkText && field.field.status === 'ENABLED') {
      if (watermarkText) {
        watermarkText += `, ${valueDefinition?.documentSettings?.presentationWatermarkText}`
      } else {
        watermarkText += valueDefinition?.documentSettings?.presentationWatermarkText
      }
    }
  })
  return watermarkText
}

function getDownloadURL(docVer: DocumentVersion) {
  return docVer.type === FileType.MP4
    ? docVer.converterVersion && docVer.converterVersion >= 2
      ? `${docVer.srcFile?.key}`
      : `${docVer.tenantId}/${docVer.documentId}/${docVer.id}/v2/${docVer.id}.original`.replace(/\s+/g, '')
    : `${docVer.srcFile?.key}`
}

// [NOTE]: We expect this to be pre-sorted
// [TODO-PWA] - Consider not always adding cache meta to `useAllDocuments`
//  - It can cause lots of rerender during content sync
//  - Consider having a separate selector that components would fall back to?
function getCachedDocumentVersion(docVerDesc: DocumentVersionORM[]): (DocumentVersionORM | undefined) {
  const [latestVersion] = docVerDesc

  if (!latestVersion) { return; }

  // 1. Return either the most recent cached doc version
  //  -or-
  // 2. next latest version
  const latestContentCachedDocVer = docVerDesc.find(docVer => docVer.meta.assets.isContentCached)
  if (latestContentCachedDocVer) return latestContentCachedDocVer

  // 3. Return the most recent (partial, thumbnail only )doc version
  //  -or-
  // 4. next latest partial version
  // 5. or no version if nothing's cached
  const latestThumbnailCachedDocVer = docVerDesc.find(docVer => docVer.meta.assets.isThumbnailCached)
  return latestThumbnailCachedDocVer
}

export const selBookmarkMap: Selector<RootState, BookmarkMap> = createSelector(
  loggedUser,
  (currentUser): BookmarkMap => {
    // [TODO] Should not make user profile optional
    // - If they cannot find a cognito/user profile, they should not be able to continue
    if (!currentUser.userProfile) {
      throw new Error('Could not find current user')
    }

    return currentUser.userProfile?.bookmarkedDocs?.reduce<BookmarkMap>(
      (acc, bookmark) => {
        acc[bookmark.docID] = { isBookmarked: true, createdAt: bookmark.createdAt }
        return acc
      },
      {},
    ) ?? {}
  },
)

const toDocumentORM = (
  document: Document,
  docVersMap: DocumentVersionsMap,
  userNotationsMap: UserNotationsMap,
  tenantsMap: TenantsMap,
  bookmarkMap: BookmarkMap,
  userTenant: Tenant,
  attachedFilesMap: AttachedFilesMap,
  documentsMap: DocumentsMap,
  cacheMap: CacheMap,
  isOnline: boolean,
  authTokens: AccessTokenDataExtended[],
): DocumentORM => {
  // NOTE: A docVer may not be loaded at the same as a Document, ensure that it can handle an undefined value
  const targetDocVersions = docVersMap[document.id] ?? [] as DocumentVersion[] | undefined
  const [docVer] = Object.values(targetDocVersions)

  const documentORM: DocumentORM = {
    model: document,
    type: ORMTypes.DOCUMENT,
    relations: {
      tenant: tenantsMap[document.tenantId],
      documentVersions: [],
      version: {
        latestPublishedDocumentVersionORM: undefined,
        cachedDocumentVersion: undefined,
        // @ts-expect-error (we will set these value(s!!!) next) -- MAKE SURE TO SET THEM IN THE NEXT STEPS
        latestUsableDocumentVersionORM: undefined,
        latestDocumentVersion: undefined,
      },
    },
    meta: {
      assets: {
        thumbnailKey: undefined,
        getAuthHeaders,
      },
      bookmark: {
        isBookmarked: !!bookmarkMap?.[document.id]?.isBookmarked,
        createdAt: bookmarkMap?.[document.id]?.createdAt,
      },
      customValues: {
        areRequiredFieldsCompleted: false,
        configsMap: {},
      },
      tenantFields: {
        labelCompletion: false,
        valuesMap: {},
        configsMap: {},
      },
      integration: {
        integrationType: document.integrationType,
        integration: document.integration,
        source: capitalize(document.integrationType),
      },
      permissions: {
        bookmark: false,
        addToFolder: false,
        MSLShare: false,
        MSLDownload: false,
        MSLPresent: false,
        MSLSelectSlides: false,
        MSLNonModifiable: false,
        MSLExternalNotatable: false,
        fieldLevel: { modify: false, download: false, present: false, share: false, externalNotation: false },
      },
      hasUnpublishedVersion: false,
      sealedStatus: undefined,
    },
  }

  // Determine latestDoc and or published version
  if (docVer) {
    const docVerDesc: DocumentVersion[] = targetDocVersions.sort((a, b) => b.versionNumber - a.versionNumber)
    const docVerORMDesc = docVerDesc.map(docVer => {
      const userNotations = userNotationsMap.documentLevel[docVer.id]
      return toDocumentVersionORM(
        docVer,
        documentORM,
        docVerDesc,
        userNotations,
        attachedFilesMap,
        documentsMap,
        docVersMap,
        cacheMap,
        userTenant,
        authTokens,
      )
    })

    documentORM.relations.documentVersions = docVerORMDesc;

    const [latestDocVer] = docVerORMDesc
    const latestPublishedDocVer = docVerORMDesc.find(({ model }) => model.status === DocumentStatus.PUBLISHED);
    const cachedDocVersion = getCachedDocumentVersion(docVerORMDesc)

    documentORM.relations.version.cachedDocumentVersionORM = cachedDocVersion
    documentORM.relations.version.latestDocumentVersionORM = latestDocVer
    documentORM.relations.version.latestPublishedDocumentVersionORM = latestPublishedDocVer
    // Latest usable document version is Online Published -> Offline Published Cached -> Latest Version (published or unpublished)
    // In a lot of places we do network checks, but maybe that should just be done from the ORM level instead of the component level?
    const usableDocVer = (isOnline ? latestPublishedDocVer : cachedDocVersion) || latestDocVer
    documentORM.relations.version.latestUsableDocumentVersionORM = usableDocVer

    // integration
    documentORM.meta.integration.integrationType = usableDocVer.model.integrationType
    documentORM.meta.integration.integration = documentORM.model.integration
    documentORM.meta.permissions = usableDocVer.meta.permissions
    documentORM.meta.customValues = usableDocVer.meta.customValues
    documentORM.meta.tenantFields = usableDocVer.meta.tenantFields
    documentORM.meta.hasUnpublishedVersion = (
      latestDocVer?.model.status === DocumentStatus.NOT_PUBLISHED &&
      !(
        [
          `${DocumentStatus.REVOKED}`,
          `${DocumentStatus.ARCHIVED}`,
          `${DocumentStatus.DELETED}`,
        ].includes(document.status)
      )
    );
    const selectedThumbnailPage = usableDocVer.model.selectedThumbnail ?? 1

    documentORM.meta.assets.thumbnailKey = detectArchivedFileKeyPath(
      usableDocVer.model,
      { number: selectedThumbnailPage, pageId: `${usableDocVer.model.id}_${selectedThumbnailPage}` },
      'sm',
    )

    // @ts-expect-error - TS doesn't the know types are narrowed down
    documentORM.meta.sealedStatus = [
      DocumentStatus.ARCHIVED,
      DocumentStatus.REVOKED,
      DocumentStatus.DELETED,
    ].find(status => status === document.status)
  }

  // [NOTE] This is a dummy DocumentVersion, this can happen when we upload a new document
  //  and the DocumentVersion has not yet been added to redux
  //  Rather than make documentVersion optional (undefined, requiring lots of fallback logic in many components)
  //  We just have a dummy version instead, this would only be available for a second or two at most
  if (!docVer) {
    const timestamp = (new Date()).toISOString();
    const dummyVer = new DocumentVersion({
      associatedFiles: [],
      tenantId: 'TEMP',
      documentId: 'TEMP',
      versionNumber: 0,
      srcFilename: 'TEMP',
      conversionStatus: 'PROCESSING',
      status: 'NOT_PUBLISHED',
      pageSettings: [],
      srcFile: new S3Object({
        key: 'TEMP',
        bucket: 'TEMP',
        region: 'TEMP',
        url: 'TEMP',
      }),
      type: 'PPTX',
      editPermissions: [],
      srcSize: 0,
      createdBy: '',
      createdAt: timestamp,
      updatedBy: '',
      updatedAt: timestamp,
    })

    const dummyDocVer = toDocumentVersionORM(
      dummyVer,
      documentORM,
      [],
      undefined,
      {},
      {},
      {},
      {},
      /** TODO: This dummy document version should not be necessary.
       * We should refactor to simply prune documents that are not
       * available yet prior to running through this process */
      {} as Tenant,
      authTokens,
    )
    documentORM.relations.version.latestDocumentVersionORM = dummyDocVer
    documentORM.relations.version.latestUsableDocumentVersionORM = dummyDocVer
  }

  return documentORM
}

const toDocumentVersionORM = (
  docVer: DocumentVersion,
  docORM: DocumentORM,
  siblingDocVer: DocumentVersion[],
  userNotations: UserNotations | undefined,
  attachedFilesMap: AttachedFilesMap,
  documentsMap: DocumentsMap,
  documentVersionsMap: DocumentVersionsMap,
  cacheMap: CacheMap,
  userTenant: Tenant,
  authTokens: AccessTokenDataExtended[],
): DocumentVersionORM => {
  const associatedFiles = getAssociatedFilesORM(
    docVer.associatedFiles ?? [],
    attachedFilesMap,
    documentsMap,
    documentVersionsMap,
    userTenant,
  );

  const isUserDoc = docORM.model.accessLevel === DocumentAccessLevel.USER

  // Assumes the incoming siblingDocVer array is sorted
  const latestPublishedVersion = siblingDocVer
    .find(docVer => docVer.status === 'PUBLISHED')
  const isLatestPublished = latestPublishedVersion?.id === docVer.id
  const { values: labelValues, configs: labelConfigs } = getMappedTenantFields(docVer, userTenant)
  const configsMap = getMappedCustomValues(
    { internalUsages: [CustomFieldUsage.DOCUMENT] },
    docVer.customValues,
    userTenant.config?.customFields)

  const gracePeriodDays = userTenant.folderUpdateGracePeriodDays;
  let withinGracePeriod = isLatestPublished;

  if (!withinGracePeriod && gracePeriodDays) {
    withinGracePeriod = !isPast(addDays(new Date(latestPublishedVersion?.updatedAt!), gracePeriodDays));
  }

  const allPages = generateAllPagesForVersion(docVer.id, docVer.numPages ?? 0, docVer.pageSettings)
  const docVerORM: DocumentVersionORM = {
    model: docVer,
    type: ORMTypes.DOCUMENT_VERSION,
    relations: {
      documentORM: docORM,
      associatedFiles: associatedFiles,
      pages: [] as PageORM[],
      pageGroups: [] as PageGroupORM[],
      userNotations,
    },
    meta: {
      assets: {
        thumbnailKey: docVer.convertedFolderKey
          ? detectArchivedFileKeyPath(
            docVer,
            {
              number: docVer.selectedThumbnail ?? 1,
              pageId: `${docVer.id}_${docVer.selectedThumbnail ?? 1}`,
            },
            'sm',
          )!
          : undefined,
        contentKey: docVer
          ? `/content/${docVer.srcFile?.key}`
          : undefined,
        getAuthHeaders,
        isContentCached: cacheMap[docVer.id]?.CONTENT?.status === 'LOADED',
        isThumbnailCached: cacheMap[docVer.id]?.THUMBNAIL?.status === 'LOADED',
        accessToken: authTokens.find(token => token.documentVersionId === docVer.id)?.accessToken,
      },
      allPages,
      version: {
        semVerLabel: `${docVer.semVer?.major}.${docVer.semVer?.minor}`,
        isLatestPublished,
        // From this version to the latest version would it be a MAJOR or MINOR update
        // If the document is not published we always consider it a major change (requires review)
        updateStatus:
          docVer.status !== DocumentStatus.PUBLISHED ||
            docORM.model.status !== DocumentStatus.PUBLISHED
            ? VERSION_UPDATE_STATUS.NOT_PUBLISHED
            : isLatestPublished
              ? VERSION_UPDATE_STATUS.CURRENT
              : siblingDocVer
                .filter((ver) => ver.versionNumber > docVer.versionNumber && ver.status === DocumentStatus.PUBLISHED)
                .every((ver) => ver.changeType === DocumentVersionChangeType.MINOR)
                ? VERSION_UPDATE_STATUS.PENDING_MINOR
                : VERSION_UPDATE_STATUS.PENDING_MAJOR,
        withinGracePeriod,
      },
      integration: {
        integrationType: docVer.integrationType,
        integration: docVer.integration,
        source: docVer.integrationType && capitalize(docVer.integrationType),
      },
      customValues: {
        areRequiredFieldsCompleted: !hasMissingRequiredDocumentFields(docVer, userTenant),
        // Personal uploads should not have any custom fields applied to them
        // even if they are the default values of the custom fields
        configsMap: isUserDoc ? {} : configsMap,
      },
      permissions: getPermissions(docORM.model, configsMap, associatedFiles, userTenant, siblingDocVer),
      tenantFields: {
        labelCompletion: getMissingRequiredFieldNames(docVer, userTenant.fields).length === 0,
        valuesMap: labelValues,
        configsMap: labelConfigs,
      },
      // @ts-expect-error - TS doesn't the know types are narrowed down
      sealedStatus: [
        DocumentStatus.ARCHIVED,
        DocumentStatus.REVOKED,
        DocumentStatus.DELETED,
      ].find(status => status === docORM.model.status),
      schedule: {
        publish: {
          isScheduled: !!docVer.scheduledPublish,
          scheduledAt: docVer.scheduledPublish,
        },
      },
      srcFileDownloadURL: getDownloadURL(docVer),
    },
  }
  // Adding watermarkText to docVerORM
  docVerORM.meta.watermarkText = getWatermarkText(docVerORM.meta.customValues.configsMap)
  const customFields = docVerORM.meta.customValues.configsMap;
  docVerORM.meta.badges = Object.values(customFields).reduce((acc: CustomFieldValueDefinition[], value) => {
    value.valuesDefinition?.forEach((definition) => {
      if (definition.badgeColor && definition.badgeLabel) {
        acc.push(definition);
      }
    })
    return acc;
  }, [])

  const pageMapValue = allPages.reduce<{ [pageId: string]: Page }>((acc, page) => {
    acc[page.pageId] = page;
    return acc;
  }, {})

  /*
  * Get linked pages given a page
  */
  const getLinkedSlides = (
    page: Page,
  ): PageORM[] | undefined =>
    page.linkedSlides
      ?.filter(slide => pageMapValue[slide])
      ?.map(slide => {
        return {
          model: pageMapValue[slide],
          type: ORMTypes.PAGE,
          relations: {
            documentVersionORM: docVerORM,
            pageGroup: undefined,
            linkedSlides: [],
          },
        }
      })

  const pageORMs = allPages.map<PageORM>((page: Page) => {
    return {
      model: page,
      type: ORMTypes.PAGE,
      relations: {
        documentVersionORM: docVerORM,
        pageGroup: undefined,
        linkedSlides: getLinkedSlides(page),
      },
    }
  })
  docVerORM.relations.pages = pageORMs
  if (docVer.pageGroups && docVer.pageGroups.length > 0) {
    // Need to populate Page Groups
    const pageLookup = pageORMs.reduce((acc, page) => {
      acc.set(page.model.pageId, page)
      return acc
    }, new Map<string, PageORM>())
    docVerORM.relations.pageGroups = docVer.pageGroups?.map<PageGroupORM>((group) => {
      const groupORM: PageGroupORM = {
        model: group,
        type: ORMTypes.PAGE_GROUP,
        meta: {
          isRequired: false,
        },
        relations: {
          documentVersionORM: docVerORM,
          pages: [] as PageORM[],
        },
      }
      const groupPages = group.pageIds ? group.pageIds.map<PageORM>((pageId) => {
        const pageORM = pageLookup.get(pageId)
        if (pageORM) {
          pageORM.relations.pageGroupORM = groupORM
          return pageORM
        } else {
          throw Error(`Page Group References non-existant page: ${pageId}`)
        }
      }) : [] as PageORM[]
      groupORM.relations.pages = groupPages
      groupORM.meta.isRequired = groupPages.some((page) => !!page.model.isRequired)
      return groupORM
    })
  }

  return docVerORM
}

export function getAssociatedFileORMFromFile(
  file: (AttachedFile | Document),
  model: AssociatedFile,
  tenant?: Tenant,
  latestUsableDocumentVersion?: DocumentVersion,
): AssociatedFileORM {
  let canShareDocVer = false;

  // IF ITS AN ATTACHED FILE, IT SHOULD CHECK IT'S OWN "isDistributable" FIELD
  if (model.type === AssociatedFileType.ATTACHED_FILE) {
    // BEAC-3442 - Temporary fix so that all files that are't explicitly marked as not
    // distributable are assumed to be distributable
    canShareDocVer = model.isDistributable !== false;
  } else if (latestUsableDocumentVersion && tenant) {
    const configsMap = getMappedCustomValues(
      { internalUsages: [CustomFieldUsage.DOCUMENT] },
      latestUsableDocumentVersion.customValues,
      tenant.config?.customFields);
    canShareDocVer = canPerformAction(DOCUMENT_ACTIONS_ENUM.share, configsMap, tenant);
  }

  return {
    model: model,
    meta: {
      canBeSharedByMSL: model.status === 'ACTIVE' && canShareDocVer,
    },
    relations: {
      latestUsableDocumentVersion,
    },
    type: ORMTypes.ASSOCIATED_FILE,
    file,
  }
}
export function getAssociatedFilesORM(
  associatedFiles: AssociatedFile[],
  attachedFilesMap: AttachedFilesMap,
  documentsMap: DocumentsMap,
  documentVersionsMap: DocumentVersionsMap,
  tenant?: Tenant,
): AssociatedFileORM[] {
  if (!associatedFiles.length) return []

  return associatedFiles.reduce((acc: AssociatedFileORM[], associatedFile: AssociatedFile) => {
    const isAttachedFile = associatedFile.type === AssociatedFileType.ATTACHED_FILE;

    // the associated file id has a '_1' appended to it, removing it for linked documents
    const attachmentIdMod = associatedFile.attachmentId.replace('_1', '')

    // Prepare latestPublishedDocVer object if this file is a Document
    let latestPublishedDocVer: DocumentVersion | undefined;
    let latestDocVer: DocumentVersion | undefined;
    if (!isAttachedFile) {
      const targetDocVersions = documentVersionsMap[attachmentIdMod]
      // [TODO-2126] - This may need to be handled more gracefully
      //               This would only happen though if records are bad i.e. in local dev environments
      if (targetDocVersions) {
        const docVerDesc: DocumentVersion[] = targetDocVersions.sort((a, b) => b.versionNumber - a.versionNumber)
        latestDocVer = docVerDesc[0]
        latestPublishedDocVer = docVerDesc.find((docVer) => docVer.status === DocumentStatus.PUBLISHED)
      }
    }

    const file = isAttachedFile
      ? attachedFilesMap[associatedFile.attachmentId]
      : documentsMap[attachmentIdMod]

    // Remove any associations which are to missing / deleted docs
    if (file) {
      acc.push(getAssociatedFileORMFromFile(file, associatedFile, tenant, latestPublishedDocVer || latestDocVer))
      return acc
    } else {
      return acc
    }
  }, [] as AssociatedFileORM[]);
}

const allDocuments: Selector<RootState, DocumentORM[]> = createSelector(
  selDocuments,
  selDocumentVersionsMap,
  selUserNotationsMap,
  selTenantMap,
  selBookmarkMap,
  loggedUser,
  selAttachedFilesMap,
  selDocumentsMap,
  selCacheMap,
  selIsOnline,
  selAuthTokens,
  ( docs,
    docVersMap,
    userNotationsMap,
    tenantsMap,
    bookmarkMap,
    currentUser,
    attachedFilesMap,
    documentsMap,
    cacheMap,
    isOnline,
    authTokens,
  ): DocumentORM[] => {
    const userTenant = tenantsMap[currentUser?.userProfile?.tenantId ?? '-1']
    if (!userTenant && currentUser?.userProfile?.role !== UserRole.ALUCIO_ADMIN) {
      throw new Error('Could not identify user\'s tenant')
    }

    const documents: DocumentORM[] = docs.map(doc => {
      return toDocumentORM(
        doc,
        docVersMap,
        userNotationsMap,
        tenantsMap,
        bookmarkMap,
        userTenant,
        attachedFilesMap,
        documentsMap,
        cacheMap,
        isOnline,
        authTokens,
      )
    });

    return documents
  },
)

const allTenantDocuments: Selector<RootState, DocumentORM[]> = createSelector(
  allDocuments,
  (documents): DocumentORM[] =>
    documents.filter(({ model }) => model.accessLevel === DocumentAccessLevel.TENANT),
);

const allPersonalDocuments: Selector<RootState, DocumentORM[]> = createSelector(
  allDocuments,
  loggedUser,
  (documents, user): DocumentORM[] =>
    documents.filter(({ model }) => model.accessLevel === DocumentAccessLevel.USER &&
      model.createdBy === user.authProfile?.attributes.email)
      .sort((a, b) => b.model.createdAt.localeCompare(a.model.createdAt)),
);

// Default instance (1 use at a time)
const allDocumentsFiltered: Selector<RootState, DocumentORM[]> = createSelector(
  allTenantDocuments,
  selOpts,
  filterCollection<DocumentORM>,
)

const allDocumentsSortedAndFiltered: Selector<RootState, DocumentORM[]> = createSelector(
  allDocumentsFiltered,
  selOpts,
  sortCollection<DocumentORM>,
)

const allPersonalDocumentsSortedAndFiltered: Selector<RootState, DocumentORM[]> = createSelector(
  allPersonalDocuments,
  selOpts,
  sortCollection<DocumentORM>,
)

// Instance factory (can use multiple at a time)
const allDocumentsFilteredFactory: () => Selector<RootState, DocumentORM[]> = () => createSelector(
  allTenantDocuments,
  selOpts,
  filterCollection<DocumentORM>,
)

const allPersonalDocumentsFilteredFactory: () => Selector<RootState, DocumentORM[]> = () => createSelector(
  allPersonalDocuments,
  selOpts,
  filterCollection<DocumentORM>,
)

export const allDocumentsSortedAndFilteredFactory: () => Selector<RootState, DocumentORM[]> = () => createSelector(
  allDocumentsFilteredFactory(),
  selOpts,
  sortCollection<DocumentORM>,
)

export const allPersonalDocumentsSortedAndFilteredFactory: () => Selector<
  RootState,
  DocumentORM[]
> = () => createSelector(
  allPersonalDocumentsFilteredFactory(),
  selOpts,
  sortCollection<DocumentORM>,
)

export const allDocumentsSortedFilteredPaginated: Selector<
  RootState,
  ReturnType<typeof paginateCollection<DocumentORM>>
> = createSelector(
  allDocumentsSortedAndFiltered,
  selOpts,
  paginateCollection<DocumentORM>,
)

export const allDocumentVersionMap: Selector<RootState, DocumentVersionORMMap> = createSelector(
  allDocuments,
  (docs): DocumentVersionORMMap => {
    return docs.reduce<DocumentVersionORMMap>(
      (acc, doc) => {
        doc.relations.documentVersions.forEach(docVerORM => {
          acc[docVerORM.model.id] = docVerORM
        })
        return acc
      },
      {},
    )
  },
)

export const allDocumentORMMap: Selector<RootState, DocumentORMMap> = createSelector(
  allDocuments,
  (docs) => {
    return docs.reduce<DocumentORMMap>(
      (acc, doc) => {
        acc[doc.model.id] = doc;
        return acc;
      }, {},
    )
  },
)

const allDocumentsLength: Selector<RootState, number> = createSelector(
  allTenantDocuments,
  (documents) => documents.length,
)

export const indexedDocumentList: Selector<RootState, IndexedDocumentORM> = createSelector(
  allDocumentsFiltered,
  (documents: DocumentORM[]): IndexedDocumentORM => {
    const indexedDocumentORM = {};
    documents.forEach((documentORM) => {
      indexedDocumentORM[documentORM.model.id] = documentORM;
    });
    return indexedDocumentORM;
  },
);

export const indexedDocumentVersionList: Selector<RootState, IndexedDocumentVersion> = createSelector(
  selDocumentVersions,
  (documentVersions: DocumentVersion[]): IndexedDocumentVersion => {
    return indexArray<DocumentVersion>(documentVersions, 'id');
  },
);

export const documentVersionORMById: Selector<RootState, DocumentVersionORM | undefined> = createSelector(
  allDocumentVersionMap,
  (_: RootState, id: string) => id,
  (indexedDocumentVersions: DocumentVersionORMMap, id): DocumentVersionORM | undefined => indexedDocumentVersions[id],
)

const documentORMById: Selector<RootState, DocumentORM | undefined> = createSelector(
  allDocumentORMMap,
  (_: RootState, id: string) => id,
  (indexedDocuments: DocumentORMMap, id): DocumentORM | undefined => indexedDocuments[id],
)

export const useAllDocuments = (opts?: FilterAndSortOptions<DocumentORM>):
  ReturnType<typeof allTenantDocuments> =>
  useSelector((state: RootState) =>
    allDocumentsSortedAndFiltered(state, undefined, opts))

export const useAllPersonalDocuments = ():
  ReturnType<typeof allPersonalDocuments> =>
  useSelector((state: RootState) =>
    allPersonalDocuments(state));

export const useAllDocumentVersionMap = ():
  ReturnType<typeof allDocumentVersionMap> =>
  useSelector((state: RootState) =>
    allDocumentVersionMap(state))

export const useAllDocumentsInstance = (opts?: FilterAndSortOptions<DocumentORM>):
  ReturnType<typeof allDocumentsSortedAndFiltered> => {
  const selectorInstance = useMemo(
    () => allDocumentsSortedAndFilteredFactory(),
    [],
  )

  return useSelector((state: RootState) => selectorInstance(state, undefined, opts))
}

export const useAllPersonalDocumentsInstance = (opts?: FilterAndSortOptions<DocumentORM>):
  ReturnType<typeof allPersonalDocumentsSortedAndFiltered> => {
  const selectorInstance = useMemo(
    () => allPersonalDocumentsSortedAndFilteredFactory(),
    [],
  )

  return useSelector((state: RootState) => selectorInstance(state, undefined, opts))
}

export const useAllDocumentsMap = ():
  ReturnType<typeof selDocumentsMap> =>
  useSelector((state: RootState) =>
    selDocumentsMap(state),
  )

// [TODO] - This naming is confusing as it's too similar to useAllDocumentVersionMap
//        - This selector is DocumentId -> Array<DocumentVersions>
export const useAllDocumentVersionsMap = ():
  ReturnType<typeof selDocumentVersionsMap> =>
  useSelector((state: RootState) =>
    selDocumentVersionsMap(state),
  )

export const useAllDocumentsLength = ():
  ReturnType<typeof allDocumentsLength> =>
  useSelector((state: RootState) =>
    allDocumentsLength(state))

// [TODO] - We have different ways of accessing a specific DocumentORM
//          We should consider consolidating (or making these more well known)
export const useDocumentVersionORM = (id: string): ReturnType<typeof documentVersionORMById> =>
  useSelector((state: RootState) => documentVersionORMById(state, id))

export const useDocumentORM = (id: string | undefined): ReturnType<typeof documentORMById> =>
  useSelector((state: RootState) => documentORMById(state, id))

export const useAllDocumentORMMap = (): ReturnType<typeof allDocumentORMMap> =>
  useSelector((state: RootState) => allDocumentORMMap(state))
