import React from 'react'
import { FolderItemORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'

import {
  DNABox,
  DNACard,
  DNAText,
  Iffy,
  useTheme,
  DNAChip,
  luxColors,
  DNAIcon,
} from '@alucio/lux-ui'

import DNACustomDeckThumbnail from 'src/components/DNA/Document/DNACustomDeckThumbnail'
import { isCustomDeckORM } from 'src/types/typeguards'
import { StyleSheet } from 'react-native'
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'

export type DNADocumentFolderItemCustomDeckMeetingRowProps = {
  isOnline?: boolean,
}

const styles = StyleSheet.create({
  containerStyle: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: 4,
    marginVertical: 4,
  },
})

const DNADocumentFolderItemCustomDeckMeetingRow: DNARowsComponent<
  FolderItemORM,
  DNADocumentFolderItemCustomDeckMeetingRowProps
> = ({
  ORM: folderItem,
  onPress,
  style,
  isDesktop = true,
  isOnline,
}) => {
  const theme = useTheme()
  const { meetingORM } = useMeetingsState();

  /**
   * This is where typescript really helps us identify coding best practice. The way this and related components were structured/typed,
   * there is a possibility of getting into this component with the wrong ORM. This is a broader issue than just checking what type the related
   * item is. Basically, the folderItem comes in as any number of folderItemORM types and should be limited to just CustomDeckORM folderitem
   * types. Since this component is intended to only ever be loaded when we are using a customDeckORM, the data structure/typings should be identified
   * prior to loading the component. Then only the necessary props should be passed in.
   *
   * Note, that the folder item is being passed in and then passed right back out unnecessarily in the onPress call. Additionally, the DNACustomDeckContextMenu
   * component is being designed as a type specific component, but being passed a property that is multi-type. If the component is intended to be
   * specific to the datatype the props should reflect this approach as well. However, if this intended to be a reusable component, then passing the
   * more generic FolderItemORM is ok.
   * */
  if (!isCustomDeckORM(folderItem.relations.itemORM)) { return null }

  const customDeckORM = folderItem.relations.itemORM
  const isVirtualMeeting = meetingORM?.model.type === 'VIRTUAL'
  const hasWebDoc = customDeckORM.meta.containsWebDocs
  const hasHTMLDoc = customDeckORM.meta.containsHTMLDocs
  const { withinGracePeriod } = customDeckORM.meta.version
  const isReviewRequired = customDeckORM.meta.version.requiresReview
  const canPresent = customDeckORM.meta.permissions.MSLPresent

  const unavailableContent =
    (!isOnline && !customDeckORM.meta.assets.isContentCached) ||
    (hasWebDoc && (isVirtualMeeting || !isOnline)) ||
    (hasHTMLDoc && isVirtualMeeting) || !canPresent

  const isDisabledHTMLorWebDocument =
    (hasWebDoc && isVirtualMeeting) ||
    (hasHTMLDoc && isVirtualMeeting)

  const isInteractive =
    (!isReviewRequired || withinGracePeriod) &&
    !unavailableContent

  const handleItemPress = () => {
    isInteractive && onPress?.(folderItem)
  }

  const messages: string[] = [];
  if (isDisabledHTMLorWebDocument) {
    messages.push('Decks containing WEB or HTML file types are not able to be used in Presenter view');
  }
  if (!canPresent) {
    messages.push('This deck contains a slide that is not presentable.');
  }
  const popOverText = messages.join('\n');

  return (
    <DNACard
      appearance="flat"
      interactive={isInteractive ? 'pressable' : undefined}
      onPress={handleItemPress}
      style={[
        {
          paddingHorizontal: theme['row-padding-horizontal'],
          paddingVertical: theme['row-padding-vertical'],
        },
        style,
        styles.containerStyle,
      ]}
    >
      <DNAPopover disablePopover={!popOverText} placement="top-start">
        <DNAPopover.Anchor>
          <DNABox
            alignY="center"
            spacing={isDesktop ? 'between' : 'md'}
            childFill={0}
            disabled={!isInteractive}
          >
            <DNABox
              spacing="lg"
              alignY="center"
              childFill={1}
              fill
            >
              <DNACustomDeckThumbnail
                customDeckORM={customDeckORM}
                width={76}
                height={44}
                unavailableContent={unavailableContent}
              />
              {/* Title */}
              <DNABox
                spacing="sm"
                appearance="col"
                childFill
                style={{ paddingRight: 16 }}
              >
                <DNABox fill style={{ width: 580 }}>
                  <DNAText b1 numberOfLines={1} status="basic" testID="customDeck-item-title">
                    {folderItem.model.customTitle ? folderItem.model.customTitle : 'Untitled Presentation'}
                  </DNAText>
                </DNABox>
                <DNABox
                  alignY="center"
                  spacing="sm"
                  fill
                  childStyle={[4, { flex: 1 }]}
                >
                  <DNAChip appearance="tag">
                    MODIFIED
                  </DNAChip>
                  <Iffy is={unavailableContent}>
                    <DNAChip
                      appearance="tag"
                      style={{ backgroundColor: luxColors.basicBlack.primary }}
                      testID={`content-not-available-${folderItem.meta.title}`}
                    >
                      CONTENT NOT AVAILABLE
                    </DNAChip>
                  </Iffy>
                </DNABox>
              </DNABox>
              {/* Update Version Notification */}
              <Iffy is={isReviewRequired}>
                <DNABox alignY="center">
                  <DNAIcon.Styled
                    name="alert"
                    status="warning"
                    size="lg"
                    appearance="ghost"
                    style={{ marginRight: 5 }}
                  />
                  <DNAText status="warning">
                    Needs review
                  </DNAText>
                </DNABox>
              </Iffy>
            </DNABox>
          </DNABox>
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText testID="web-html-custom-popup" status="basic">
            { popOverText }
          </DNAText>
        </DNAPopover.Content>
      </DNAPopover>
    </DNACard>
  );
}

export default DNADocumentFolderItemCustomDeckMeetingRow
