import React from 'react'
import {
  EmailTemplate, User, ShareType, CustomValues,
  CustomDeckDependencies,
} from '@alucio/aws-beacon-amplify/src/models'
import { GenericToast, ToastOrientations } from '@alucio/lux-ui';
import { ToastActions } from '@alucio/lux-ui/lib/components/Toast/useToast';
import { getShareableLink, ShareableLinkResult } from './common'
import { isSafari, isWindows } from 'react-device-detect'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import ROUTES from 'src/router/routeDef';

const JUMP_LINE = '%0D%0A'
const BODY_ARG_LENGTH = 6 // => '&body='
const MAX_LENGTH_ALLOWED_ON_WINDOWS = 2000

export interface ShareFileOption {
  isMainDoc: boolean,
  isDefault: boolean,
  beingShared: boolean,
  isDistributable: boolean,
  contentProps: ContentShareProps
}

export interface ContentShareProps {
  contentId: string,
  sharedContentId?: string,
  title?: string,
  type: ShareType,
  customDeckDependencies?: CustomDeckDependencies,
}

export interface ExistingShareLink {
  title?: string,
  expiresAt: string,
  link: string,
  id: string,
}

/**
  * This function generates a shareable link and copies it to clipboard
  * @param shareFileOptions array of ShareFileOption
  * @param toast: toast actions
  * @param customValues: tenant custom values
  * @param optional meetingId, if a meeting id is attached to this link
  * @returns list of shareLinks
 */
async function generateAndCopyLinkToClipboard(
  shareFileOptions: ShareFileOption[],
  toast: ToastActions,
  customValues: CustomValues[],
  meetingId?: string,
  customDeckDependencies?: CustomDeckDependencies,
): Promise<ShareableLinkResult[]> {
  const shareableLinksList: Promise<ShareableLinkResult>[] = shareFileOptions.map((share: ShareFileOption) => {
    const { contentId, type, title } = share.contentProps
    return getShareableLink(contentId, type, title, customValues, meetingId, customDeckDependencies)
  })
  const result: ShareableLinkResult[] = await Promise.all(shareableLinksList)
  const clipboardText = result.reduce((acc, val) => {
    return `${acc}${val.title}${val.title ? '\n' : ''}${val.link}?expiresAt=${val.expiresAt} \n\n`
  }, '')
  copyToClipboard(toast, clipboardText)
  return result
}

/**
  * This function copies existing sharebale link to clipboard
  * @param toast: toast actions
  * @param existingShareLinks: list of existing links to copy to clipboard
  * @returns existing share links
 */
async function copyExistingLinkToClipboard(
  toast: ToastActions,
  existingShareLinks: ExistingShareLink[],
): Promise<ExistingShareLink[]> {
  const clipboardText = existingShareLinks.reduce((acc, val) => {
    return `${acc}${val.title ? val.title : ''}${val.title ? '\n' : ''}${val.link}?expiresAt=${val.expiresAt} \n\n`
  }, '')
  copyToClipboard(toast, clipboardText)
  return existingShareLinks
}

/**
 * this fuctions generates shareable links and opens the email client
 * @param shareFileOptions array of ShareFileOption
 * @param user current user
 * @param customValues tenant custom values
 * @param toast toast actions
 * @param meetingId optional if the links are attached to a meeting id
 * @returns list of shareable links
 */
async function shareEmailGenerateLink(
  shareFileOptions: ShareFileOption[],
  user: User,
  customValues: CustomValues[],
  toast: ToastActions,
  meetingId?: string,
): Promise<ShareableLinkResult[]> {
  const shares: ContentShareProps[] =
    shareFileOptions.map((shareFileOption: ShareFileOption): ContentShareProps => shareFileOption.contentProps);
  const shareableLinksList = shares.map(share => {
    const { title, contentId, type, customDeckDependencies } = share
    return getShareableLink(contentId, type, title, customValues, meetingId, customDeckDependencies)
  })
  const result = await Promise.all(shareableLinksList)

  const bodyText = result.reduce((acc, val) => {
    /* eslint-disable max-len */
    return `${acc}${encodeURIComponent(val.title)}${val.title ? JUMP_LINE : ''}${encodeURIComponent(val.link)}%20(link expires ${val.expiresAt})${JUMP_LINE + JUMP_LINE}`
  }, '')

  const cc = user.shareCC && user.shareCC.length > 0 ? encodeURIComponent(user.shareCC.join(',')) : '';
  const bcc = user.shareBCC && user.shareBCC.length > 0 ? encodeURIComponent(user.shareBCC.join(',')) : '';

  await sendEmail(cc, bcc, JUMP_LINE + bodyText, toast)
  return result
}

/**
 * this function opens the email client with existing links
 * @param existingShareLinks: list of existing links to copy to clipboard
 * @param user: current user
 * @param toast: toast actions
 * @returns existing share links
 */
async function shareEmailExistingLinks(
  existingShareLinks: ExistingShareLink[],
  user: User,
  toast: ToastActions,
): Promise<ExistingShareLink[]> {
  const bodyText = existingShareLinks.reduce((acc, val) => {
    /* eslint-disable max-len */
    return `${acc}${val.title ? encodeURIComponent(val.title) : ''}${val.title ? JUMP_LINE : ''}${encodeURIComponent(val.link)}%20(link expires ${val.expiresAt})${JUMP_LINE + JUMP_LINE}`
  }, '')
  const linkResult = existingShareLinks
  const cc = user.shareCC && user.shareCC.length > 0 ? encodeURIComponent(user.shareCC.join(',')) : '';
  const bcc = user.shareBCC && user.shareBCC.length > 0 ? encodeURIComponent(user.shareBCC.join(',')) : '';

  await sendEmail(cc, bcc, JUMP_LINE + bodyText, toast)
  return linkResult
}

/**
 * this function generates and copies email template to clipboard
 * @param toast toast actions
 * @param shareFileOptions array of ShareFileOption
 * @param attachmentsInfo attached files to email template (and attached files to the document??)
 * @param emailTemplate the email template to copy
 * @param customValues tenant custom values
 * @param meetingId optional if the links are attached to a meeting id
 * @returns list of shareable links
 */
async function generateLinksAndcopyEmailTemplateToClipboard(
  toast: ToastActions,
  shareFileOptions: ShareFileOption[],
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  meetingId?: string,
  customDeckDependencies?: CustomDeckDependencies,
): Promise<ShareableLinkResult[]> {
  const shares: ContentShareProps[] = shareFileOptions.map((shareFileOption: ShareFileOption): ContentShareProps => shareFileOption.contentProps);
  const toastWidth = 275

  const processor = (links: ShareableLinkResult[]) => links.reduce(
    (acc, val, idx) => {
      return (
        `${acc}${val.title}${val.title ? '\n' : ''}${val.link}?expiresAt=${val.expiresAt}` +
        `${idx !== links.length - 1 ? '\n\n' : ''}`
      )
    },
    '',
  )

  const bodyTextAndFileResults = await getEmailBodyAndFilesResultsWithTemplate(
    shares,
    attachmentsInfo,
    emailTemplate,
    processor,
    customValues,
    meetingId,
    customDeckDependencies,
  )

  const handleCopyLink = () => {
    navigator.clipboard.writeText(bodyTextAndFileResults.bodyText).then(() => {
      toast.add(<GenericToast
        title="Email copied."
        status="success"
        width={toastWidth}
      />, ToastOrientations.TOP_RIGHT, true, 3000)
    }, () => {
      toast.add(
        <GenericToast title="Unable to access clipboard." width={toastWidth} status="error" />,
        ToastOrientations.TOP_RIGHT,
      )
    })
  }

  navigator.clipboard.writeText(bodyTextAndFileResults.bodyText).then(
    () => {
      toast.add(
        <GenericToast
          title="Email copied."
          status="success"
          width={150}
        />, ToastOrientations.TOP_RIGHT, true, 3000)
      return undefined
    },
    () => {
      toast.add(
        <GenericToast
          title="Email generated"
          width={toastWidth}
          status="information"
          actionConfig={{ actionTitle: 'Copy email', callback: handleCopyLink }}
        />,
        ToastOrientations.TOP_RIGHT, false, 0);
      return undefined
    },
  )
  return bodyTextAndFileResults.filesResults
}

/**
 * this function copies an email template to clipboard with existing links
 * @param toast toast actions
 * @param attachmentsInfo attached files to email template (and attached files to the document??)
 * @param customValues tenant custom values
 * @param existingShareLinks list of existing shareable links
 * @param emailTemplate the email template to copy
 * @param meetingId optional if the links are attached to a meeting id
 * @returns list of existing sharable links
 */
async function copyExistingEmailTemplateToClipboard(
  toast: ToastActions,
  attachmentsInfo: ContentShareProps[],
  customValues: CustomValues[],
  existingShareLinks: ExistingShareLink[],
  emailTemplate: EmailTemplate,
  meetingId?: string,
): Promise<ExistingShareLink[]> {
  const processor = (links: ExistingShareLink[]) => links.reduce(
    (acc, val, idx) => {
      return (
        `${acc}${val.title ? val.title : ''}${val.title ? '\n' : ''}${val.link}?expiresAt=${val.expiresAt}` +
        `${idx !== links.length - 1 ? '\n\n' : ''}`
      )
    },
    '',
  )

  const bodyTextAndFileResults = await copyExistingEmailBodyAndFileWithTemplate(
    attachmentsInfo,
    customValues,
    processor,
    existingShareLinks,
    emailTemplate,
    meetingId,
  )

  copyToClipboard(toast, bodyTextAndFileResults.bodyText)
  return bodyTextAndFileResults.filesResults
}

/**
 * this function generates links and opens email client
 * @param shareFileOptions array of ShareFileOption
 * @param attachmentsInfo attached files to email template (and attached files to the document??)
 * @param emailTemplate the email template to copy
 * @param customValues tenant custom values
 * @param toast toast actions
 * @param meetingId optional if the links are attached to a meeting id
 * @returns list of shareable link
 */
async function shareEmailTemplateGenerateLinks(
  shareFileOptions: ShareFileOption[],
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  toast: ToastActions,
  meetingId?: string,
  customDeckDependencies?: CustomDeckDependencies,
): Promise<ShareableLinkResult[]> {
  const splitSeparator = isWindows ? ';' : ',';

  const shares: ContentShareProps[] = shareFileOptions.map((shareFileOption: ShareFileOption): ContentShareProps => shareFileOption.contentProps);

  const processor = (links: ShareableLinkResult[]) => links.reduce((acc, val, idx) => {
    return `${acc}${encodeURIComponent(val.title)}${val.title ? JUMP_LINE : ''}` +
      `${encodeURIComponent(val.link)}%20(link expires ${val.expiresAt})` +
      `${idx !== links.length - 1 ? JUMP_LINE + JUMP_LINE : ''}`
  }, '')

  const emailBodyAndFilesResults = await getEmailBodyAndFilesResultsWithTemplate(
    shares,
    attachmentsInfo,
    emailTemplate,
    processor,
    customValues,
    meetingId,
    customDeckDependencies,
  )
  const bodyTextToCopy = emailBodyAndFilesResults.bodyText.replace(/\n/g, JUMP_LINE);
  const cc = emailTemplate.cc && emailTemplate.cc.length > 0 ? encodeURIComponent(emailTemplate.cc.join(splitSeparator)) : '';
  const bcc = emailTemplate.bcc && emailTemplate.bcc.length > 0 ? encodeURIComponent(emailTemplate.bcc.join(splitSeparator)) : '';

  await sendEmail(cc, bcc, bodyTextToCopy, toast, emailTemplate.subject)
  return emailBodyAndFilesResults.filesResults
}

/**
 * This function opens email client with existing links
 * @param toast toast actions
 * @param attachmentsInfo attached files to email template (and attached files to the document??)
 * @param customValues tenant custom values
 * @param existingShareLinks list of existing shareable links
 * @param emailTemplate the email template to copy
 * @param meetingId optional if the links are attached to a meeting id
 * @returns list of existing sharable links
 */
async function shareEmailTemplateCopyExistingLinks(
  toast: ToastActions,
  attachmentsInfo: ContentShareProps[],
  customValues: CustomValues[],
  existingShareLinks: ExistingShareLink[],
  emailTemplate: EmailTemplate,
  meetingId?: string,
) {
  const splitSeparator = isWindows ? ';' : ',';
  const processor = (links: ExistingShareLink[]) => links.reduce((acc, val, idx) => {
    return `${acc}${val.title ? encodeURIComponent(val.title) : ''}${val.title ? JUMP_LINE : ''}` +
      `${encodeURIComponent(val.link)}%20(link expires ${val.expiresAt})` +
      `${idx !== links.length - 1 ? JUMP_LINE + JUMP_LINE : ''}`
  }, '')

  const bodyTextAndFileResults = await copyExistingEmailBodyAndFileWithTemplate(
    attachmentsInfo,
    customValues,
    processor,
    existingShareLinks,
    emailTemplate,
    meetingId,
  )
  const bodyTextToCopy = bodyTextAndFileResults.bodyText.replace(/\n/g, JUMP_LINE);
  const cc = emailTemplate.cc && emailTemplate.cc.length > 0 ? encodeURIComponent(emailTemplate.cc.join(splitSeparator)) : '';
  const bcc = emailTemplate.bcc && emailTemplate.bcc.length > 0 ? encodeURIComponent(emailTemplate.bcc.join(splitSeparator)) : '';

  await sendEmail(cc, bcc, bodyTextToCopy, toast, emailTemplate.subject)
  return bodyTextAndFileResults.filesResults
}

/**
 * this function copies email template and file with existing share links
 * @param attachments list attached documents
 * @param customValues tenant custom values
 * @param processor function that returns the shareable links as string
 * @param existingShareLinks existing shareable links
 * @param emailTemplate the email template to copy
 * @param meetingId optional if the links are attached to a meeting id
 * @returns email body text and list of existing share links
 */
async function copyExistingEmailBodyAndFileWithTemplate(
  attachments: ContentShareProps[],
  customValues: CustomValues[],
  processor: (links: ExistingShareLink[]) => string,
  existingShareLinks: ExistingShareLink[],
  emailTemplate: EmailTemplate,
  meetingId?: string,
): Promise<{bodyText: string, filesResults: ExistingShareLink[]}> {
  const attachmentsLinksList = attachments.map(share => {
    const { title, contentId, type } = share
    return getShareableLink(contentId, type, title, customValues, meetingId)
  })
  const attachmentsResults = await Promise.all(attachmentsLinksList)
  const fileLinks = processor(existingShareLinks)
  const attachmentLinks = processor(attachmentsResults)

  const bodyText = getBodyText(emailTemplate, fileLinks, attachmentLinks)
  return { bodyText, filesResults: existingShareLinks };
}

/**
 * function that generates links and copies the email template to clipboard
 * @param shareFileOptions array of ShareFileOption
 * @param attachments list attached documents
 * @param emailTemplate the email template to copy
 * @param processor function that returns the shareable links as string
 * @param customValues tenant custom values
 * @param meetingId optional if the links are attached to a meeting id
 * @returns email body text and list of shareable links
 */
async function getEmailBodyAndFilesResultsWithTemplate(
  shareFileOptions: ContentShareProps[],
  attachments: ContentShareProps[],
  emailTemplate: EmailTemplate,
  processor: (links: ShareableLinkResult[]) => string,
  customValues: CustomValues[],
  meetingId?: string,
  customDeckDependencies?: CustomDeckDependencies,
): Promise<{bodyText: string, filesResults: ShareableLinkResult[]}> {
  const shareableLinksList = shareFileOptions.map(share => {
    const { title, contentId, type } = share
    return getShareableLink(contentId, type, title, customValues, meetingId, customDeckDependencies)
  })
  const filesResults = await Promise.all(shareableLinksList)

  const attachmentsLinksList = attachments.map(share => {
    const { title, contentId, type } = share
    return getShareableLink(contentId, type, title, customValues, meetingId, customDeckDependencies)
  })
  const attachmentsResults = await Promise.all(attachmentsLinksList)
  const fileLinks = processor(filesResults)
  const attachmentLinks = processor(attachmentsResults)

  const bodyText = getBodyText(emailTemplate, fileLinks, attachmentLinks)
  return { bodyText, filesResults };
}

const checkIfShouldAddNewlines = (body: string, placeholder: string) => {
  const bodyLines = body.split('\n');
  const lineWithPlaceholder = bodyLines.find(l => l.indexOf(placeholder) > -1);
  let shouldAddNewLines = false;
  if (lineWithPlaceholder && !lineWithPlaceholder.trim().startsWith(placeholder)) {
    shouldAddNewLines = true;
  }

  return shouldAddNewLines;
}

async function emailTemplatePreview(
  toast: ToastActions,
  subject?: string,
  body?: string,
  cc?: string[],
  bcc?: string[],
  attachments?: ContentShareProps[]): Promise<void> {
  const splitSeparator = isWindows ? ';' : ',';
  const formattedBCC = (bcc?.length && encodeURIComponent(bcc.join(splitSeparator))) || '';
  const formattedCC = (cc?.length && encodeURIComponent(cc.join(splitSeparator))) || '';

  if (body && attachments) {
    const expiresAt = format(addDays(new Date(), 30), 'yyyy-MM-dd')
    const links = attachments.reduce((acc, val) => {
      /* eslint-disable max-len */
      return `${acc}${encodeURIComponent(val.title ?? '')}${val.title ? JUMP_LINE : ''}[Document link]%20(link expires ${expiresAt})${JUMP_LINE + JUMP_LINE}`
    }, '')

    body = body.replace('[Attachments]', checkIfShouldAddNewlines(body, '[Attachments]') ? `\n${links}\n` : links);
  }
  body = body ? body.replace(/\n/g, JUMP_LINE) : '';

  await sendEmail(formattedCC, formattedBCC, body, toast, subject);
}

/**
 * this function opens the email client
 * @param cc email cc
 * @param bcc email bcc
 * @param body content of the email
 * @param toast toast actions
 * @param subject email subject
 */
const sendEmail = async (cc: string, bcc: string, body: string, toast: ToastActions, subject?: string) => {
  let hasMultipleArg = false
  let mailto = 'mailto:?'

  const buildArg = (field: string, value?: string) => {
    let arg = ''

    if (value) {
      if (hasMultipleArg) {
        arg += '&'
      } else {
        hasMultipleArg = !hasMultipleArg
      }
      arg += `${field}=${value}`
    }

    return arg
  }

  mailto += buildArg('cc', cc)
  mailto += buildArg('bcc', bcc)
  mailto += buildArg('subject', subject)

  mailto += buildArg('body', body)
  if (!isSafari) {
    if (isWindows && excedesWindowsLenghtLimit(mailto, body)) {
      await navigator.clipboard.writeText(decodeURIComponent(body))
      body = 'Paste the message from your clipboard here. (Ctrl-V)'
    }
    const mail = document.createElement('a');
    mail.href = mailto;
    mail.setAttribute('data-testid', 'emulated-email-client')
    mail.target = '_blank';
    mail.click();
  } else {
    // On safari due to privacy restrictions it does not allow us to auto-click a generated
    // mailto link from an async method
    await (new Promise<void>((resolve) => {
      let emailGeneratedToastId = '';
      const handleOpenEmailClick = () => {
        const mail = document.createElement('a');
        mail.href = mailto;
        mail.setAttribute('data-testid', 'emulated-email-client')
        mail.target = '_blank';
        mail.click();
        toast.remove(emailGeneratedToastId)
        resolve()
      }

      emailGeneratedToastId = toast.add(
        <GenericToast
          title="Email generated"
          width={275}
          status="information"
          actionConfig={{ actionTitle: 'Open email', callback: handleOpenEmailClick }}
        />,
        ToastOrientations.TOP_RIGHT, false, 0);
    }))
  }
}

/**
  * This function copies a text to clipboard
  * @param toast: toast actions
  * @param clipboardText: string to copy to clipboard
  * @param successTitle: title to show on success toast defaults to 'Link copied, expires in 30 days.'
 */
async function copyToClipboard(
  toast: ToastActions,
  clipboardText: string,
  successTitle: string = 'Link copied, expires in 30 days.',
) {
  const toastWidth = 275
  const generatingToastId = toast.add(<GenericToast
    title="Generating Link"
    status="loading"
    width={toastWidth}
  />, ToastOrientations.TOP_RIGHT)

  const linkCopiedToast = (<GenericToast
    title={successTitle}
    status="success"
    width={toastWidth}
  />)

  navigator.clipboard.writeText(clipboardText).then(
    () => {
      toast.remove(generatingToastId)
      toast.add(linkCopiedToast, ToastOrientations.TOP_RIGHT);
    },
    () => {
      toast.remove(generatingToastId);
      let copyToastId = '';
      const handleCopyLink = () => {
        navigator.clipboard.writeText(clipboardText).then(() => {
          toast.remove(copyToastId);
          toast.add(linkCopiedToast, ToastOrientations.TOP_RIGHT);
        }, () => {
          toast.remove(copyToastId);
          toast.add(
            <GenericToast title="Unable to access clipboard." width={toastWidth} status="error" />,
            ToastOrientations.TOP_RIGHT,
          )
        })
      }
      copyToastId = toast.add(
        <GenericToast
          title="Link generated."
          width={toastWidth}
          status="information"
          actionConfig={{ actionTitle: 'Copy link', callback: handleCopyLink }}
        />,
        ToastOrientations.TOP_RIGHT, false, 0);
    },
  )
}

/**
  * This function copies the document page url to clipboard
  * @param toast: toast actions
  * @param documentId: document id
  * @returns document page url
*/
const copyDocPageUrlHandler = async (
  toast: ToastActions,
  documentId: string) => {
  const path = ROUTES.BEACON_COPY_LINK
    .PATH.replace(':docId?', documentId)
    .replace(':page?', '1')

  await copyToClipboard(toast, `${window.location.origin}${path}`, 'Link copied')

  analytics?.track('SHARE_PUBLISHER_COPY_LINK', {
    action: 'COPY_LINK',
    category: 'DOCUMENT',
    documentId,
  })
}

/**
 * this function returns the body text given a template and list of links
 * @param emailTemplate the email template to copy
 * @param fileLinks document or hub share link
 * @param attachmentLinks attached document share link
 * @returns email body text
 */
function getBodyText(
  emailTemplate: EmailTemplate,
  fileLinks: string,
  attachmentLinks: string,
): string {
  let bodyText = emailTemplate.body!.replace('[Files]', checkIfShouldAddNewlines(emailTemplate.body!, '[Files]') ? `\n${fileLinks}\n` : fileLinks);
  bodyText = bodyText.replace('[Attachments]', checkIfShouldAddNewlines(emailTemplate.body!, '[Attachments]') ? `\n${attachmentLinks}\n` : attachmentLinks);
  return bodyText
}

const excedesWindowsLenghtLimit = (mailto: string, body: string) => mailto.length + body.length + BODY_ARG_LENGTH > MAX_LENGTH_ALLOWED_ON_WINDOWS;

export {
  generateAndCopyLinkToClipboard,
  copyExistingLinkToClipboard,
  copyDocPageUrlHandler,
  shareEmailGenerateLink,
  shareEmailExistingLinks,
  generateLinksAndcopyEmailTemplateToClipboard,
  copyExistingEmailTemplateToClipboard,
  shareEmailTemplateGenerateLinks,
  shareEmailTemplateCopyExistingLinks,
  emailTemplatePreview,
}
