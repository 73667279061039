import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  DNABox,
  DNADivider,
  DNAText,
  InformationMessage,
  Iffy,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { DocumentVersionORM } from 'src/types/orms';
import { GroupStatus, usePresentationBuilderState }
  from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider';

const styles = StyleSheet.create({
  container: {
    paddingTop: 24,
    paddingHorizontal: 24,
    backgroundColor: colors['color-gray-10'],
  },
});

type InformationRowProps = {
  title?: string,
  unresolvedCount: number,
  latestPublishedDocumentVersionORM?: DocumentVersionORM,
}

const InformationRow: React.FC<InformationRowProps> = (props) => {
  const { title, unresolvedCount, latestPublishedDocumentVersionORM } = props;
  const [isInfoHidden, setIsInfoHidden] = useState(true);

  return (
    <InformationMessage.Collapsible.Item
      key={title}
      text={
        <DNABox fill appearance="col" spacing="sm">
          <DNAText status="warning" numberOfLines={5}>
            <DNAText status="warning" bold>{`${title} `}</DNAText>
            was recently modified - {unresolvedCount} item(s) need to be resolved
          </DNAText>
          {!isInfoHidden &&
            <DNAText status="warning">
              {latestPublishedDocumentVersionORM?.model.releaseNotes}
            </DNAText>
          }
        </DNABox>
      }
      actionButtonFunction={() => setIsInfoHidden(p => !p)}
      actionButtonText={isInfoHidden ? 'View release notes' : 'Hide notes'}
    />
  )
};

const InformationBanner: React.FC = () => {
  const {
    selectedGroups,
    setShowUnavailableMessage,
    setDisablePreivew,
    showUnavailableMessage,
  } = usePresentationBuilderState();

  const unresolvedArr = useMemo(() => {
    const resMap = new Map<string, { documentVersionORM: DocumentVersionORM, unresolvedCount: number }>();
    let disablePrev = false;
    let unavailable = false;
    const values = [GroupStatus.DELETED, GroupStatus.REVOKED, GroupStatus.ARCHIVED];
    unavailable = selectedGroups.some(({ groupStatus }) => {
      return values.includes(groupStatus);
    });
    selectedGroups.forEach(({ documentVersionORM, groupStatus }) => {
      if (documentVersionORM && groupStatus === GroupStatus.MAJOR_UPDATE) {
        const id = documentVersionORM.model.id;
        const unresolvedCount = (resMap.get(id)?.unresolvedCount ?? 0) + 1;
        resMap.set(id, {
          documentVersionORM,
          unresolvedCount,
        });
        disablePrev = true;
      }
    });
    disablePrev = unavailable || disablePrev || !selectedGroups.some(({ visible }) => visible);
    setShowUnavailableMessage(unavailable);
    setDisablePreivew(disablePrev);
    return Array.from(resMap.values());
  }, [selectedGroups]);

  const unresolvedSlideCount = useMemo(() => {
    return unresolvedArr.reduce((accumulator, currVal) => accumulator + currVal.unresolvedCount, 0);
  }, [unresolvedArr]);

  const renderItems = useCallback(() => {
    return unresolvedArr.map(({
      documentVersionORM:{
        model:{ title },
        relations: { documentORM: { relations: { version: { latestPublishedDocumentVersionORM } } } },
      },
      unresolvedCount,
    }, index) => {
      const isLastItem = index === unresolvedArr.length - 1;
      return (
        <Fragment key={title}>
          <InformationRow
            title={title}
            unresolvedCount={unresolvedCount}
            latestPublishedDocumentVersionORM={latestPublishedDocumentVersionORM}
          />
          {!isLastItem && <DNADivider style={{ backgroundColor: colors['color-warning-20'] }}/>}
        </Fragment>
      )
    })
  }, [unresolvedArr])

  if (!unresolvedSlideCount && !showUnavailableMessage) return null;
  return (
    <DNABox appearance="col" style={styles.container} spacing="md">
      <Iffy is={showUnavailableMessage}>
        <InformationMessage
          text="If a slide is unavailable, it may have been deleted or you may not have access."
          numberOfLines={1}
          variance="warning"
        />
      </Iffy>
      <Iffy is={unresolvedSlideCount}>
        <InformationMessage.Collapsible
          variance="warning"
          collapsedDefault
        >
          <InformationMessage.Collapsible.Parent
            text={`${unresolvedSlideCount} slides were recently modified and need your review`}
          />
          <InformationMessage.Collapsible.Items>
            {renderItems()}
          </InformationMessage.Collapsible.Items>
        </InformationMessage.Collapsible>
      </Iffy>
    </DNABox>
  )
};

export default InformationBanner;
