import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNACard, DNADivider, DNAText, Iffy, Tabs } from '@alucio/lux-ui';
import { ReportProvider, useReport } from 'src/components/Reports/ReportProvider';
import { ReportSelector } from 'src/components/Reports/ReportSelector';
import { EmbeddedReport } from 'src/components/Reports/EmbeddedReport';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';

const styles = StyleSheet.create({
  mainWrapper: {
    paddingHorizontal: 48,
    paddingVertical: 20,
  },
});

const viewerFolder = 'Beacon_Embedded_Viewer'
const FIGMA_ANALYTICS_DEMO_URL = 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com' +
    '%2Fproto%2FEuo76my4iCPmlWBh5kpcyp%2FAnalytics-%255BViewer%252C-Publisher%252C-Reports%255D%3Fpage-id%3D14%253A14' +
    '%26hide-ui=1%26node-id%3D753%253A15820%26viewport%3D2761%252C648%252C1%26scaling%3Dmin-zoom' +
    '%26starting-point-node-id%3D752%253A11272';

const ViewerTabs = () => {
  const { onDashboardChange, setIsTeamViewer, isTeamLeader } = useReport();
  const tabs =  isTeamLeader ? [{ title: 'My Reports' }, { title: 'Team Reports' }]
    : [{ title: 'My Reports' }];
  const [selectedTab, setSelectedTab] = useState(tabs[0].title);
  return (
    <DNABox appearance="col">
      <Iffy is={isTeamLeader}>
        <Tabs
          selectedTab={selectedTab}
          selectedTabVariant="primary"
          tabs={tabs}
          onSelectTab={(tab) => {
            const isTeamViewer = tab === 'Team Reports';
            setSelectedTab(tab);
            setIsTeamViewer(isTeamViewer);
            onDashboardChange(isTeamViewer);
          }}
        />
        <DNADivider />
      </Iffy>
    </DNABox>
  );
};

const ReportsViewer = () => {
  const enableDemoMode = useFeatureFlags('enableDemoMode');

  return (

    <ReportProvider folderName={viewerFolder}>
      <DNABox
        spacing="md"
        style={styles.mainWrapper}
        appearance="col"
        fill
      >
        <Iffy is={!enableDemoMode}>
          { /* HEADER */}
          <DNABox alignY="center" spacing="between">
            <DNAText testID="page-title" h5 status="secondary">Analytics</DNAText>
          </DNABox>
          <DNADivider />
          <DNACard appearance="outline" style={{ padding: 12 }}>
            <DNABox appearance="col" spacing="md" >
              <ViewerTabs />
              <ReportSelector />
              <DNABox fill>
                <EmbeddedReport />
              </DNABox>
            </DNABox>
          </DNACard>

        </Iffy>
        <Iffy is={enableDemoMode}>
          <DNABox fill>
            <iframe
              style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
              width="1240"
              height="857"
              src={FIGMA_ANALYTICS_DEMO_URL}
              allowFullScreen
            />
          </DNABox>
        </Iffy>
      </DNABox>
    </ReportProvider >
  )
}

ReportsViewer.displayName = 'ReportsViewer';

export default ReportsViewer
