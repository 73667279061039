import React, { useMemo, useContext, createContext, useRef, PropsWithChildren } from 'react';
import { StateFrom, InterpreterFrom } from 'xstate'
import { useMachine } from '@xstate/react'

import { DNABox, DNAText, DNAButton } from '@alucio/lux-ui'
import updateVersionMachine, {
  StateMeta,
  StateTags,
  MetaValues,
} from 'src/state/machines/versioning/updateVersionRedesign'
import { DocumentORM, DocumentVersionORM } from 'src/types/types'
import { ConversionStatus, ConversionWarningCode, FileType } from '@alucio/aws-beacon-amplify/src/models';
import Error from 'src/components/DNA/Error'
import { getPresentable } from 'src/state/context/ContentProvider/helper';
import useContentPageData, { ContentPageData } from 'src/hooks/useContentPageData/useContentPageData';
import { MAX_OFFLINE_FILE_SIZE_BYTES } from 'src/worker/machines/sync/syncEntry';

// import { inspect } from '@xstate/inspect';
// inspect({ iframe: false });

enum ConditionalEnum {
  hasOptimizedFinishedThisSession,
  hasInfoMessage,
  isCriticalError,
  isCancellingNewVersionUpload,
  isUploadingNewVersion,
  isPublishedViewMode,
  isScheduledViewMode,
  isProcessingNewVersion,
  isUpdatingThumbnail,
  isInDraftingState,
  isDraftDirty,
  isCreatingFromExisting,
  canCreateFromExisting,
  canCreateFromUpload,
  canCreateNewVersion,
  canPublish,
  exceedsMaxOfflineSize,
  isPasswordProtected,
  isNavBlocked,
  hasProcessingError,
  isModifying,
  isDocumentPublished,
  isExitBlocked,
  isPublishing,

  isWebDoc,
  isVideo,
  isHTMLDoc,
  isNonDocumentFile,
}

type StateConditions = { [K in keyof typeof ConditionalEnum]: boolean }

type VersioningMachine = ReturnType<typeof updateVersionMachine>

/**
 * Context
 */
type VersioningPanelContextValue = {
  // Is there a better way to infer this? ReturnType<typeof useMachine<YourGenericsHere?>>
  state: StateFrom<VersioningMachine>,
  meta: Partial<MetaValues>,
  send: InterpreterFrom<VersioningMachine>['send'],
  currentDocumentVersionORM: DocumentVersionORM,
  latestPublishedDocumentVersionORM?: DocumentVersionORM,
  documentORM: DocumentORM,
  documentVersionORM: DocumentVersionORM,
  toggleSlider: (onInvisCb?: () => void) => void,
  cond: StateConditions,
  contentPageData: ContentPageData[] | undefined,
}
const VersioningPanelContext = createContext<VersioningPanelContextValue>(null!)

const VersioningPanelProvider: React.FC<PropsWithChildren<{
  documentORM: DocumentORM,
  toggleSlider: () => void,
  initialDocumentId?: string,
}>> = (props) => {
  const { toggleSlider, documentORM, children } = props
  const { latestDocumentVersionORM } = documentORM.relations.version
  const machine = useRef(updateVersionMachine(latestDocumentVersionORM.model)).current

  const [state, send] = useMachine(machine)

  const meta = Object
    .values<Record<string, StateMeta>>(state.meta)
    .reduce<Partial<MetaValues>>((acc, meta) => ({ ...acc, ...meta }), {})

  const currentDocumentVersionORM = useMemo(
    () => {
      const targetVersionORM = documentORM
        .relations
        .documentVersions
        .find(docVerORM => docVerORM.model.id === state.context.documentVersionId)

      // [TODO-2126] - (BUG) There's a good chance that a delete will affect other publishers (concurrently)
      //             - The other windows record will auotmagically be removed from Redux causing another window
      //               to crash
      //             - Think it's okay to let it crash as is while we figure out a better fix

      return (
        targetVersionORM ??
        documentORM.relations.version.latestPublishedDocumentVersionORM
      )
    },
    [documentORM, state.context.documentVersionId],
  )

  const latestPublishedVersion = documentORM.relations.version.latestPublishedDocumentVersionORM

  const hasOptimizedFinishedThisSession = state.context.hasOptimizedFinishedThisSession
  const hasInfoMessage = !!meta.infoMessage
  const isUploadingNewVersion = state.matches('draft.documentInfo.processing.upload.uploading')
  const isPublishedViewMode = state.matches('published')
  const isScheduledViewMode = state.matches('schedule')
  const isInDraftingState = !(isPublishedViewMode || isScheduledViewMode)

  // [TODO-2126] - We should centralize the interface for critical errors
  //               Whether it's tags/state nodes/etc
  const isCriticalError = currentDocumentVersionORM?.model.conversionStatus === ConversionStatus.ERROR
  const presentable = getPresentable(documentORM
    .relations
    .documentVersions
    .find(docVerORM => docVerORM.model.id === state.context.documentVersionId) ?? latestPublishedVersion);
  const { contentPageData } = useContentPageData(presentable, latestDocumentVersionORM?.model.status !== 'PUBLISHED');
  const isProcessingNewVersion = state.matches('draft.documentInfo.processing')
  const isUpdatingThumbnail = state.matches('draft.documentInfo.uploadThumbnail')
  const isCreatingFromExisting = state.matches('draft.documentInfo.processing.existing.optimizing')
  const canCreateFromExisting = state.can({ type: 'CREATE_FROM_EXISTING' })
  // @ts-ignore - We don't need to have a valid File Payload to do this check
  const canCreateFromUpload = state.can({ type: 'CREATE_FROM_UPLOAD', payload: { file: undefined } })
  const canCreateNewVersion = canCreateFromExisting || canCreateFromUpload
  const isCancellingNewVersionUpload = state.context.cancelUpload
  const canPublish = state.can({ type: 'PUBLISH_VERSION', payload: {} })

  // [TODO-2126] - Conslidate our forms at the component/tab level and thus our dirty flags
  const isDraftDirty = (
    state.context.documentInfoIsDirty ||
    state.context.documentSettingsIsDirty ||
    state.context.documentPublishIsDirty ||
    state.context.documentSlidesDataIsDirty
  )

  const isExitBlocked = state.hasTag(StateTags.DISABLE_EXIT)
  const isNavBlocked = state.hasTag(StateTags.DISABLE_NAV)
  const hasProcessingError = state.hasTag(StateTags.PROCESSING_ERROR)
  const isModifying = state.hasTag(StateTags.DISABLE_MODIFY)
  const isDocumentPublished = documentORM.model.status === 'PUBLISHED'
  const isPublishing = state.matches('draft.documentPublish.publishing') ||
    state.matches('draft.documentPublish.setPublishStatus')
  const isWebDoc = currentDocumentVersionORM?.model.type === FileType.WEB
  const isVideo = currentDocumentVersionORM?.model.type === FileType.MP4
  const isHTMLDoc = currentDocumentVersionORM?.model.type === FileType.HTML
  const isNonDocumentFile = isWebDoc || isVideo || isHTMLDoc
  const exceedsMaxOfflineSize = !!currentDocumentVersionORM?.model.convertedArchiveSize &&
    currentDocumentVersionORM?.model.convertedArchiveSize > MAX_OFFLINE_FILE_SIZE_BYTES

  const isPasswordProtected = currentDocumentVersionORM?.model.conversionWarningCode ===
    ConversionWarningCode.PASSWORD_PROTECTED

  const cond: StateConditions = {
    hasOptimizedFinishedThisSession,
    hasInfoMessage,
    isCriticalError,
    isCancellingNewVersionUpload,
    isUploadingNewVersion,
    isPublishedViewMode,
    isScheduledViewMode,
    isProcessingNewVersion,
    isUpdatingThumbnail,
    isInDraftingState,
    isDraftDirty,
    isCreatingFromExisting,
    canCreateFromExisting,
    canCreateFromUpload,
    canCreateNewVersion,
    canPublish,
    exceedsMaxOfflineSize,
    isPasswordProtected,
    isNavBlocked,
    hasProcessingError,
    isModifying,
    isDocumentPublished,
    isExitBlocked,
    isPublishing,

    isWebDoc,
    isVideo,
    isHTMLDoc,
    isNonDocumentFile,
  }

  // [NOTE] - This would happen if we have a Document with no child versions
  if (!currentDocumentVersionORM) {
    return (
      <DNABox
        fill
        appearance="col"
        alignX="center"
        alignY="center"
        style={{ backgroundColor: 'white' }}
      >
        <Error
          promptLogout={false}
          message="Could not open Document for versioning!"
        >
          <DNAText>{documentORM.model.id}</DNAText>
          <DNAButton
            appearance="ghost"
            onPress={toggleSlider}
          >
            Go back
          </DNAButton>
        </Error>
      </DNABox>
    )
  }

  return (
    <VersioningPanelContext.Provider
      value={{
        documentORM,
        latestPublishedDocumentVersionORM: latestPublishedVersion,
        currentDocumentVersionORM: currentDocumentVersionORM,
        documentVersionORM: latestDocumentVersionORM,
        send,
        state,
        meta,
        toggleSlider,
        cond,
        contentPageData,
      }}
    >
      {children}
    </VersioningPanelContext.Provider>
  )
}

export const useVersioningPanel = () => useContext(VersioningPanelContext)
export default VersioningPanelProvider
