import React from 'react';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import MeetingTable from 'src/components/Meeting/MeetingTable';
import { useMeetingList } from 'src/state/redux/selector/meeting';
import orderBy from 'lodash/orderBy'
import { MeetingORM } from 'src/types/orms';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import {
  DNABox,
  DNADivider, DNAText, Iffy, DNAButton, DNAHeader,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useDispatch } from 'src/state/redux';
import { drawerActions, DRAWER_ENTITIES } from 'src/state/redux/slice/drawer';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { StyleSheet } from 'react-native';

export const isNotDeleted = (item: unknown) : item is MeetingORM => item
  ? (item as MeetingORM).model.status !== 'DELETED'
  : false

const S = StyleSheet.create({
  header: {
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    padding: 0,
    shadowOpacity: 0,
  },
});

const useDNAMeetingsSharedComponents = () => {
  const meetingList = useMeetingList();
  const dispatch = useDispatch();
  const enableAddNewMeeting = useFeatureFlags('enableAddNewMeeting');

  const visibleMeetings = meetingList
    .filter(isNotDeleted)

  /** TODO: perhaps we should look into why these are being stored in a seemingly random
   * order on the backend should performance become an issue in the future. Additionally,
   * pagination might need to be implemented here if the list gets too large */
  const sortedMeetings = orderBy(visibleMeetings, ['model.startTime'], ['desc'])

  const onAddMeeting = (): void => {
    dispatch(drawerActions.toggle({
      entity: DRAWER_ENTITIES.MEETING,
    }));
  };
  return {
    sortedMeetings,
    onAddMeeting,
    enableAddNewMeeting,
  }
}

const DNAMeetingsHistoryTablet:React.FC = () => {
  const { sortedMeetings, onAddMeeting, enableAddNewMeeting } = useDNAMeetingsSharedComponents()
  return (
    <DNABox appearance="col" spacing="between">
      <Iffy is={enableAddNewMeeting}>
        <DNAHeader status="flat" size="medium" style={S.header} >
          <DNAHeader.Right>
            <DNAButton
              size="lg"
              status="tertiary"
              appearance="outline"
              padding="sm"
              iconLeft="plus"
              onPress={onAddMeeting}
            >
              Create meeting
            </DNAButton>
          </DNAHeader.Right>
        </DNAHeader>
      </Iffy>
      <DNABox
        fill
        style={{
          backgroundColor: colors['color-text-white'],
          overflow:'scroll',
        }}
      >

        {/* TODO: Adjust this width properly after discussing expected behavior with Thai & Kenny */}
        <DNABox fill style={{ minWidth: 1372 }}>
          { sortedMeetings.length

          // Table
            ? <MeetingTable meetingORM={sortedMeetings}/>

          // Empty State
            : <DNAEmpty
                useContainer={false}
                emptyVariant={EmptyVariant.MeetingsHistoryEmpty}
            />
      }
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

const DNAMeetingsHistoryDesktop:React.FC = () => {
  const { sortedMeetings, onAddMeeting, enableAddNewMeeting } = useDNAMeetingsSharedComponents()

  return (
    <DNABox
      style={{ minWidth: 1160 }}
      alignX="center"
      fill
    >
      <DNABox
        fill
        style={{
          paddingHorizontal: 48,
          paddingVertical: 20,
        }}
        alignX="center"
      >
        <DNABox fill spacing="md" appearance="col" testID="page-container">
          {/* Header */}
          <DNABox alignY="center" spacing="between">
            <DNAText testID="page-title" h5 status="secondary">Meeting History</DNAText>
            <Iffy is={enableAddNewMeeting}>
              <DNAButton
                appearance="outline"
                status="secondary"
                size="md"
                onPress={onAddMeeting}
                iconLeft="plus"
                testID="create-meeting-button"
              >
                Create meeting
              </DNAButton>
            </Iffy>
          </DNABox>
          <DNADivider />
          {/* Empty */}
          <Iffy is={!sortedMeetings.length}>
            <DNAEmpty
              useContainer={false}
              emptyVariant={EmptyVariant.MeetingsHistoryEmpty}
            />
          </Iffy>
          {/* Table */}
          <Iffy is={sortedMeetings.length}>
            <MeetingTable meetingORM={sortedMeetings}/>
          </Iffy>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

type DNAMeetingsHistoryVariants = Record<DeviceMode, React.ElementType>
const dnaMeetingsHistoryVariants:DNAMeetingsHistoryVariants = {
  desktop: DNAMeetingsHistoryDesktop,
  tablet: DNAMeetingsHistoryTablet,
}

const DNAMeetingsHistory:React.FC = () => {
  const { deviceMode } = useAppSettings()
  const CurrentVariant = dnaMeetingsHistoryVariants[deviceMode]

  return <CurrentVariant/>
}

export default DNAMeetingsHistory
