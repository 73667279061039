import { LDFlagValue } from 'launchdarkly-js-sdk-common'

export interface FeatureFlag {
  flag: string,
  defaultValue: LDFlagValue
}

// [NOTE] - Please add the `as` assertion to `defaultValue` so we can cast
//          the type correctly
export const FeatureFlags = {
  test: {
    flag: 'fakeFlag',
    defaultValue: 1 as number,
  },
  enableEmailTemplates: {
    flag: 'BEAC_2516_Enable_Document_Sharing_Templates',
    defaultValue: true as boolean,
  },
  enableCustomDeckSharedFolders: {
    // [TODO:] 2718 remove this when we have a new feature flag for collaboration
    flag: 'BEAC_2712_custom_decks_shared_folders',
    defaultValue: true as boolean,
  },
  enableAddNewMeeting: {
    flag: 'BEAC_2829_enable_add_meeting',
    defaultValue: false as boolean,
  },
  publisherShareSentDownloadReport: {
    flag: 'BEAC_3865_publisher_share_sent_download_report',
    defaultValue: true as boolean,
  },
  enableCustomDecksCollaborationEdit: {
    flag: 'BEAC_2818_custom_deck_collaboration',
    defaultValue: false as boolean,
  },
  enableNewContentPreviewModal: {
    flag: 'BEAC_1538_Content_Preview_Modal_V2',
    defaultValue: true as boolean,
  },
  enableMSLReports: {
    flag: 'BEAC_2388_msl_reports',
    defaultValue: false as boolean,
  },
  enableZendeskKnowledgebase: {
    flag: 'BEAC_2519_zendesk_knowledgebase',
    defaultValue: false as boolean,
  },
  enableZendeskChat: {
    flag: 'BEAC_3240_zendesk_chat',
    defaultValue: false as boolean,
  },
  enableWebVideoDocuments: {
    flag: 'BEAC_3336_web_video_doc_types',
    defaultValue: true as boolean,
  },
  enableHighlighter: {
    flag: 'BEAC_2820_highlighter',
    defaultValue: true as boolean,
  },
  enableAllowAddFileToFolder: {
    flag: 'BEAC_1954_allow_add_file_to_folder',
    defaultValue: true as boolean,
  },
  enableDemoMode: {
    flag: 'BEAC_3900_analytics_demo_mode',
    defaultValue: false as boolean,
  },
  enableSendEmailImmediatly: {
    flag: 'BEAC_2777_immediate_publish_emails',
    defaultValue: false as boolean,
  },
  enableZipDocuments: {
    flag: 'BEAC_3849_html_document_type',
    defaultValue: true as boolean,
  },
  enableBulkAddToFolder: {
    flag: 'BEAC_1832_bulk_add_to_folder',
    defaultValue: true as boolean,
  },
  enableBulkShareEmail: {
    flag: 'BEAC_2131_bulk_share_email',
    defaultValue: true as boolean,
  },
  enableMeetingFollowUp: {
    flag: 'BEAC_4227_meeting_slide_follow_up',
    defaultValue: true as boolean,
  },
  enableMeetingNotes: {
    flag: 'BEAC_4227_meeting_slide_notes',
    defaultValue: false as boolean,
  },
  enableMeetingReactions: {
    flag: 'BEAC_4227_meeting_slide_reactions',
    defaultValue: false as boolean,
  },
  enableKendra: {
    flag: 'BEAC_3448_full_text_search',
    defaultValue: false as boolean,
  },
  enableMeetingEyeball: {
    flag: 'BEAC_4227_meeting_slide_eyeball',
    defaultValue: true as boolean,
  },
  presentationVanityURL: {
    flag: 'BEAC_3569_presentation_vanity_url',
    defaultValue: true as boolean,
  },
  enableHubs: {
    flag: 'BEAC_3259_beacon_hubs',
    defaultValue: false as boolean,
  },
  enablePublisherUpload: {
    flag: 'BEAC_5374_publisher_upload',
    defaultValue: true as boolean,
  },
  enableCustomNotes: {
    flag: 'BEAC_5069_custom_notes',
    defaultValue: false as boolean,
  },
  enableMyUploads: {
    flag: 'enable_viewer_file_upload',
    defaultValue: false as boolean,
  },
  enablePublisherReports: {
    flag: 'BEAC_1849_enable_publisher_reports',
    defaultValue: false as boolean,
  },
  disablePublisherFolder: {
    flag: 'BEAC_2327_disable_publisher_folder',
    defaultValue: true as boolean,
  },
  enableSharedFolders: {
    flag: 'enable_shared_folders',
    defaultValue: false as boolean,
  },
  enableEmailNotification: {
    flag: 'BEAC_1535_msl_email_alerts',
    defaultValue: false as boolean,
  },
  enableEditSharedFolder: {
    flag: 'BEAC_5647_edit_shared_folder',
    defaultValue: true as boolean,
  },
  enableShareCustomDeck: {
    flag: 'BEAC_5351_enable_share_custom_decks',
    defaultValue: false as boolean,
  },
  enablePowerPointAddin: {
    flag: 'enable_powerpoint_addin',
    defaultValue: false as boolean,
  },
  hideSlideTrackingReport: {
    flag: 'BEAC_5966_turn_off_slide_tracking_reporting',
    defaultValue: false as boolean,
  },
  hideCopyTitleAndLink: {
    flag: 'BEAC_5702_disable_copy_title_and_link',
    defaultValue: false as boolean,
  },
  enableSkipDatastoreOnReady: {
    flag: 'BEAC_5956_skip_datastore_onready',
    defaultValue: false as boolean,
  },
  notifyOnlyOwnerOnExpiry: {
    flag: 'BEAC_5649_notify_only_owner_on_expiry',
    defaultValue: false as boolean,
  },
  enableMultipleIFrames: {
    flag: 'BEAC_5977_enable_multiple_iframes_on_presentation',
    defaultValue: false as boolean,
  },
  enableOpenSearch: {
    flag: 'BEAC_5828_is_opensearch_search_enabled',
    defaultValue: false as boolean,
  },
  enableNew3PC: {
    flag: 'BEAC_6084_3pc_window_popout',
    defaultValue: false as boolean,
  },
  enableHybridSearch: {
    flag: 'BEAC_6142_hybrid_search',
    defaultValue: false as boolean,
  },
} as const satisfies Record<string, FeatureFlag>

export type FeatureFlagKeys = keyof typeof FeatureFlags
