/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      lockedFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      additionalRoles
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      lockedFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      additionalRoles
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      lockedFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      additionalRoles
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
      }
      folderUpdateGracePeriodDays
      medInfoURL
      publisherListConfig {
        fields {
          fieldName
          header
          width
        }
        sort {
          field
          isAsc
        }
      }
      mslListConfig {
        fields {
          fieldName
          header
          width
        }
        sort {
          field
          isAsc
        }
      }
      featureFlags {
        flagId
        value
      }
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
        forms {
          id
          name
          label
          fields {
            id
            fieldName
            fieldLabel
            reportingName
            status
            description
            required
            maxLength
            formatValidation
            fieldType
            showByDefault
            dependentFieldId
            controlType
            order
            settings {
              isBadge
              fieldPosition
            }
            usage
            documentSettings {
              fieldPosition
            }
            fieldValueDefinitions {
              id
              value
              label
              disabled
              isDefault
              documentSettings {
                presentationWatermarkText
                permission {
                  present
                  modify
                  share
                  download
                  externalNotation
                }
              }
              badgeColor
              badgeLabel
              createdAt
              dependentValueIds
            }
            displayOnParentSelection
            displayOnValueSelection
            objectSetting {
              childrenFieldIds
              restriction {
                syncUpdate
                syncDelete
                update
                delete
              }
            }
            isChildField
            dateRestriction
            createdAt
            scaleNumber
            precisionNumber
          }
          targetRequestTemplate
          targetType
          targetAddress
          targetParameters {
            key
            value
          }
          status
        }
        meetingFields {
          id
          fieldName
          status
          description
          required
          maxLength
          type
          controlType
          fieldValueDefinitions {
            id
            value
            disabled
            createdAt
          }
          createdAt
        }
        customFields {
          id
          fieldName
          fieldLabel
          reportingName
          status
          description
          required
          maxLength
          formatValidation
          fieldType
          showByDefault
          dependentFieldId
          controlType
          order
          settings {
            isBadge
            fieldPosition
          }
          usage
          documentSettings {
            fieldPosition
          }
          fieldValueDefinitions {
            id
            value
            label
            disabled
            isDefault
            documentSettings {
              presentationWatermarkText
              permission {
                present
                modify
                share
                download
                externalNotation
              }
            }
            badgeColor
            badgeLabel
            createdAt
            dependentValueIds
          }
          displayOnParentSelection
          displayOnValueSelection
          objectSetting {
            childrenFieldIds
            restriction {
              syncUpdate
              syncDelete
              update
              delete
            }
          }
          isChildField
          dateRestriction
          createdAt
          scaleNumber
          precisionNumber
        }
        defaultDocumentPermissions {
          present
          modify
          share
          download
          externalNotation
        }
        mslListConfig {
          sort {
            field
            isAsc
          }
          fields {
            field
            width
          }
        }
        publisherListConfig {
          sort {
            field
            isAsc
          }
          fields {
            field
            width
          }
        }
        crmIntegration {
          id
          crmIntegrationType
          instanceUrl
          clientId
          redirectUri
          name
          accountsSettings {
            query
            displaySettings {
              shortened
              extended
            }
            addressSettings {
              alias
              displaySettings {
                shortened
                extended
              }
            }
          }
          meetingSetting {
            apiName
            presentationsFieldName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
              }
            }
            type
          }
          additionalSettings {
            allowCommentsOnTasks
            detailContentTableSettings {
              name
              apiName
              relationshipName
              prefix
            }
            enableSurveys
            crmAccountToObjectSettings {
              accountFieldNames {
                accountFieldName
                objectFieldName
              }
              apiName
            }
          }
          loginUrl
          crmStandaloneForms {
            id
            apiName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
              }
            }
            type
            parents {
              apiName
              relationshipName
            }
            usage
          }
        }
        sessionTimeout
        quickFilters {
          id
          label
          path
        }
        hubsConfig {
          disabledSections
          disableNotationDescription
        }
        reports {
          key
          value
        }
        emailDocumentUpdateFrequency
        sharePPTXasPDF
      }
      kendraIndexId
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
      }
      folderUpdateGracePeriodDays
      medInfoURL
      publisherListConfig {
        fields {
          fieldName
          header
          width
        }
        sort {
          field
          isAsc
        }
      }
      mslListConfig {
        fields {
          fieldName
          header
          width
        }
        sort {
          field
          isAsc
        }
      }
      featureFlags {
        flagId
        value
      }
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
        forms {
          id
          name
          label
          fields {
            id
            fieldName
            fieldLabel
            reportingName
            status
            description
            required
            maxLength
            formatValidation
            fieldType
            showByDefault
            dependentFieldId
            controlType
            order
            settings {
              isBadge
              fieldPosition
            }
            usage
            documentSettings {
              fieldPosition
            }
            fieldValueDefinitions {
              id
              value
              label
              disabled
              isDefault
              documentSettings {
                presentationWatermarkText
                permission {
                  present
                  modify
                  share
                  download
                  externalNotation
                }
              }
              badgeColor
              badgeLabel
              createdAt
              dependentValueIds
            }
            displayOnParentSelection
            displayOnValueSelection
            objectSetting {
              childrenFieldIds
              restriction {
                syncUpdate
                syncDelete
                update
                delete
              }
            }
            isChildField
            dateRestriction
            createdAt
            scaleNumber
            precisionNumber
          }
          targetRequestTemplate
          targetType
          targetAddress
          targetParameters {
            key
            value
          }
          status
        }
        meetingFields {
          id
          fieldName
          status
          description
          required
          maxLength
          type
          controlType
          fieldValueDefinitions {
            id
            value
            disabled
            createdAt
          }
          createdAt
        }
        customFields {
          id
          fieldName
          fieldLabel
          reportingName
          status
          description
          required
          maxLength
          formatValidation
          fieldType
          showByDefault
          dependentFieldId
          controlType
          order
          settings {
            isBadge
            fieldPosition
          }
          usage
          documentSettings {
            fieldPosition
          }
          fieldValueDefinitions {
            id
            value
            label
            disabled
            isDefault
            documentSettings {
              presentationWatermarkText
              permission {
                present
                modify
                share
                download
                externalNotation
              }
            }
            badgeColor
            badgeLabel
            createdAt
            dependentValueIds
          }
          displayOnParentSelection
          displayOnValueSelection
          objectSetting {
            childrenFieldIds
            restriction {
              syncUpdate
              syncDelete
              update
              delete
            }
          }
          isChildField
          dateRestriction
          createdAt
          scaleNumber
          precisionNumber
        }
        defaultDocumentPermissions {
          present
          modify
          share
          download
          externalNotation
        }
        mslListConfig {
          sort {
            field
            isAsc
          }
          fields {
            field
            width
          }
        }
        publisherListConfig {
          sort {
            field
            isAsc
          }
          fields {
            field
            width
          }
        }
        crmIntegration {
          id
          crmIntegrationType
          instanceUrl
          clientId
          redirectUri
          name
          accountsSettings {
            query
            displaySettings {
              shortened
              extended
            }
            addressSettings {
              alias
              displaySettings {
                shortened
                extended
              }
            }
          }
          meetingSetting {
            apiName
            presentationsFieldName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
              }
            }
            type
          }
          additionalSettings {
            allowCommentsOnTasks
            detailContentTableSettings {
              name
              apiName
              relationshipName
              prefix
            }
            enableSurveys
            crmAccountToObjectSettings {
              accountFieldNames {
                accountFieldName
                objectFieldName
              }
              apiName
            }
          }
          loginUrl
          crmStandaloneForms {
            id
            apiName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
              }
            }
            type
            parents {
              apiName
              relationshipName
            }
            usage
          }
        }
        sessionTimeout
        quickFilters {
          id
          label
          path
        }
        hubsConfig {
          disabledSections
          disableNotationDescription
        }
        reports {
          key
          value
        }
        emailDocumentUpdateFrequency
        sharePPTXasPDF
      }
      kendraIndexId
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant(
    $input: DeleteTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    deleteTenant(input: $input, condition: $condition) {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
      }
      folderUpdateGracePeriodDays
      medInfoURL
      publisherListConfig {
        fields {
          fieldName
          header
          width
        }
        sort {
          field
          isAsc
        }
      }
      mslListConfig {
        fields {
          fieldName
          header
          width
        }
        sort {
          field
          isAsc
        }
      }
      featureFlags {
        flagId
        value
      }
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
        forms {
          id
          name
          label
          fields {
            id
            fieldName
            fieldLabel
            reportingName
            status
            description
            required
            maxLength
            formatValidation
            fieldType
            showByDefault
            dependentFieldId
            controlType
            order
            settings {
              isBadge
              fieldPosition
            }
            usage
            documentSettings {
              fieldPosition
            }
            fieldValueDefinitions {
              id
              value
              label
              disabled
              isDefault
              documentSettings {
                presentationWatermarkText
                permission {
                  present
                  modify
                  share
                  download
                  externalNotation
                }
              }
              badgeColor
              badgeLabel
              createdAt
              dependentValueIds
            }
            displayOnParentSelection
            displayOnValueSelection
            objectSetting {
              childrenFieldIds
              restriction {
                syncUpdate
                syncDelete
                update
                delete
              }
            }
            isChildField
            dateRestriction
            createdAt
            scaleNumber
            precisionNumber
          }
          targetRequestTemplate
          targetType
          targetAddress
          targetParameters {
            key
            value
          }
          status
        }
        meetingFields {
          id
          fieldName
          status
          description
          required
          maxLength
          type
          controlType
          fieldValueDefinitions {
            id
            value
            disabled
            createdAt
          }
          createdAt
        }
        customFields {
          id
          fieldName
          fieldLabel
          reportingName
          status
          description
          required
          maxLength
          formatValidation
          fieldType
          showByDefault
          dependentFieldId
          controlType
          order
          settings {
            isBadge
            fieldPosition
          }
          usage
          documentSettings {
            fieldPosition
          }
          fieldValueDefinitions {
            id
            value
            label
            disabled
            isDefault
            documentSettings {
              presentationWatermarkText
              permission {
                present
                modify
                share
                download
                externalNotation
              }
            }
            badgeColor
            badgeLabel
            createdAt
            dependentValueIds
          }
          displayOnParentSelection
          displayOnValueSelection
          objectSetting {
            childrenFieldIds
            restriction {
              syncUpdate
              syncDelete
              update
              delete
            }
          }
          isChildField
          dateRestriction
          createdAt
          scaleNumber
          precisionNumber
        }
        defaultDocumentPermissions {
          present
          modify
          share
          download
          externalNotation
        }
        mslListConfig {
          sort {
            field
            isAsc
          }
          fields {
            field
            width
          }
        }
        publisherListConfig {
          sort {
            field
            isAsc
          }
          fields {
            field
            width
          }
        }
        crmIntegration {
          id
          crmIntegrationType
          instanceUrl
          clientId
          redirectUri
          name
          accountsSettings {
            query
            displaySettings {
              shortened
              extended
            }
            addressSettings {
              alias
              displaySettings {
                shortened
                extended
              }
            }
          }
          meetingSetting {
            apiName
            presentationsFieldName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
              }
            }
            type
          }
          additionalSettings {
            allowCommentsOnTasks
            detailContentTableSettings {
              name
              apiName
              relationshipName
              prefix
            }
            enableSurveys
            crmAccountToObjectSettings {
              accountFieldNames {
                accountFieldName
                objectFieldName
              }
              apiName
            }
          }
          loginUrl
          crmStandaloneForms {
            id
            apiName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
              }
            }
            type
            parents {
              apiName
              relationshipName
            }
            usage
          }
        }
        sessionTimeout
        quickFilters {
          id
          label
          path
        }
        hubsConfig {
          disabledSections
          disableNotationDescription
        }
        reports {
          key
          value
        }
        emailDocumentUpdateFrequency
        sharePPTXasPDF
      }
      kendraIndexId
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentVersion = /* GraphQL */ `
  mutation CreateDocumentVersion(
    $input: CreateDocumentVersionInput!
    $condition: ModelDocumentVersionConditionInput
  ) {
    createDocumentVersion(input: $input, condition: $condition) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDocumentVersion = /* GraphQL */ `
  mutation UpdateDocumentVersion(
    $input: UpdateDocumentVersionInput!
    $condition: ModelDocumentVersionConditionInput
  ) {
    updateDocumentVersion(input: $input, condition: $condition) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDocumentVersion = /* GraphQL */ `
  mutation DeleteDocumentVersion(
    $input: DeleteDocumentVersionInput!
    $condition: ModelDocumentVersionConditionInput
  ) {
    deleteDocumentVersion(input: $input, condition: $condition) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAttachedFile = /* GraphQL */ `
  mutation CreateAttachedFile(
    $input: CreateAttachedFileInput!
    $condition: ModelAttachedFileConditionInput
  ) {
    createAttachedFile(input: $input, condition: $condition) {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAttachedFile = /* GraphQL */ `
  mutation UpdateAttachedFile(
    $input: UpdateAttachedFileInput!
    $condition: ModelAttachedFileConditionInput
  ) {
    updateAttachedFile(input: $input, condition: $condition) {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAttachedFile = /* GraphQL */ `
  mutation DeleteAttachedFile(
    $input: DeleteAttachedFileInput!
    $condition: ModelAttachedFileConditionInput
  ) {
    deleteAttachedFile(input: $input, condition: $condition) {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEmailTemplate = /* GraphQL */ `
  mutation CreateEmailTemplate(
    $input: CreateEmailTemplateInput!
    $condition: ModelEmailTemplateConditionInput
  ) {
    createEmailTemplate(input: $input, condition: $condition) {
      id
      tenantId
      labelValues {
        key
        value
      }
      customFilterValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      subject
      title
      cc
      bcc
      body
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      status
      editPermissions
      createdBy
      createdAt
      updatedBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEmailTemplate = /* GraphQL */ `
  mutation UpdateEmailTemplate(
    $input: UpdateEmailTemplateInput!
    $condition: ModelEmailTemplateConditionInput
  ) {
    updateEmailTemplate(input: $input, condition: $condition) {
      id
      tenantId
      labelValues {
        key
        value
      }
      customFilterValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      subject
      title
      cc
      bcc
      body
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      status
      editPermissions
      createdBy
      createdAt
      updatedBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEmailTemplate = /* GraphQL */ `
  mutation DeleteEmailTemplate(
    $input: DeleteEmailTemplateInput!
    $condition: ModelEmailTemplateConditionInput
  ) {
    deleteEmailTemplate(input: $input, condition: $condition) {
      id
      tenantId
      labelValues {
        key
        value
      }
      customFilterValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      subject
      title
      cc
      bcc
      body
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      status
      editPermissions
      createdBy
      createdAt
      updatedBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentSetting = /* GraphQL */ `
  mutation CreateDocumentSetting(
    $input: CreateDocumentSettingInput!
    $condition: ModelDocumentSettingConditionInput
  ) {
    createDocumentSetting(input: $input, condition: $condition) {
      id
      tenantId
      createdAt
      createdBy
      updatedAt
      updatedBy
      documentId
      documentVersionId
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      status
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDocumentSetting = /* GraphQL */ `
  mutation UpdateDocumentSetting(
    $input: UpdateDocumentSettingInput!
    $condition: ModelDocumentSettingConditionInput
  ) {
    updateDocumentSetting(input: $input, condition: $condition) {
      id
      tenantId
      createdAt
      createdBy
      updatedAt
      updatedBy
      documentId
      documentVersionId
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      status
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDocumentSetting = /* GraphQL */ `
  mutation DeleteDocumentSetting(
    $input: DeleteDocumentSettingInput!
    $condition: ModelDocumentSettingConditionInput
  ) {
    deleteDocumentSetting(input: $input, condition: $condition) {
      id
      tenantId
      createdAt
      createdBy
      updatedAt
      updatedBy
      documentId
      documentVersionId
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      status
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserNotations = /* GraphQL */ `
  mutation CreateUserNotations(
    $input: CreateUserNotationsInput!
    $condition: ModelUserNotationsConditionInput
  ) {
    createUserNotations(input: $input, condition: $condition) {
      id
      tenantId
      createdAt
      createdBy
      updatedAt
      updatedBy
      documentId
      documentVersionId
      customDeckId
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      status
      type
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserNotations = /* GraphQL */ `
  mutation UpdateUserNotations(
    $input: UpdateUserNotationsInput!
    $condition: ModelUserNotationsConditionInput
  ) {
    updateUserNotations(input: $input, condition: $condition) {
      id
      tenantId
      createdAt
      createdBy
      updatedAt
      updatedBy
      documentId
      documentVersionId
      customDeckId
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      status
      type
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserNotations = /* GraphQL */ `
  mutation DeleteUserNotations(
    $input: DeleteUserNotationsInput!
    $condition: ModelUserNotationsConditionInput
  ) {
    deleteUserNotations(input: $input, condition: $condition) {
      id
      tenantId
      createdAt
      createdBy
      updatedAt
      updatedBy
      documentId
      documentVersionId
      customDeckId
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      status
      type
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCustomDeck = /* GraphQL */ `
  mutation CreateCustomDeck(
    $input: CreateCustomDeckInput!
    $condition: ModelCustomDeckConditionInput
  ) {
    createCustomDeck(input: $input, condition: $condition) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        pages {
          pageId
          pageNumber
          documentVersionId
        }
        visible
        srcId
        docAccessLevel
        name
        locked
      }
      title
      editMutex {
        userId
        timeStarted
        lastSeenAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCustomDeck = /* GraphQL */ `
  mutation UpdateCustomDeck(
    $input: UpdateCustomDeckInput!
    $condition: ModelCustomDeckConditionInput
  ) {
    updateCustomDeck(input: $input, condition: $condition) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        pages {
          pageId
          pageNumber
          documentVersionId
        }
        visible
        srcId
        docAccessLevel
        name
        locked
      }
      title
      editMutex {
        userId
        timeStarted
        lastSeenAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCustomDeck = /* GraphQL */ `
  mutation DeleteCustomDeck(
    $input: DeleteCustomDeckInput!
    $condition: ModelCustomDeckConditionInput
  ) {
    deleteCustomDeck(input: $input, condition: $condition) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        pages {
          pageId
          pageNumber
          documentVersionId
        }
        visible
        srcId
        docAccessLevel
        name
        locked
      }
      title
      editMutex {
        userId
        timeStarted
        lastSeenAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFolder = /* GraphQL */ `
  mutation CreateFolder(
    $input: CreateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    createFolder(input: $input, condition: $condition) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        targetCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        targetLabels {
          key
          values
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      shareStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFolder = /* GraphQL */ `
  mutation UpdateFolder(
    $input: UpdateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    updateFolder(input: $input, condition: $condition) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        targetCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        targetLabels {
          key
          values
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      shareStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFolder = /* GraphQL */ `
  mutation DeleteFolder(
    $input: DeleteFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    deleteFolder(input: $input, condition: $condition) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        targetCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        targetLabels {
          key
          values
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      shareStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createContentShare = /* GraphQL */ `
  mutation CreateContentShare(
    $input: CreateContentShareInput!
    $condition: ModelContentShareConditionInput
  ) {
    createContentShare(input: $input, condition: $condition) {
      id
      token
      tenantId
      type
      contentId
      meetingId
      expiresAt
      createdAt
      updatedAt
      createdBy
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      revoked
      customDeckDependencies {
        versions
        folderId
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateContentShare = /* GraphQL */ `
  mutation UpdateContentShare(
    $input: UpdateContentShareInput!
    $condition: ModelContentShareConditionInput
  ) {
    updateContentShare(input: $input, condition: $condition) {
      id
      token
      tenantId
      type
      contentId
      meetingId
      expiresAt
      createdAt
      updatedAt
      createdBy
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      revoked
      customDeckDependencies {
        versions
        folderId
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteContentShare = /* GraphQL */ `
  mutation DeleteContentShare(
    $input: DeleteContentShareInput!
    $condition: ModelContentShareConditionInput
  ) {
    deleteContentShare(input: $input, condition: $condition) {
      id
      token
      tenantId
      type
      contentId
      meetingId
      expiresAt
      createdAt
      updatedAt
      createdBy
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      revoked
      customDeckDependencies {
        versions
        folderId
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createIntegrationLog = /* GraphQL */ `
  mutation CreateIntegrationLog(
    $input: CreateIntegrationLogInput!
    $condition: ModelIntegrationLogConditionInput
  ) {
    createIntegrationLog(input: $input, condition: $condition) {
      integrationId
      id
      log {
        level
        timestamp
        message
        srcDocId
        srcDocVersion
      }
      status
      tenantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateIntegrationLog = /* GraphQL */ `
  mutation UpdateIntegrationLog(
    $input: UpdateIntegrationLogInput!
    $condition: ModelIntegrationLogConditionInput
  ) {
    updateIntegrationLog(input: $input, condition: $condition) {
      integrationId
      id
      log {
        level
        timestamp
        message
        srcDocId
        srcDocVersion
      }
      status
      tenantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteIntegrationLog = /* GraphQL */ `
  mutation DeleteIntegrationLog(
    $input: DeleteIntegrationLogInput!
    $condition: ModelIntegrationLogConditionInput
  ) {
    deleteIntegrationLog(input: $input, condition: $condition) {
      integrationId
      id
      log {
        level
        timestamp
        message
        srcDocId
        srcDocVersion
      }
      status
      tenantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createIntegrationSettings = /* GraphQL */ `
  mutation CreateIntegrationSettings(
    $input: CreateIntegrationSettingsInput!
    $condition: ModelIntegrationSettingsConditionInput
  ) {
    createIntegrationSettings(input: $input, condition: $condition) {
      id
      tenantId
      syncContentEvery
      name
      mapping {
        key
        fieldType
        dataType
        targetFieldName
        srcFieldname
        valueMappings {
          srcValue
          targetValue
        }
      }
      clientConfigurationFields {
        key
        value
      }
      integrationType
      enabled
      notificationEmail
      errorSyncEmail
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateIntegrationSettings = /* GraphQL */ `
  mutation UpdateIntegrationSettings(
    $input: UpdateIntegrationSettingsInput!
    $condition: ModelIntegrationSettingsConditionInput
  ) {
    updateIntegrationSettings(input: $input, condition: $condition) {
      id
      tenantId
      syncContentEvery
      name
      mapping {
        key
        fieldType
        dataType
        targetFieldName
        srcFieldname
        valueMappings {
          srcValue
          targetValue
        }
      }
      clientConfigurationFields {
        key
        value
      }
      integrationType
      enabled
      notificationEmail
      errorSyncEmail
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteIntegrationSettings = /* GraphQL */ `
  mutation DeleteIntegrationSettings(
    $input: DeleteIntegrationSettingsInput!
    $condition: ModelIntegrationSettingsConditionInput
  ) {
    deleteIntegrationSettings(input: $input, condition: $condition) {
      id
      tenantId
      syncContentEvery
      name
      mapping {
        key
        fieldType
        dataType
        targetFieldName
        srcFieldname
        valueMappings {
          srcValue
          targetValue
        }
      }
      clientConfigurationFields {
        key
        value
      }
      integrationType
      enabled
      notificationEmail
      errorSyncEmail
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMeeting = /* GraphQL */ `
  mutation CreateMeeting(
    $input: CreateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    createMeeting(input: $input, condition: $condition) {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        groupId
        folderItemId
        title
        groupTitle
        status
        contentType
        events {
          pageNumber
          pageId
          timestamp
          end
        }
        openedAt
        closedAt
        presentedMeta {
          pageId
          presented
          followUp
          sentiment
          note
          title
        }
      }
      type
      fieldValues {
        fieldId
        valueOrId
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      attendees {
        id
        name
        status
        updatedAt
        email
        crmAccountId
        crmAddressId
        attendeeType
        crmRecord {
          crmCallId
          crmCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
              }
            }
            values
          }
          crmSyncStatus
          lastSyncedAt
        }
        lastSyncedAt
      }
      crmRecord {
        crmCallId
        crmCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        crmSyncStatus
        lastSyncedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMeeting = /* GraphQL */ `
  mutation UpdateMeeting(
    $input: UpdateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    updateMeeting(input: $input, condition: $condition) {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        groupId
        folderItemId
        title
        groupTitle
        status
        contentType
        events {
          pageNumber
          pageId
          timestamp
          end
        }
        openedAt
        closedAt
        presentedMeta {
          pageId
          presented
          followUp
          sentiment
          note
          title
        }
      }
      type
      fieldValues {
        fieldId
        valueOrId
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      attendees {
        id
        name
        status
        updatedAt
        email
        crmAccountId
        crmAddressId
        attendeeType
        crmRecord {
          crmCallId
          crmCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
              }
            }
            values
          }
          crmSyncStatus
          lastSyncedAt
        }
        lastSyncedAt
      }
      crmRecord {
        crmCallId
        crmCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        crmSyncStatus
        lastSyncedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMeeting = /* GraphQL */ `
  mutation DeleteMeeting(
    $input: DeleteMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    deleteMeeting(input: $input, condition: $condition) {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        groupId
        folderItemId
        title
        groupTitle
        status
        contentType
        events {
          pageNumber
          pageId
          timestamp
          end
        }
        openedAt
        closedAt
        presentedMeta {
          pageId
          presented
          followUp
          sentiment
          note
          title
        }
      }
      type
      fieldValues {
        fieldId
        valueOrId
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      attendees {
        id
        name
        status
        updatedAt
        email
        crmAccountId
        crmAddressId
        attendeeType
        crmRecord {
          crmCallId
          crmCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
              }
            }
            values
          }
          crmSyncStatus
          lastSyncedAt
        }
        lastSyncedAt
      }
      crmRecord {
        crmCallId
        crmCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        crmSyncStatus
        lastSyncedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHub = /* GraphQL */ `
  mutation CreateHub(
    $input: CreateHubInput!
    $condition: ModelHubConditionInput
  ) {
    createHub(input: $input, condition: $condition) {
      id
      tenantId
      name
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      hubSections {
        id
        sectionHeader
        status
        type
        visible
        order
        textarea {
          content
        }
        sharedFiles {
          id
          contentId
          title
          contentType
          documentVersionSettings {
            associatedFiles {
              associatedFileId
              versionId
              notation {
                id
                type
                description
                status
                pageId
                coordinate {
                  x
                  y
                }
                createdAt
                createdBy
                updatedAt
                updatedBy
              }
            }
          }
          status
          createdAt
          updatedAt
          notation {
            id
            type
            description
            status
            pageId
            coordinate {
              x
              y
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        toDos {
          id
          title
          status
          completedAt
          resolution
          order
          createdAt
          updatedAt
        }
        links {
          id
          url
          title
          status
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      meetingId
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHub = /* GraphQL */ `
  mutation UpdateHub(
    $input: UpdateHubInput!
    $condition: ModelHubConditionInput
  ) {
    updateHub(input: $input, condition: $condition) {
      id
      tenantId
      name
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      hubSections {
        id
        sectionHeader
        status
        type
        visible
        order
        textarea {
          content
        }
        sharedFiles {
          id
          contentId
          title
          contentType
          documentVersionSettings {
            associatedFiles {
              associatedFileId
              versionId
              notation {
                id
                type
                description
                status
                pageId
                coordinate {
                  x
                  y
                }
                createdAt
                createdBy
                updatedAt
                updatedBy
              }
            }
          }
          status
          createdAt
          updatedAt
          notation {
            id
            type
            description
            status
            pageId
            coordinate {
              x
              y
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        toDos {
          id
          title
          status
          completedAt
          resolution
          order
          createdAt
          updatedAt
        }
        links {
          id
          url
          title
          status
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      meetingId
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHub = /* GraphQL */ `
  mutation DeleteHub(
    $input: DeleteHubInput!
    $condition: ModelHubConditionInput
  ) {
    deleteHub(input: $input, condition: $condition) {
      id
      tenantId
      name
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      hubSections {
        id
        sectionHeader
        status
        type
        visible
        order
        textarea {
          content
        }
        sharedFiles {
          id
          contentId
          title
          contentType
          documentVersionSettings {
            associatedFiles {
              associatedFileId
              versionId
              notation {
                id
                type
                description
                status
                pageId
                coordinate {
                  x
                  y
                }
                createdAt
                createdBy
                updatedAt
                updatedBy
              }
            }
          }
          status
          createdAt
          updatedAt
          notation {
            id
            type
            description
            status
            pageId
            coordinate {
              x
              y
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        toDos {
          id
          title
          status
          completedAt
          resolution
          order
          createdAt
          updatedAt
        }
        links {
          id
          url
          title
          status
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      meetingId
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCustomFormRecord = /* GraphQL */ `
  mutation CreateCustomFormRecord(
    $input: CreateCustomFormRecordInput!
    $condition: ModelCustomFormRecordConditionInput
  ) {
    createCustomFormRecord(input: $input, condition: $condition) {
      id
      tenantId
      customFormId
      entity
      status
      crmFields {
        accountId
        externalId
        apiName
        recordTypeId
        syncStatus
        lastSyncedAt
      }
      name
      parentId
      parentModel
      values {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCustomFormRecord = /* GraphQL */ `
  mutation UpdateCustomFormRecord(
    $input: UpdateCustomFormRecordInput!
    $condition: ModelCustomFormRecordConditionInput
  ) {
    updateCustomFormRecord(input: $input, condition: $condition) {
      id
      tenantId
      customFormId
      entity
      status
      crmFields {
        accountId
        externalId
        apiName
        recordTypeId
        syncStatus
        lastSyncedAt
      }
      name
      parentId
      parentModel
      values {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCustomFormRecord = /* GraphQL */ `
  mutation DeleteCustomFormRecord(
    $input: DeleteCustomFormRecordInput!
    $condition: ModelCustomFormRecordConditionInput
  ) {
    deleteCustomFormRecord(input: $input, condition: $condition) {
      id
      tenantId
      customFormId
      entity
      status
      crmFields {
        accountId
        externalId
        apiName
        recordTypeId
        syncStatus
        lastSyncedAt
      }
      name
      parentId
      parentModel
      values {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTenantLambda = /* GraphQL */ `
  mutation CreateTenantLambda($tenant: CreateTenantInputLambda) {
    createTenantLambda(tenant: $tenant) {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
      }
      folderUpdateGracePeriodDays
      medInfoURL
      publisherListConfig {
        fields {
          fieldName
          header
          width
        }
        sort {
          field
          isAsc
        }
      }
      mslListConfig {
        fields {
          fieldName
          header
          width
        }
        sort {
          field
          isAsc
        }
      }
      featureFlags {
        flagId
        value
      }
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
        forms {
          id
          name
          label
          fields {
            id
            fieldName
            fieldLabel
            reportingName
            status
            description
            required
            maxLength
            formatValidation
            fieldType
            showByDefault
            dependentFieldId
            controlType
            order
            settings {
              isBadge
              fieldPosition
            }
            usage
            documentSettings {
              fieldPosition
            }
            fieldValueDefinitions {
              id
              value
              label
              disabled
              isDefault
              documentSettings {
                presentationWatermarkText
                permission {
                  present
                  modify
                  share
                  download
                  externalNotation
                }
              }
              badgeColor
              badgeLabel
              createdAt
              dependentValueIds
            }
            displayOnParentSelection
            displayOnValueSelection
            objectSetting {
              childrenFieldIds
              restriction {
                syncUpdate
                syncDelete
                update
                delete
              }
            }
            isChildField
            dateRestriction
            createdAt
            scaleNumber
            precisionNumber
          }
          targetRequestTemplate
          targetType
          targetAddress
          targetParameters {
            key
            value
          }
          status
        }
        meetingFields {
          id
          fieldName
          status
          description
          required
          maxLength
          type
          controlType
          fieldValueDefinitions {
            id
            value
            disabled
            createdAt
          }
          createdAt
        }
        customFields {
          id
          fieldName
          fieldLabel
          reportingName
          status
          description
          required
          maxLength
          formatValidation
          fieldType
          showByDefault
          dependentFieldId
          controlType
          order
          settings {
            isBadge
            fieldPosition
          }
          usage
          documentSettings {
            fieldPosition
          }
          fieldValueDefinitions {
            id
            value
            label
            disabled
            isDefault
            documentSettings {
              presentationWatermarkText
              permission {
                present
                modify
                share
                download
                externalNotation
              }
            }
            badgeColor
            badgeLabel
            createdAt
            dependentValueIds
          }
          displayOnParentSelection
          displayOnValueSelection
          objectSetting {
            childrenFieldIds
            restriction {
              syncUpdate
              syncDelete
              update
              delete
            }
          }
          isChildField
          dateRestriction
          createdAt
          scaleNumber
          precisionNumber
        }
        defaultDocumentPermissions {
          present
          modify
          share
          download
          externalNotation
        }
        mslListConfig {
          sort {
            field
            isAsc
          }
          fields {
            field
            width
          }
        }
        publisherListConfig {
          sort {
            field
            isAsc
          }
          fields {
            field
            width
          }
        }
        crmIntegration {
          id
          crmIntegrationType
          instanceUrl
          clientId
          redirectUri
          name
          accountsSettings {
            query
            displaySettings {
              shortened
              extended
            }
            addressSettings {
              alias
              displaySettings {
                shortened
                extended
              }
            }
          }
          meetingSetting {
            apiName
            presentationsFieldName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
              }
            }
            type
          }
          additionalSettings {
            allowCommentsOnTasks
            detailContentTableSettings {
              name
              apiName
              relationshipName
              prefix
            }
            enableSurveys
            crmAccountToObjectSettings {
              accountFieldNames {
                accountFieldName
                objectFieldName
              }
              apiName
            }
          }
          loginUrl
          crmStandaloneForms {
            id
            apiName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
              }
            }
            type
            parents {
              apiName
              relationshipName
            }
            usage
          }
        }
        sessionTimeout
        quickFilters {
          id
          label
          path
        }
        hubsConfig {
          disabledSections
          disableNotationDescription
        }
        reports {
          key
          value
        }
        emailDocumentUpdateFrequency
        sharePPTXasPDF
      }
      kendraIndexId
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentFromS3Upload = /* GraphQL */ `
  mutation CreateDocumentFromS3Upload(
    $inputDoc: CreateDocumentFromS3UploadInput!
  ) {
    createDocumentFromS3Upload(inputDoc: $inputDoc) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserDocumentFromS3Upload = /* GraphQL */ `
  mutation CreateUserDocumentFromS3Upload(
    $inputDoc: CreateDocumentFromS3UploadInput!
  ) {
    createUserDocumentFromS3Upload(inputDoc: $inputDoc) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createWebDocument = /* GraphQL */ `
  mutation CreateWebDocument {
    createWebDocument {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDocumentThumbnail = /* GraphQL */ `
  mutation UpdateDocumentThumbnail(
    $inputDocumentThumbnail: UpdateDocumentThumbnailInput!
  ) {
    updateDocumentThumbnail(inputDocumentThumbnail: $inputDocumentThumbnail) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentFromS3UploadIntegrationEngine = /* GraphQL */ `
  mutation CreateDocumentFromS3UploadIntegrationEngine(
    $inputDoc: CreateDocumentFromS3UploadEngineInput!
  ) {
    createDocumentFromS3UploadIntegrationEngine(inputDoc: $inputDoc) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentVersionFromS3Upload = /* GraphQL */ `
  mutation CreateDocumentVersionFromS3Upload(
    $inputVersion: CreateDocumentVersionFromS3UploadInput!
  ) {
    createDocumentVersionFromS3Upload(inputVersion: $inputVersion) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentVersionFromS3UploadIntegrationEngine = /* GraphQL */ `
  mutation CreateDocumentVersionFromS3UploadIntegrationEngine(
    $inputVersion: CreateDocumentVersionFromS3UploadEngineInput!
  ) {
    createDocumentVersionFromS3UploadIntegrationEngine(
      inputVersion: $inputVersion
    ) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentVersionFromExisting = /* GraphQL */ `
  mutation CreateDocumentVersionFromExisting(
    $documentId: ID!
    $newVersionNumber: Int!
    $existingVersionId: ID!
  ) {
    createDocumentVersionFromExisting(
      documentId: $documentId
      newVersionNumber: $newVersionNumber
      existingVersionId: $existingVersionId
    ) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDocumentVersionFromExistingIntegrationEngine = /* GraphQL */ `
  mutation CreateDocumentVersionFromExistingIntegrationEngine(
    $input: CreateDocumentVersionFromExistingEngineInput
  ) {
    createDocumentVersionFromExistingIntegrationEngine(input: $input) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAttachedFileFromS3Upload = /* GraphQL */ `
  mutation CreateAttachedFileFromS3Upload(
    $inputAttached: CreateAttachedFileFromS3UploadInput!
  ) {
    createAttachedFileFromS3Upload(inputAttached: $inputAttached) {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const downloadDocumentVersionAsPdfFile = /* GraphQL */ `
  mutation DownloadDocumentVersionAsPdfFile($documentVersionId: ID!) {
    downloadDocumentVersionAsPdfFile(documentVersionId: $documentVersionId)
  }
`;
export const downloadCustomDeckAsPdfFile = /* GraphQL */ `
  mutation DownloadCustomDeckAsPdfFile($customDeckId: ID!) {
    downloadCustomDeckAsPdfFile(customDeckId: $customDeckId)
  }
`;
export const generateContentShare = /* GraphQL */ `
  mutation GenerateContentShare(
    $type: SHARE_TYPE!
    $contentId: ID!
    $expiresInMins: Int!
    $customValues: [CustomValuesInput!]!
    $meetingId: String
    $customDeckDependencies: CustomDeckDependenciesInput
  ) {
    generateContentShare(
      type: $type
      contentId: $contentId
      expiresInMins: $expiresInMins
      customValues: $customValues
      meetingId: $meetingId
      customDeckDependencies: $customDeckDependencies
    ) {
      id
      token
      tenantId
      type
      contentId
      meetingId
      expiresAt
      createdAt
      updatedAt
      createdBy
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      revoked
      customDeckDependencies {
        versions
        folderId
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserLambda = /* GraphQL */ `
  mutation UpdateUserLambda($user: UpdateUserLambdaInput) {
    updateUserLambda(user: $user) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      lockedFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      additionalRoles
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCustomDeckLambda = /* GraphQL */ `
  mutation UpdateCustomDeckLambda(
    $customDeck: UpdateCustomDeckLambdaInput!
    $folderId: ID!
    $rootFolderId: ID!
  ) {
    updateCustomDeckLambda(
      customDeck: $customDeck
      folderId: $folderId
      rootFolderId: $rootFolderId
    ) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        pages {
          pageId
          pageNumber
          documentVersionId
        }
        visible
        srcId
        docAccessLevel
        name
        locked
      }
      title
      editMutex {
        userId
        timeStarted
        lastSeenAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserStatusLambda = /* GraphQL */ `
  mutation UpdateUserStatusLambda($user: DisableUserLambdaInput) {
    updateUserStatusLambda(user: $user) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      lockedFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      additionalRoles
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTenantUser = /* GraphQL */ `
  mutation CreateTenantUser(
    $user: CreateUserLambdaInput
    $sendInvite: Boolean
  ) {
    createTenantUser(user: $user, sendInvite: $sendInvite) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      lockedFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      additionalRoles
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const submitIntegrationRun = /* GraphQL */ `
  mutation SubmitIntegrationRun($input: RunIntegrationEngineInput) {
    submitIntegrationRun(input: $input) {
      success
      message
    }
  }
`;
export const submitUserIntegrationRun = /* GraphQL */ `
  mutation SubmitUserIntegrationRun($input: RunIntegrationEngineInput) {
    submitUserIntegrationRun(input: $input) {
      success
      message
    }
  }
`;
export const updateTenantLogo = /* GraphQL */ `
  mutation UpdateTenantLogo($input: UpdateTenantLogoInput) {
    updateTenantLogo(input: $input)
  }
`;
export const lockCustomDeck = /* GraphQL */ `
  mutation LockCustomDeck(
    $customDeckId: String!
    $timestarted: String!
    $lock: Boolean!
  ) {
    lockCustomDeck(
      customDeckId: $customDeckId
      timestarted: $timestarted
      lock: $lock
    ) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        pages {
          pageId
          pageNumber
          documentVersionId
        }
        visible
        srcId
        docAccessLevel
        name
        locked
      }
      title
      editMutex {
        userId
        timeStarted
        lastSeenAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateZendeskOrg = /* GraphQL */ `
  mutation UpdateZendeskOrg($input: UpdateZendeskOrgInput!) {
    updateZendeskOrg(input: $input)
  }
`;
export const publishDocument = /* GraphQL */ `
  mutation PublishDocument($documentId: String!) {
    publishDocument(documentId: $documentId) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDocumentPageData = /* GraphQL */ `
  mutation UpdateDocumentPageData($input: UpdateJsonPagesLambdaInput!) {
    updateDocumentPageData(input: $input)
  }
`;
export const generateDownloadKey = /* GraphQL */ `
  mutation GenerateDownloadKey(
    $token: ID!
    $contentId: ID
    $contentType: ASSOCIATED_FILE_TYPE
  ) {
    generateDownloadKey(
      token: $token
      contentId: $contentId
      contentType: $contentType
    )
  }
`;
export const generateDownloadKeyByTenant = /* GraphQL */ `
  mutation GenerateDownloadKeyByTenant(
    $hubId: ID!
    $contentId: ID
    $contentType: ASSOCIATED_FILE_TYPE
  ) {
    generateDownloadKeyByTenant(
      hubId: $hubId
      contentId: $contentId
      contentType: $contentType
    )
  }
`;
export const pushAnalytics = /* GraphQL */ `
  mutation PushAnalytics($input: AWSJSON!, $userToken: String) {
    pushAnalytics(input: $input, userToken: $userToken)
  }
`;
export const pushLogs = /* GraphQL */ `
  mutation PushLogs($input: [String!]!, $shouldSendEmail: Boolean) {
    pushLogs(input: $input, shouldSendEmail: $shouldSendEmail)
  }
`;
export const getLatestDocumentVersionId = /* GraphQL */ `
  mutation GetLatestDocumentVersionId($input: AWSJSON!) {
    getLatestDocumentVersionId(input: $input)
  }
`;
export const submitTenantForm = /* GraphQL */ `
  mutation SubmitTenantForm($formId: ID!, $customValues: [CustomValuesInput!]) {
    submitTenantForm(formId: $formId, customValues: $customValues)
  }
`;
export const updateFolderSharePermissions = /* GraphQL */ `
  mutation UpdateFolderSharePermissions(
    $folderId: ID!
    $sharePermissions: [SharePermissionInput!]
    $sendEmailNotification: Boolean
  ) {
    updateFolderSharePermissions(
      folderId: $folderId
      sharePermissions: $sharePermissions
      sendEmailNotification: $sendEmailNotification
    ) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        targetCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        targetLabels {
          key
          values
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      shareStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFolderItems = /* GraphQL */ `
  mutation UpdateFolderItems(
    $foldersUpdates: [UpdateFolderInput!]!
    $rootFolderId: String
  ) {
    updateFolderItems(
      foldersUpdates: $foldersUpdates
      rootFolderId: $rootFolderId
    ) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        targetCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        targetLabels {
          key
          values
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      shareStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSubFolder = /* GraphQL */ `
  mutation CreateSubFolder($newSubFolder: CreateSubFolderInput!) {
    createSubFolder(newSubFolder: $newSubFolder) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        targetCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        targetLabels {
          key
          values
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      shareStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
