import React, { useCallback, useState, useMemo } from 'react'
import { StyleSheet, TouchableWithoutFeedback, ViewStyle } from 'react-native'
import {
  DNAButton,
  DNANotifications,
  Icon,
  DNABox,
  Iffy,
} from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { useHistory } from 'src/router'
import { useAppSettings, DeviceMode } from 'src/state/context/AppSettings'
import folderQuery from 'src/state/redux/folder/query'
import { useAllFoldersUpdatedDocsMap } from 'src/state/redux/selector/folder'
import format from 'date-fns/format'
import { DNANotificationsItemProps } from '@alucio/lux-ui/src/components/layout/DNANotifications/DNANotifications'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton'

const styles = StyleSheet.create({
  notificationIcon: {
    width: 8,
    height: 8,
    color: colors['color-danger-500'],
    marginLeft: 8,
    fontSize: 16,
  },
})

interface Styles {
  wrapper: ViewStyle,
}

type DeviceModeStyles = Record<DeviceMode, StyleSheet.NamedStyles<Styles>>

const deviceModeStyles: DeviceModeStyles = {
  desktop: StyleSheet.create({
    wrapper: {
      position: 'absolute',
      right: 1,
      top: -6,
      cursor: 'pointer',
    },
  }),
  tablet: StyleSheet.create({
    wrapper: {
      position: 'absolute',
      right: 9,
      top: 3,
      cursor: 'pointer',
    },
  }),
}

type DeviceModeButtonProps = Record<DeviceMode, DNAButtonProps>
const deviceModeButtonProps:DeviceModeButtonProps = {
  desktop: {
    appearance: 'outline',
    status: 'tertiary',
    size: 'lg',
    padding: 'sm',
  },
  tablet: {
    padding:'none',
    rounded:'full',
    appearance:'ghostLink',
    status:'dark',
    size:'xl',
  },
}

export const Notification = () => {
  const history = useHistory();
  const [contentVisible, setContentVisible] = useState<boolean>(false);
  const currentActiveFilter = useMemo(() => folderQuery.merge(folderQuery.filters.active), [])
  const updatedDocumentsMap = useAllFoldersUpdatedDocsMap(currentActiveFilter)

  const updatedDocumentsArray = Array.from(updatedDocumentsMap.entries())
  const hasUpdates = updatedDocumentsArray.length > 0
  const toggleContent = () => setContentVisible(p => !p)
  const { deviceMode } = useAppSettings()
  const currentDeviceModeButtonProps = deviceModeButtonProps[deviceMode]
  const currentDeviceModeStyle = deviceModeStyles[deviceMode]

  const items: DNANotificationsItemProps[] = []
  if (hasUpdates) {
    let maxDate: Date | undefined
    updatedDocumentsArray.forEach(obj => {
      const doc = obj[0]
      if (doc) {
        const date = new Date(doc.model.updatedAt)
        if (!maxDate || date > maxDate) {
          maxDate = date
        }
      }
    })

    items.push({
      date: format(maxDate || new Date(), 'MMMM dd, yyyy'),
      shortDescription: 'New version updates',
      actionLabel: 'Manage updates',
      icon: 'file',
      onPress: () => {
        history.push('/folders/updates')
        toggleContent();
      },
    })
  }

  // Current default notification
  items.push({
    date: 'September 11th, 2024',
    shortDescription: 'New features available!',
    actionLabel: 'See what’s new',
    icon: 'star',
    highlight: true,
    onPress: useCallback(() => {
      history.push('/notifications')
      toggleContent();
    }, []),
  })

  const BellButton: React.FC = () => (
    <DNAPopover
      lazyMount
      placement="bottom-end"
      interactive={true}
      visible={contentVisible}
      onBackdropPress={toggleContent}
      type="menu"

    >
      <DNAPopover.Anchor style={{ height: '100%' }}>
        <DNAButton
          iconLeft="bell-outline"
          onPress={toggleContent}
          testID="notification-bell"
          {...currentDeviceModeButtonProps}
        />
        <Iffy is={hasUpdates}>
          <TouchableWithoutFeedback onPress={toggleContent}>
            <DNABox
              style={currentDeviceModeStyle.wrapper}
            >
              <Icon name={'record'} style={styles.notificationIcon} />
            </DNABox>
          </TouchableWithoutFeedback>
        </Iffy>
      </DNAPopover.Anchor>

      <DNAPopover.Content offset={10}>
        <DNANotifications
          items={items}
          onPress={useCallback(() => {
            history.push('/notifications')
            toggleContent();
          }, [])}
        />
      </DNAPopover.Content>
    </DNAPopover>
  )

  return <BellButton />
}

export default Notification
