import React, { useEffect } from 'react';
import { Image, StyleSheet } from 'react-native';
import { DNABox, util } from '@alucio/lux-ui';
import * as logger from 'src/utils/logger';

const S = StyleSheet.create({
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
  },
  defaultLoaderImageSize: {
    height: 200,
    width: 200,
  },
})

export enum DNALoaderEvents {
  LOGIN = 'LOGIN',
  CONTENT_PREVIEW_MODAL = 'CONTENT_PREVIEW_MODAL',
  MEETING= 'MEETING'
}

export enum DNALoaderContexts {
  DOCUMENT_INITIAL_LOAD = 'DOCUMENT_INITIAL_LOAD',
  DOCUMENT_CHANGE = 'DOCUMENT_CHANGE'
}

interface DNALoaderProps {
  isDarkMode?: boolean
  height?: number
  width?: number
  analyticsEventType?: DNALoaderEvents
  analyticsContext?: DNALoaderContexts
}

const DNALoader: React.FC<DNALoaderProps> = ({
  isDarkMode = false,
  height,
  width,
  analyticsEventType,
  analyticsContext,
}) => {
  // analytics
  useEffect(() => {
    if (analyticsEventType) {
      const startTime = (new Date()).getTime();

      return () => {
        const endTime = (new Date()).getTime();
        const resolution = endTime - startTime;
        const loadingInfo = {
          analyticsEventType,
          analyticsContext,
          time: resolution,
        }
        logger.debug.debug('LOADER_LOAD_TIME', loadingInfo)
        analytics?.track('LOADER_LOAD_TIME', loadingInfo);
      }
    }
  }, [])

  return (
    <DNABox
      alignX="center"
      alignY="center"
      style={util.mergeStyles(
        undefined,
        S.loaderContainer,
        [{ backgroundColor: 'white' }, !isDarkMode],
      )}
    >
      <Image
        testID="dna-loader"
        style={util.mergeStyles(
          undefined,
          S.defaultLoaderImageSize,
          [{ height }, height],
          [{ width }, width],
        )}
        source={!isDarkMode
          ? require('../../../../assets/images/LoaderDNA.gif')
          : require('../../../../assets/images/LoaderDNA_dark.gif')}
      />
    </DNABox>
  );
}

export default DNALoader
