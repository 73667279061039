import React, { PropsWithChildren, useState } from 'react'
import { ScrollView } from 'react-native'
import { DNABox, Iffy } from '@alucio/lux-ui'

import DNANavigationMenu from 'src/components/DNA/NavigationMenu/DNANavigationMenu'
import DNAAppHeader from 'src/components/DNA/Header/DNAAppHeader'
import DNAFooter from 'src/components/Footer/Footer'
import NetworkConnectionBanner from 'src/components/NetworkConnection/NetworkConnectionBanner'

import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import InfoPanel from 'src/components/InfoPanel/InfoPanel'
import { RootState } from 'src/state/redux'
import { useSelector } from 'react-redux'
import useCurrentPage from '../hooks/useCurrentPage'

type DeviceModeVariants = Record<DeviceMode, React.ElementType>

const useAppContainerSharedComponents = () => {
  const { deviceMode } = useAppSettings()
  const currentPage = useCurrentPage()
  const currentConfigOptions = currentPage?.configOptions?.[deviceMode]

  return {
    currentConfigOptions,
    deviceMode,
  }
}

const AppContainerDesktop: React.FC<PropsWithChildren> = ({ children }) => {
  const { isOnline } = useAppSettings()
  const { activeItemORM } = useSelector((state: RootState) => state.infoPanel)
  const [width, setWidth] = useState(0)
  const { currentConfigOptions } = useAppContainerSharedComponents()
  const { enableHorizontalScroll, disableGlobalScroll } = { ...currentConfigOptions }

  return (
    <DNABox fill appearance="col">
      <DNAAppHeader />
      <DNABox fill style={{ zIndex: -1 }}>
        <DNANavigationMenu />
        <DNABox appearance="col" fill>
          <DNABox>
            <Iffy is={!isOnline}>
              <NetworkConnectionBanner />
            </Iffy>
          </DNABox>
          <DNABox fill>
            <ScrollView
              contentContainerStyle={{
                flex: 1,
                justifyContent: 'space-between',
                zIndex: -1,
                overflow: disableGlobalScroll ? undefined : 'scroll',
              }}
            >
              {enableHorizontalScroll
                ? <>
                  <DNABox
                    appearance="row"
                    onLayout={(event) => {
                      const { width = 0 } = event.nativeEvent.layout
                      setWidth(width)
                    }}
                  >
                    {children}
                  </DNABox>
                  <DNAFooter width={width} />
                </>
                : <>
                  {children}
                  <DNAFooter />
                </>
              }
            </ScrollView>
          </DNABox>
        </DNABox>
        <InfoPanel
          variant="Folder"
          itemORM={activeItemORM}
        />
      </DNABox>
    </DNABox>
  )
}

const AppContainerTablet: React.FC<PropsWithChildren> = ({ children }) => {
  const { isOnline } = useAppSettings()
  const { activeItemORM } = useSelector((state: RootState) => state.infoPanel)

  return (
    <DNABox fill appearance="col">
      {/* DEV Note - Items added under the app header require a zIndex of -1 for the popups to
          show properly */}
      <DNAAppHeader />
      <DNABox fill appearance="col" style={{ zIndex: -1 }}>
        {/* TODO: Investigate a better way of getting these items to have visibility underneath
            popups from the DNAAppHeader besides having to add these zIndex:-1 styles, should
            probably switch to adding a +1 on the app header zindex which would remove the necessity
            for devs to remember to add -1 props here but that could have unintended consequences
            with modals */}
        <DNABox style={{ zIndex: -1 }}>
          <Iffy is={!isOnline}>
            <NetworkConnectionBanner />
          </Iffy>
        </DNABox>
        <DNABox fill appearance="row">
          <ScrollView style={{ zIndex: -1 }} contentContainerStyle={{ flex: 1, zIndex: -1 }}>
            {children}
          </ScrollView>
          <InfoPanel
            variant="Folder"
            itemORM={activeItemORM}
          />
        </DNABox>
        <DNANavigationMenu />
      </DNABox>
    </DNABox>
  )
}

const deviceModeVariants:DeviceModeVariants = {
  desktop: AppContainerDesktop,
  tablet: AppContainerTablet,
}

const AppContainer: React.FC<PropsWithChildren> = (props) => {
  const { deviceMode } = useAppContainerSharedComponents()
  const CurrentDeviceModeVariant = deviceModeVariants[deviceMode]
  return <CurrentDeviceModeVariant {...props}/>
}

export default AppContainer
