import { Interpreter, ActorRef, State } from 'xstate'
import { GraphQLResult, GraphQLQuery } from '@aws-amplify/api'

/** AMPLIFY */
import {
  DocumentVersion,
  DocumentVersionChangeType,
  AttachedFile,
  AssociatedFile,
} from '@alucio/aws-beacon-amplify/src/models'
import {
  CreateDocumentVersionFromS3UploadInput,
  CreateDocumentVersionFromS3UploadMutation,
  CreateDocumentVersionFromExistingMutation,
  UpdateDocumentThumbnailInput,
} from '@alucio/aws-beacon-amplify/src/API'

/** BEACON */
import { UploadStatus } from 'src/components/DNA/FileUpload/FileUpload'
import { DocumentORM } from 'src/types/types'
import { DocumentVersionDiff } from 'src/state/machines/versioning/observableVersion'
import {
  SlideSettingsEvents,
  SlideSettingsContext,
  SlideSettingsState,
} from './SlideSettings/slideSettingsTypes'

/** LUX-UI */
import {
  InformationMessageVariants,
} from '@alucio/lux-ui/src/components/controls/InformationMessage/InformationMessage'
import { ContentPageData } from 'src/hooks/useContentPageData/useContentPageData'

/**
 * ---------------------
 *  META VALUES
 * ---------------------
 */
export type InfoMessage = { message: string, status: InformationMessageVariants }
export type InfoMessageMeta = { infoMessage: InfoMessage }
export type CancelMessageMeta = { cancelMessage: InfoMessage }
export type MetaValues = Partial<InfoMessageMeta & CancelMessageMeta>
export type StateMeta = Record<string, MetaValues>

/**
 * ---------------------
 *  STATE MACHINE
 * ---------------------
 */

// https://github.com/statelyai/xstate/issues/849#issuecomment-1001212591
// [TODO] - Make reusable?
type ActorRefFromInterpreter<
  T extends Interpreter<any, any, any, any>
> = T extends Interpreter<infer TC, any, infer TE>
  ? ActorRef<TE, State<TC, TE>>
  : never;

export type UpdateVersionContext = {
  documentInfoIsDirty: boolean,
  documentSettingsIsDirty: boolean,
  documentPublishIsDirty: boolean,
  documentSlidesDataIsDirty: boolean,
  changesCheckResult?: ChangesCheckResult,
  errors: Record<string, string>,
  documentVersionId: string,
  versionForm: Partial<DocumentVersion>,
  hasOptimizedFinishedThisSession: boolean,
  getDocumentORM: () => DocumentORM,
  cancelUpload: boolean,
  selectedTabIndex: number,
  // [TODO] - Fix versionActor type
  versionActor?: any,
  slideSettingsActor?: ActorRefFromInterpreter<
    Interpreter<SlideSettingsContext, any, SlideSettingsEvents, SlideSettingsState>
  >
}

export type UpdateVersionState = {
  value: { }
  context: UpdateVersionContext
}

export type UpdateVersionActor = Interpreter<
  UpdateVersionContext,
  any,
  UpdateVersionEvents,
  UpdateVersionState
>

export enum StateTags {
  // TAB TAGS
  DOCUMENT_INFO_TAB = 'DOCUMENT_INFO_TAB',
  DOCUMENT_SETTINGS_TAB = 'DOCUMENT_SETTINGS_TAB',
  DOCUMENT_ASSOCIATED_FILES = 'DOCUMENT_ASSOCIATED_FILES',
  DOCUMENT_RELEASE_NOTES_TAB = 'DOCUMENT_RELEASE_NOTES_TAB',
  DOCUMENT_PUBLISH_TAB = 'DOCUMENT_PUBLISH_TAB',
  // DISABLE TAGS
  DISABLE_EXIT = 'DISABLE_EXIT',
  DISABLE_NAV = 'DISABLE_NAV',
  DISABLE_MODIFY = 'DISABLE_MODIFY',
  DISABLE_DRAFT_DELETE = 'DISABLE_DRAFT_DELETE',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
}

type ChangesCheckResult = {
  disableMinor: boolean,
  detectedChangeType: DocumentVersionChangeType,
}

/**
 * ---------------------
 *  EVENTS
 * ---------------------
 */

// [NOTE] - Extracts "type" keys into a string union
type Distribute<U> = U extends { type: string } ? U['type'] : never;

export type EVT_ATTACHED_FILE_UPLOAD_STATUS_CHANGE = {
  type: 'ATTACHED_FILE_UPLOAD_STATUS_CHANGE',
  payload: {
    status: UploadStatus
  }
}

export type EVT_ATTACHED_FILE_UPLOADED = {
  type: 'ATTACHED_FILE_UPLOADED',
  payload: {
    attachedFile: AttachedFile
  }
}

export type EVT_ASSOCIATED_FILE_DELETE = {
  type: 'ASSOCIATED_FILE_DELETE',
  payload: { attachmentId: string }
}

export type EVT_ASSOCIATED_FILE_UPDATED = {
  type: 'ASSOCIATED_FILE_UPDATED',
  payload: {
    associatedFile: AssociatedFile
  }
}

export type EVT_ASSOCIATED_DOCUMENT_LINK = {
  type: 'ASSOCIATED_DOCUMENT_LINK',
  payload: { documentId: string }
}

export type EVT_SET_IS_DIRTY = {
  type: 'SET_IS_DIRTY',
  payload: {
    type: 'info' | 'settings' | 'publish' | 'slidesData',
    isDirty: boolean
  }
}

export type EVT_UPLOAD_THUMBNAIL = {
  type: 'UPLOAD_THUMBNAIL',
  payload: { file: File }
}

export type EVT_REMOVE_THUMBNAIL = {
  type: 'REMOVE_THUMBNAIL'
}

export type EVT_UPDATE_THUMBNAIL = {
  type: 'UPDATE_THUMBNAIL' | 'REMOVE_THUMBNAIL',
  data: {
    S3Key?: string,
    apiInputObj: UpdateDocumentThumbnailInput,
    file?: File,
  }
}

export type EVT_UPDATED_THUMBNAIL = {
  type: 'UPDATED_THUMBNAIL',
  data: {
    selectedThumbnail?: number,
    hasCustomThumbnail?: boolean,
  }
}

export type EVT_CREATE_FROM_EXISTING = { type: 'CREATE_FROM_EXISTING' }

export type EVT_CREATE_FROM_UPLOAD = {
  type: 'CREATE_FROM_UPLOAD',
  payload: { file: File }
}
export type EVT_CANCEL_UPLOAD = {
  type: 'CANCEL_UPLOAD'
}

export type EVT_SWITCH_TAB_INFO = {
  type: 'SWITCH_TAB_INFO'
}

export type EVT_SWITCH_TAB_SETTINGS = {
  type: 'SWITCH_TAB_SETTINGS',
  payload?: {
    versionForm?: Partial<DocumentVersion>
  }
}

export type EVT_SWITCH_TAB_ASSOCIATED_FILES = {
  type: 'SWITCH_TAB_ASSOCIATED_FILES',
  payload?: {
    versionForm?: Partial<DocumentVersion>
  }
}

export type EVT_SWITCH_TAB_PUBLISH = {
  type: 'SWITCH_TAB_PUBLISH',
  payload?: {
    versionForm?: Partial<DocumentVersion>
  }
}

export type EVT_SWITCH_TAB_NEXT = {
  type: 'SWITCH_TAB_NEXT',
  payload?: {
    versionForm?: Partial<DocumentVersion>
  }
}

export type EVT_SWITCH_TAB_PREV = {
  type: 'SWITCH_TAB_PREV'
}

export type EVT_SAVE_DRAFT = {
  type: 'SAVE_DRAFT',
  payload: {
    versionForm?: Partial<DocumentVersion>,
    contentPageData?: ContentPageData[],
  }
}

export type EVT_DELETE_DRAFT = {
  type: 'DELETE_DRAFT'
}

export type EVT_SWITCH_DOCUMENT_VERSION = {
  type: 'SWITCH_DOCUMENT_VERSION',
  payload: {
    documentVersionId: string,
  }
}

export type EVT_SYNC_VERSION_FORM = {
  type: 'SYNC_VERSION_FORM',
  payload: {
    versionForm: Partial<DocumentVersion>,
  }
}

export type EVT_SYNC_DISTRIBUTABLE = {
  type: 'SYNC_DISTRIBUTABLE',
  payload: {
    versionForm: Pick<DocumentVersion, 'distributable'>
  }
}

export type EVT_PUBLISH_VERSION = {
  type: 'PUBLISH_VERSION',
  payload: {
    versionForm?: Partial<DocumentVersion>,
    contentPageData?: ContentPageData[],
  }
}

export type EVT_SCHEDULE_PUBLISH_VERSION = {
  type: 'SCHEDULE_PUBLISH_VERSION',
  payload: {
    versionForm?: Partial<DocumentVersion>,
    contentPageData?: ContentPageData[],
  }
}

export type EVT_CANCEL_SCHEDULE_VERSION = {
  type: 'CANCEL_SCHEDULE_VERSION',
}

export type EVT_CREATE_FILE = {
  type: 'done.invoke.uploadFile',
  data: {
    S3Key: string,
    apiInputObj: CreateDocumentVersionFromS3UploadInput,
    file: File,
  }
}

export type EVT_CREATE_FILE_RECORD = {
  type: 'done.invoke.createRecordForUpload',
  data: GraphQLResult<CreateDocumentVersionFromS3UploadMutation>
}

export type EVT_UPDATED_SLIDES_DATA = {
  type: 'done.invoke.UpdateVersion.draft.draftSaveProcessing:invocation[0]',
}

// This event will be fired from publish service automatically when code throws an exception
export type EVT_PUBLISH_ERROR = {
  type: 'error.platform.publish',
  data: Error
}

export type EVT_VERSION_UPDATE = { type: 'VERSION_UPDATE', payload: DocumentVersionDiff }

// From Child -> Parent Machine
export type EVT_SLIDE_SETTINGS_SYNC = {
  type: 'SLIDE_SETTINGS_SYNC',
  payload: Partial<DocumentVersion>
}

/**
 * ---------------------
 *  SERVICES
 * ---------------------
 */

export type CreateFromExistingOptimizing = {
  data: GraphQLResult<GraphQLQuery<CreateDocumentVersionFromExistingMutation>>
}

export type UpdateVersionServices = 
  | CreateFromExistingOptimizing

// [TODO] - Is there a convenient way to create a helper objectthat generates events for us?
//          Enum -> Events | Helper Object perhaps
export type UpdateVersionEvents =
  | EVT_CREATE_FROM_UPLOAD
  | EVT_CANCEL_UPLOAD
  | EVT_CREATE_FROM_EXISTING
  | EVT_SET_IS_DIRTY
  | EVT_CREATE_FILE_RECORD
  | EVT_ATTACHED_FILE_UPLOAD_STATUS_CHANGE
  | EVT_ATTACHED_FILE_UPLOADED
  | EVT_ASSOCIATED_FILE_DELETE
  | EVT_ASSOCIATED_FILE_UPDATED
  | EVT_ASSOCIATED_DOCUMENT_LINK
  | EVT_SYNC_VERSION_FORM
  | EVT_SYNC_DISTRIBUTABLE
  | EVT_SWITCH_DOCUMENT_VERSION
  | EVT_SWITCH_TAB_INFO
  | EVT_SWITCH_TAB_SETTINGS
  | EVT_SWITCH_TAB_ASSOCIATED_FILES
  | EVT_SWITCH_TAB_PUBLISH
  | EVT_SWITCH_TAB_NEXT
  | EVT_SWITCH_TAB_PREV
  | EVT_VERSION_UPDATE
  | EVT_PUBLISH_VERSION
  | EVT_SCHEDULE_PUBLISH_VERSION
  | EVT_CANCEL_SCHEDULE_VERSION
  | EVT_CREATE_FILE
  | EVT_PUBLISH_ERROR
  | EVT_SAVE_DRAFT
  | EVT_UPDATE_THUMBNAIL
  | EVT_UPLOAD_THUMBNAIL
  | EVT_REMOVE_THUMBNAIL
  | EVT_UPDATED_THUMBNAIL
  | EVT_DELETE_DRAFT
  | EVT_SLIDE_SETTINGS_SYNC
  | EVT_UPDATED_SLIDES_DATA

export type SwitchTabEvents =
  | EVT_SWITCH_TAB_INFO
  | EVT_SWITCH_TAB_SETTINGS
  | EVT_SWITCH_TAB_PUBLISH
  | EVT_SWITCH_TAB_ASSOCIATED_FILES
  | EVT_SWITCH_TAB_PREV
  | EVT_SWITCH_TAB_NEXT

export type SyncFormEvents =
  | EVT_SYNC_VERSION_FORM
  | EVT_SYNC_DISTRIBUTABLE
  | EVT_SWITCH_TAB_SETTINGS
  | EVT_SWITCH_TAB_PUBLISH
  | EVT_SWITCH_TAB_NEXT

export type EventKeys = Distribute<UpdateVersionEvents>
