import { useEffect, useMemo, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { ZenObservable } from 'zen-observable-ts';
import { Hub as HubSub } from '@aws-amplify/core'
import { ControlMessage } from '@aws-amplify/datastore/lib-esm/sync'
import { API, graphqlOperation } from '@aws-amplify/api';
import { DataStore } from '@aws-amplify/datastore';
import { LOG_MESSAGE_EVENT, SYNC_STATE_EVENT } from '@alucio/core';
import {
  AttachedFile,
  CustomDeck,
  CustomFormRecord,
  Document, DocumentAccessLevel,
  DocumentStatus,
  DocumentVersion,
  EmailTemplate,
  EmailTemplateStatus,
  Folder,
  Hub,
  Meeting,
  Tenant,
  User,
  UserNotations,
} from '@alucio/aws-beacon-amplify/src/models';
import { getUser } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import CacheDB from 'src/worker/db/cacheDB';
import workerChannel from 'src/worker/channels/workerChannel';
import hydrate from 'src/state/datastore/hydrate';
import { useSafePromise } from '@alucio/lux-ui';
import { getAuthHeaders } from 'src/utils/loadCloudfrontAsset/common';
import { useAppSettings } from 'src/state/context/AppSettings';
import { store, useDispatch } from 'src/state/redux';
import { attachedFileActions } from 'src/state/redux/slice/attachedFile';
import { cacheActions } from 'src/state/redux/slice/Cache/cache';
import { customDeckActions } from 'src/state/redux/slice/customDeck';
import { customFormRecordActions } from 'src/state/redux/slice/customFormRecord';
import { documentActions } from 'src/state/redux/slice/document';
import { documentVersionActions } from 'src/state/redux/slice/documentVersion';
import { emailTemplateActions } from 'src/state/redux/slice/emailTemplate';
import { folderActions } from 'src/state/redux/slice/folder';
import { hubActions } from 'src/state/redux/slice/hub';
import { meetingActions } from 'src/state/redux/slice/meeting';
import { tenantActions } from 'src/state/redux/slice/tenant';
import { userActions } from 'src/state/redux/slice/user';
import { userNotationsActions } from 'src/state/redux/slice/userNotations';
import { allCustomDecks } from 'src/state/redux/selector/folder';
import { useCurrentUser, useUserTenant } from 'src/state/redux/selector/user';
import { getIndexedValidCustomFields, matchesLockedFilters } from './query';
import * as logger from 'src/utils/logger';
import { useLDClient } from 'launchdarkly-react-client-sdk';

const DOC_MAP = {
  attachedFile: {
    INSERT: attachedFileActions.add,
    // [TODO] - It's possible that the lambda function using a combined mutation is
    //  causing an "update" sub to fire instead of an "add"
    UPDATE: attachedFileActions.upsert,
  },
  customDeck: {
    INSERT: customDeckActions.add,
    UPDATE: customDeckActions.update,
  },
  customFormRecord: {
    INSERT: customFormRecordActions.add,
    UPDATE: customFormRecordActions.update,
  },
  document: {
    INSERT: documentActions.add,
    // for docs we are doing an upsert instead of update
    // because if doc has a lockedFilter and Publisher updates the doc to not lockedFilters we modify redux accordingly
    UPDATE: documentActions.upsert,
    // remove actions is to remove docs that DO NOT satisfy the lockedFilters or are DELETED docs
    REMOVE: documentActions.remove,
  },
  documentVersion: {
    INSERT: documentVersionActions.add,
    UPDATE: documentVersionActions.upsert,
    REMOVE: documentVersionActions.remove,
  },
  emailTemplate: {
    INSERT: emailTemplateActions.add,
    UPDATE: emailTemplateActions.update,
    REMOVE: emailTemplateActions.remove,
  },
  folder: {
    INSERT: folderActions.add,
    UPDATE: folderActions.upsert,
  },
  hubs: {
    INSERT: hubActions.add,
    UPDATE: hubActions.update,
    REMOVE: hubActions.remove,
  },
  meetings: {
    INSERT: meetingActions.add,
    UPDATE: meetingActions.update,
  },
  tenant: {
    INSERT: tenantActions.add,
    UPDATE: tenantActions.update,
  },
  user: {
    INSERT: userActions.add,
    UPDATE: userActions.update,
  },
  userNotations: {
    INSERT: userNotationsActions.add,
    UPDATE: userNotationsActions.upsert,
    REMOVE: userNotationsActions.remove,
  },
}

const setupWorkerChannel = () => {
  // We want to attempt to wake up the worker before sending a message
  workerChannel.registerBeforeHook(async () => {
    logger.offline.serviceWorker.debug('Sending keepalive request to wake up worker')
    await fetch('/keepalive')
  })
  logger.offline.serviceWorker.debug('Worker channel setup complete')
}

setupWorkerChannel()

export function useDataStoreSubscription(isDataStoreReady: boolean) {
  const [isReady, setIsReady] = useState<boolean>(false)
  const [deferSyncLoad, setDeferSyncLoad] = useState<boolean>(false)
  const subscriptions = useRef<ZenObservable.Subscription[]>([])
  const hubListener = useRef<() => void | undefined>()
  const isDatastoreSyncing = useRef<boolean>(false)
  const datastoreSyncingDuration = useRef<number>(0)
  
  const currentUser = useCurrentUser()
  const currentTenant = useUserTenant();
  const LDClient = useLDClient()
  const { isOfflineEnabled } = useAppSettings();
  const { makeSafe } = useSafePromise()
  
  const validIndexedCustomFields = useMemo(
    () => getIndexedValidCustomFields(currentTenant?.config?.customFields || []),
    [currentTenant]
  );

  useEffect(
    () => {
      if (!isDataStoreReady) {
        return;
      }

      logger.userInit.debug('Subscribing to datastore-beacon listener')
      hubListener.current = HubSub.listen(
        'datastore-beacon',
        async (capsule) => {
          logger.userInit.debug('datastore-beacon', capsule)
          const { event } = capsule.payload
          
          if (event === ControlMessage.SYNC_ENGINE_SYNC_QUERIES_STARTED) {
            logger.userInit.debug("disabling client-side subscriptions")
            isDatastoreSyncing.current = true
            datastoreSyncingDuration.current = performance.now()
          }

          // [TODO] - Need to handle an offline disconnect in the middle of syncing
          if (event === ControlMessage.SYNC_ENGINE_SYNC_QUERIES_READY) {
            logger.userInit.debug("enabling client-side subscriptions")
            isDatastoreSyncing.current = false
            logger.userInit.debug(
              'datastore-beacon',
              `${performance.now() - datastoreSyncingDuration.current}ms`,
              'triggering rehydrate'
            )
            datastoreSyncingDuration.current = 0
            setDeferSyncLoad(true)
          }
        }
      )
      return () => {
        if (hubListener.current) {
          logger.userInit.debug('Unsubscribing to datastore-beacon listener')
          hubListener.current?.()
        }
      }
    },
    [isDataStoreReady]
  )

  // useEffect(
  //   () => {
  //     if (isDatastoreSyncing.current && !isOnline) {
  //       isDatastoreSyncing.current = false
  //       datastoreSyncingDuration.current = 0
  //       logger.userInit.debug('Offline mode detected - Unsetting subscription no-op')
  //       // [NOTE] - We do not need to unset deferSyncLoad as if it's already executing
  //       //        - There should be nothing else that interferes if it's already begun
  //       //          and subscriptions are re-enabled at this point anyways
  //       //        - Technically when offline as well, Amplify subscriptions will be unsubscribed automatically
  //       //          this is just a fallback, but any reconnection will hook back into the normal paused -> unpaused lifecycle 
  //     }
  //   },
  //   // Not exactly the same offline monitor that Amplify uses, but it should still be accurate
  //   [isOnline]
  // )

  // When a delta/full sync happens after the app has initialized, we need to rehydrate Redux
  // after the sync since we no-op subscriptions temporarily during sync
  useEffect(
    () => {
      if (deferSyncLoad) {
        if (LDClient === undefined || isOfflineEnabled === undefined) {
          logger.userInit.warn('Not ready to defer sync load', { LDClient, isOfflineEnabled })
          return
        }

        makeSafe(new Promise<void>(
          (resolve) => {
            const t0 = performance.now()
            logger.userInit.debug('Rehydrating Redux - Start')
            hydrate(isOfflineEnabled, LDClient, true).then(() => {
              setDeferSyncLoad(false)
              logger.userInit.debug('Rehydrating Redux - Finished', `${performance.now() - t0}ms`)
              resolve()
            })
          }
        ))
      }
    },
    [isOfflineEnabled, deferSyncLoad, LDClient]
  )

  useEffect(() => {
    // Prevent subscribing to datastore before it's ready
    // This prevents duplicate records on initial login (1 set from subscriptions and 1 from hydrating)
    if (!isDataStoreReady) return;

    logger.clientState.dataStore.info('DataStore is ready, setting up subscriptions');

    // [TODO]: Can probably make this generic
    // [TODO]: BEAC-999 the doc subscription is slightly different because we are filtering DELETED and lockedFilter files before populating redux
    const docsSub = DataStore
      .observe(Document)
      .subscribe(async msg => {
        if (isDatastoreSyncing.current) {
          return
        }

        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        if (updatedObj) {
          if (updatedObj.status === 'DELETED') {
            logger.auth.sessionManagement.subscriptions.debug(`Document ${element.id} marked as DELETED`)
            store.dispatch(DOC_MAP.document.REMOVE(updatedObj))
          } else {
            store.dispatch(DOC_MAP.document[opType](updatedObj))
          }
        }
      });

    const verSub = DataStore
      .observe(DocumentVersion)
      .subscribe(async msg => {
        if (isDatastoreSyncing.current) {
          return
        }

        const { model, opType, element } = msg
        const updatedObj = await DataStore.query<DocumentVersion>(model, element.id)
        logger.auth.sessionManagement.subscriptions.debug(`Got Subscription Update for DocVer ${element.id}`)
        if (updatedObj) {
          if (updatedObj.status === 'DELETED') {
            // This doc version draft was deleted so we remove the record from redux
            logger.auth.sessionManagement.subscriptions.debug(`DocumentVersion ${element.id} marked as DELETED`)
            store.dispatch(DOC_MAP.documentVersion.REMOVE(updatedObj))
          } else {
            let matchesFilter = true;
            let isPersonalFile = false;

            // [TODO-2126] - Do a performance analysis since runs on every version subscription (which could fire 3 off for any "singular" update)
            if (updatedObj.status === DocumentStatus.PUBLISHED) {
              logger.auth.sessionManagement.subscriptions.debug('Update for Published DocVer...checking filters')
              // Need to check for locked filters
              const docVersions = await DataStore.query<DocumentVersion>(
                DocumentVersion,
                c => c.and(p => [p.id.beginsWith(updatedObj.documentId), p.status.ne(DocumentStatus.DELETED)]),
                { sort: s => s.versionNumber('DESCENDING') },
              )
              const latestPublished = docVersions.find((ver) => ver.status === DocumentStatus.PUBLISHED)
              logger.auth.sessionManagement.subscriptions.debug(
                `Fetched ${docVersions.length} versions. Latest Published ID is: ${latestPublished?.id}`,
              )

              if (latestPublished?.id === updatedObj.id) {
                logger.auth.sessionManagement.subscriptions.debug(
                  'Updated object is latest published, evaluating filters',
                )
                const doc = await DataStore.query<Document>(Document, updatedObj.documentId)
                isPersonalFile = doc?.accessLevel === DocumentAccessLevel.USER &&
                  doc?.createdBy === currentUser.userProfile?.email;
                matchesFilter = matchesLockedFilters(
                  currentUser.userProfile?.lockedFiltersCustomValues!,
                  validIndexedCustomFields)(updatedObj.status === DocumentStatus.PUBLISHED, updatedObj.customValues)

                if (!matchesFilter && !isPersonalFile) {
                  // eslint-disable-next-line max-len
                  logger.auth.sessionManagement.subscriptions.debug(`Doc no longer matches filters, removing document ${updatedObj.documentId}`)
                  doc && store.dispatch(DOC_MAP.document.REMOVE(doc))
                  docVersions.forEach((ver) => { store.dispatch(DOC_MAP.documentVersion.REMOVE(ver)) })
                } else {
                  // Repopulate Redux if needed
                  const docVerFromRedux = store
                    .getState()
                    .documentVersion
                    .records
                    .filter((ver) => ver.documentId === updatedObj.documentId);

                  if (docVerFromRedux.length !== docVersions.length) {
                    logger.auth.sessionManagement.subscriptions.debug('Refreshing Redux with doc and docversions')
                    const doc = await DataStore.query<Document>(Document, updatedObj.documentId)
                    if (!doc) {
                      logger.auth.sessionManagement.subscriptions.warn(
                        `Unable to locate doc ${updatedObj.documentId} from datastore`,
                      )
                    } else {
                      store.dispatch(DOC_MAP.document.UPDATE(doc))
                      if (opType !== 'INSERT') {
                        docVersions.forEach((ver) => { store.dispatch(DOC_MAP.documentVersion.UPDATE(ver)) })
                      }
                    }
                  }
                }
              }
            }
            if (matchesFilter || isPersonalFile) {
              store.dispatch(DOC_MAP.documentVersion[opType](updatedObj))

              // @ts-ignore - batchSave debugging
              // console.log('Receieved Sub', updatedObj.id + ' ' + updatedObj._version)
              // Make sure folders are updated to the latest version if auto-updating
              // NOTE: Including validation for null because that whats we get instead of undefined if the
              // field does not exist in DynamoDB Tenant record
              if (updatedObj.status === DocumentStatus.PUBLISHED &&
                currentTenant?.folderUpdateGracePeriodDays !== null &&
                currentTenant?.folderUpdateGracePeriodDays !== undefined) {
                const folders = store.getState().folder.records
                store.dispatch(folderActions.applyAutoUpdate(
                  folders,
                  [updatedObj],
                  currentTenant?.folderUpdateGracePeriodDays,
                ))
                const customDecks = allCustomDecks(store.getState());
                store.dispatch(customDeckActions.applyAutoUpdate(
                  customDecks, currentTenant?.folderUpdateGracePeriodDays));
              }
            }
          }
        }
      });

    const userNotationsSub = DataStore
      .observe(UserNotations)
      .subscribe(async msg => {
        if (isDatastoreSyncing.current) {
          return
        }

        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        store.dispatch(DOC_MAP.userNotations[opType](updatedObj))
      });

    const attachedFileSub = DataStore
      .observe(AttachedFile)
      .subscribe(async msg => {
        if (isDatastoreSyncing.current) {
          return
        }

        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        store.dispatch(DOC_MAP.attachedFile[opType](updatedObj))
      });

    const customDeckSub = DataStore
      .observe(CustomDeck)
      .subscribe(async msg => {
        if (isDatastoreSyncing.current) {
          return
        }

        const { model, opType, element } = msg;
        const updatedObj = await DataStore.query(model, element.id)
        store.dispatch(DOC_MAP.customDeck[opType](updatedObj))
      })

    const customFormRecordSub = DataStore
      .observe(CustomFormRecord)
      .subscribe(async msg => {
        if (isDatastoreSyncing.current) {
          return
        }

        const { model, opType, element } = msg;
        const updatedObj = await DataStore.query(model, element.id)
        store.dispatch(DOC_MAP.customFormRecord[opType](updatedObj))
      })

    const tenantSub = DataStore
      .observe(Tenant)
      .subscribe(async msg => {
        if (isDatastoreSyncing.current) {
          return
        }

        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        store.dispatch(DOC_MAP.tenant[opType](updatedObj))
      });

    const userSub = DataStore
      .observe<User>(User)
      .subscribe(async msg => {
        if (isDatastoreSyncing.current) {
          return
        }

        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        // AppSync subscription notifications do not include the bookmark array due to field level
        // permissions. As such we need to check to see if it exists and if not query the API directly
        if (updatedObj?.bookmarkedDocs || element.id !== currentUser.userProfile?.id) {
          store.dispatch(DOC_MAP.user[opType](updatedObj))
        } else {
          // Need to query AppSync directly. Data in datastore was updated via subscription and is missing
          // bookmarks
          const queryParams = {
            id: element.id,
          }
          const updatedUser: any = await API.graphql(graphqlOperation(getUser, queryParams));
          store.dispatch(DOC_MAP.user[opType](updatedUser.data.getUser))
        }
      });

    const emailTemplateSub = DataStore
      .observe(EmailTemplate)
      .subscribe(async msg => {
        if (isDatastoreSyncing.current) {
          return
        }

        const { model, opType, element } = msg
        const updatedObj = await DataStore.query<EmailTemplate>(model, element.id)
        let matchesFilter = true;

        if (updatedObj?.status === EmailTemplateStatus.ACTIVE) {
          logger.auth.sessionManagement.subscriptions.debug('Updated object is Active, evaluating filters')
          matchesFilter = matchesLockedFilters(
            currentUser.userProfile?.lockedFiltersCustomValues!,
            validIndexedCustomFields)(true, updatedObj.customFilterValues)

          if (!matchesFilter) {
            logger.auth.sessionManagement.subscriptions.debug(
              `Email Template no longer matches filters, removing emailTemplate ${updatedObj.id}`,
            )
            const emailTemplate = await DataStore.query<EmailTemplate>(model, element.id)
            emailTemplate && store.dispatch(DOC_MAP.emailTemplate.REMOVE(emailTemplate))
          }
        }

        if (matchesFilter) {
          store.dispatch(DOC_MAP.emailTemplate[opType](updatedObj))
        }
      });

    const meetingsSub = DataStore
      .observe(Meeting)
      .subscribe(async msg => {
        if (isDatastoreSyncing.current) {
          return
        }

        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        store.dispatch(DOC_MAP.meetings[opType](updatedObj))
      });

    const hubsSub = DataStore
      .observe(Hub)
      .subscribe(async msg => {
        if (isDatastoreSyncing.current) {
          return
        }

        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        if (updatedObj) {
          if (updatedObj.status === 'DELETED') {
            store.dispatch(DOC_MAP.hubs.REMOVE(updatedObj))
          } else {
            store.dispatch(DOC_MAP.hubs[opType](updatedObj))
          }
        }
      });

    const folderSub = DataStore
      .observe(Folder)
      .subscribe(async msg => {
        if (isDatastoreSyncing.current) {
          return
        }

        const { model, opType, element } = msg
        const updatedObj = await DataStore.query(model, element.id)
        store.dispatch(DOC_MAP.folder[opType](updatedObj))
      });

    subscriptions.current = [
      attachedFileSub,
      customDeckSub,
      customFormRecordSub,
      docsSub,
      verSub,
      emailTemplateSub,
      folderSub,
      hubsSub,
      meetingsSub,
      tenantSub,
      userSub,
      userNotationsSub,
    ];

    logger.auth.sessionManagement.subscriptions.info('Subscriptions set up complete');
    setIsReady(true)

    return () => {
      logger.auth.sessionManagement.subscriptions.info('Unsubscribing from all subscriptions');
      subscriptions.current.forEach(sub => sub.unsubscribe());
    }
  }, [isDataStoreReady])

  return isReady;
}

export const useSyncMachineSubscription = (isDataStoreReady: boolean, isOfflineEnabled?: boolean) => {
  const workerSubs = useRef<ZenObservable.Subscription[]>([])
  const [isReady, setIsReady] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { isPWAStandalone } = useAppSettings()
  // PWALogger callback to save logs into Redux
  // [TODO-PWA] - DISABLE LOGGING IN PRODUCTION
  // useEffect(() => {
  //   logger.PWALogger.subscriptions.setCallback(msg => store.dispatch(cacheActions.addLog('CLIENT - \t\t' + msg)))
  // }, [])

  useEffect(() => {
    const startSubs = async () => {
      if (!isDataStoreReady || !isOfflineEnabled) return;

      logger.PWALogger.debug('Starting subscriptions')

      // [TODO-PWA]
      //  - Correctly handle if Broadcast-Channel on iOS ever closes for any reason
      //    https://github.com/pubkey/broadcast-channel#handling-indexeddb-onclose-events
      workerChannel
        .setOnCloseCallback(() => {
          logger.PWALogger.debug('IDB closed');
          dispatch(cacheActions.addLog('CLIENT - IDB Closed'));
        })

      const TempSub = workerChannel
        .observable
        .filter(msg => msg.type === 'SYNC_STATE')
        .subscribe(_ => {
          logger.PWALogger.debug('Received initial SyncMachine State')
          setIsReady(true)
          TempSub.unsubscribe()
        })

      //  Subscribe to SyncManager
      const syncMachineSub = workerChannel
        .observable
        .filter(msg => msg.type === 'SYNC_STATE')
        .subscribe(m => {
          const msg = m as SYNC_STATE_EVENT;
          dispatch(cacheActions.updateSync(msg.state));
        })

      const authSub = workerChannel
        .observable
        .filter(msg => msg.type === 'REQUEST_AUTH_HEADERS')
        .subscribe(async () => {
          logger.PWALogger.debug('Sending requested auth headers')

          // [TODO-PWA] - Should handle an error or timeout here or in the machine
          const authHeaders = await getAuthHeaders()
          // TODO: BEAC-4119 Validate this properly using the state machine
          if (authHeaders['Alucio-Authorization'] === '') {
            logger.PWALogger.error('No auth headers returned, you are possibly offline')
            return
          }

          workerChannel.postMessageExtended({
            type: 'AUTH_HEADERS',
            headers: authHeaders,
          })
        })

      const logSub = workerChannel
        .observable
        .filter(msg => msg.type === 'LOG_MESSAGE')
        .subscribe(m => {
          const msg: LOG_MESSAGE_EVENT = m as LOG_MESSAGE_EVENT
          logger.PWALogger[msg.level.toLowerCase()](msg.message)
        })

      logger.PWALogger.debug('Sending Client "Ready" state to WORKER')

      // For iOS we don't start syncing immediately if they're not in PWA to prevent excess syncing
      const isMeetingPopOutRoute = window.location.href.includes('meeting-content')
      workerChannel.postMessageExtended(
        {
          type: 'CLIENT_CONNECTED',
          value: isMeetingPopOutRoute || (isIOS && !isPWAStandalone) ? 'PAUSE_SYNC' : 'START_SYNC',
        })
      logger.PWALogger.debug('Waiting for SyncMachine State')

      workerSubs.current.push(syncMachineSub)
      workerSubs.current.push(authSub)
      workerSubs.current.push(logSub)
    }

    startSubs()
  }, [isDataStoreReady])

  useEffect(() => {
    return () => {
      logger.PWALogger.debug('Unsubscribing from worker subscriptions');
      workerSubs.current.forEach(sub => sub.unsubscribe());
    }
  }, [])

  return isReady
}

/**
 * We only need to derive the status for Cached on the DocumentORM ONCE whenever we go offline
 * Setting the entries here will trigger a re-render to the Document selector
 */
export const useOfflineManifestSubscription = (): void => {
  const { isOnline, isOfflineEnabled } = useAppSettings()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOnline === false && isOfflineEnabled) {
      const updateManifests = async () => {
        logger.offline.contentCache.manifest.debug('Updating offline manifests');
        const cacheDB = new CacheDB()
        await cacheDB.open()

        const cacheManifest = await cacheDB.getCacheManifest()
        dispatch(cacheActions.setManifestEntries(cacheManifest))

        await cacheDB.close()
        logger.offline.contentCache.manifest.debug('Offline manifests updated');
      }

      updateManifests()
    }
  }, [isOnline, isOfflineEnabled])
}
